<template>
  <div>
    <b-overlay :show="saving">
      <b-card
        footer-class="text-muted"
        body-class="p-0"
      >
        <b-tabs
          v-model="tabIndex"
          pills
          nav-class="mb-3 pt-2 pr-2 pl-2"
          small
          justified
        >
          <b-tab button-id="businesPlan">
            <div slot="title">
              <feather-icon
                class="mr-1"
                icon="BoxIcon"
              /> {{ $t('businesPlanForm.tabs.dashboard') }}
            </div>
            <dashboard />
          </b-tab>
          <b-tab button-id="premisses">
            <div slot="title">
              <feather-icon
                class="mr-1"
                icon="AnchorIcon"
              /> {{ $t('businesPlanForm.tabs.incomeAndExpenses') }}
            </div>
            <incoming-and-expenses />
          </b-tab>
          <b-tab button-id="personAndMedicalCost">
            <div slot="title">
              <feather-icon
                class="mr-1"
                icon="DollarSignIcon"
              /> {{ $t('businesPlanForm.tabs.businessPlan') }}
            </div>
            <business-plan />
          </b-tab>
          <!--<b-tab button-id="ticket">
            <div slot="title">
              <feather-icon
                class="mr-1"
                icon="AlertCircleIcon"
              /> {{ $t('businesPlanForm.tabs.analysis') }}
            </div>
            <analysis />
          </b-tab>-->
        </b-tabs>
      </b-card>
    </b-overlay>
  </div>
</template>

<script>
import {
  BTabs, BTab, BCard, BOverlay,
} from 'bootstrap-vue'
import Dashboard from '@/views/components/BusinesPlanForm/Dashboard.vue'
import IncomingAndExpenses from '@/views/components/BusinesPlanForm/IncomingAndExpenses.vue'
// import Analysis from '@/views/components/BusinesPlanForm/Analysis.vue'
import BusinessPlan from '@/views/components/BusinesPlanForm/BusinessPlan.vue'

export default {
  name: 'BusinesPlanForm',

  components: {
    BTabs,
    BTab,
    BCard,
    BOverlay,
    Dashboard,
    BusinessPlan,
    IncomingAndExpenses,
    // Analysis,
  },
  data() {
    return {
      saving: false,
      tabIndex: 0,
    }
  },
}
</script>

<style>
</style>

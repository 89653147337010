<template>
  <div>
    <b-overlay :show="saving">
      <b-card
        footer-class="text-muted"
        body-class="p-0"
      >
        <b-tabs
          v-model="tabIndex"
          pills
          nav-class="mb-3 pt-2 pr-2 pl-2"
          small
          justified
        >
          <b-tab button-id="businesPlan">
            <div slot="title">
              {{ $t('BusinessPlan.tabs.dre') }}
            </div>
            <div class="mx-2">
              <h4>Demonstrativo de Resultados do Exercício</h4>
              <h6>Receita Bruta</h6>
              <b-table
                striped
                hover
                responsive
                class="position-relative"
                :items="receitaBrutaItems.concat(custoItems)"
                :fields="fields"
              />
              <h6>Lucro Bruto</h6>
              <b-table
                striped
                hover
                responsive
                class="position-relative"
                :items="lucroBrutoItems"
                :fields="fields"
              />
              <h6>EBITDA</h6>
              <b-table
                striped
                hover
                responsive
                class="position-relative"
                :items="ebitdaItems"
                :fields="fields"
              />
              <h6>EBT</h6>
              <b-table
                striped
                hover
                responsive
                class="position-relative"
                :items="ebtItems"
                :fields="fields"
              />
              <h6>Lucro líquido</h6>
              <b-table
                striped
                hover
                responsive
                class="position-relative"
                :items="lucroLiquidoItems"
                :fields="fields"
              />
            </div>
          </b-tab>
          <b-tab button-id="premisses">
            <div slot="title">
              {{ $t('BusinessPlan.tabs.moneyFlow') }}
            </div>
            <div class="mx-2">
              <h4>Fluxo de caixa</h4>
              <h6>Caixa inicial</h6>
              <b-table
                striped
                hover
                responsive
                class="position-relative"
                :items="caixaInicialItems"
                :fields="fields"
              />
              <h6>fc Operacional</h6>
              <b-table
                striped
                hover
                responsive
                class="position-relative"
                :items="fcOperacionalItems"
                :fields="fields"
              />
              <h6>fc Investimentos</h6>
              <b-table
                striped
                hover
                responsive
                class="position-relative"
                :items="fcInvestimentosItems"
                :fields="fields"
              />
              <h6>fc Financiamentos</h6>
              <b-table
                striped
                hover
                responsive
                class="position-relative"
                :items="fcFinanciamentosItems"
                :fields="fields"
              />
              <h6>Caixa Final</h6>
              <b-table
                striped
                hover
                responsive
                class="position-relative"
                :items="fcCaixaFinalItems"
                :fields="fields"
              />
              <h6>FCF</h6>
              <b-table
                striped
                hover
                responsive
                class="position-relative"
                :items="fcfAcumuladoItems"
                :fields="fields"
              />
              <h6>FC DO PROJETO</h6>
              <b-table
                striped
                hover
                responsive
                class="position-relative"
                :items="fcProjetoItems"
                :fields="fields"
              />
            </div>
          </b-tab>
          <b-tab button-id="personAndMedicalCost">
            <div slot="title">
              {{ $t('BusinessPlan.tabs.balance') }}
            </div>
            <div class="mx-2">
              <h4>Balanço Patrimonial</h4>
              <h6>Ativo</h6>
              <b-table
                striped
                hover
                responsive
                class="position-relative"
                :items="bpAtivoItems"
                :fields="fields"
              />
              <h6>Passivo</h6>
              <b-table
                striped
                hover
                responsive
                class="position-relative"
                :items="bpPassivoItems"
                :fields="fields"
              />
              <h6>Patrimônio Líquido</h6>
              <b-table
                striped
                hover
                responsive
                class="position-relative"
                :items="bpPatrimonioItems"
                :fields="fields"
              />
            </div>
          </b-tab>
          <b-tab button-id="ticket">
            <div slot="title">
              {{ $t('BusinessPlan.tabs.premisses') }}
            </div>
            <h4 class="mx-2">
              Premissas e composições
            </h4>
            <div class="mx-2 my-2">
              <h6>Premissas</h6>
              <b-table
                striped
                hover
                responsive
                class="position-relative"
                :items="premissasFinanceirasItems"
                :fields="fields"
              />
            </div>
            <div class="mx-2 my-2">
              <h5>Composições</h5>
              <h6>Receita bruta</h6>
              <b-table
                striped
                hover
                responsive
                class="position-relative"
                :items="composicoesReceitasBrutasItems"
                :fields="fields"
              />
              <h6>Capex</h6>
              <b-table
                striped
                hover
                responsive
                class="position-relative"
                :items="capexItems"
                :fields="fields"
              />
              <h6>Manutenção</h6>
              <b-table
                striped
                hover
                responsive
                class="position-relative"
                :items="manutencaoItems"
                :fields="fields"
              />
              <h6>Opex</h6>
              <b-table
                striped
                hover
                responsive
                class="position-relative"
                :items="opexItems"
                :fields="fields"
              />
              <h6>Depreciação</h6>
              <b-table
                striped
                hover
                responsive
                class="position-relative"
                :items="depreciacaoItems"
                :fields="fields"
              />
              <h5>Impostos</h5>
              <h6>Impostos + PCLD</h6>
              <b-table
                striped
                hover
                responsive
                class="position-relative"
                :items="pcldItems"
                :fields="fields"
              />
              <h6>Impostos sobre lucro</h6>
              <b-table
                striped
                hover
                responsive
                class="position-relative"
                :items="impostoSobreLucroItems"
                :fields="fields"
              />
              <h6>Presumido</h6>
              <b-table
                striped
                hover
                responsive
                class="position-relative"
                :items="presumidoItems.concat(presumidoAliquotaEfetivaItems)"
                :fields="fields"
              />
              <h6>Capital de giro</h6>
              <b-table
                striped
                hover
                responsive
                class="position-relative"
                :items="capitalDeGiroItems"
                :fields="fields"
              />
              <h6>Caixa mínimo e dividendos</h6>
              <b-table
                striped
                hover
                responsive
                class="position-relative"
                :items="caixaMinimoDividendoItems"
                :fields="fields"
              />
              <h6>Dívida</h6>
              <b-table
                striped
                hover
                responsive
                class="position-relative"
                :items="dividaItems"
                :fields="fields"
              />
              <h5>Análises de viabilidade</h5>
              <h6>Valuation</h6>
              <b-table
                striped
                hover
                responsive
                class="position-relative"
                :items="valuationItems"
                :fields="fields"
              />
            </div>
          </b-tab>
        </b-tabs>
      </b-card>
    </b-overlay>
  </div>
</template>

<script>
import { irr } from 'node-irr'
import { mapGetters } from 'vuex'
import {
  BTable, BTabs, BTab, BCard, BOverlay,
} from 'bootstrap-vue'

export default {
  name: 'BusinessPlan',

  components: {
    BTable,
    BTabs,
    BTab,
    BCard,
    BOverlay,
  },
  data() {
    return {
      saving: false,
      tabIndex: 0,
      custoItems: [
        {
          type: '(-) Custos médicos',
          ano0: 0,
          ano1: 0,
          ano2: 0,
          ano3: 0,
          ano4: 0,
          ano5: 0,
          ano6: 0,
          ano7: 0,
          ano8: 0,
          ano9: 0,
          ano10: 0,
          ano11: 0,
          ano12: 0,
          ano13: 0,
          ano14: 0,
          ano15: 0,
          ano16: 0,
          ano17: 0,
          ano18: 0,
          ano19: 0,
          ano20: 0,
        },
        {
          type: '(-) Médicos fixos',
          ano0: 0,
          ano1: 0,
          ano2: 0,
          ano3: 0,
          ano4: 0,
          ano5: 0,
          ano6: 0,
          ano7: 0,
          ano8: 0,
          ano9: 0,
          ano10: 0,
          ano11: 0,
          ano12: 0,
          ano13: 0,
          ano14: 0,
          ano15: 0,
          ano16: 0,
          ano17: 0,
          ano18: 0,
          ano19: 0,
          ano20: 0,
        },
        {
          type: '(-) Médicos (% receita)',
          ano0: 0,
          ano1: 0,
          ano2: 0,
          ano3: 0,
          ano4: 0,
          ano5: 0,
          ano6: 0,
          ano7: 0,
          ano8: 0,
          ano9: 0,
          ano10: 0,
          ano11: 0,
          ano12: 0,
          ano13: 0,
          ano14: 0,
          ano15: 0,
          ano16: 0,
          ano17: 0,
          ano18: 0,
          ano19: 0,
          ano20: 0,
        },
        {
          type: '(-) Físicos e dosimetristas',
          ano0: 0,
          ano1: 0,
          ano2: 0,
          ano3: 0,
          ano4: 0,
          ano5: 0,
          ano6: 0,
          ano7: 0,
          ano8: 0,
          ano9: 0,
          ano10: 0,
          ano11: 0,
          ano12: 0,
          ano13: 0,
          ano14: 0,
          ano15: 0,
          ano16: 0,
          ano17: 0,
          ano18: 0,
          ano19: 0,
          ano20: 0,
        },
      ],
    }
  },
  computed: {
    ...mapGetters('businessPlan', ['dashboard', 'staff', 'incomings', 'expenses']),
    receitaBrutaItems() {
      if (this.dashboard && this.dashboard.project && this.dashboard.project.startYear) {
        const result = [
          {
            type: '',
            ano0: this.dashboard.project.startYear + 0,
            ano1: this.dashboard.project.startYear + 1,
            ano2: this.dashboard.project.startYear + 2,
            ano3: this.dashboard.project.startYear + 3,
            ano4: this.dashboard.project.startYear + 4,
            ano5: this.dashboard.project.startYear + 5,
            ano6: this.dashboard.project.startYear + 6,
            ano7: this.dashboard.project.startYear + 7,
            ano8: this.dashboard.project.startYear + 8,
            ano9: this.dashboard.project.startYear + 9,
            ano10: this.dashboard.project.startYear + 10,
            ano11: this.dashboard.project.startYear + 11,
            ano12: this.dashboard.project.startYear + 12,
            ano13: this.dashboard.project.startYear + 13,
            ano14: this.dashboard.project.startYear + 14,
            ano15: this.dashboard.project.startYear + 15,
            ano16: this.dashboard.project.startYear + 16,
            ano17: this.dashboard.project.startYear + 17,
            ano18: this.dashboard.project.startYear + 18,
            ano19: this.dashboard.project.startYear + 19,
            ano20: this.dashboard.project.startYear + 20,
          },
          {
            type: 'Receita bruta',
            ano0: 0,
            ano1: 0,
            ano2: 0,
            ano3: 0,
            ano4: 0,
            ano5: 0,
            ano6: 0,
            ano7: 0,
            ano8: 0,
            ano9: 0,
            ano10: 0,
            ano11: 0,
            ano12: 0,
            ano13: 0,
            ano14: 0,
            ano15: 0,
            ano16: 0,
            ano17: 0,
            ano18: 0,
            ano19: 0,
            ano20: 0,
          },
          {
            type: '(%) Crescimento receita bruta',
            ano0: 0,
            ano1: 0,
            ano2: 0,
            ano3: 0,
            ano4: 0,
            ano5: 0,
            ano6: 0,
            ano7: 0,
            ano8: 0,
            ano9: 0,
            ano10: 0,
            ano11: 0,
            ano12: 0,
            ano13: 0,
            ano14: 0,
            ano15: 0,
            ano16: 0,
            ano17: 0,
            ano18: 0,
            ano19: 0,
            ano20: 0,
          },
          {
            type: '(-) Impostos e deduções',
            ano0: this.dashboard.project.startYear + 0,
            ano1: this.dashboard.project.startYear + 1,
            ano2: this.dashboard.project.startYear + 2,
            ano3: this.dashboard.project.startYear + 3,
            ano4: this.dashboard.project.startYear + 4,
            ano5: this.dashboard.project.startYear + 5,
            ano6: this.dashboard.project.startYear + 6,
            ano7: this.dashboard.project.startYear + 7,
            ano8: this.dashboard.project.startYear + 8,
            ano9: this.dashboard.project.startYear + 9,
            ano10: this.dashboard.project.startYear + 10,
            ano11: this.dashboard.project.startYear + 11,
            ano12: this.dashboard.project.startYear + 12,
            ano13: this.dashboard.project.startYear + 13,
            ano14: this.dashboard.project.startYear + 14,
            ano15: this.dashboard.project.startYear + 15,
            ano16: this.dashboard.project.startYear + 16,
            ano17: this.dashboard.project.startYear + 17,
            ano18: this.dashboard.project.startYear + 18,
            ano19: this.dashboard.project.startYear + 19,
            ano20: this.dashboard.project.startYear + 20,
          },
          {
            type: '(+) Receita liquida',
            ano0: this.dashboard.project.startYear + 0,
            ano1: this.dashboard.project.startYear + 1,
            ano2: this.dashboard.project.startYear + 2,
            ano3: this.dashboard.project.startYear + 3,
            ano4: this.dashboard.project.startYear + 4,
            ano5: this.dashboard.project.startYear + 5,
            ano6: this.dashboard.project.startYear + 6,
            ano7: this.dashboard.project.startYear + 7,
            ano8: this.dashboard.project.startYear + 8,
            ano9: this.dashboard.project.startYear + 9,
            ano10: this.dashboard.project.startYear + 10,
            ano11: this.dashboard.project.startYear + 11,
            ano12: this.dashboard.project.startYear + 12,
            ano13: this.dashboard.project.startYear + 13,
            ano14: this.dashboard.project.startYear + 14,
            ano15: this.dashboard.project.startYear + 15,
            ano16: this.dashboard.project.startYear + 16,
            ano17: this.dashboard.project.startYear + 17,
            ano18: this.dashboard.project.startYear + 18,
            ano19: this.dashboard.project.startYear + 19,
            ano20: this.dashboard.project.startYear + 20,
          },
        ]
        for (let i = 0; i <= 20; i += 1) {
          result[1][`ano${i}`] = this.composicoesReceitasBrutasItems[1] ? this.composicoesReceitasBrutasItems[1][`ano${i}`] : 0
          result[3][`ano${i}`] = this.pcldItems[0] ? this.pcldItems[0][`ano${i}`] : 0
          result[4][`ano${i}`] = (result[1][`ano${i}`] - result[3][`ano${i}`]).toFixed(2)
          if (i > 0) { result[2][`ano${i}`] = (((result[1][`ano${i}`] / result[1][`ano${i - 1}`]) - 1) * 100).toFixed(2) }
        }
        return result
      }
      return []
    },
    lucroBrutoItems() {
      if (this.dashboard && this.dashboard.project && this.dashboard.project.startYear && this.opexItems && this.opexItems.length && this.receitaBrutaItems && this.receitaBrutaItems.length && this.manutencaoItems && this.manutencaoItems.length) {
        const result = [
          {
            type: '',
            ano0: this.dashboard.project.startYear + 0,
            ano1: this.dashboard.project.startYear + 1,
            ano2: this.dashboard.project.startYear + 2,
            ano3: this.dashboard.project.startYear + 3,
            ano4: this.dashboard.project.startYear + 4,
            ano5: this.dashboard.project.startYear + 5,
            ano6: this.dashboard.project.startYear + 6,
            ano7: this.dashboard.project.startYear + 7,
            ano8: this.dashboard.project.startYear + 8,
            ano9: this.dashboard.project.startYear + 9,
            ano10: this.dashboard.project.startYear + 10,
            ano11: this.dashboard.project.startYear + 11,
            ano12: this.dashboard.project.startYear + 12,
            ano13: this.dashboard.project.startYear + 13,
            ano14: this.dashboard.project.startYear + 14,
            ano15: this.dashboard.project.startYear + 15,
            ano16: this.dashboard.project.startYear + 16,
            ano17: this.dashboard.project.startYear + 17,
            ano18: this.dashboard.project.startYear + 18,
            ano19: this.dashboard.project.startYear + 19,
            ano20: this.dashboard.project.startYear + 20,
          },
          {
            type: 'Lucro Bruto',
            ano0: 0,
            ano1: 0,
            ano2: 0,
            ano3: 0,
            ano4: 0,
            ano5: 0,
            ano6: 0,
            ano7: 0,
            ano8: 0,
            ano9: 0,
            ano10: 0,
            ano11: 0,
            ano12: 0,
            ano13: 0,
            ano14: 0,
            ano15: 0,
            ano16: 0,
            ano17: 0,
            ano18: 0,
            ano19: 0,
            ano20: 0,
          },
          {
            type: '(%)Margem bruta',
            ano0: 0,
            ano1: 0,
            ano2: 0,
            ano3: 0,
            ano4: 0,
            ano5: 0,
            ano6: 0,
            ano7: 0,
            ano8: 0,
            ano9: 0,
            ano10: 0,
            ano11: 0,
            ano12: 0,
            ano13: 0,
            ano14: 0,
            ano15: 0,
            ano16: 0,
            ano17: 0,
            ano18: 0,
            ano19: 0,
            ano20: 0,
          },
          {
            type: 'Custos operacionais',
            ano0: 0,
            ano1: 0,
            ano2: 0,
            ano3: 0,
            ano4: 0,
            ano5: 0,
            ano6: 0,
            ano7: 0,
            ano8: 0,
            ano9: 0,
            ano10: 0,
            ano11: 0,
            ano12: 0,
            ano13: 0,
            ano14: 0,
            ano15: 0,
            ano16: 0,
            ano17: 0,
            ano18: 0,
            ano19: 0,
            ano20: 0,
          },
          {
            type: 'Administrativo',
            ano0: 0,
            ano1: 0,
            ano2: 0,
            ano3: 0,
            ano4: 0,
            ano5: 0,
            ano6: 0,
            ano7: 0,
            ano8: 0,
            ano9: 0,
            ano10: 0,
            ano11: 0,
            ano12: 0,
            ano13: 0,
            ano14: 0,
            ano15: 0,
            ano16: 0,
            ano17: 0,
            ano18: 0,
            ano19: 0,
            ano20: 0,
          },
          {
            type: 'Pessoal',
            ano0: 0,
            ano1: 0,
            ano2: 0,
            ano3: 0,
            ano4: 0,
            ano5: 0,
            ano6: 0,
            ano7: 0,
            ano8: 0,
            ano9: 0,
            ano10: 0,
            ano11: 0,
            ano12: 0,
            ano13: 0,
            ano14: 0,
            ano15: 0,
            ano16: 0,
            ano17: 0,
            ano18: 0,
            ano19: 0,
            ano20: 0,
          },
          {
            type: 'Manutenção',
            ano0: 0,
            ano1: 0,
            ano2: 0,
            ano3: 0,
            ano4: 0,
            ano5: 0,
            ano6: 0,
            ano7: 0,
            ano8: 0,
            ano9: 0,
            ano10: 0,
            ano11: 0,
            ano12: 0,
            ano13: 0,
            ano14: 0,
            ano15: 0,
            ano16: 0,
            ano17: 0,
            ano18: 0,
            ano19: 0,
            ano20: 0,
          },
          {
            type: 'Aluguel',
            ano0: 0,
            ano1: 0,
            ano2: 0,
            ano3: 0,
            ano4: 0,
            ano5: 0,
            ano6: 0,
            ano7: 0,
            ano8: 0,
            ano9: 0,
            ano10: 0,
            ano11: 0,
            ano12: 0,
            ano13: 0,
            ano14: 0,
            ano15: 0,
            ano16: 0,
            ano17: 0,
            ano18: 0,
            ano19: 0,
            ano20: 0,
          },
          {
            type: 'Serviços compartilhados',
            ano0: 0,
            ano1: 0,
            ano2: 0,
            ano3: 0,
            ano4: 0,
            ano5: 0,
            ano6: 0,
            ano7: 0,
            ano8: 0,
            ano9: 0,
            ano10: 0,
            ano11: 0,
            ano12: 0,
            ano13: 0,
            ano14: 0,
            ano15: 0,
            ano16: 0,
            ano17: 0,
            ano18: 0,
            ano19: 0,
            ano20: 0,
          },
        ]
        for (let i = 0; i <= 20; i += 1) {
          result[1][`ano${i}`] = (parseFloat(this.receitaBrutaItems[4][`ano${i}`]) - parseFloat(this.custoItems[0][`ano${i}`])).toFixed(2)
          result[2][`ano${i}`] = parseFloat(this.receitaBrutaItems[4][`ano${i}`]) > 0 ? ((parseFloat(result[1][`ano${i}`]) / parseFloat(this.receitaBrutaItems[4][`ano${i}`])) * 100).toFixed(2) : 0
          result[4][`ano${i}`] = parseFloat(this.opexItems[6][`ano${i}`])
          result[5][`ano${i}`] = parseFloat(this.opexItems[7][`ano${i}`])
          result[6][`ano${i}`] = parseFloat(this.manutencaoItems[0][`ano${i}`])
          result[7][`ano${i}`] = parseFloat(this.opexItems[3][`ano${i}`])
          result[8][`ano${i}`] = parseFloat(this.opexItems[4][`ano${i}`])
          result[3][`ano${i}`] = result[4][`ano${i}`] + result[5][`ano${i}`] + result[6][`ano${i}`] + result[7][`ano${i}`] + result[8][`ano${i}`]
        }
        return result
      }
      return []
    },
    ebitdaItems() {
      if (this.dashboard && this.dashboard.project && this.dashboard.project.startYear && this.lucroBrutoItems && this.lucroBrutoItems.length && this.depreciacaoItems && this.depreciacaoItems.length) {
        const p = [
          {
            type: 'EBITDA',
            ano0: 0,
            ano1: 0,
            ano2: 0,
            ano3: 0,
            ano4: 0,
            ano5: 0,
            ano6: 0,
            ano7: 0,
            ano8: 0,
            ano9: 0,
            ano10: 0,
            ano11: 0,
            ano12: 0,
            ano13: 0,
            ano14: 0,
            ano15: 0,
            ano16: 0,
            ano17: 0,
            ano18: 0,
            ano19: 0,
            ano20: 0,
          },
          {
            type: '% Margem EBITDA',
            ano0: 0,
            ano1: 0,
            ano2: 0,
            ano3: 0,
            ano4: 0,
            ano5: 0,
            ano6: 0,
            ano7: 0,
            ano8: 0,
            ano9: 0,
            ano10: 0,
            ano11: 0,
            ano12: 0,
            ano13: 0,
            ano14: 0,
            ano15: 0,
            ano16: 0,
            ano17: 0,
            ano18: 0,
            ano19: 0,
            ano20: 0,
          },
          {
            type: 'Custos não operacionais',
            ano0: 0,
            ano1: 0,
            ano2: 0,
            ano3: 0,
            ano4: 0,
            ano5: 0,
            ano6: 0,
            ano7: 0,
            ano8: 0,
            ano9: 0,
            ano10: 0,
            ano11: 0,
            ano12: 0,
            ano13: 0,
            ano14: 0,
            ano15: 0,
            ano16: 0,
            ano17: 0,
            ano18: 0,
            ano19: 0,
            ano20: 0,
          },
          {
            type: 'Depreciação',
            ano0: 0,
            ano1: 0,
            ano2: 0,
            ano3: 0,
            ano4: 0,
            ano5: 0,
            ano6: 0,
            ano7: 0,
            ano8: 0,
            ano9: 0,
            ano10: 0,
            ano11: 0,
            ano12: 0,
            ano13: 0,
            ano14: 0,
            ano15: 0,
            ano16: 0,
            ano17: 0,
            ano18: 0,
            ano19: 0,
            ano20: 0,
          },
        ]
        for (let i = 0; i <= 20; i += 1) {
          p[0][`ano${i}`] = (parseFloat(this.lucroBrutoItems[1][`ano${i}`]) - parseFloat(this.lucroBrutoItems[3][`ano${i}`])).toFixed(2)
          p[1][`ano${i}`] = ((parseFloat(p[0][`ano${i}`]) / parseFloat(this.receitaBrutaItems[4][`ano${i}`])) * 100).toFixed(2)
          p[3][`ano${i}`] = parseFloat(this.depreciacaoItems[0][`ano${i}`])
          p[2][`ano${i}`] = parseFloat(p[3][`ano${i}`])
        }
        return p
      }
      return []
    },
    ebtItems() {
      if (this.dashboard && this.dashboard.project && this.dashboard.project.startYear && this.lucroBrutoItems && this.lucroBrutoItems.length && this.ebitdaItems && this.ebitdaItems.length) {
        const p = [
          {
            type: 'EBT',
            ano0: 0,
            ano1: 0,
            ano2: 0,
            ano3: 0,
            ano4: 0,
            ano5: 0,
            ano6: 0,
            ano7: 0,
            ano8: 0,
            ano9: 0,
            ano10: 0,
            ano11: 0,
            ano12: 0,
            ano13: 0,
            ano14: 0,
            ano15: 0,
            ano16: 0,
            ano17: 0,
            ano18: 0,
            ano19: 0,
            ano20: 0,
          },
          {
            type: '% Margem EBIT',
            ano0: 0,
            ano1: 0,
            ano2: 0,
            ano3: 0,
            ano4: 0,
            ano5: 0,
            ano6: 0,
            ano7: 0,
            ano8: 0,
            ano9: 0,
            ano10: 0,
            ano11: 0,
            ano12: 0,
            ano13: 0,
            ano14: 0,
            ano15: 0,
            ano16: 0,
            ano17: 0,
            ano18: 0,
            ano19: 0,
            ano20: 0,
          },
          {
            type: '% Margem EBT',
            ano0: 0,
            ano1: 0,
            ano2: 0,
            ano3: 0,
            ano4: 0,
            ano5: 0,
            ano6: 0,
            ano7: 0,
            ano8: 0,
            ano9: 0,
            ano10: 0,
            ano11: 0,
            ano12: 0,
            ano13: 0,
            ano14: 0,
            ano15: 0,
            ano16: 0,
            ano17: 0,
            ano18: 0,
            ano19: 0,
            ano20: 0,
          },
          {
            type: '(-) IR/CSLL',
            ano0: 0,
            ano1: 0,
            ano2: 0,
            ano3: 0,
            ano4: 0,
            ano5: 0,
            ano6: 0,
            ano7: 0,
            ano8: 0,
            ano9: 0,
            ano10: 0,
            ano11: 0,
            ano12: 0,
            ano13: 0,
            ano14: 0,
            ano15: 0,
            ano16: 0,
            ano17: 0,
            ano18: 0,
            ano19: 0,
            ano20: 0,
          },
        ]
        for (let i = 0; i <= 20; i += 1) {
          p[0][`ano${i}`] = (parseFloat(this.ebitdaItems[0][`ano${i}`]) - parseFloat(this.ebitdaItems[2][`ano${i}`])).toFixed(2)
          if (i > 0) {
            p[1][`ano${i}`] = ((this.receitaBrutaItems[4][`ano${i}`] !== 0 ? parseFloat(p[0][`ano${i}`]) / this.receitaBrutaItems[4][`ano${i}`] : 0) * 100).toFixed(2)
          }
          p[2][`ano${i}`] = ((this.receitaBrutaItems[4][`ano${i}`] !== 0 ? parseFloat(p[0][`ano${i}`]) / this.receitaBrutaItems[4][`ano${i}`] : 0) * 100).toFixed(2)
          if (this.impostoSobreLucroItems && this.impostoSobreLucroItems.length) {
            p[3][`ano${i}`] = parseFloat(this.impostoSobreLucroItems[0][`ano${i}`])
          }
        }
        return p
      }
      return []
    },
    lucroLiquidoItems() {
      if (this.dashboard && this.dashboard.project && this.dashboard.project.startYear && this.ebtItems && this.ebtItems.length && this.receitaBrutaItems && this.receitaBrutaItems.length) {
        const p = [
          {
            type: 'Lucro Líquido',
            ano0: 0,
            ano1: 0,
            ano2: 0,
            ano3: 0,
            ano4: 0,
            ano5: 0,
            ano6: 0,
            ano7: 0,
            ano8: 0,
            ano9: 0,
            ano10: 0,
            ano11: 0,
            ano12: 0,
            ano13: 0,
            ano14: 0,
            ano15: 0,
            ano16: 0,
            ano17: 0,
            ano18: 0,
            ano19: 0,
            ano20: 0,
          },
          {
            type: '% Margem Líquida',
            ano0: 0,
            ano1: 0,
            ano2: 0,
            ano3: 0,
            ano4: 0,
            ano5: 0,
            ano6: 0,
            ano7: 0,
            ano8: 0,
            ano9: 0,
            ano10: 0,
            ano11: 0,
            ano12: 0,
            ano13: 0,
            ano14: 0,
            ano15: 0,
            ano16: 0,
            ano17: 0,
            ano18: 0,
            ano19: 0,
            ano20: 0,
          },
        ]
        for (let i = 0; i <= 20; i += 1) {
          p[0][`ano${i}`] = (parseFloat(this.ebtItems[0][`ano${i}`]) + parseFloat(this.ebtItems[3][`ano${i}`])).toFixed(2)
          p[1][`ano${i}`] = 0
          if (parseFloat(this.receitaBrutaItems[4][`ano${i}`])) {
            p[1][`ano${i}`] = ((parseFloat(p[0][`ano${i}`]) / parseFloat(this.receitaBrutaItems[4][`ano${i}`])) * 100).toFixed(2)
          }
        }
        return p
      }
      return []
    },
    premissasFinanceirasItems() {
      if (this.expenses && this.dashboard && this.dashboard.project && this.dashboard.project.startYear) {
        const p = [
          {
            type: 'Financeiras',
            ano0: this.dashboard.project.startYear + 0,
            ano1: this.dashboard.project.startYear + 1,
            ano2: this.dashboard.project.startYear + 2,
            ano3: this.dashboard.project.startYear + 3,
            ano4: this.dashboard.project.startYear + 4,
            ano5: this.dashboard.project.startYear + 5,
            ano6: this.dashboard.project.startYear + 6,
            ano7: this.dashboard.project.startYear + 7,
            ano8: this.dashboard.project.startYear + 8,
            ano9: this.dashboard.project.startYear + 9,
            ano10: this.dashboard.project.startYear + 10,
            ano11: this.dashboard.project.startYear + 11,
            ano12: this.dashboard.project.startYear + 12,
            ano13: this.dashboard.project.startYear + 13,
            ano14: this.dashboard.project.startYear + 14,
            ano15: this.dashboard.project.startYear + 15,
            ano16: this.dashboard.project.startYear + 16,
            ano17: this.dashboard.project.startYear + 17,
            ano18: this.dashboard.project.startYear + 18,
            ano19: this.dashboard.project.startYear + 19,
            ano20: this.dashboard.project.startYear + 20,
          },
          {
            type: 'CDI',
            ano0: this.expenses.indices.cdi,
            ano1: 0,
            ano2: 0,
            ano3: 0,
            ano4: 0,
            ano5: 0,
            ano6: 0,
            ano7: 0,
            ano8: 0,
            ano9: 0,
            ano10: 0,
            ano11: 0,
            ano12: 0,
            ano13: 0,
            ano14: 0,
            ano15: 0,
            ano16: 0,
            ano17: 0,
            ano18: 0,
            ano19: 0,
            ano20: 0,
          },
          {
            type: 'BRL/USD',
            ano0: this.expenses.indices.usdToBrl,
            ano1: 0,
            ano2: 0,
            ano3: 0,
            ano4: 0,
            ano5: 0,
            ano6: 0,
            ano7: 0,
            ano8: 0,
            ano9: 0,
            ano10: 0,
            ano11: 0,
            ano12: 0,
            ano13: 0,
            ano14: 0,
            ano15: 0,
            ano16: 0,
            ano17: 0,
            ano18: 0,
            ano19: 0,
            ano20: 0,
          },
          {
            type: 'US CPI',
            ano0: this.expenses.indices.usCpi,
            ano1: 0,
            ano2: 0,
            ano3: 0,
            ano4: 0,
            ano5: 0,
            ano6: 0,
            ano7: 0,
            ano8: 0,
            ano9: 0,
            ano10: 0,
            ano11: 0,
            ano12: 0,
            ano13: 0,
            ano14: 0,
            ano15: 0,
            ano16: 0,
            ano17: 0,
            ano18: 0,
            ano19: 0,
            ano20: 0,
          },
          {
            type: 'Indice CPI',
            ano0: 1,
            ano1: 0,
            ano2: 0,
            ano3: 0,
            ano4: 0,
            ano5: 0,
            ano6: 0,
            ano7: 0,
            ano8: 0,
            ano9: 0,
            ano10: 0,
            ano11: 0,
            ano12: 0,
            ano13: 0,
            ano14: 0,
            ano15: 0,
            ano16: 0,
            ano17: 0,
            ano18: 0,
            ano19: 0,
            ano20: 0,
          },
          {
            type: 'IPCA',
            ano0: this.expenses.indices.ipca,
            ano1: 0,
            ano2: 0,
            ano3: 0,
            ano4: 0,
            ano5: 0,
            ano6: 0,
            ano7: 0,
            ano8: 0,
            ano9: 0,
            ano10: 0,
            ano11: 0,
            ano12: 0,
            ano13: 0,
            ano14: 0,
            ano15: 0,
            ano16: 0,
            ano17: 0,
            ano18: 0,
            ano19: 0,
            ano20: 0,
          },
          {
            type: 'Indice IPCA',
            ano0: 1,
            ano1: 0,
            ano2: 0,
            ano3: 0,
            ano4: 0,
            ano5: 0,
            ano6: 0,
            ano7: 0,
            ano8: 0,
            ano9: 0,
            ano10: 0,
            ano11: 0,
            ano12: 0,
            ano13: 0,
            ano14: 0,
            ano15: 0,
            ano16: 0,
            ano17: 0,
            ano18: 0,
            ano19: 0,
            ano20: 0,
          },
          {
            type: 'Indice IPCA (ticket proton)',
            ano0: 1,
            ano1: 0,
            ano2: 0,
            ano3: 0,
            ano4: 0,
            ano5: 0,
            ano6: 0,
            ano7: 0,
            ano8: 0,
            ano9: 0,
            ano10: 0,
            ano11: 0,
            ano12: 0,
            ano13: 0,
            ano14: 0,
            ano15: 0,
            ano16: 0,
            ano17: 0,
            ano18: 0,
            ano19: 0,
            ano20: 0,
          },
        ]
        for (let i = 1; i <= 20; i += 1) {
          p[1][`ano${i}`] = parseFloat(p[1][`ano${i - 1}`]) >= 9 ? parseFloat(p[1][`ano${i - 1}`]) : (parseFloat(p[1][`ano${i - 1}`]) + parseFloat(this.expenses.indices.cdiPercentPerYear))
          p[2][`ano${i}`] = (parseFloat(p[2][`ano${i - 1}`]) * (1 + ((parseFloat(this.expenses.indices.usdToBrlPercentPerYear)) / 100)))
          p[3][`ano${i}`] = (parseFloat(p[3][`ano${i - 1}`]) * (1 + ((parseFloat(this.expenses.indices.usCpiPercentPerYear)) / 100)))
          p[4][`ano${i}`] = (1 * (1 + ((parseFloat(p[3][`ano${i}`])) / 100)))
          p[5][`ano${i}`] = parseFloat(p[5][`ano${i - 1}`]) >= 9 ? parseFloat(p[5][`ano${i - 1}`]) : (parseFloat(p[5][`ano${i - 1}`]) * (1 + ((parseFloat(this.expenses.indices.ipcaPercentPerYear)) / 100)))
          p[6][`ano${i}`] = (1 * (1 + ((parseFloat(p[5][`ano${i}`])) / 100)))
          const ipcaProtonPercent = (parseFloat(this.expenses.indices.ipcaProton) / 100) * parseFloat(p[5][`ano${i}`])
          p[7][`ano${i}`] = (1 * (1 + ((ipcaProtonPercent) / 100)))
        }
        for (let i = 1; i <= 20; i += 1) {
          p[1][`ano${i}`] = p[1][`ano${i}`].toFixed(2)
          p[2][`ano${i}`] = p[2][`ano${i}`].toFixed(2)
          p[3][`ano${i}`] = p[3][`ano${i}`].toFixed(2)
          p[4][`ano${i}`] = p[4][`ano${i}`].toFixed(3)
          p[5][`ano${i}`] = p[5][`ano${i}`].toFixed(2)
          p[6][`ano${i}`] = p[6][`ano${i}`].toFixed(4)
          p[7][`ano${i}`] = p[7][`ano${i}`].toFixed(4)
        }
        return p
      }
      return []
    },
    composicoesReceitasBrutasItems() {
      if (this.premissasFinanceirasItems && this.premissasFinanceirasItems.length && this.dashboard && this.dashboard.project && this.dashboard.project.startYear) {
        const p = [
          {
            type: 'Receita bruta',
            ano0: this.dashboard.project.startYear + 0,
            ano1: this.dashboard.project.startYear + 1,
            ano2: this.dashboard.project.startYear + 2,
            ano3: this.dashboard.project.startYear + 3,
            ano4: this.dashboard.project.startYear + 4,
            ano5: this.dashboard.project.startYear + 5,
            ano6: this.dashboard.project.startYear + 6,
            ano7: this.dashboard.project.startYear + 7,
            ano8: this.dashboard.project.startYear + 8,
            ano9: this.dashboard.project.startYear + 9,
            ano10: this.dashboard.project.startYear + 10,
            ano11: this.dashboard.project.startYear + 11,
            ano12: this.dashboard.project.startYear + 12,
            ano13: this.dashboard.project.startYear + 13,
            ano14: this.dashboard.project.startYear + 14,
            ano15: this.dashboard.project.startYear + 15,
            ano16: this.dashboard.project.startYear + 16,
            ano17: this.dashboard.project.startYear + 17,
            ano18: this.dashboard.project.startYear + 18,
            ano19: this.dashboard.project.startYear + 19,
            ano20: this.dashboard.project.startYear + 20,
          },
          {
            type: 'Receita bruta total',
            ano0: this.dashboard.project.startYear + 0,
            ano1: this.dashboard.project.startYear + 1,
            ano2: this.dashboard.project.startYear + 2,
            ano3: this.dashboard.project.startYear + 3,
            ano4: this.dashboard.project.startYear + 4,
            ano5: this.dashboard.project.startYear + 5,
            ano6: this.dashboard.project.startYear + 6,
            ano7: this.dashboard.project.startYear + 7,
            ano8: this.dashboard.project.startYear + 8,
            ano9: this.dashboard.project.startYear + 9,
            ano10: this.dashboard.project.startYear + 10,
            ano11: this.dashboard.project.startYear + 11,
            ano12: this.dashboard.project.startYear + 12,
            ano13: this.dashboard.project.startYear + 13,
            ano14: this.dashboard.project.startYear + 14,
            ano15: this.dashboard.project.startYear + 15,
            ano16: this.dashboard.project.startYear + 16,
            ano17: this.dashboard.project.startYear + 17,
            ano18: this.dashboard.project.startYear + 18,
            ano19: this.dashboard.project.startYear + 19,
            ano20: this.dashboard.project.startYear + 20,
          },
          {
            type: 'Taxa de ocupação proton (%)',
            ano0: this.dashboard.capex.proton.pacients ? this.incomings.taxaOcupacaoList.proton.ano0 : 0,
            ano1: this.dashboard.capex.proton.pacients ? this.incomings.taxaOcupacaoList.proton.ano1 : 0,
            ano2: this.dashboard.capex.proton.pacients ? this.incomings.taxaOcupacaoList.proton.ano2 : 0,
            ano3: this.dashboard.capex.proton.pacients ? this.incomings.taxaOcupacaoList.proton.ano3 : 0,
            ano4: this.dashboard.capex.proton.pacients ? this.incomings.taxaOcupacaoList.proton.ano4 : 0,
            ano5: this.dashboard.capex.proton.pacients ? this.incomings.taxaOcupacaoList.proton.ano5 : 0,
            ano6: this.dashboard.capex.proton.pacients ? this.incomings.taxaOcupacaoList.proton.ano6 : 0,
            ano7: this.dashboard.capex.proton.pacients ? this.incomings.taxaOcupacaoList.proton.ano7 : 0,
            ano8: this.dashboard.capex.proton.pacients ? this.incomings.taxaOcupacaoList.proton.ano8 : 0,
            ano9: this.dashboard.capex.proton.pacients ? this.incomings.taxaOcupacaoList.proton.ano9 : 0,
            ano10: this.dashboard.capex.proton.pacients ? this.incomings.taxaOcupacaoList.proton.ano10 : 0,
            ano11: this.dashboard.capex.proton.pacients ? this.incomings.taxaOcupacaoList.proton.ano11 : 0,
            ano12: this.dashboard.capex.proton.pacients ? this.incomings.taxaOcupacaoList.proton.ano12 : 0,
            ano13: this.dashboard.capex.proton.pacients ? this.incomings.taxaOcupacaoList.proton.ano13 : 0,
            ano14: this.dashboard.capex.proton.pacients ? this.incomings.taxaOcupacaoList.proton.ano14 : 0,
            ano15: this.dashboard.capex.proton.pacients ? this.incomings.taxaOcupacaoList.proton.ano15 : 0,
            ano16: this.dashboard.capex.proton.pacients ? this.incomings.taxaOcupacaoList.proton.ano16 : 0,
            ano17: this.dashboard.capex.proton.pacients ? this.incomings.taxaOcupacaoList.proton.ano17 : 0,
            ano18: this.dashboard.capex.proton.pacients ? this.incomings.taxaOcupacaoList.proton.ano18 : 0,
            ano19: this.dashboard.capex.proton.pacients ? this.incomings.taxaOcupacaoList.proton.ano19 : 0,
            ano20: this.dashboard.capex.proton.pacients ? this.incomings.taxaOcupacaoList.proton.ano20 : 0,
          },
          {
            type: 'Taxa de ocupação trueBeam1 (%)',
            ano0: this.dashboard.capex.trueBeam1.pacients ? this.incomings.taxaOcupacaoList.trueBeam1.ano0 : 0,
            ano1: this.dashboard.capex.trueBeam1.pacients ? this.incomings.taxaOcupacaoList.trueBeam1.ano1 : 0,
            ano2: this.dashboard.capex.trueBeam1.pacients ? this.incomings.taxaOcupacaoList.trueBeam1.ano2 : 0,
            ano3: this.dashboard.capex.trueBeam1.pacients ? this.incomings.taxaOcupacaoList.trueBeam1.ano3 : 0,
            ano4: this.dashboard.capex.trueBeam1.pacients ? this.incomings.taxaOcupacaoList.trueBeam1.ano4 : 0,
            ano5: this.dashboard.capex.trueBeam1.pacients ? this.incomings.taxaOcupacaoList.trueBeam1.ano5 : 0,
            ano6: this.dashboard.capex.trueBeam1.pacients ? this.incomings.taxaOcupacaoList.trueBeam1.ano6 : 0,
            ano7: this.dashboard.capex.trueBeam1.pacients ? this.incomings.taxaOcupacaoList.trueBeam1.ano7 : 0,
            ano8: this.dashboard.capex.trueBeam1.pacients ? this.incomings.taxaOcupacaoList.trueBeam1.ano8 : 0,
            ano9: this.dashboard.capex.trueBeam1.pacients ? this.incomings.taxaOcupacaoList.trueBeam1.ano9 : 0,
            ano10: this.dashboard.capex.trueBeam1.pacients ? this.incomings.taxaOcupacaoList.trueBeam1.ano10 : 0,
            ano11: this.dashboard.capex.trueBeam1.pacients ? this.incomings.taxaOcupacaoList.trueBeam1.ano11 : 0,
            ano12: this.dashboard.capex.trueBeam1.pacients ? this.incomings.taxaOcupacaoList.trueBeam1.ano12 : 0,
            ano13: this.dashboard.capex.trueBeam1.pacients ? this.incomings.taxaOcupacaoList.trueBeam1.ano13 : 0,
            ano14: this.dashboard.capex.trueBeam1.pacients ? this.incomings.taxaOcupacaoList.trueBeam1.ano14 : 0,
            ano15: this.dashboard.capex.trueBeam1.pacients ? this.incomings.taxaOcupacaoList.trueBeam1.ano15 : 0,
            ano16: this.dashboard.capex.trueBeam1.pacients ? this.incomings.taxaOcupacaoList.trueBeam1.ano16 : 0,
            ano17: this.dashboard.capex.trueBeam1.pacients ? this.incomings.taxaOcupacaoList.trueBeam1.ano17 : 0,
            ano18: this.dashboard.capex.trueBeam1.pacients ? this.incomings.taxaOcupacaoList.trueBeam1.ano18 : 0,
            ano19: this.dashboard.capex.trueBeam1.pacients ? this.incomings.taxaOcupacaoList.trueBeam1.ano19 : 0,
            ano20: this.dashboard.capex.trueBeam1.pacients ? this.incomings.taxaOcupacaoList.trueBeam1.ano20 : 0,
          },
          {
            type: 'Taxa de ocupação trueBeam2 (%)',
            ano0: this.dashboard.capex.trueBeam2.pacients ? this.incomings.taxaOcupacaoList.trueBeam2.ano0 : 0,
            ano1: this.dashboard.capex.trueBeam2.pacients ? this.incomings.taxaOcupacaoList.trueBeam2.ano1 : 0,
            ano2: this.dashboard.capex.trueBeam2.pacients ? this.incomings.taxaOcupacaoList.trueBeam2.ano2 : 0,
            ano3: this.dashboard.capex.trueBeam2.pacients ? this.incomings.taxaOcupacaoList.trueBeam2.ano3 : 0,
            ano4: this.dashboard.capex.trueBeam2.pacients ? this.incomings.taxaOcupacaoList.trueBeam2.ano4 : 0,
            ano5: this.dashboard.capex.trueBeam2.pacients ? this.incomings.taxaOcupacaoList.trueBeam2.ano5 : 0,
            ano6: this.dashboard.capex.trueBeam2.pacients ? this.incomings.taxaOcupacaoList.trueBeam2.ano6 : 0,
            ano7: this.dashboard.capex.trueBeam2.pacients ? this.incomings.taxaOcupacaoList.trueBeam2.ano7 : 0,
            ano8: this.dashboard.capex.trueBeam2.pacients ? this.incomings.taxaOcupacaoList.trueBeam2.ano8 : 0,
            ano9: this.dashboard.capex.trueBeam2.pacients ? this.incomings.taxaOcupacaoList.trueBeam2.ano9 : 0,
            ano10: this.dashboard.capex.trueBeam2.pacients ? this.incomings.taxaOcupacaoList.trueBeam2.ano10 : 0,
            ano11: this.dashboard.capex.trueBeam2.pacients ? this.incomings.taxaOcupacaoList.trueBeam2.ano11 : 0,
            ano12: this.dashboard.capex.trueBeam2.pacients ? this.incomings.taxaOcupacaoList.trueBeam2.ano12 : 0,
            ano13: this.dashboard.capex.trueBeam2.pacients ? this.incomings.taxaOcupacaoList.trueBeam2.ano13 : 0,
            ano14: this.dashboard.capex.trueBeam2.pacients ? this.incomings.taxaOcupacaoList.trueBeam2.ano14 : 0,
            ano15: this.dashboard.capex.trueBeam2.pacients ? this.incomings.taxaOcupacaoList.trueBeam2.ano15 : 0,
            ano16: this.dashboard.capex.trueBeam2.pacients ? this.incomings.taxaOcupacaoList.trueBeam2.ano16 : 0,
            ano17: this.dashboard.capex.trueBeam2.pacients ? this.incomings.taxaOcupacaoList.trueBeam2.ano17 : 0,
            ano18: this.dashboard.capex.trueBeam2.pacients ? this.incomings.taxaOcupacaoList.trueBeam2.ano18 : 0,
            ano19: this.dashboard.capex.trueBeam2.pacients ? this.incomings.taxaOcupacaoList.trueBeam2.ano19 : 0,
            ano20: this.dashboard.capex.trueBeam2.pacients ? this.incomings.taxaOcupacaoList.trueBeam2.ano20 : 0,
          },
          {
            type: 'Taxa de ocupação acelerator1 (%)',
            ano0: this.dashboard.capex.acelerator1.pacients ? this.incomings.taxaOcupacaoList.acelerator1.ano0 : 0,
            ano1: this.dashboard.capex.acelerator1.pacients ? this.incomings.taxaOcupacaoList.acelerator1.ano1 : 0,
            ano2: this.dashboard.capex.acelerator1.pacients ? this.incomings.taxaOcupacaoList.acelerator1.ano2 : 0,
            ano3: this.dashboard.capex.acelerator1.pacients ? this.incomings.taxaOcupacaoList.acelerator1.ano3 : 0,
            ano4: this.dashboard.capex.acelerator1.pacients ? this.incomings.taxaOcupacaoList.acelerator1.ano4 : 0,
            ano5: this.dashboard.capex.acelerator1.pacients ? this.incomings.taxaOcupacaoList.acelerator1.ano5 : 0,
            ano6: this.dashboard.capex.acelerator1.pacients ? this.incomings.taxaOcupacaoList.acelerator1.ano6 : 0,
            ano7: this.dashboard.capex.acelerator1.pacients ? this.incomings.taxaOcupacaoList.acelerator1.ano7 : 0,
            ano8: this.dashboard.capex.acelerator1.pacients ? this.incomings.taxaOcupacaoList.acelerator1.ano8 : 0,
            ano9: this.dashboard.capex.acelerator1.pacients ? this.incomings.taxaOcupacaoList.acelerator1.ano9 : 0,
            ano10: this.dashboard.capex.acelerator1.pacients ? this.incomings.taxaOcupacaoList.acelerator1.ano10 : 0,
            ano11: this.dashboard.capex.acelerator1.pacients ? this.incomings.taxaOcupacaoList.acelerator1.ano11 : 0,
            ano12: this.dashboard.capex.acelerator1.pacients ? this.incomings.taxaOcupacaoList.acelerator1.ano12 : 0,
            ano13: this.dashboard.capex.acelerator1.pacients ? this.incomings.taxaOcupacaoList.acelerator1.ano13 : 0,
            ano14: this.dashboard.capex.acelerator1.pacients ? this.incomings.taxaOcupacaoList.acelerator1.ano14 : 0,
            ano15: this.dashboard.capex.acelerator1.pacients ? this.incomings.taxaOcupacaoList.acelerator1.ano15 : 0,
            ano16: this.dashboard.capex.acelerator1.pacients ? this.incomings.taxaOcupacaoList.acelerator1.ano16 : 0,
            ano17: this.dashboard.capex.acelerator1.pacients ? this.incomings.taxaOcupacaoList.acelerator1.ano17 : 0,
            ano18: this.dashboard.capex.acelerator1.pacients ? this.incomings.taxaOcupacaoList.acelerator1.ano18 : 0,
            ano19: this.dashboard.capex.acelerator1.pacients ? this.incomings.taxaOcupacaoList.acelerator1.ano19 : 0,
            ano20: this.dashboard.capex.acelerator1.pacients ? this.incomings.taxaOcupacaoList.acelerator1.ano20 : 0,
          },
          {
            type: 'Taxa de ocupação acelerator2 (%)',
            ano0: this.dashboard.capex.acelerator2.pacients ? this.incomings.taxaOcupacaoList.acelerator2.ano0 : 0,
            ano1: this.dashboard.capex.acelerator2.pacients ? this.incomings.taxaOcupacaoList.acelerator2.ano1 : 0,
            ano2: this.dashboard.capex.acelerator2.pacients ? this.incomings.taxaOcupacaoList.acelerator2.ano2 : 0,
            ano3: this.dashboard.capex.acelerator2.pacients ? this.incomings.taxaOcupacaoList.acelerator2.ano3 : 0,
            ano4: this.dashboard.capex.acelerator2.pacients ? this.incomings.taxaOcupacaoList.acelerator2.ano4 : 0,
            ano5: this.dashboard.capex.acelerator2.pacients ? this.incomings.taxaOcupacaoList.acelerator2.ano5 : 0,
            ano6: this.dashboard.capex.acelerator2.pacients ? this.incomings.taxaOcupacaoList.acelerator2.ano6 : 0,
            ano7: this.dashboard.capex.acelerator2.pacients ? this.incomings.taxaOcupacaoList.acelerator2.ano7 : 0,
            ano8: this.dashboard.capex.acelerator2.pacients ? this.incomings.taxaOcupacaoList.acelerator2.ano8 : 0,
            ano9: this.dashboard.capex.acelerator2.pacients ? this.incomings.taxaOcupacaoList.acelerator2.ano9 : 0,
            ano10: this.dashboard.capex.acelerator2.pacients ? this.incomings.taxaOcupacaoList.acelerator2.ano10 : 0,
            ano11: this.dashboard.capex.acelerator2.pacients ? this.incomings.taxaOcupacaoList.acelerator2.ano11 : 0,
            ano12: this.dashboard.capex.acelerator2.pacients ? this.incomings.taxaOcupacaoList.acelerator2.ano12 : 0,
            ano13: this.dashboard.capex.acelerator2.pacients ? this.incomings.taxaOcupacaoList.acelerator2.ano13 : 0,
            ano14: this.dashboard.capex.acelerator2.pacients ? this.incomings.taxaOcupacaoList.acelerator2.ano14 : 0,
            ano15: this.dashboard.capex.acelerator2.pacients ? this.incomings.taxaOcupacaoList.acelerator2.ano15 : 0,
            ano16: this.dashboard.capex.acelerator2.pacients ? this.incomings.taxaOcupacaoList.acelerator2.ano16 : 0,
            ano17: this.dashboard.capex.acelerator2.pacients ? this.incomings.taxaOcupacaoList.acelerator2.ano17 : 0,
            ano18: this.dashboard.capex.acelerator2.pacients ? this.incomings.taxaOcupacaoList.acelerator2.ano18 : 0,
            ano19: this.dashboard.capex.acelerator2.pacients ? this.incomings.taxaOcupacaoList.acelerator2.ano19 : 0,
            ano20: this.dashboard.capex.acelerator2.pacients ? this.incomings.taxaOcupacaoList.acelerator2.ano20 : 0,
          },
          {
            type: 'Proton',
            ano0: 0,
            ano1: 0,
            ano2: 0,
            ano3: 0,
            ano4: 0,
            ano5: 0,
            ano6: 0,
            ano7: 0,
            ano8: 0,
            ano9: 0,
            ano10: 0,
            ano11: 0,
            ano12: 0,
            ano13: 0,
            ano14: 0,
            ano15: 0,
            ano16: 0,
            ano17: 0,
            ano18: 0,
            ano19: 0,
            ano20: 0,
          },
          {
            type: 'trueBeam1',
            ano0: 0,
            ano1: 0,
            ano2: 0,
            ano3: 0,
            ano4: 0,
            ano5: 0,
            ano6: 0,
            ano7: 0,
            ano8: 0,
            ano9: 0,
            ano10: 0,
            ano11: 0,
            ano12: 0,
            ano13: 0,
            ano14: 0,
            ano15: 0,
            ano16: 0,
            ano17: 0,
            ano18: 0,
            ano19: 0,
            ano20: 0,
          },
          {
            type: 'trueBeam2',
            ano0: 0,
            ano1: 0,
            ano2: 0,
            ano3: 0,
            ano4: 0,
            ano5: 0,
            ano6: 0,
            ano7: 0,
            ano8: 0,
            ano9: 0,
            ano10: 0,
            ano11: 0,
            ano12: 0,
            ano13: 0,
            ano14: 0,
            ano15: 0,
            ano16: 0,
            ano17: 0,
            ano18: 0,
            ano19: 0,
            ano20: 0,
          },
          {
            type: 'acelerator1',
            ano0: 0,
            ano1: 0,
            ano2: 0,
            ano3: 0,
            ano4: 0,
            ano5: 0,
            ano6: 0,
            ano7: 0,
            ano8: 0,
            ano9: 0,
            ano10: 0,
            ano11: 0,
            ano12: 0,
            ano13: 0,
            ano14: 0,
            ano15: 0,
            ano16: 0,
            ano17: 0,
            ano18: 0,
            ano19: 0,
            ano20: 0,
          },
          {
            type: 'acelerator2',
            ano0: 0,
            ano1: 0,
            ano2: 0,
            ano3: 0,
            ano4: 0,
            ano5: 0,
            ano6: 0,
            ano7: 0,
            ano8: 0,
            ano9: 0,
            ano10: 0,
            ano11: 0,
            ano12: 0,
            ano13: 0,
            ano14: 0,
            ano15: 0,
            ano16: 0,
            ano17: 0,
            ano18: 0,
            ano19: 0,
            ano20: 0,
          },
          {
            type: 'ressonancia',
            ano0: 0,
            ano1: 0,
            ano2: 0,
            ano3: 0,
            ano4: 0,
            ano5: 0,
            ano6: 0,
            ano7: 0,
            ano8: 0,
            ano9: 0,
            ano10: 0,
            ano11: 0,
            ano12: 0,
            ano13: 0,
            ano14: 0,
            ano15: 0,
            ano16: 0,
            ano17: 0,
            ano18: 0,
            ano19: 0,
            ano20: 0,
          },
          {
            type: 'tomografia',
            ano0: 0,
            ano1: 0,
            ano2: 0,
            ano3: 0,
            ano4: 0,
            ano5: 0,
            ano6: 0,
            ano7: 0,
            ano8: 0,
            ano9: 0,
            ano10: 0,
            ano11: 0,
            ano12: 0,
            ano13: 0,
            ano14: 0,
            ano15: 0,
            ano16: 0,
            ano17: 0,
            ano18: 0,
            ano19: 0,
            ano20: 0,
          },
          {
            type: 'capacidade',
            ano0: parseFloat(this.incomings.capacidadeAnual),
            ano1: parseFloat(this.incomings.capacidadeAnual),
            ano2: parseFloat(this.incomings.capacidadeAnual),
            ano3: parseFloat(this.incomings.capacidadeAnual),
            ano4: parseFloat(this.incomings.capacidadeAnual),
            ano5: parseFloat(this.incomings.capacidadeAnual),
            ano6: parseFloat(this.incomings.capacidadeAnual),
            ano7: parseFloat(this.incomings.capacidadeAnual),
            ano8: parseFloat(this.incomings.capacidadeAnual),
            ano9: parseFloat(this.incomings.capacidadeAnual),
            ano10: parseFloat(this.incomings.capacidadeAnual),
            ano11: parseFloat(this.incomings.capacidadeAnual),
            ano12: parseFloat(this.incomings.capacidadeAnual),
            ano13: parseFloat(this.incomings.capacidadeAnual),
            ano14: parseFloat(this.incomings.capacidadeAnual),
            ano15: parseFloat(this.incomings.capacidadeAnual),
            ano16: parseFloat(this.incomings.capacidadeAnual),
            ano17: parseFloat(this.incomings.capacidadeAnual),
            ano18: parseFloat(this.incomings.capacidadeAnual),
            ano19: parseFloat(this.incomings.capacidadeAnual),
            ano20: parseFloat(this.incomings.capacidadeAnual),
          },
          {
            type: 'cap. com hipofrac.',
            ano0: parseFloat(this.incomings.capacidadeComHipofracionamento),
            ano1: parseFloat(this.incomings.capacidadeComHipofracionamento),
            ano2: parseFloat(this.incomings.capacidadeComHipofracionamento),
            ano3: parseFloat(this.incomings.capacidadeComHipofracionamento),
            ano4: parseFloat(this.incomings.capacidadeComHipofracionamento),
            ano5: parseFloat(this.incomings.capacidadeComHipofracionamento),
            ano6: parseFloat(this.incomings.capacidadeComHipofracionamento),
            ano7: parseFloat(this.incomings.capacidadeComHipofracionamento),
            ano8: parseFloat(this.incomings.capacidadeComHipofracionamento),
            ano9: parseFloat(this.incomings.capacidadeComHipofracionamento),
            ano10: parseFloat(this.incomings.capacidadeComHipofracionamento),
            ano11: parseFloat(this.incomings.capacidadeComHipofracionamento),
            ano12: parseFloat(this.incomings.capacidadeComHipofracionamento),
            ano13: parseFloat(this.incomings.capacidadeComHipofracionamento),
            ano14: parseFloat(this.incomings.capacidadeComHipofracionamento),
            ano15: parseFloat(this.incomings.capacidadeComHipofracionamento),
            ano16: parseFloat(this.incomings.capacidadeComHipofracionamento),
            ano17: parseFloat(this.incomings.capacidadeComHipofracionamento),
            ano18: parseFloat(this.incomings.capacidadeComHipofracionamento),
            ano19: parseFloat(this.incomings.capacidadeComHipofracionamento),
            ano20: parseFloat(this.incomings.capacidadeComHipofracionamento),
          },
          {
            type: 'ticket proton',
            ano0: this.dashboard.capex.proton.ticket ? (parseFloat(this.dashboard.capex.proton.ticket) * this.premissasFinanceirasItems[7].ano0).toFixed(2) : 0,
            ano1: 0,
            ano2: 0,
            ano3: 0,
            ano4: 0,
            ano5: 0,
            ano6: 0,
            ano7: 0,
            ano8: 0,
            ano9: 0,
            ano10: 0,
            ano11: 0,
            ano12: 0,
            ano13: 0,
            ano14: 0,
            ano15: 0,
            ano16: 0,
            ano17: 0,
            ano18: 0,
            ano19: 0,
            ano20: 0,
          },
          {
            type: 'ticket trueBeam1',
            ano0: this.dashboard.capex.trueBeam1.ticket ? (parseFloat(this.dashboard.capex.trueBeam1.ticket) * this.premissasFinanceirasItems[7].ano0).toFixed(2) : 0,
            ano1: 0,
            ano2: 0,
            ano3: 0,
            ano4: 0,
            ano5: 0,
            ano6: 0,
            ano7: 0,
            ano8: 0,
            ano9: 0,
            ano10: 0,
            ano11: 0,
            ano12: 0,
            ano13: 0,
            ano14: 0,
            ano15: 0,
            ano16: 0,
            ano17: 0,
            ano18: 0,
            ano19: 0,
            ano20: 0,
          },
          {
            type: 'ticket trueBeam2',
            ano0: this.dashboard.capex.trueBeam2.ticket ? (parseFloat(this.dashboard.capex.trueBeam2.ticket) * this.premissasFinanceirasItems[7].ano0).toFixed(2) : 0,
            ano1: 0,
            ano2: 0,
            ano3: 0,
            ano4: 0,
            ano5: 0,
            ano6: 0,
            ano7: 0,
            ano8: 0,
            ano9: 0,
            ano10: 0,
            ano11: 0,
            ano12: 0,
            ano13: 0,
            ano14: 0,
            ano15: 0,
            ano16: 0,
            ano17: 0,
            ano18: 0,
            ano19: 0,
            ano20: 0,
          },
          {
            type: 'ticket acelerator1',
            ano0: this.dashboard.capex.acelerator1.ticket ? (parseFloat(this.dashboard.capex.acelerator1.ticket) * this.premissasFinanceirasItems[6].ano0).toFixed(2) : 0,
            ano1: 0,
            ano2: 0,
            ano3: 0,
            ano4: 0,
            ano5: 0,
            ano6: 0,
            ano7: 0,
            ano8: 0,
            ano9: 0,
            ano10: 0,
            ano11: 0,
            ano12: 0,
            ano13: 0,
            ano14: 0,
            ano15: 0,
            ano16: 0,
            ano17: 0,
            ano18: 0,
            ano19: 0,
            ano20: 0,
          },
          {
            type: 'ticket acelerator2',
            ano0: this.dashboard.capex.acelerator2.ticket ? (parseFloat(this.dashboard.capex.acelerator2.ticket) * this.premissasFinanceirasItems[6].ano0).toFixed(2) : 0,
            ano1: 0,
            ano2: 0,
            ano3: 0,
            ano4: 0,
            ano5: 0,
            ano6: 0,
            ano7: 0,
            ano8: 0,
            ano9: 0,
            ano10: 0,
            ano11: 0,
            ano12: 0,
            ano13: 0,
            ano14: 0,
            ano15: 0,
            ano16: 0,
            ano17: 0,
            ano18: 0,
            ano19: 0,
            ano20: 0,
          },
          {
            type: 'ticket ressonancia',
            ano0: this.dashboard.capex.ressonancia.ticket ? (parseFloat(this.dashboard.capex.ressonancia.ticket) * this.premissasFinanceirasItems[6].ano0).toFixed(2) : 0,
            ano1: 0,
            ano2: 0,
            ano3: 0,
            ano4: 0,
            ano5: 0,
            ano6: 0,
            ano7: 0,
            ano8: 0,
            ano9: 0,
            ano10: 0,
            ano11: 0,
            ano12: 0,
            ano13: 0,
            ano14: 0,
            ano15: 0,
            ano16: 0,
            ano17: 0,
            ano18: 0,
            ano19: 0,
            ano20: 0,
          },
          {
            type: 'ticket tomografo',
            ano0: this.dashboard.capex.tomografo.ticket ? (parseFloat(this.dashboard.capex.tomografo.ticket) * this.premissasFinanceirasItems[6].ano0).toFixed(2) : 0,
            ano1: 0,
            ano2: 0,
            ano3: 0,
            ano4: 0,
            ano5: 0,
            ano6: 0,
            ano7: 0,
            ano8: 0,
            ano9: 0,
            ano10: 0,
            ano11: 0,
            ano12: 0,
            ano13: 0,
            ano14: 0,
            ano15: 0,
            ano16: 0,
            ano17: 0,
            ano18: 0,
            ano19: 0,
            ano20: 0,
          },
          {
            type: 'pacientes por ano',
            ano0: 0,
            ano1: 0,
            ano2: 0,
            ano3: 0,
            ano4: 0,
            ano5: 0,
            ano6: 0,
            ano7: 0,
            ano8: 0,
            ano9: 0,
            ano10: 0,
            ano11: 0,
            ano12: 0,
            ano13: 0,
            ano14: 0,
            ano15: 0,
            ano16: 0,
            ano17: 0,
            ano18: 0,
            ano19: 0,
            ano20: 0,
          },
          {
            type: 'média por mês',
            ano0: 0,
            ano1: 0,
            ano2: 0,
            ano3: 0,
            ano4: 0,
            ano5: 0,
            ano6: 0,
            ano7: 0,
            ano8: 0,
            ano9: 0,
            ano10: 0,
            ano11: 0,
            ano12: 0,
            ano13: 0,
            ano14: 0,
            ano15: 0,
            ano16: 0,
            ano17: 0,
            ano18: 0,
            ano19: 0,
            ano20: 0,
          },
          {
            type: '% crescimento',
            ano0: 0,
            ano1: 0,
            ano2: 0,
            ano3: 0,
            ano4: 0,
            ano5: 0,
            ano6: 0,
            ano7: 0,
            ano8: 0,
            ano9: 0,
            ano10: 0,
            ano11: 0,
            ano12: 0,
            ano13: 0,
            ano14: 0,
            ano15: 0,
            ano16: 0,
            ano17: 0,
            ano18: 0,
            ano19: 0,
            ano20: 0,
          },
        ]
        for (let i = 1; i <= 20; i += 1) {
          p[16][`ano${i}`] = p[16][`ano${i - 1}`] * this.premissasFinanceirasItems[7][`ano${i}`]
          p[17][`ano${i}`] = p[17][`ano${i - 1}`] * this.premissasFinanceirasItems[7][`ano${i}`]
          p[18][`ano${i}`] = p[18][`ano${i - 1}`] * this.premissasFinanceirasItems[7][`ano${i}`]
          p[19][`ano${i}`] = p[19][`ano${i - 1}`] * this.premissasFinanceirasItems[7][`ano${i}`]
          p[20][`ano${i}`] = p[20][`ano${i - 1}`] * this.premissasFinanceirasItems[6][`ano${i}`]
          p[21][`ano${i}`] = p[21][`ano${i - 1}`] * this.premissasFinanceirasItems[6][`ano${i}`]
          p[22][`ano${i}`] = p[22][`ano${i - 1}`] * this.premissasFinanceirasItems[6][`ano${i}`]
        }
        for (let i = 1; i <= 20; i += 1) {
          p[16][`ano${i}`] = Math.ceil(p[16][`ano${i}`].toFixed(2))
          p[17][`ano${i}`] = Math.ceil(p[17][`ano${i}`].toFixed(2))
          p[18][`ano${i}`] = Math.ceil(p[18][`ano${i}`].toFixed(2))
          p[19][`ano${i}`] = Math.ceil(p[19][`ano${i}`].toFixed(2))
          p[20][`ano${i}`] = Math.ceil(p[20][`ano${i}`].toFixed(2))
          p[21][`ano${i}`] = Math.ceil(p[21][`ano${i}`].toFixed(2))
          p[22][`ano${i}`] = Math.ceil(p[22][`ano${i}`].toFixed(2))
        }

        const taxaOcupacao = parseFloat(this.incomings.taxaOcupacao)
        const totalPacients = parseFloat(this.incomings.pacients)
        for (let i = 0; i <= 20; i += 1) {
          p[7][`ano${i}`] = parseFloat(this.dashboard.capex.proton.pacients) ? (p[2][`ano${i}`] * p[16][`ano${i}`] * (parseFloat(this.dashboard.capex.proton.pacients) / parseFloat(this.incomings.pacients)) * parseFloat(this.incomings.capacidadeComHipofracionamento)) / 100000 : 0
          p[8][`ano${i}`] = parseFloat(this.dashboard.capex.trueBeam1.pacients) ? (p[3][`ano${i}`] * p[17][`ano${i}`] * (parseFloat(this.dashboard.capex.trueBeam1.pacients) / parseFloat(this.incomings.pacients)) * parseFloat(this.incomings.capacidadeComHipofracionamento)) / 100000 : 0
          p[9][`ano${i}`] = parseFloat(this.dashboard.capex.trueBeam2.pacients) ? (p[4][`ano${i}`] * p[18][`ano${i}`] * (parseFloat(this.dashboard.capex.trueBeam2.pacients) / parseFloat(this.incomings.pacients)) * parseFloat(this.incomings.capacidadeComHipofracionamento)) / 100000 : 0
          p[10][`ano${i}`] = parseFloat(this.dashboard.capex.acelerator1.pacients) ? (p[5][`ano${i}`] * p[19][`ano${i}`] * (parseFloat(this.dashboard.capex.acelerator1.pacients) / parseFloat(this.incomings.pacients)) * parseFloat(this.incomings.capacidadeComHipofracionamento)) / 100000 : 0
          p[11][`ano${i}`] = parseFloat(this.dashboard.capex.acelerator2.pacients) ? (p[6][`ano${i}`] * p[20][`ano${i}`] * (parseFloat(this.dashboard.capex.acelerator2.pacients) / parseFloat(this.incomings.pacients)) * parseFloat(this.incomings.capacidadeComHipofracionamento)) / 100000 : 0

          // Calculo para ressonancia e tomografia
          const protonPacients = parseFloat(this.dashboard.capex.proton.pacients)
          const trueBeam1Pacients = parseFloat(this.dashboard.capex.trueBeam1.pacients)
          const trueBeam2Pacients = parseFloat(this.dashboard.capex.trueBeam2.pacients)
          const capacidadeComHipofracionamento = parseFloat(this.incomings.capacidadeComHipofracionamento)
          const taxaOcupacaoRessonancia = parseFloat(this.incomings.taxaOcupacaoRessonancia)
          const taxaOcupacaoTomografo = parseFloat(this.incomings.taxaOcupacaoTomografo)

          if (taxaOcupacaoRessonancia) {
            const ressonanciaProton = (p[2][`ano${i}`] < taxaOcupacao) ? (p[2][`ano${i}`] * p[21][`ano${i}`] * protonPacients * 12) : (p[2][`ano${i}`] * p[21][`ano${i}`] * (protonPacients / totalPacients) * capacidadeComHipofracionamento)
            const ressonanciaTrueBeam1 = (p[3][`ano${i}`] < taxaOcupacao) ? (p[3][`ano${i}`] * p[21][`ano${i}`] * trueBeam1Pacients * 12) : (p[3][`ano${i}`] * p[21][`ano${i}`] * (trueBeam1Pacients / totalPacients) * capacidadeComHipofracionamento)
            const ressonanciaTrueBeam2 = (p[4][`ano${i}`] < taxaOcupacao) ? (p[4][`ano${i}`] * p[21][`ano${i}`] * trueBeam2Pacients * 12) : (p[4][`ano${i}`] * p[21][`ano${i}`] * (trueBeam2Pacients / totalPacients) * capacidadeComHipofracionamento)
            p[12][`ano${i}`] = (taxaOcupacaoRessonancia * (ressonanciaProton + ressonanciaTrueBeam1 + ressonanciaTrueBeam2)) / 10000000
          } else p[12][`ano${i}`] = 0

          if (taxaOcupacaoTomografo) {
            const tomografiaProton = (p[2][`ano${i}`] < taxaOcupacao) ? (p[2][`ano${i}`] * p[22][`ano${i}`] * protonPacients * 12) : (p[2][`ano${i}`] * p[22][`ano${i}`] * (protonPacients / totalPacients) * capacidadeComHipofracionamento)
            const tomografiaTrueBeam1 = (p[3][`ano${i}`] < taxaOcupacao) ? (p[3][`ano${i}`] * p[22][`ano${i}`] * trueBeam1Pacients * 12) : (p[3][`ano${i}`] * p[22][`ano${i}`] * (trueBeam1Pacients / totalPacients) * capacidadeComHipofracionamento)
            const tomografiaTrueBeam2 = (p[4][`ano${i}`] < taxaOcupacao) ? (p[4][`ano${i}`] * p[22][`ano${i}`] * trueBeam2Pacients * 12) : (p[4][`ano${i}`] * p[22][`ano${i}`] * (trueBeam2Pacients / totalPacients) * capacidadeComHipofracionamento)
            p[13][`ano${i}`] = (taxaOcupacaoTomografo * (tomografiaProton + tomografiaTrueBeam1 + tomografiaTrueBeam2)) / 10000000
          } else p[13][`ano${i}`] = 0

          p[1][`ano${i}`] = p.filter((_, index) => index >= 7 && index <= 13)
            .map(e => e[`ano${i}`])
            .reduce((a, b) => a + b)
        }
        for (let i = 0; i <= 20; i += 1) {
          const pacienteAnoProton = (p[2][`ano${i}`] <= taxaOcupacao) ? (p[2][`ano${i}`] * parseFloat(p[14][`ano${i}`])) : (p[2][`ano${i}`] * parseFloat(p[15][`ano${i}`]))
          const pacienteAnoTrueBeam1 = (p[3][`ano${i}`] <= taxaOcupacao) ? (p[3][`ano${i}`] * parseFloat(p[14][`ano${i}`])) : (p[3][`ano${i}`] * parseFloat(p[15][`ano${i}`]))
          const pacienteAnoTrueBeam2 = (p[4][`ano${i}`] <= taxaOcupacao) ? (p[4][`ano${i}`] * parseFloat(p[14][`ano${i}`])) : (p[4][`ano${i}`] * parseFloat(p[15][`ano${i}`]))
          const pacienteAnoAcelerator1 = (p[5][`ano${i}`] <= taxaOcupacao) ? (p[5][`ano${i}`] * parseFloat(p[14][`ano${i}`])) : (p[5][`ano${i}`] * parseFloat(p[15][`ano${i}`]))
          const pacienteAnoAcelerator2 = (p[6][`ano${i}`] <= taxaOcupacao) ? (p[6][`ano${i}`] * parseFloat(p[14][`ano${i}`])) : (p[6][`ano${i}`] * parseFloat(p[15][`ano${i}`]))
          p[23][`ano${i}`] = (pacienteAnoProton + pacienteAnoTrueBeam1 + pacienteAnoTrueBeam2 + pacienteAnoAcelerator1 + pacienteAnoAcelerator2) / 100
          p[24][`ano${i}`] = p[23][`ano${i}`] / 12
        }
        for (let i = 0; i <= 20; i += 1) {
          p[7][`ano${i}`] = Math.ceil(p[7][`ano${i}`].toFixed(2))
          p[8][`ano${i}`] = Math.ceil(p[8][`ano${i}`].toFixed(2))
          p[9][`ano${i}`] = Math.ceil(p[9][`ano${i}`].toFixed(2))
          p[10][`ano${i}`] = Math.ceil(p[10][`ano${i}`].toFixed(2))
          p[11][`ano${i}`] = Math.ceil(p[11][`ano${i}`].toFixed(2))
          p[12][`ano${i}`] = Math.ceil(p[12][`ano${i}`].toFixed(2))
          p[13][`ano${i}`] = Math.ceil(p[13][`ano${i}`].toFixed(2))
          p[23][`ano${i}`] = Math.ceil(p[23][`ano${i}`].toFixed(2))
          p[24][`ano${i}`] = Math.ceil(p[24][`ano${i}`].toFixed(2))

          p[1][`ano${i}`] = Math.floor(p[1][`ano${i}`].toFixed(2))
        }
        return p
      }
      return []
    },
    capexItems() {
      if (this.premissasFinanceirasItems && this.premissasFinanceirasItems.length && this.dashboard && this.dashboard.project && this.dashboard.project.startYear && this.dashboard.capex) {
        const p = [
          {
            type: 'Capex',
            ano0: this.dashboard.project.startYear + 0,
            ano1: this.dashboard.project.startYear + 1,
            ano2: this.dashboard.project.startYear + 2,
            ano3: this.dashboard.project.startYear + 3,
            ano4: this.dashboard.project.startYear + 4,
            ano5: this.dashboard.project.startYear + 5,
            ano6: this.dashboard.project.startYear + 6,
            ano7: this.dashboard.project.startYear + 7,
            ano8: this.dashboard.project.startYear + 8,
            ano9: this.dashboard.project.startYear + 9,
            ano10: this.dashboard.project.startYear + 10,
            ano11: this.dashboard.project.startYear + 11,
            ano12: this.dashboard.project.startYear + 12,
            ano13: this.dashboard.project.startYear + 13,
            ano14: this.dashboard.project.startYear + 14,
            ano15: this.dashboard.project.startYear + 15,
            ano16: this.dashboard.project.startYear + 16,
            ano17: this.dashboard.project.startYear + 17,
            ano18: this.dashboard.project.startYear + 18,
            ano19: this.dashboard.project.startYear + 19,
            ano20: this.dashboard.project.startYear + 20,
          },
          {
            type: 'Equipamentos',
            ano0: 0,
            ano1: 0,
            ano2: 0,
            ano3: 0,
            ano4: 0,
            ano5: 0,
            ano6: 0,
            ano7: 0,
            ano8: 0,
            ano9: 0,
            ano10: 0,
            ano11: 0,
            ano12: 0,
            ano13: 0,
            ano14: 0,
            ano15: 0,
            ano16: 0,
            ano17: 0,
            ano18: 0,
            ano19: 0,
            ano20: 0,
          },
          {
            type: 'Proton',
            ano0: 0,
            ano1: 0,
            ano2: 0,
            ano3: 0,
            ano4: 0,
            ano5: 0,
            ano6: 0,
            ano7: 0,
            ano8: 0,
            ano9: 0,
            ano10: 0,
            ano11: 0,
            ano12: 0,
            ano13: 0,
            ano14: 0,
            ano15: 0,
            ano16: 0,
            ano17: 0,
            ano18: 0,
            ano19: 0,
            ano20: 0,
          },
          {
            type: '% Pagamento',
            ano0: 0,
            ano1: 0,
            ano2: 0,
            ano3: 0,
            ano4: 0,
            ano5: 0,
            ano6: 0,
            ano7: 0,
            ano8: 0,
            ano9: 0,
            ano10: 0,
            ano11: 0,
            ano12: 0,
            ano13: 0,
            ano14: 0,
            ano15: 0,
            ano16: 0,
            ano17: 0,
            ano18: 0,
            ano19: 0,
            ano20: 0,
          },
          {
            type: 'Impostos',
            ano0: 0,
            ano1: 0,
            ano2: 0,
            ano3: 0,
            ano4: 0,
            ano5: 0,
            ano6: 0,
            ano7: 0,
            ano8: 0,
            ano9: 0,
            ano10: 0,
            ano11: 0,
            ano12: 0,
            ano13: 0,
            ano14: 0,
            ano15: 0,
            ano16: 0,
            ano17: 0,
            ano18: 0,
            ano19: 0,
            ano20: 0,
          },
          {
            type: 'ICMS',
            ano0: 0,
            ano1: 0,
            ano2: 0,
            ano3: 0,
            ano4: 0,
            ano5: 0,
            ano6: 0,
            ano7: 0,
            ano8: 0,
            ano9: 0,
            ano10: 0,
            ano11: 0,
            ano12: 0,
            ano13: 0,
            ano14: 0,
            ano15: 0,
            ano16: 0,
            ano17: 0,
            ano18: 0,
            ano19: 0,
            ano20: 0,
          },
          {
            type: 'PIS',
            ano0: 0,
            ano1: 0,
            ano2: 0,
            ano3: 0,
            ano4: 0,
            ano5: 0,
            ano6: 0,
            ano7: 0,
            ano8: 0,
            ano9: 0,
            ano10: 0,
            ano11: 0,
            ano12: 0,
            ano13: 0,
            ano14: 0,
            ano15: 0,
            ano16: 0,
            ano17: 0,
            ano18: 0,
            ano19: 0,
            ano20: 0,
          },
          {
            type: 'COFINS',
            ano0: 0,
            ano1: 0,
            ano2: 0,
            ano3: 0,
            ano4: 0,
            ano5: 0,
            ano6: 0,
            ano7: 0,
            ano8: 0,
            ano9: 0,
            ano10: 0,
            ano11: 0,
            ano12: 0,
            ano13: 0,
            ano14: 0,
            ano15: 0,
            ano16: 0,
            ano17: 0,
            ano18: 0,
            ano19: 0,
            ano20: 0,
          },
          {
            type: 'IPI',
            ano0: 0,
            ano1: 0,
            ano2: 0,
            ano3: 0,
            ano4: 0,
            ano5: 0,
            ano6: 0,
            ano7: 0,
            ano8: 0,
            ano9: 0,
            ano10: 0,
            ano11: 0,
            ano12: 0,
            ano13: 0,
            ano14: 0,
            ano15: 0,
            ano16: 0,
            ano17: 0,
            ano18: 0,
            ano19: 0,
            ano20: 0,
          },
          {
            type: 'II',
            ano0: 0,
            ano1: 0,
            ano2: 0,
            ano3: 0,
            ano4: 0,
            ano5: 0,
            ano6: 0,
            ano7: 0,
            ano8: 0,
            ano9: 0,
            ano10: 0,
            ano11: 0,
            ano12: 0,
            ano13: 0,
            ano14: 0,
            ano15: 0,
            ano16: 0,
            ano17: 0,
            ano18: 0,
            ano19: 0,
            ano20: 0,
          },
          {
            type: 'Custos com importacao',
            ano0: 0,
            ano1: 0,
            ano2: 0,
            ano3: 0,
            ano4: 0,
            ano5: 0,
            ano6: 0,
            ano7: 0,
            ano8: 0,
            ano9: 0,
            ano10: 0,
            ano11: 0,
            ano12: 0,
            ano13: 0,
            ano14: 0,
            ano15: 0,
            ano16: 0,
            ano17: 0,
            ano18: 0,
            ano19: 0,
            ano20: 0,
          },
          {
            type: 'Seguro',
            ano0: 0,
            ano1: 0,
            ano2: 0,
            ano3: 0,
            ano4: 0,
            ano5: 0,
            ano6: 0,
            ano7: 0,
            ano8: 0,
            ano9: 0,
            ano10: 0,
            ano11: 0,
            ano12: 0,
            ano13: 0,
            ano14: 0,
            ano15: 0,
            ano16: 0,
            ano17: 0,
            ano18: 0,
            ano19: 0,
            ano20: 0,
          },
          {
            type: 'Frete internacional',
            ano0: 0,
            ano1: 0,
            ano2: 0,
            ano3: 0,
            ano4: 0,
            ano5: 0,
            ano6: 0,
            ano7: 0,
            ano8: 0,
            ano9: 0,
            ano10: 0,
            ano11: 0,
            ano12: 0,
            ano13: 0,
            ano14: 0,
            ano15: 0,
            ano16: 0,
            ano17: 0,
            ano18: 0,
            ano19: 0,
            ano20: 0,
          },
          {
            type: 'Taxas',
            ano0: 0,
            ano1: 0,
            ano2: 0,
            ano3: 0,
            ano4: 0,
            ano5: 0,
            ano6: 0,
            ano7: 0,
            ano8: 0,
            ano9: 0,
            ano10: 0,
            ano11: 0,
            ano12: 0,
            ano13: 0,
            ano14: 0,
            ano15: 0,
            ano16: 0,
            ano17: 0,
            ano18: 0,
            ano19: 0,
            ano20: 0,
          },
          {
            type: 'Portos e armazenagem',
            ano0: 0,
            ano1: 0,
            ano2: 0,
            ano3: 0,
            ano4: 0,
            ano5: 0,
            ano6: 0,
            ano7: 0,
            ano8: 0,
            ano9: 0,
            ano10: 0,
            ano11: 0,
            ano12: 0,
            ano13: 0,
            ano14: 0,
            ano15: 0,
            ano16: 0,
            ano17: 0,
            ano18: 0,
            ano19: 0,
            ano20: 0,
          },
          {
            type: 'Assessoria',
            ano0: 0,
            ano1: 0,
            ano2: 0,
            ano3: 0,
            ano4: 0,
            ano5: 0,
            ano6: 0,
            ano7: 0,
            ano8: 0,
            ano9: 0,
            ano10: 0,
            ano11: 0,
            ano12: 0,
            ano13: 0,
            ano14: 0,
            ano15: 0,
            ano16: 0,
            ano17: 0,
            ano18: 0,
            ano19: 0,
            ano20: 0,
          },
          {
            type: 'Transporte',
            ano0: 0,
            ano1: 0,
            ano2: 0,
            ano3: 0,
            ano4: 0,
            ano5: 0,
            ano6: 0,
            ano7: 0,
            ano8: 0,
            ano9: 0,
            ano10: 0,
            ano11: 0,
            ano12: 0,
            ano13: 0,
            ano14: 0,
            ano15: 0,
            ano16: 0,
            ano17: 0,
            ano18: 0,
            ano19: 0,
            ano20: 0,
          },
          {
            type: 'Custos de desembaraço',
            ano0: 0,
            ano1: 0,
            ano2: 0,
            ano3: 0,
            ano4: 0,
            ano5: 0,
            ano6: 0,
            ano7: 0,
            ano8: 0,
            ano9: 0,
            ano10: 0,
            ano11: 0,
            ano12: 0,
            ano13: 0,
            ano14: 0,
            ano15: 0,
            ano16: 0,
            ano17: 0,
            ano18: 0,
            ano19: 0,
            ano20: 0,
          },
          {
            type: 'True beam 1',
            ano0: 0,
            ano1: 0,
            ano2: 0,
            ano3: 0,
            ano4: 0,
            ano5: 0,
            ano6: 0,
            ano7: 0,
            ano8: 0,
            ano9: 0,
            ano10: 0,
            ano11: 0,
            ano12: 0,
            ano13: 0,
            ano14: 0,
            ano15: 0,
            ano16: 0,
            ano17: 0,
            ano18: 0,
            ano19: 0,
            ano20: 0,
          },
          {
            type: '% Pagamento',
            ano0: 0,
            ano1: 0,
            ano2: 0,
            ano3: 0,
            ano4: 0,
            ano5: 0,
            ano6: 0,
            ano7: 0,
            ano8: 0,
            ano9: 0,
            ano10: 0,
            ano11: 0,
            ano12: 0,
            ano13: 0,
            ano14: 0,
            ano15: 0,
            ano16: 0,
            ano17: 0,
            ano18: 0,
            ano19: 0,
            ano20: 0,
          },
          {
            type: 'Impostos',
            ano0: 0,
            ano1: 0,
            ano2: 0,
            ano3: 0,
            ano4: 0,
            ano5: 0,
            ano6: 0,
            ano7: 0,
            ano8: 0,
            ano9: 0,
            ano10: 0,
            ano11: 0,
            ano12: 0,
            ano13: 0,
            ano14: 0,
            ano15: 0,
            ano16: 0,
            ano17: 0,
            ano18: 0,
            ano19: 0,
            ano20: 0,
          },
          {
            type: 'ICMS',
            ano0: 0,
            ano1: 0,
            ano2: 0,
            ano3: 0,
            ano4: 0,
            ano5: 0,
            ano6: 0,
            ano7: 0,
            ano8: 0,
            ano9: 0,
            ano10: 0,
            ano11: 0,
            ano12: 0,
            ano13: 0,
            ano14: 0,
            ano15: 0,
            ano16: 0,
            ano17: 0,
            ano18: 0,
            ano19: 0,
            ano20: 0,
          },
          {
            type: 'PIS',
            ano0: 0,
            ano1: 0,
            ano2: 0,
            ano3: 0,
            ano4: 0,
            ano5: 0,
            ano6: 0,
            ano7: 0,
            ano8: 0,
            ano9: 0,
            ano10: 0,
            ano11: 0,
            ano12: 0,
            ano13: 0,
            ano14: 0,
            ano15: 0,
            ano16: 0,
            ano17: 0,
            ano18: 0,
            ano19: 0,
            ano20: 0,
          },
          {
            type: 'COFINS',
            ano0: 0,
            ano1: 0,
            ano2: 0,
            ano3: 0,
            ano4: 0,
            ano5: 0,
            ano6: 0,
            ano7: 0,
            ano8: 0,
            ano9: 0,
            ano10: 0,
            ano11: 0,
            ano12: 0,
            ano13: 0,
            ano14: 0,
            ano15: 0,
            ano16: 0,
            ano17: 0,
            ano18: 0,
            ano19: 0,
            ano20: 0,
          },
          {
            type: 'IPI',
            ano0: 0,
            ano1: 0,
            ano2: 0,
            ano3: 0,
            ano4: 0,
            ano5: 0,
            ano6: 0,
            ano7: 0,
            ano8: 0,
            ano9: 0,
            ano10: 0,
            ano11: 0,
            ano12: 0,
            ano13: 0,
            ano14: 0,
            ano15: 0,
            ano16: 0,
            ano17: 0,
            ano18: 0,
            ano19: 0,
            ano20: 0,
          },
          {
            type: 'II',
            ano0: 0,
            ano1: 0,
            ano2: 0,
            ano3: 0,
            ano4: 0,
            ano5: 0,
            ano6: 0,
            ano7: 0,
            ano8: 0,
            ano9: 0,
            ano10: 0,
            ano11: 0,
            ano12: 0,
            ano13: 0,
            ano14: 0,
            ano15: 0,
            ano16: 0,
            ano17: 0,
            ano18: 0,
            ano19: 0,
            ano20: 0,
          },
          {
            type: 'Custos com importacao',
            ano0: 0,
            ano1: 0,
            ano2: 0,
            ano3: 0,
            ano4: 0,
            ano5: 0,
            ano6: 0,
            ano7: 0,
            ano8: 0,
            ano9: 0,
            ano10: 0,
            ano11: 0,
            ano12: 0,
            ano13: 0,
            ano14: 0,
            ano15: 0,
            ano16: 0,
            ano17: 0,
            ano18: 0,
            ano19: 0,
            ano20: 0,
          },
          {
            type: 'Seguro',
            ano0: 0,
            ano1: 0,
            ano2: 0,
            ano3: 0,
            ano4: 0,
            ano5: 0,
            ano6: 0,
            ano7: 0,
            ano8: 0,
            ano9: 0,
            ano10: 0,
            ano11: 0,
            ano12: 0,
            ano13: 0,
            ano14: 0,
            ano15: 0,
            ano16: 0,
            ano17: 0,
            ano18: 0,
            ano19: 0,
            ano20: 0,
          },
          {
            type: 'Frete internacional',
            ano0: 0,
            ano1: 0,
            ano2: 0,
            ano3: 0,
            ano4: 0,
            ano5: 0,
            ano6: 0,
            ano7: 0,
            ano8: 0,
            ano9: 0,
            ano10: 0,
            ano11: 0,
            ano12: 0,
            ano13: 0,
            ano14: 0,
            ano15: 0,
            ano16: 0,
            ano17: 0,
            ano18: 0,
            ano19: 0,
            ano20: 0,
          },
          {
            type: 'Taxas',
            ano0: 0,
            ano1: 0,
            ano2: 0,
            ano3: 0,
            ano4: 0,
            ano5: 0,
            ano6: 0,
            ano7: 0,
            ano8: 0,
            ano9: 0,
            ano10: 0,
            ano11: 0,
            ano12: 0,
            ano13: 0,
            ano14: 0,
            ano15: 0,
            ano16: 0,
            ano17: 0,
            ano18: 0,
            ano19: 0,
            ano20: 0,
          },
          {
            type: 'Portos e armazenagem',
            ano0: 0,
            ano1: 0,
            ano2: 0,
            ano3: 0,
            ano4: 0,
            ano5: 0,
            ano6: 0,
            ano7: 0,
            ano8: 0,
            ano9: 0,
            ano10: 0,
            ano11: 0,
            ano12: 0,
            ano13: 0,
            ano14: 0,
            ano15: 0,
            ano16: 0,
            ano17: 0,
            ano18: 0,
            ano19: 0,
            ano20: 0,
          },
          {
            type: 'Assessoria',
            ano0: 0,
            ano1: 0,
            ano2: 0,
            ano3: 0,
            ano4: 0,
            ano5: 0,
            ano6: 0,
            ano7: 0,
            ano8: 0,
            ano9: 0,
            ano10: 0,
            ano11: 0,
            ano12: 0,
            ano13: 0,
            ano14: 0,
            ano15: 0,
            ano16: 0,
            ano17: 0,
            ano18: 0,
            ano19: 0,
            ano20: 0,
          },
          {
            type: 'Transporte',
            ano0: 0,
            ano1: 0,
            ano2: 0,
            ano3: 0,
            ano4: 0,
            ano5: 0,
            ano6: 0,
            ano7: 0,
            ano8: 0,
            ano9: 0,
            ano10: 0,
            ano11: 0,
            ano12: 0,
            ano13: 0,
            ano14: 0,
            ano15: 0,
            ano16: 0,
            ano17: 0,
            ano18: 0,
            ano19: 0,
            ano20: 0,
          },
          {
            type: 'Custos de desembaraço',
            ano0: 0,
            ano1: 0,
            ano2: 0,
            ano3: 0,
            ano4: 0,
            ano5: 0,
            ano6: 0,
            ano7: 0,
            ano8: 0,
            ano9: 0,
            ano10: 0,
            ano11: 0,
            ano12: 0,
            ano13: 0,
            ano14: 0,
            ano15: 0,
            ano16: 0,
            ano17: 0,
            ano18: 0,
            ano19: 0,
            ano20: 0,
          },
          {
            type: 'True beam 2',
            ano0: 0,
            ano1: 0,
            ano2: 0,
            ano3: 0,
            ano4: 0,
            ano5: 0,
            ano6: 0,
            ano7: 0,
            ano8: 0,
            ano9: 0,
            ano10: 0,
            ano11: 0,
            ano12: 0,
            ano13: 0,
            ano14: 0,
            ano15: 0,
            ano16: 0,
            ano17: 0,
            ano18: 0,
            ano19: 0,
            ano20: 0,
          },
          {
            type: '% Pagamento',
            ano0: 0,
            ano1: 0,
            ano2: 0,
            ano3: 0,
            ano4: 0,
            ano5: 0,
            ano6: 0,
            ano7: 0,
            ano8: 0,
            ano9: 0,
            ano10: 0,
            ano11: 0,
            ano12: 0,
            ano13: 0,
            ano14: 0,
            ano15: 0,
            ano16: 0,
            ano17: 0,
            ano18: 0,
            ano19: 0,
            ano20: 0,
          },
          {
            type: 'Impostos',
            ano0: 0,
            ano1: 0,
            ano2: 0,
            ano3: 0,
            ano4: 0,
            ano5: 0,
            ano6: 0,
            ano7: 0,
            ano8: 0,
            ano9: 0,
            ano10: 0,
            ano11: 0,
            ano12: 0,
            ano13: 0,
            ano14: 0,
            ano15: 0,
            ano16: 0,
            ano17: 0,
            ano18: 0,
            ano19: 0,
            ano20: 0,
          },
          {
            type: 'ICMS',
            ano0: 0,
            ano1: 0,
            ano2: 0,
            ano3: 0,
            ano4: 0,
            ano5: 0,
            ano6: 0,
            ano7: 0,
            ano8: 0,
            ano9: 0,
            ano10: 0,
            ano11: 0,
            ano12: 0,
            ano13: 0,
            ano14: 0,
            ano15: 0,
            ano16: 0,
            ano17: 0,
            ano18: 0,
            ano19: 0,
            ano20: 0,
          },
          {
            type: 'PIS',
            ano0: 0,
            ano1: 0,
            ano2: 0,
            ano3: 0,
            ano4: 0,
            ano5: 0,
            ano6: 0,
            ano7: 0,
            ano8: 0,
            ano9: 0,
            ano10: 0,
            ano11: 0,
            ano12: 0,
            ano13: 0,
            ano14: 0,
            ano15: 0,
            ano16: 0,
            ano17: 0,
            ano18: 0,
            ano19: 0,
            ano20: 0,
          },
          {
            type: 'COFINS',
            ano0: 0,
            ano1: 0,
            ano2: 0,
            ano3: 0,
            ano4: 0,
            ano5: 0,
            ano6: 0,
            ano7: 0,
            ano8: 0,
            ano9: 0,
            ano10: 0,
            ano11: 0,
            ano12: 0,
            ano13: 0,
            ano14: 0,
            ano15: 0,
            ano16: 0,
            ano17: 0,
            ano18: 0,
            ano19: 0,
            ano20: 0,
          },
          {
            type: 'IPI',
            ano0: 0,
            ano1: 0,
            ano2: 0,
            ano3: 0,
            ano4: 0,
            ano5: 0,
            ano6: 0,
            ano7: 0,
            ano8: 0,
            ano9: 0,
            ano10: 0,
            ano11: 0,
            ano12: 0,
            ano13: 0,
            ano14: 0,
            ano15: 0,
            ano16: 0,
            ano17: 0,
            ano18: 0,
            ano19: 0,
            ano20: 0,
          },
          {
            type: 'II',
            ano0: 0,
            ano1: 0,
            ano2: 0,
            ano3: 0,
            ano4: 0,
            ano5: 0,
            ano6: 0,
            ano7: 0,
            ano8: 0,
            ano9: 0,
            ano10: 0,
            ano11: 0,
            ano12: 0,
            ano13: 0,
            ano14: 0,
            ano15: 0,
            ano16: 0,
            ano17: 0,
            ano18: 0,
            ano19: 0,
            ano20: 0,
          },
          {
            type: 'Custos com importacao',
            ano0: 0,
            ano1: 0,
            ano2: 0,
            ano3: 0,
            ano4: 0,
            ano5: 0,
            ano6: 0,
            ano7: 0,
            ano8: 0,
            ano9: 0,
            ano10: 0,
            ano11: 0,
            ano12: 0,
            ano13: 0,
            ano14: 0,
            ano15: 0,
            ano16: 0,
            ano17: 0,
            ano18: 0,
            ano19: 0,
            ano20: 0,
          },
          {
            type: 'Seguro',
            ano0: 0,
            ano1: 0,
            ano2: 0,
            ano3: 0,
            ano4: 0,
            ano5: 0,
            ano6: 0,
            ano7: 0,
            ano8: 0,
            ano9: 0,
            ano10: 0,
            ano11: 0,
            ano12: 0,
            ano13: 0,
            ano14: 0,
            ano15: 0,
            ano16: 0,
            ano17: 0,
            ano18: 0,
            ano19: 0,
            ano20: 0,
          },
          {
            type: 'Frete internacional',
            ano0: 0,
            ano1: 0,
            ano2: 0,
            ano3: 0,
            ano4: 0,
            ano5: 0,
            ano6: 0,
            ano7: 0,
            ano8: 0,
            ano9: 0,
            ano10: 0,
            ano11: 0,
            ano12: 0,
            ano13: 0,
            ano14: 0,
            ano15: 0,
            ano16: 0,
            ano17: 0,
            ano18: 0,
            ano19: 0,
            ano20: 0,
          },
          {
            type: 'Taxas',
            ano0: 0,
            ano1: 0,
            ano2: 0,
            ano3: 0,
            ano4: 0,
            ano5: 0,
            ano6: 0,
            ano7: 0,
            ano8: 0,
            ano9: 0,
            ano10: 0,
            ano11: 0,
            ano12: 0,
            ano13: 0,
            ano14: 0,
            ano15: 0,
            ano16: 0,
            ano17: 0,
            ano18: 0,
            ano19: 0,
            ano20: 0,
          },
          {
            type: 'Portos e armazenagem',
            ano0: 0,
            ano1: 0,
            ano2: 0,
            ano3: 0,
            ano4: 0,
            ano5: 0,
            ano6: 0,
            ano7: 0,
            ano8: 0,
            ano9: 0,
            ano10: 0,
            ano11: 0,
            ano12: 0,
            ano13: 0,
            ano14: 0,
            ano15: 0,
            ano16: 0,
            ano17: 0,
            ano18: 0,
            ano19: 0,
            ano20: 0,
          },
          {
            type: 'Assessoria',
            ano0: 0,
            ano1: 0,
            ano2: 0,
            ano3: 0,
            ano4: 0,
            ano5: 0,
            ano6: 0,
            ano7: 0,
            ano8: 0,
            ano9: 0,
            ano10: 0,
            ano11: 0,
            ano12: 0,
            ano13: 0,
            ano14: 0,
            ano15: 0,
            ano16: 0,
            ano17: 0,
            ano18: 0,
            ano19: 0,
            ano20: 0,
          },
          {
            type: 'Transporte',
            ano0: 0,
            ano1: 0,
            ano2: 0,
            ano3: 0,
            ano4: 0,
            ano5: 0,
            ano6: 0,
            ano7: 0,
            ano8: 0,
            ano9: 0,
            ano10: 0,
            ano11: 0,
            ano12: 0,
            ano13: 0,
            ano14: 0,
            ano15: 0,
            ano16: 0,
            ano17: 0,
            ano18: 0,
            ano19: 0,
            ano20: 0,
          },
          {
            type: 'Custos de desembaraço',
            ano0: 0,
            ano1: 0,
            ano2: 0,
            ano3: 0,
            ano4: 0,
            ano5: 0,
            ano6: 0,
            ano7: 0,
            ano8: 0,
            ano9: 0,
            ano10: 0,
            ano11: 0,
            ano12: 0,
            ano13: 0,
            ano14: 0,
            ano15: 0,
            ano16: 0,
            ano17: 0,
            ano18: 0,
            ano19: 0,
            ano20: 0,
          },
          {
            type: 'Acelerador 1',
            ano0: 0,
            ano1: 0,
            ano2: 0,
            ano3: 0,
            ano4: 0,
            ano5: 0,
            ano6: 0,
            ano7: 0,
            ano8: 0,
            ano9: 0,
            ano10: 0,
            ano11: 0,
            ano12: 0,
            ano13: 0,
            ano14: 0,
            ano15: 0,
            ano16: 0,
            ano17: 0,
            ano18: 0,
            ano19: 0,
            ano20: 0,
          },
          {
            type: '% Pagamento',
            ano0: 0,
            ano1: 0,
            ano2: 0,
            ano3: 0,
            ano4: 0,
            ano5: 0,
            ano6: 0,
            ano7: 0,
            ano8: 0,
            ano9: 0,
            ano10: 0,
            ano11: 0,
            ano12: 0,
            ano13: 0,
            ano14: 0,
            ano15: 0,
            ano16: 0,
            ano17: 0,
            ano18: 0,
            ano19: 0,
            ano20: 0,
          },
          {
            type: 'Impostos',
            ano0: 0,
            ano1: 0,
            ano2: 0,
            ano3: 0,
            ano4: 0,
            ano5: 0,
            ano6: 0,
            ano7: 0,
            ano8: 0,
            ano9: 0,
            ano10: 0,
            ano11: 0,
            ano12: 0,
            ano13: 0,
            ano14: 0,
            ano15: 0,
            ano16: 0,
            ano17: 0,
            ano18: 0,
            ano19: 0,
            ano20: 0,
          },
          {
            type: 'ICMS',
            ano0: 0,
            ano1: 0,
            ano2: 0,
            ano3: 0,
            ano4: 0,
            ano5: 0,
            ano6: 0,
            ano7: 0,
            ano8: 0,
            ano9: 0,
            ano10: 0,
            ano11: 0,
            ano12: 0,
            ano13: 0,
            ano14: 0,
            ano15: 0,
            ano16: 0,
            ano17: 0,
            ano18: 0,
            ano19: 0,
            ano20: 0,
          },
          {
            type: 'PIS',
            ano0: 0,
            ano1: 0,
            ano2: 0,
            ano3: 0,
            ano4: 0,
            ano5: 0,
            ano6: 0,
            ano7: 0,
            ano8: 0,
            ano9: 0,
            ano10: 0,
            ano11: 0,
            ano12: 0,
            ano13: 0,
            ano14: 0,
            ano15: 0,
            ano16: 0,
            ano17: 0,
            ano18: 0,
            ano19: 0,
            ano20: 0,
          },
          {
            type: 'COFINS',
            ano0: 0,
            ano1: 0,
            ano2: 0,
            ano3: 0,
            ano4: 0,
            ano5: 0,
            ano6: 0,
            ano7: 0,
            ano8: 0,
            ano9: 0,
            ano10: 0,
            ano11: 0,
            ano12: 0,
            ano13: 0,
            ano14: 0,
            ano15: 0,
            ano16: 0,
            ano17: 0,
            ano18: 0,
            ano19: 0,
            ano20: 0,
          },
          {
            type: 'IPI',
            ano0: 0,
            ano1: 0,
            ano2: 0,
            ano3: 0,
            ano4: 0,
            ano5: 0,
            ano6: 0,
            ano7: 0,
            ano8: 0,
            ano9: 0,
            ano10: 0,
            ano11: 0,
            ano12: 0,
            ano13: 0,
            ano14: 0,
            ano15: 0,
            ano16: 0,
            ano17: 0,
            ano18: 0,
            ano19: 0,
            ano20: 0,
          },
          {
            type: 'II',
            ano0: 0,
            ano1: 0,
            ano2: 0,
            ano3: 0,
            ano4: 0,
            ano5: 0,
            ano6: 0,
            ano7: 0,
            ano8: 0,
            ano9: 0,
            ano10: 0,
            ano11: 0,
            ano12: 0,
            ano13: 0,
            ano14: 0,
            ano15: 0,
            ano16: 0,
            ano17: 0,
            ano18: 0,
            ano19: 0,
            ano20: 0,
          },
          {
            type: 'Custos com importacao',
            ano0: 0,
            ano1: 0,
            ano2: 0,
            ano3: 0,
            ano4: 0,
            ano5: 0,
            ano6: 0,
            ano7: 0,
            ano8: 0,
            ano9: 0,
            ano10: 0,
            ano11: 0,
            ano12: 0,
            ano13: 0,
            ano14: 0,
            ano15: 0,
            ano16: 0,
            ano17: 0,
            ano18: 0,
            ano19: 0,
            ano20: 0,
          },
          {
            type: 'Seguro',
            ano0: 0,
            ano1: 0,
            ano2: 0,
            ano3: 0,
            ano4: 0,
            ano5: 0,
            ano6: 0,
            ano7: 0,
            ano8: 0,
            ano9: 0,
            ano10: 0,
            ano11: 0,
            ano12: 0,
            ano13: 0,
            ano14: 0,
            ano15: 0,
            ano16: 0,
            ano17: 0,
            ano18: 0,
            ano19: 0,
            ano20: 0,
          },
          {
            type: 'Frete internacional',
            ano0: 0,
            ano1: 0,
            ano2: 0,
            ano3: 0,
            ano4: 0,
            ano5: 0,
            ano6: 0,
            ano7: 0,
            ano8: 0,
            ano9: 0,
            ano10: 0,
            ano11: 0,
            ano12: 0,
            ano13: 0,
            ano14: 0,
            ano15: 0,
            ano16: 0,
            ano17: 0,
            ano18: 0,
            ano19: 0,
            ano20: 0,
          },
          {
            type: 'Taxas',
            ano0: 0,
            ano1: 0,
            ano2: 0,
            ano3: 0,
            ano4: 0,
            ano5: 0,
            ano6: 0,
            ano7: 0,
            ano8: 0,
            ano9: 0,
            ano10: 0,
            ano11: 0,
            ano12: 0,
            ano13: 0,
            ano14: 0,
            ano15: 0,
            ano16: 0,
            ano17: 0,
            ano18: 0,
            ano19: 0,
            ano20: 0,
          },
          {
            type: 'Portos e armazenagem',
            ano0: 0,
            ano1: 0,
            ano2: 0,
            ano3: 0,
            ano4: 0,
            ano5: 0,
            ano6: 0,
            ano7: 0,
            ano8: 0,
            ano9: 0,
            ano10: 0,
            ano11: 0,
            ano12: 0,
            ano13: 0,
            ano14: 0,
            ano15: 0,
            ano16: 0,
            ano17: 0,
            ano18: 0,
            ano19: 0,
            ano20: 0,
          },
          {
            type: 'Assessoria',
            ano0: 0,
            ano1: 0,
            ano2: 0,
            ano3: 0,
            ano4: 0,
            ano5: 0,
            ano6: 0,
            ano7: 0,
            ano8: 0,
            ano9: 0,
            ano10: 0,
            ano11: 0,
            ano12: 0,
            ano13: 0,
            ano14: 0,
            ano15: 0,
            ano16: 0,
            ano17: 0,
            ano18: 0,
            ano19: 0,
            ano20: 0,
          },
          {
            type: 'Transporte',
            ano0: 0,
            ano1: 0,
            ano2: 0,
            ano3: 0,
            ano4: 0,
            ano5: 0,
            ano6: 0,
            ano7: 0,
            ano8: 0,
            ano9: 0,
            ano10: 0,
            ano11: 0,
            ano12: 0,
            ano13: 0,
            ano14: 0,
            ano15: 0,
            ano16: 0,
            ano17: 0,
            ano18: 0,
            ano19: 0,
            ano20: 0,
          },
          {
            type: 'Custos de desembaraço',
            ano0: 0,
            ano1: 0,
            ano2: 0,
            ano3: 0,
            ano4: 0,
            ano5: 0,
            ano6: 0,
            ano7: 0,
            ano8: 0,
            ano9: 0,
            ano10: 0,
            ano11: 0,
            ano12: 0,
            ano13: 0,
            ano14: 0,
            ano15: 0,
            ano16: 0,
            ano17: 0,
            ano18: 0,
            ano19: 0,
            ano20: 0,
          },
          {
            type: 'Acelerador 2',
            ano0: 0,
            ano1: 0,
            ano2: 0,
            ano3: 0,
            ano4: 0,
            ano5: 0,
            ano6: 0,
            ano7: 0,
            ano8: 0,
            ano9: 0,
            ano10: 0,
            ano11: 0,
            ano12: 0,
            ano13: 0,
            ano14: 0,
            ano15: 0,
            ano16: 0,
            ano17: 0,
            ano18: 0,
            ano19: 0,
            ano20: 0,
          },
          {
            type: '% Pagamento',
            ano0: 0,
            ano1: 0,
            ano2: 0,
            ano3: 0,
            ano4: 0,
            ano5: 0,
            ano6: 0,
            ano7: 0,
            ano8: 0,
            ano9: 0,
            ano10: 0,
            ano11: 0,
            ano12: 0,
            ano13: 0,
            ano14: 0,
            ano15: 0,
            ano16: 0,
            ano17: 0,
            ano18: 0,
            ano19: 0,
            ano20: 0,
          },
          {
            type: 'Impostos',
            ano0: 0,
            ano1: 0,
            ano2: 0,
            ano3: 0,
            ano4: 0,
            ano5: 0,
            ano6: 0,
            ano7: 0,
            ano8: 0,
            ano9: 0,
            ano10: 0,
            ano11: 0,
            ano12: 0,
            ano13: 0,
            ano14: 0,
            ano15: 0,
            ano16: 0,
            ano17: 0,
            ano18: 0,
            ano19: 0,
            ano20: 0,
          },
          {
            type: 'ICMS',
            ano0: 0,
            ano1: 0,
            ano2: 0,
            ano3: 0,
            ano4: 0,
            ano5: 0,
            ano6: 0,
            ano7: 0,
            ano8: 0,
            ano9: 0,
            ano10: 0,
            ano11: 0,
            ano12: 0,
            ano13: 0,
            ano14: 0,
            ano15: 0,
            ano16: 0,
            ano17: 0,
            ano18: 0,
            ano19: 0,
            ano20: 0,
          },
          {
            type: 'PIS',
            ano0: 0,
            ano1: 0,
            ano2: 0,
            ano3: 0,
            ano4: 0,
            ano5: 0,
            ano6: 0,
            ano7: 0,
            ano8: 0,
            ano9: 0,
            ano10: 0,
            ano11: 0,
            ano12: 0,
            ano13: 0,
            ano14: 0,
            ano15: 0,
            ano16: 0,
            ano17: 0,
            ano18: 0,
            ano19: 0,
            ano20: 0,
          },
          {
            type: 'COFINS',
            ano0: 0,
            ano1: 0,
            ano2: 0,
            ano3: 0,
            ano4: 0,
            ano5: 0,
            ano6: 0,
            ano7: 0,
            ano8: 0,
            ano9: 0,
            ano10: 0,
            ano11: 0,
            ano12: 0,
            ano13: 0,
            ano14: 0,
            ano15: 0,
            ano16: 0,
            ano17: 0,
            ano18: 0,
            ano19: 0,
            ano20: 0,
          },
          {
            type: 'IPI',
            ano0: 0,
            ano1: 0,
            ano2: 0,
            ano3: 0,
            ano4: 0,
            ano5: 0,
            ano6: 0,
            ano7: 0,
            ano8: 0,
            ano9: 0,
            ano10: 0,
            ano11: 0,
            ano12: 0,
            ano13: 0,
            ano14: 0,
            ano15: 0,
            ano16: 0,
            ano17: 0,
            ano18: 0,
            ano19: 0,
            ano20: 0,
          },
          {
            type: 'II',
            ano0: 0,
            ano1: 0,
            ano2: 0,
            ano3: 0,
            ano4: 0,
            ano5: 0,
            ano6: 0,
            ano7: 0,
            ano8: 0,
            ano9: 0,
            ano10: 0,
            ano11: 0,
            ano12: 0,
            ano13: 0,
            ano14: 0,
            ano15: 0,
            ano16: 0,
            ano17: 0,
            ano18: 0,
            ano19: 0,
            ano20: 0,
          },
          {
            type: 'Custos com importacao',
            ano0: 0,
            ano1: 0,
            ano2: 0,
            ano3: 0,
            ano4: 0,
            ano5: 0,
            ano6: 0,
            ano7: 0,
            ano8: 0,
            ano9: 0,
            ano10: 0,
            ano11: 0,
            ano12: 0,
            ano13: 0,
            ano14: 0,
            ano15: 0,
            ano16: 0,
            ano17: 0,
            ano18: 0,
            ano19: 0,
            ano20: 0,
          },
          {
            type: 'Seguro',
            ano0: 0,
            ano1: 0,
            ano2: 0,
            ano3: 0,
            ano4: 0,
            ano5: 0,
            ano6: 0,
            ano7: 0,
            ano8: 0,
            ano9: 0,
            ano10: 0,
            ano11: 0,
            ano12: 0,
            ano13: 0,
            ano14: 0,
            ano15: 0,
            ano16: 0,
            ano17: 0,
            ano18: 0,
            ano19: 0,
            ano20: 0,
          },
          {
            type: 'Frete internacional',
            ano0: 0,
            ano1: 0,
            ano2: 0,
            ano3: 0,
            ano4: 0,
            ano5: 0,
            ano6: 0,
            ano7: 0,
            ano8: 0,
            ano9: 0,
            ano10: 0,
            ano11: 0,
            ano12: 0,
            ano13: 0,
            ano14: 0,
            ano15: 0,
            ano16: 0,
            ano17: 0,
            ano18: 0,
            ano19: 0,
            ano20: 0,
          },
          {
            type: 'Taxas',
            ano0: 0,
            ano1: 0,
            ano2: 0,
            ano3: 0,
            ano4: 0,
            ano5: 0,
            ano6: 0,
            ano7: 0,
            ano8: 0,
            ano9: 0,
            ano10: 0,
            ano11: 0,
            ano12: 0,
            ano13: 0,
            ano14: 0,
            ano15: 0,
            ano16: 0,
            ano17: 0,
            ano18: 0,
            ano19: 0,
            ano20: 0,
          },
          {
            type: 'Portos e armazenagem',
            ano0: 0,
            ano1: 0,
            ano2: 0,
            ano3: 0,
            ano4: 0,
            ano5: 0,
            ano6: 0,
            ano7: 0,
            ano8: 0,
            ano9: 0,
            ano10: 0,
            ano11: 0,
            ano12: 0,
            ano13: 0,
            ano14: 0,
            ano15: 0,
            ano16: 0,
            ano17: 0,
            ano18: 0,
            ano19: 0,
            ano20: 0,
          },
          {
            type: 'Assessoria',
            ano0: 0,
            ano1: 0,
            ano2: 0,
            ano3: 0,
            ano4: 0,
            ano5: 0,
            ano6: 0,
            ano7: 0,
            ano8: 0,
            ano9: 0,
            ano10: 0,
            ano11: 0,
            ano12: 0,
            ano13: 0,
            ano14: 0,
            ano15: 0,
            ano16: 0,
            ano17: 0,
            ano18: 0,
            ano19: 0,
            ano20: 0,
          },
          {
            type: 'Transporte',
            ano0: 0,
            ano1: 0,
            ano2: 0,
            ano3: 0,
            ano4: 0,
            ano5: 0,
            ano6: 0,
            ano7: 0,
            ano8: 0,
            ano9: 0,
            ano10: 0,
            ano11: 0,
            ano12: 0,
            ano13: 0,
            ano14: 0,
            ano15: 0,
            ano16: 0,
            ano17: 0,
            ano18: 0,
            ano19: 0,
            ano20: 0,
          },
          {
            type: 'Custos de desembaraço',
            ano0: 0,
            ano1: 0,
            ano2: 0,
            ano3: 0,
            ano4: 0,
            ano5: 0,
            ano6: 0,
            ano7: 0,
            ano8: 0,
            ano9: 0,
            ano10: 0,
            ano11: 0,
            ano12: 0,
            ano13: 0,
            ano14: 0,
            ano15: 0,
            ano16: 0,
            ano17: 0,
            ano18: 0,
            ano19: 0,
            ano20: 0,
          },
          {
            type: 'Outros equipamentos',
            ano0: 0,
            ano1: 0,
            ano2: 0,
            ano3: 0,
            ano4: 0,
            ano5: 0,
            ano6: 0,
            ano7: 0,
            ano8: 0,
            ano9: 0,
            ano10: 0,
            ano11: 0,
            ano12: 0,
            ano13: 0,
            ano14: 0,
            ano15: 0,
            ano16: 0,
            ano17: 0,
            ano18: 0,
            ano19: 0,
            ano20: 0,
          },
          {
            type: '% Pagamento',
            ano0: 0,
            ano1: 0,
            ano2: 0,
            ano3: 0,
            ano4: 0,
            ano5: 0,
            ano6: 0,
            ano7: 0,
            ano8: 0,
            ano9: 0,
            ano10: 0,
            ano11: 0,
            ano12: 0,
            ano13: 0,
            ano14: 0,
            ano15: 0,
            ano16: 0,
            ano17: 0,
            ano18: 0,
            ano19: 0,
            ano20: 0,
          },
          {
            type: 'QA + Dosimetria',
            ano0: 0,
            ano1: 0,
            ano2: 0,
            ano3: 0,
            ano4: 0,
            ano5: 0,
            ano6: 0,
            ano7: 0,
            ano8: 0,
            ano9: 0,
            ano10: 0,
            ano11: 0,
            ano12: 0,
            ano13: 0,
            ano14: 0,
            ano15: 0,
            ano16: 0,
            ano17: 0,
            ano18: 0,
            ano19: 0,
            ano20: 0,
          },
          {
            type: 'Acessorio Imobilização',
            ano0: 0,
            ano1: 0,
            ano2: 0,
            ano3: 0,
            ano4: 0,
            ano5: 0,
            ano6: 0,
            ano7: 0,
            ano8: 0,
            ano9: 0,
            ano10: 0,
            ano11: 0,
            ano12: 0,
            ano13: 0,
            ano14: 0,
            ano15: 0,
            ano16: 0,
            ano17: 0,
            ano18: 0,
            ano19: 0,
            ano20: 0,
          },
          {
            type: 'Software',
            ano0: 0,
            ano1: 0,
            ano2: 0,
            ano3: 0,
            ano4: 0,
            ano5: 0,
            ano6: 0,
            ano7: 0,
            ano8: 0,
            ano9: 0,
            ano10: 0,
            ano11: 0,
            ano12: 0,
            ano13: 0,
            ano14: 0,
            ano15: 0,
            ano16: 0,
            ano17: 0,
            ano18: 0,
            ano19: 0,
            ano20: 0,
          },
          {
            type: 'Tomografia',
            ano0: 0,
            ano1: 0,
            ano2: 0,
            ano3: 0,
            ano4: 0,
            ano5: 0,
            ano6: 0,
            ano7: 0,
            ano8: 0,
            ano9: 0,
            ano10: 0,
            ano11: 0,
            ano12: 0,
            ano13: 0,
            ano14: 0,
            ano15: 0,
            ano16: 0,
            ano17: 0,
            ano18: 0,
            ano19: 0,
            ano20: 0,
          },
          {
            type: 'Ressonancia magnética',
            ano0: 0,
            ano1: 0,
            ano2: 0,
            ano3: 0,
            ano4: 0,
            ano5: 0,
            ano6: 0,
            ano7: 0,
            ano8: 0,
            ano9: 0,
            ano10: 0,
            ano11: 0,
            ano12: 0,
            ano13: 0,
            ano14: 0,
            ano15: 0,
            ano16: 0,
            ano17: 0,
            ano18: 0,
            ano19: 0,
            ano20: 0,
          },
          {
            type: 'Outros equipamentos',
            ano0: 0,
            ano1: 0,
            ano2: 0,
            ano3: 0,
            ano4: 0,
            ano5: 0,
            ano6: 0,
            ano7: 0,
            ano8: 0,
            ano9: 0,
            ano10: 0,
            ano11: 0,
            ano12: 0,
            ano13: 0,
            ano14: 0,
            ano15: 0,
            ano16: 0,
            ano17: 0,
            ano18: 0,
            ano19: 0,
            ano20: 0,
          },
          {
            type: 'Impostos sobre outras importações',
            ano0: 0,
            ano1: 0,
            ano2: 0,
            ano3: 0,
            ano4: 0,
            ano5: 0,
            ano6: 0,
            ano7: 0,
            ano8: 0,
            ano9: 0,
            ano10: 0,
            ano11: 0,
            ano12: 0,
            ano13: 0,
            ano14: 0,
            ano15: 0,
            ano16: 0,
            ano17: 0,
            ano18: 0,
            ano19: 0,
            ano20: 0,
          },
          {
            type: 'ICMS',
            ano0: 0,
            ano1: 0,
            ano2: 0,
            ano3: 0,
            ano4: 0,
            ano5: 0,
            ano6: 0,
            ano7: 0,
            ano8: 0,
            ano9: 0,
            ano10: 0,
            ano11: 0,
            ano12: 0,
            ano13: 0,
            ano14: 0,
            ano15: 0,
            ano16: 0,
            ano17: 0,
            ano18: 0,
            ano19: 0,
            ano20: 0,
          },
          {
            type: 'PIS',
            ano0: 0,
            ano1: 0,
            ano2: 0,
            ano3: 0,
            ano4: 0,
            ano5: 0,
            ano6: 0,
            ano7: 0,
            ano8: 0,
            ano9: 0,
            ano10: 0,
            ano11: 0,
            ano12: 0,
            ano13: 0,
            ano14: 0,
            ano15: 0,
            ano16: 0,
            ano17: 0,
            ano18: 0,
            ano19: 0,
            ano20: 0,
          },
          {
            type: 'COFINS',
            ano0: 0,
            ano1: 0,
            ano2: 0,
            ano3: 0,
            ano4: 0,
            ano5: 0,
            ano6: 0,
            ano7: 0,
            ano8: 0,
            ano9: 0,
            ano10: 0,
            ano11: 0,
            ano12: 0,
            ano13: 0,
            ano14: 0,
            ano15: 0,
            ano16: 0,
            ano17: 0,
            ano18: 0,
            ano19: 0,
            ano20: 0,
          },
          {
            type: 'IPI',
            ano0: 0,
            ano1: 0,
            ano2: 0,
            ano3: 0,
            ano4: 0,
            ano5: 0,
            ano6: 0,
            ano7: 0,
            ano8: 0,
            ano9: 0,
            ano10: 0,
            ano11: 0,
            ano12: 0,
            ano13: 0,
            ano14: 0,
            ano15: 0,
            ano16: 0,
            ano17: 0,
            ano18: 0,
            ano19: 0,
            ano20: 0,
          },
          {
            type: 'OUTROS',
            ano0: 0,
            ano1: 0,
            ano2: 0,
            ano3: 0,
            ano4: 0,
            ano5: 0,
            ano6: 0,
            ano7: 0,
            ano8: 0,
            ano9: 0,
            ano10: 0,
            ano11: 0,
            ano12: 0,
            ano13: 0,
            ano14: 0,
            ano15: 0,
            ano16: 0,
            ano17: 0,
            ano18: 0,
            ano19: 0,
            ano20: 0,
          },
          {
            type: 'Obras e estruturas',
            ano0: 0,
            ano1: 0,
            ano2: 0,
            ano3: 0,
            ano4: 0,
            ano5: 0,
            ano6: 0,
            ano7: 0,
            ano8: 0,
            ano9: 0,
            ano10: 0,
            ano11: 0,
            ano12: 0,
            ano13: 0,
            ano14: 0,
            ano15: 0,
            ano16: 0,
            ano17: 0,
            ano18: 0,
            ano19: 0,
            ano20: 0,
          },
          {
            type: '% Pagamento',
            ano0: 0,
            ano1: 0,
            ano2: 0,
            ano3: 0,
            ano4: 0,
            ano5: 0,
            ano6: 0,
            ano7: 0,
            ano8: 0,
            ano9: 0,
            ano10: 0,
            ano11: 0,
            ano12: 0,
            ano13: 0,
            ano14: 0,
            ano15: 0,
            ano16: 0,
            ano17: 0,
            ano18: 0,
            ano19: 0,
            ano20: 0,
          },
          {
            type: 'Obras civis',
            ano0: 0,
            ano1: 0,
            ano2: 0,
            ano3: 0,
            ano4: 0,
            ano5: 0,
            ano6: 0,
            ano7: 0,
            ano8: 0,
            ano9: 0,
            ano10: 0,
            ano11: 0,
            ano12: 0,
            ano13: 0,
            ano14: 0,
            ano15: 0,
            ano16: 0,
            ano17: 0,
            ano18: 0,
            ano19: 0,
            ano20: 0,
          },
          {
            type: '% Pagamento',
            ano0: 0,
            ano1: 0,
            ano2: 0,
            ano3: 0,
            ano4: 0,
            ano5: 0,
            ano6: 0,
            ano7: 0,
            ano8: 0,
            ano9: 0,
            ano10: 0,
            ano11: 0,
            ano12: 0,
            ano13: 0,
            ano14: 0,
            ano15: 0,
            ano16: 0,
            ano17: 0,
            ano18: 0,
            ano19: 0,
            ano20: 0,
          },
          {
            type: 'Mobiliário',
            ano0: 0,
            ano1: 0,
            ano2: 0,
            ano3: 0,
            ano4: 0,
            ano5: 0,
            ano6: 0,
            ano7: 0,
            ano8: 0,
            ano9: 0,
            ano10: 0,
            ano11: 0,
            ano12: 0,
            ano13: 0,
            ano14: 0,
            ano15: 0,
            ano16: 0,
            ano17: 0,
            ano18: 0,
            ano19: 0,
            ano20: 0,
          },
        ]
        for (let i = 0; i <= 20; i += 1) {
          if (i === 0) {
            p[3][`ano${i}`] = parseFloat(this.dashboard.capex.proton.year) ? (100 * parseFloat(1 / parseFloat(this.dashboard.capex.proton.year))).toFixed(2) : 0
            p[19][`ano${i}`] = parseFloat(this.dashboard.capex.trueBeam1.year) ? (100 * parseFloat(1 / parseFloat(this.dashboard.capex.trueBeam1.year))).toFixed(2) : 0
            p[35][`ano${i}`] = parseFloat(this.dashboard.capex.trueBeam2.year) ? (100 * parseFloat(1 / parseFloat(this.dashboard.capex.trueBeam2.year))).toFixed(2) : 0
            p[51][`ano${i}`] = parseFloat(this.dashboard.capex.acelerator1.year) ? (100 * parseFloat(1 / parseFloat(this.dashboard.capex.acelerator1.year))).toFixed(2) : 0
            p[67][`ano${i}`] = parseFloat(this.dashboard.capex.acelerator2.year) ? (100 * parseFloat(1 / parseFloat(this.dashboard.capex.acelerator2.year))).toFixed(2) : 0
            p[83][`ano${i}`] = parseFloat(this.dashboard.capex.otherEquipments.year) ? (100 * parseFloat(1 / parseFloat(this.dashboard.capex.otherEquipments.year))).toFixed(2) : 0
            p[97][`ano${i}`] = parseFloat(this.dashboard.capex.handleAndStructure.year) ? (100 * parseFloat(1 / parseFloat(this.dashboard.capex.handleAndStructure.year))).toFixed(2) : 0
            p[99][`ano${i}`] = parseFloat(this.dashboard.capex.mobiliario.year) ? (100 * parseFloat(1 / parseFloat(this.dashboard.capex.mobiliario.year))).toFixed(2) : 0
          } else {
            let protonSum = 0
            let trueBeam1Sum = 0
            let trueBeam2Sum = 0
            let acelerator1Sum = 0
            let acelerator2Sum = 0
            let otherEquipmentsSum = 0
            let handleAndStructureSum = 0
            let mobiliarioSum = 0
            for (let j = 0; j < i; j += 1) {
              protonSum += parseFloat(p[3][`ano${j}`])
              trueBeam1Sum += parseFloat(p[19][`ano${j}`])
              trueBeam2Sum += parseFloat(p[35][`ano${j}`])
              acelerator1Sum += parseFloat(p[51][`ano${j}`])
              acelerator2Sum += parseFloat(p[67][`ano${j}`])
              otherEquipmentsSum += parseFloat(p[83][`ano${j}`])
              handleAndStructureSum += parseFloat(p[97][`ano${j}`])
              mobiliarioSum += parseFloat(p[99][`ano${j}`])
            }
            if (protonSum < 100) {
              p[3][`ano${i}`] = parseFloat(this.dashboard.capex.proton.year) ? (100 * parseFloat(1 / parseFloat(this.dashboard.capex.proton.year))).toFixed(2) : 0
            } else {
              p[3][`ano${i}`] = 0
            }
            if (trueBeam1Sum < 100) {
              p[19][`ano${i}`] = parseFloat(this.dashboard.capex.trueBeam1.year) ? (100 * parseFloat(1 / parseFloat(this.dashboard.capex.trueBeam1.year))).toFixed(2) : 0
            } else {
              p[19][`ano${i}`] = 0
            }
            if (trueBeam2Sum < 100) {
              p[35][`ano${i}`] = parseFloat(this.dashboard.capex.trueBeam2.year) ? (100 * parseFloat(1 / parseFloat(this.dashboard.capex.trueBeam2.year))).toFixed(2) : 0
            } else {
              p[35][`ano${i}`] = 0
            }
            if (acelerator1Sum < 100) {
              p[51][`ano${i}`] = parseFloat(this.dashboard.capex.acelerator1.year) ? (100 * parseFloat(1 / parseFloat(this.dashboard.capex.acelerator1.year))).toFixed(2) : 0
            } else {
              p[51][`ano${i}`] = 0
            }
            if (acelerator2Sum < 100) {
              p[67][`ano${i}`] = parseFloat(this.dashboard.capex.acelerator2.year) ? (100 * parseFloat(1 / parseFloat(this.dashboard.capex.acelerator2.year))).toFixed(2) : 0
            } else {
              p[67][`ano${i}`] = 0
            }
            if (otherEquipmentsSum < 100) {
              p[83][`ano${i}`] = parseFloat(this.dashboard.capex.otherEquipments.year) ? (100 * parseFloat(1 / parseFloat(this.dashboard.capex.otherEquipments.year))).toFixed(2) : 0
            } else {
              p[83][`ano${i}`] = 0
            }
            if (handleAndStructureSum < 100) {
              p[97][`ano${i}`] = parseFloat(this.dashboard.capex.handleAndStructure.year) ? (100 * parseFloat(1 / parseFloat(this.dashboard.capex.handleAndStructure.year))).toFixed(2) : 0
            } else {
              p[97][`ano${i}`] = 0
            }
            if (mobiliarioSum < 100) {
              p[99][`ano${i}`] = parseFloat(this.dashboard.capex.mobiliario.year) ? (100 * parseFloat(1 / parseFloat(this.dashboard.capex.mobiliario.year))).toFixed(2) : 0
            } else {
              p[99][`ano${i}`] = 0
            }
          }
          // Proton
          p[2][`ano${i}`] = (parseFloat(this.dashboard.capex.proton.cost) * parseFloat(p[3][`ano${i}`] / 100) * parseFloat(this.premissasFinanceirasItems[2][`ano${i}`]) * parseFloat(this.premissasFinanceirasItems[4][`ano${i}`]) * (1 + (parseFloat(this.dashboard.capex.others.reservaTecnica ? this.dashboard.capex.others.reservaTecnica : 0) / 100))).toFixed(2)
          p[2][`ano${i}`] = Number.isNaN(parseFloat(p[2][`ano${i}`])) ? 0 : p[2][`ano${i}`]
          // True beam 1
          p[18][`ano${i}`] = (parseFloat(this.dashboard.capex.trueBeam1.cost) * parseFloat(p[19][`ano${i}`] / 100) * parseFloat(this.premissasFinanceirasItems[2][`ano${i}`]) * parseFloat(this.premissasFinanceirasItems[4][`ano${i}`]) * (1 + (parseFloat(this.dashboard.capex.others.reservaTecnica ? this.dashboard.capex.others.reservaTecnica : 0) / 100))).toFixed(2)
          p[18][`ano${i}`] = Number.isNaN(parseFloat(p[18][`ano${i}`])) ? 0 : p[18][`ano${i}`]
          // True beam 2
          p[34][`ano${i}`] = (parseFloat(this.dashboard.capex.trueBeam2.cost) * parseFloat(p[35][`ano${i}`] / 100) * parseFloat(this.premissasFinanceirasItems[2][`ano${i}`]) * parseFloat(this.premissasFinanceirasItems[4][`ano${i}`]) * (1 + (parseFloat(this.dashboard.capex.others.reservaTecnica ? this.dashboard.capex.others.reservaTecnica : 0) / 100))).toFixed(2)
          p[34][`ano${i}`] = Number.isNaN(parseFloat(p[34][`ano${i}`])) ? 0 : p[34][`ano${i}`]
          // Acelerator 1
          p[50][`ano${i}`] = (parseFloat(this.dashboard.capex.acelerator1.cost) * parseFloat(p[51][`ano${i}`] / 100) * parseFloat(this.premissasFinanceirasItems[2][`ano${i}`]) * parseFloat(this.premissasFinanceirasItems[4][`ano${i}`]) * (1 + (parseFloat(this.dashboard.capex.others.reservaTecnica ? this.dashboard.capex.others.reservaTecnica : 0) / 100))).toFixed(2)
          p[50][`ano${i}`] = Number.isNaN(parseFloat(p[50][`ano${i}`])) ? 0 : p[50][`ano${i}`]
          // Acelerator 2
          p[66][`ano${i}`] = (parseFloat(this.dashboard.capex.acelerator2.cost) * parseFloat(p[67][`ano${i}`] / 100) * parseFloat(this.premissasFinanceirasItems[2][`ano${i}`]) * parseFloat(this.premissasFinanceirasItems[4][`ano${i}`]) * (1 + (parseFloat(this.dashboard.capex.others.reservaTecnica ? this.dashboard.capex.others.reservaTecnica : 0) / 100))).toFixed(2)
          p[66][`ano${i}`] = Number.isNaN(parseFloat(p[66][`ano${i}`])) ? 0 : p[66][`ano${i}`]
          // Outros equipamentos
          p[84][`ano${i}`] = (parseFloat(this.dashboard.capex.anotherEquipments.dosimetria) * (parseFloat(p[83][`ano${i}`]) / 100) * (1 + (parseFloat(this.dashboard.capex.others.reservaTecnica ? this.dashboard.capex.others.reservaTecnica : 0) / 100)) * parseFloat(this.premissasFinanceirasItems[4][`ano${i}`]) * parseFloat(this.premissasFinanceirasItems[2][`ano${i}`])).toFixed(2)
          p[85][`ano${i}`] = (parseFloat(this.dashboard.capex.anotherEquipments.acessorioImobilizacao) * (parseFloat(p[83][`ano${i}`]) / 100) * (1 + (parseFloat(this.dashboard.capex.others.reservaTecnica ? this.dashboard.capex.others.reservaTecnica : 0) / 100)) * parseFloat(this.premissasFinanceirasItems[4][`ano${i}`]) * parseFloat(this.premissasFinanceirasItems[2][`ano${i}`])).toFixed(2)
          p[86][`ano${i}`] = (parseFloat(this.dashboard.capex.anotherEquipments.software) * (parseFloat(p[83][`ano${i}`]) / 100) * (1 + (parseFloat(this.dashboard.capex.others.reservaTecnica ? this.dashboard.capex.others.reservaTecnica : 0) / 100)) * parseFloat(this.premissasFinanceirasItems[4][`ano${i}`]) * parseFloat(this.premissasFinanceirasItems[2][`ano${i}`])).toFixed(2)
          p[87][`ano${i}`] = (parseFloat(this.dashboard.capex.anotherEquipments.ressonanciaMagnetica) * (parseFloat(p[83][`ano${i}`]) / 100) * (1 + (parseFloat(this.dashboard.capex.others.reservaTecnica ? this.dashboard.capex.others.reservaTecnica : 0) / 100)) * parseFloat(this.premissasFinanceirasItems[4][`ano${i}`]) * parseFloat(this.premissasFinanceirasItems[2][`ano${i}`])).toFixed(2)
          p[88][`ano${i}`] = (parseFloat(this.dashboard.capex.anotherEquipments.tomografia) * (parseFloat(p[83][`ano${i}`]) / 100) * (1 + (parseFloat(this.dashboard.capex.others.reservaTecnica ? this.dashboard.capex.others.reservaTecnica : 0) / 100)) * parseFloat(this.premissasFinanceirasItems[4][`ano${i}`]) * parseFloat(this.premissasFinanceirasItems[2][`ano${i}`])).toFixed(2)
          p[89][`ano${i}`] = (parseFloat(this.dashboard.capex.anotherEquipments.outros) * (parseFloat(p[83][`ano${i}`]) / 100) * (1 + (parseFloat(this.dashboard.capex.others.reservaTecnica ? this.dashboard.capex.others.reservaTecnica : 0) / 100)) * parseFloat(this.premissasFinanceirasItems[4][`ano${i}`]) * parseFloat(this.premissasFinanceirasItems[2][`ano${i}`])).toFixed(2)
          // Obras civis e mobiliario
          p[98][`ano${i}`] = ((parseFloat(p[97][`ano${i}`]) / 100) * (parseFloat(this.dashboard.capex.obras.civil)) * (parseFloat(this.premissasFinanceirasItems[6][`ano${i}`])) * (1 + (parseFloat(this.dashboard.capex.others.reservaTecnica ? this.dashboard.capex.others.reservaTecnica : 0) / 100))).toFixed(2)
          p[100][`ano${i}`] = ((parseFloat(p[99][`ano${i}`]) / 100) * (parseFloat(this.dashboard.capex.obras.mobiliario)) * (parseFloat(this.premissasFinanceirasItems[6][`ano${i}`])) * (1 + (parseFloat(this.dashboard.capex.others.reservaTecnica ? this.dashboard.capex.others.reservaTecnica : 0) / 100))).toFixed(2)
          p[96][`ano${i}`] = (parseFloat(p[98][`ano${i}`]) + parseFloat(p[100][`ano${i}`])).toFixed(2)
          // CUSTO IMPORTACAO
          // Proton
          p[11][`ano${i}`] = (parseFloat(p[2][`ano${i}`]) ? parseFloat(p[2][`ano${i}`]) * (1 + (parseFloat(this.dashboard.capex.others.reservaTecnica) / 100)) * parseFloat(this.premissasFinanceirasItems[6][`ano${i}`]) * (parseFloat(this.dashboard.capex.importTax.seguro) / 100) : 0).toFixed(2)
          p[12][`ano${i}`] = (parseFloat(p[2][`ano${i}`]) ? parseFloat(p[2][`ano${i}`]) * (1 + (parseFloat(this.dashboard.capex.others.reservaTecnica) / 100)) * parseFloat(this.premissasFinanceirasItems[6][`ano${i}`]) * (parseFloat(this.dashboard.capex.importTax.frete) / 100) : 0).toFixed(2)
          p[13][`ano${i}`] = (parseFloat(p[2][`ano${i}`]) ? parseFloat(p[2][`ano${i}`]) * (1 + (parseFloat(this.dashboard.capex.others.reservaTecnica) / 100)) * parseFloat(this.premissasFinanceirasItems[6][`ano${i}`]) * (parseFloat(this.dashboard.capex.importTax.taxas) / 100) : 0).toFixed(2)
          p[14][`ano${i}`] = (parseFloat(p[2][`ano${i}`]) ? parseFloat(p[2][`ano${i}`]) * (1 + (parseFloat(this.dashboard.capex.others.reservaTecnica) / 100)) * parseFloat(this.premissasFinanceirasItems[6][`ano${i}`]) * (parseFloat(this.dashboard.capex.importTax.portosArmazenamento) / 100) : 0).toFixed(2)
          p[15][`ano${i}`] = (parseFloat(p[2][`ano${i}`]) ? parseFloat(p[2][`ano${i}`]) * (1 + (parseFloat(this.dashboard.capex.others.reservaTecnica) / 100)) * parseFloat(this.premissasFinanceirasItems[6][`ano${i}`]) * (parseFloat(this.dashboard.capex.importTax.acessoria) / 100) : 0).toFixed(2)
          p[16][`ano${i}`] = (parseFloat(p[2][`ano${i}`]) ? parseFloat(p[2][`ano${i}`]) * (1 + (parseFloat(this.dashboard.capex.others.reservaTecnica) / 100)) * parseFloat(this.premissasFinanceirasItems[6][`ano${i}`]) * (parseFloat(this.dashboard.capex.importTax.transporte) / 100) : 0).toFixed(2)
          p[17][`ano${i}`] = (parseFloat(p[2][`ano${i}`]) ? parseFloat(p[2][`ano${i}`]) * (1 + (parseFloat(this.dashboard.capex.others.reservaTecnica) / 100)) * parseFloat(this.premissasFinanceirasItems[6][`ano${i}`]) * (parseFloat(this.dashboard.capex.importTax.desembaraco) / 100) : 0).toFixed(2)
          p[10][`ano${i}`] = parseFloat(p[11][`ano${i}`]) + parseFloat(p[12][`ano${i}`]) + parseFloat(p[13][`ano${i}`]) + parseFloat(p[14][`ano${i}`]) + parseFloat(p[15][`ano${i}`]) + parseFloat(p[16][`ano${i}`]) + parseFloat(p[17][`ano${i}`])
          // True Beam 1
          p[27][`ano${i}`] = (parseFloat(p[18][`ano${i}`]) ? parseFloat(p[18][`ano${i}`]) * (1 + (parseFloat(this.dashboard.capex.others.reservaTecnica) / 100)) * parseFloat(this.premissasFinanceirasItems[6][`ano${i}`]) * (parseFloat(this.dashboard.capex.importTax.seguro) / 100) : 0).toFixed(2)
          p[28][`ano${i}`] = (parseFloat(p[18][`ano${i}`]) ? parseFloat(p[18][`ano${i}`]) * (1 + (parseFloat(this.dashboard.capex.others.reservaTecnica) / 100)) * parseFloat(this.premissasFinanceirasItems[6][`ano${i}`]) * (parseFloat(this.dashboard.capex.importTax.frete) / 100) : 0).toFixed(2)
          p[29][`ano${i}`] = (parseFloat(p[18][`ano${i}`]) ? parseFloat(p[18][`ano${i}`]) * (1 + (parseFloat(this.dashboard.capex.others.reservaTecnica) / 100)) * parseFloat(this.premissasFinanceirasItems[6][`ano${i}`]) * (parseFloat(this.dashboard.capex.importTax.taxas) / 100) : 0).toFixed(2)
          p[30][`ano${i}`] = (parseFloat(p[18][`ano${i}`]) ? parseFloat(p[18][`ano${i}`]) * (1 + (parseFloat(this.dashboard.capex.others.reservaTecnica) / 100)) * parseFloat(this.premissasFinanceirasItems[6][`ano${i}`]) * (parseFloat(this.dashboard.capex.importTax.portosArmazenamento) / 100) : 0).toFixed(2)
          p[31][`ano${i}`] = (parseFloat(p[18][`ano${i}`]) ? parseFloat(p[18][`ano${i}`]) * (1 + (parseFloat(this.dashboard.capex.others.reservaTecnica) / 100)) * parseFloat(this.premissasFinanceirasItems[6][`ano${i}`]) * (parseFloat(this.dashboard.capex.importTax.acessoria) / 100) : 0).toFixed(2)
          p[32][`ano${i}`] = (parseFloat(p[18][`ano${i}`]) ? parseFloat(p[18][`ano${i}`]) * (1 + (parseFloat(this.dashboard.capex.others.reservaTecnica) / 100)) * parseFloat(this.premissasFinanceirasItems[6][`ano${i}`]) * (parseFloat(this.dashboard.capex.importTax.transporte) / 100) : 0).toFixed(2)
          p[33][`ano${i}`] = (parseFloat(p[18][`ano${i}`]) ? parseFloat(p[18][`ano${i}`]) * (1 + (parseFloat(this.dashboard.capex.others.reservaTecnica) / 100)) * parseFloat(this.premissasFinanceirasItems[6][`ano${i}`]) * (parseFloat(this.dashboard.capex.importTax.desembaraco) / 100) : 0).toFixed(2)
          p[26][`ano${i}`] = parseFloat(p[27][`ano${i}`]) + parseFloat(p[28][`ano${i}`]) + parseFloat(p[29][`ano${i}`]) + parseFloat(p[30][`ano${i}`]) + parseFloat(p[31][`ano${i}`]) + parseFloat(p[32][`ano${i}`]) + parseFloat(p[33][`ano${i}`])
          // True Beam 2
          p[43][`ano${i}`] = (parseFloat(p[34][`ano${i}`]) ? parseFloat(p[34][`ano${i}`]) * (1 + (parseFloat(this.dashboard.capex.others.reservaTecnica) / 100)) * parseFloat(this.premissasFinanceirasItems[6][`ano${i}`]) * (parseFloat(this.dashboard.capex.importTax.seguro) / 100) : 0).toFixed(2)
          p[44][`ano${i}`] = (parseFloat(p[34][`ano${i}`]) ? parseFloat(p[34][`ano${i}`]) * (1 + (parseFloat(this.dashboard.capex.others.reservaTecnica) / 100)) * parseFloat(this.premissasFinanceirasItems[6][`ano${i}`]) * (parseFloat(this.dashboard.capex.importTax.frete) / 100) : 0).toFixed(2)
          p[45][`ano${i}`] = (parseFloat(p[34][`ano${i}`]) ? parseFloat(p[34][`ano${i}`]) * (1 + (parseFloat(this.dashboard.capex.others.reservaTecnica) / 100)) * parseFloat(this.premissasFinanceirasItems[6][`ano${i}`]) * (parseFloat(this.dashboard.capex.importTax.taxas) / 100) : 0).toFixed(2)
          p[46][`ano${i}`] = (parseFloat(p[34][`ano${i}`]) ? parseFloat(p[34][`ano${i}`]) * (1 + (parseFloat(this.dashboard.capex.others.reservaTecnica) / 100)) * parseFloat(this.premissasFinanceirasItems[6][`ano${i}`]) * (parseFloat(this.dashboard.capex.importTax.portosArmazenamento) / 100) : 0).toFixed(2)
          p[47][`ano${i}`] = (parseFloat(p[34][`ano${i}`]) ? parseFloat(p[34][`ano${i}`]) * (1 + (parseFloat(this.dashboard.capex.others.reservaTecnica) / 100)) * parseFloat(this.premissasFinanceirasItems[6][`ano${i}`]) * (parseFloat(this.dashboard.capex.importTax.acessoria) / 100) : 0).toFixed(2)
          p[48][`ano${i}`] = (parseFloat(p[34][`ano${i}`]) ? parseFloat(p[34][`ano${i}`]) * (1 + (parseFloat(this.dashboard.capex.others.reservaTecnica) / 100)) * parseFloat(this.premissasFinanceirasItems[6][`ano${i}`]) * (parseFloat(this.dashboard.capex.importTax.transporte) / 100) : 0).toFixed(2)
          p[49][`ano${i}`] = (parseFloat(p[34][`ano${i}`]) ? parseFloat(p[34][`ano${i}`]) * (1 + (parseFloat(this.dashboard.capex.others.reservaTecnica) / 100)) * parseFloat(this.premissasFinanceirasItems[6][`ano${i}`]) * (parseFloat(this.dashboard.capex.importTax.desembaraco) / 100) : 0).toFixed(2)
          p[42][`ano${i}`] = parseFloat(p[43][`ano${i}`]) + parseFloat(p[44][`ano${i}`]) + parseFloat(p[45][`ano${i}`]) + parseFloat(p[46][`ano${i}`]) + parseFloat(p[47][`ano${i}`]) + parseFloat(p[48][`ano${i}`]) + parseFloat(p[49][`ano${i}`])
          // Acelerator 1
          p[59][`ano${i}`] = (parseFloat(p[50][`ano${i}`]) ? parseFloat(p[50][`ano${i}`]) * (1 + (parseFloat(this.dashboard.capex.others.reservaTecnica) / 100)) * parseFloat(this.premissasFinanceirasItems[6][`ano${i}`]) * (parseFloat(this.dashboard.capex.importTax.seguro) / 100) : 0).toFixed(2)
          p[60][`ano${i}`] = (parseFloat(p[50][`ano${i}`]) ? parseFloat(p[50][`ano${i}`]) * (1 + (parseFloat(this.dashboard.capex.others.reservaTecnica) / 100)) * parseFloat(this.premissasFinanceirasItems[6][`ano${i}`]) * (parseFloat(this.dashboard.capex.importTax.frete) / 100) : 0).toFixed(2)
          p[61][`ano${i}`] = (parseFloat(p[50][`ano${i}`]) ? parseFloat(p[50][`ano${i}`]) * (1 + (parseFloat(this.dashboard.capex.others.reservaTecnica) / 100)) * parseFloat(this.premissasFinanceirasItems[6][`ano${i}`]) * (parseFloat(this.dashboard.capex.importTax.taxas) / 100) : 0).toFixed(2)
          p[62][`ano${i}`] = (parseFloat(p[50][`ano${i}`]) ? parseFloat(p[50][`ano${i}`]) * (1 + (parseFloat(this.dashboard.capex.others.reservaTecnica) / 100)) * parseFloat(this.premissasFinanceirasItems[6][`ano${i}`]) * (parseFloat(this.dashboard.capex.importTax.portosArmazenamento) / 100) : 0).toFixed(2)
          p[63][`ano${i}`] = (parseFloat(p[50][`ano${i}`]) ? parseFloat(p[50][`ano${i}`]) * (1 + (parseFloat(this.dashboard.capex.others.reservaTecnica) / 100)) * parseFloat(this.premissasFinanceirasItems[6][`ano${i}`]) * (parseFloat(this.dashboard.capex.importTax.acessoria) / 100) : 0).toFixed(2)
          p[64][`ano${i}`] = (parseFloat(p[50][`ano${i}`]) ? parseFloat(p[50][`ano${i}`]) * (1 + (parseFloat(this.dashboard.capex.others.reservaTecnica) / 100)) * parseFloat(this.premissasFinanceirasItems[6][`ano${i}`]) * (parseFloat(this.dashboard.capex.importTax.transporte) / 100) : 0).toFixed(2)
          p[65][`ano${i}`] = (parseFloat(p[50][`ano${i}`]) ? parseFloat(p[50][`ano${i}`]) * (1 + (parseFloat(this.dashboard.capex.others.reservaTecnica) / 100)) * parseFloat(this.premissasFinanceirasItems[6][`ano${i}`]) * (parseFloat(this.dashboard.capex.importTax.desembaraco) / 100) : 0).toFixed(2)
          p[58][`ano${i}`] = parseFloat(p[59][`ano${i}`]) + parseFloat(p[60][`ano${i}`]) + parseFloat(p[61][`ano${i}`]) + parseFloat(p[62][`ano${i}`]) + parseFloat(p[63][`ano${i}`]) + parseFloat(p[64][`ano${i}`]) + parseFloat(p[65][`ano${i}`])
          // Acelerator 2
          p[75][`ano${i}`] = (parseFloat(p[66][`ano${i}`]) ? parseFloat(p[66][`ano${i}`]) * (1 + (parseFloat(this.dashboard.capex.others.reservaTecnica) / 100)) * parseFloat(this.premissasFinanceirasItems[6][`ano${i}`]) * (parseFloat(this.dashboard.capex.importTax.seguro) / 100) : 0).toFixed(2)
          p[76][`ano${i}`] = (parseFloat(p[66][`ano${i}`]) ? parseFloat(p[66][`ano${i}`]) * (1 + (parseFloat(this.dashboard.capex.others.reservaTecnica) / 100)) * parseFloat(this.premissasFinanceirasItems[6][`ano${i}`]) * (parseFloat(this.dashboard.capex.importTax.frete) / 100) : 0).toFixed(2)
          p[77][`ano${i}`] = (parseFloat(p[66][`ano${i}`]) ? parseFloat(p[66][`ano${i}`]) * (1 + (parseFloat(this.dashboard.capex.others.reservaTecnica) / 100)) * parseFloat(this.premissasFinanceirasItems[6][`ano${i}`]) * (parseFloat(this.dashboard.capex.importTax.taxas) / 100) : 0).toFixed(2)
          p[78][`ano${i}`] = (parseFloat(p[66][`ano${i}`]) ? parseFloat(p[66][`ano${i}`]) * (1 + (parseFloat(this.dashboard.capex.others.reservaTecnica) / 100)) * parseFloat(this.premissasFinanceirasItems[6][`ano${i}`]) * (parseFloat(this.dashboard.capex.importTax.portosArmazenamento) / 100) : 0).toFixed(2)
          p[79][`ano${i}`] = (parseFloat(p[66][`ano${i}`]) ? parseFloat(p[66][`ano${i}`]) * (1 + (parseFloat(this.dashboard.capex.others.reservaTecnica) / 100)) * parseFloat(this.premissasFinanceirasItems[6][`ano${i}`]) * (parseFloat(this.dashboard.capex.importTax.acessoria) / 100) : 0).toFixed(2)
          p[80][`ano${i}`] = (parseFloat(p[66][`ano${i}`]) ? parseFloat(p[66][`ano${i}`]) * (1 + (parseFloat(this.dashboard.capex.others.reservaTecnica) / 100)) * parseFloat(this.premissasFinanceirasItems[6][`ano${i}`]) * (parseFloat(this.dashboard.capex.importTax.transporte) / 100) : 0).toFixed(2)
          p[81][`ano${i}`] = (parseFloat(p[66][`ano${i}`]) ? parseFloat(p[66][`ano${i}`]) * (1 + (parseFloat(this.dashboard.capex.others.reservaTecnica) / 100)) * parseFloat(this.premissasFinanceirasItems[6][`ano${i}`]) * (parseFloat(this.dashboard.capex.importTax.desembaraco) / 100) : 0).toFixed(2)
          p[74][`ano${i}`] = parseFloat(p[75][`ano${i}`]) + parseFloat(p[76][`ano${i}`]) + parseFloat(p[77][`ano${i}`]) + parseFloat(p[78][`ano${i}`]) + parseFloat(p[79][`ano${i}`]) + parseFloat(p[80][`ano${i}`]) + parseFloat(p[81][`ano${i}`])
          // Custo taxas
          const pisTax = (parseFloat(this.dashboard.capex.tax.pis) / 100)
          const cofinsTax = (parseFloat(this.dashboard.capex.tax.cofins) / 100)
          const outrosTax = (parseFloat(this.dashboard.capex.tax.outros) / 100)
          const ipiTax = (parseFloat(this.dashboard.capex.tax.ipi) / 100)
          const icmsTax = (parseFloat(this.dashboard.capex.tax.icms) / 100)
          // Proton
          p[6][`ano${i}`] = (parseFloat(p[2][`ano${i}`]) ? parseFloat(p[2][`ano${i}`]) * pisTax : 0).toFixed(2)
          p[7][`ano${i}`] = (parseFloat(p[2][`ano${i}`]) ? parseFloat(p[2][`ano${i}`]) * cofinsTax : 0).toFixed(2)
          p[9][`ano${i}`] = (parseFloat(p[2][`ano${i}`]) ? parseFloat(p[2][`ano${i}`]) * outrosTax : 0).toFixed(2)
          p[8][`ano${i}`] = (parseFloat(p[2][`ano${i}`]) ? (parseFloat(p[2][`ano${i}`]) + parseFloat(p[9][`ano${i}`])) * ipiTax : 0).toFixed(2)
          p[5][`ano${i}`] = (parseFloat(p[2][`ano${i}`]) ? ((icmsTax / (1 - icmsTax)) * p[10][`ano${i}`]) + ((icmsTax / (1 - icmsTax)) * p[2][`ano${i}`]) + ((icmsTax / (1 - icmsTax)) * (parseFloat(p[6][`ano${i}`]) + parseFloat(p[7][`ano${i}`]) + parseFloat(p[8][`ano${i}`]) + parseFloat(p[9][`ano${i}`]))) : 0).toFixed(2)
          p[4][`ano${i}`] = (parseFloat(p[5][`ano${i}`]) + parseFloat(p[6][`ano${i}`]) + parseFloat(p[7][`ano${i}`]) + parseFloat(p[8][`ano${i}`])).toFixed(2)
          // True beam 1
          p[22][`ano${i}`] = (parseFloat(p[18][`ano${i}`]) ? parseFloat(p[18][`ano${i}`]) * pisTax : 0).toFixed(2)
          p[23][`ano${i}`] = (parseFloat(p[18][`ano${i}`]) ? parseFloat(p[18][`ano${i}`]) * cofinsTax : 0).toFixed(2)
          p[25][`ano${i}`] = (parseFloat(p[18][`ano${i}`]) ? parseFloat(p[18][`ano${i}`]) * outrosTax : 0).toFixed(2)
          p[24][`ano${i}`] = (parseFloat(p[18][`ano${i}`]) ? (parseFloat(p[18][`ano${i}`]) + parseFloat(p[25][`ano${i}`])) * ipiTax : 0).toFixed(2)
          p[21][`ano${i}`] = (parseFloat(p[18][`ano${i}`]) ? ((icmsTax / (1 - icmsTax)) * p[26][`ano${i}`]) + ((icmsTax / (1 - icmsTax)) * p[18][`ano${i}`]) + ((icmsTax / (1 - icmsTax)) * (parseFloat(p[22][`ano${i}`]) + parseFloat(p[23][`ano${i}`]) + parseFloat(p[24][`ano${i}`]) + parseFloat(p[25][`ano${i}`]))) : 0).toFixed(2)
          p[20][`ano${i}`] = (parseFloat(p[21][`ano${i}`]) + parseFloat(p[22][`ano${i}`]) + parseFloat(p[23][`ano${i}`]) + parseFloat(p[24][`ano${i}`])).toFixed(2)
          // True beam 2
          p[38][`ano${i}`] = (parseFloat(p[34][`ano${i}`]) ? parseFloat(p[34][`ano${i}`]) * pisTax : 0).toFixed(2)
          p[39][`ano${i}`] = (parseFloat(p[34][`ano${i}`]) ? parseFloat(p[34][`ano${i}`]) * cofinsTax : 0).toFixed(2)
          p[41][`ano${i}`] = (parseFloat(p[34][`ano${i}`]) ? parseFloat(p[34][`ano${i}`]) * outrosTax : 0).toFixed(2)
          p[40][`ano${i}`] = (parseFloat(p[34][`ano${i}`]) ? (parseFloat(p[34][`ano${i}`]) + parseFloat(p[41][`ano${i}`])) * ipiTax : 0).toFixed(2)
          p[37][`ano${i}`] = (parseFloat(p[34][`ano${i}`]) ? ((icmsTax / (1 - icmsTax)) * p[42][`ano${i}`]) + ((icmsTax / (1 - icmsTax)) * p[34][`ano${i}`]) + ((icmsTax / (1 - icmsTax)) * (parseFloat(p[38][`ano${i}`]) + parseFloat(p[39][`ano${i}`]) + parseFloat(p[40][`ano${i}`]) + parseFloat(p[41][`ano${i}`]))) : 0).toFixed(2)
          p[36][`ano${i}`] = (parseFloat(p[37][`ano${i}`]) + parseFloat(p[38][`ano${i}`]) + parseFloat(p[39][`ano${i}`]) + parseFloat(p[40][`ano${i}`])).toFixed(2)
          // Acelerator 1
          p[54][`ano${i}`] = (parseFloat(p[50][`ano${i}`]) ? parseFloat(p[50][`ano${i}`]) * pisTax : 0).toFixed(2)
          p[55][`ano${i}`] = (parseFloat(p[50][`ano${i}`]) ? parseFloat(p[50][`ano${i}`]) * cofinsTax : 0).toFixed(2)
          p[57][`ano${i}`] = (parseFloat(p[50][`ano${i}`]) ? parseFloat(p[50][`ano${i}`]) * outrosTax : 0).toFixed(2)
          p[56][`ano${i}`] = (parseFloat(p[50][`ano${i}`]) ? (parseFloat(p[50][`ano${i}`]) + parseFloat(p[57][`ano${i}`])) * ipiTax : 0).toFixed(2)
          p[53][`ano${i}`] = (parseFloat(p[50][`ano${i}`]) ? ((icmsTax / (1 - icmsTax)) * p[58][`ano${i}`]) + ((icmsTax / (1 - icmsTax)) * p[50][`ano${i}`]) + ((icmsTax / (1 - icmsTax)) * (parseFloat(p[54][`ano${i}`]) + parseFloat(p[55][`ano${i}`]) + parseFloat(p[56][`ano${i}`]) + parseFloat(p[57][`ano${i}`]))) : 0).toFixed(2)
          p[52][`ano${i}`] = (parseFloat(p[53][`ano${i}`]) + parseFloat(p[54][`ano${i}`]) + parseFloat(p[55][`ano${i}`]) + parseFloat(p[56][`ano${i}`])).toFixed(2)
          // Acelerator 2
          p[70][`ano${i}`] = (parseFloat(p[66][`ano${i}`]) ? parseFloat(p[66][`ano${i}`]) * pisTax : 0).toFixed(2)
          p[71][`ano${i}`] = (parseFloat(p[66][`ano${i}`]) ? parseFloat(p[66][`ano${i}`]) * cofinsTax : 0).toFixed(2)
          p[73][`ano${i}`] = (parseFloat(p[66][`ano${i}`]) ? parseFloat(p[66][`ano${i}`]) * outrosTax : 0).toFixed(2)
          p[72][`ano${i}`] = (parseFloat(p[66][`ano${i}`]) ? (parseFloat(p[66][`ano${i}`]) + parseFloat(p[73][`ano${i}`])) * ipiTax : 0).toFixed(2)
          p[69][`ano${i}`] = (parseFloat(p[66][`ano${i}`]) ? ((icmsTax / (1 - icmsTax)) * p[74][`ano${i}`]) + ((icmsTax / (1 - icmsTax)) * p[66][`ano${i}`]) + ((icmsTax / (1 - icmsTax)) * (parseFloat(p[70][`ano${i}`]) + parseFloat(p[71][`ano${i}`]) + parseFloat(p[72][`ano${i}`]) + parseFloat(p[73][`ano${i}`]))) : 0).toFixed(2)
          p[68][`ano${i}`] = (parseFloat(p[69][`ano${i}`]) + parseFloat(p[70][`ano${i}`]) + parseFloat(p[71][`ano${i}`]) + parseFloat(p[72][`ano${i}`])).toFixed(2)

          p[1][`ano${i}`] = (
            parseFloat(p[2][`ano${i}`]) + parseFloat(p[4][`ano${i}`]) + parseFloat(p[10][`ano${i}`])
            + parseFloat(p[18][`ano${i}`]) + parseFloat(p[20][`ano${i}`]) + parseFloat(p[26][`ano${i}`])
            + parseFloat(p[34][`ano${i}`]) + parseFloat(p[36][`ano${i}`]) + parseFloat(p[42][`ano${i}`])
            + parseFloat(p[40][`ano${i}`]) + parseFloat(p[52][`ano${i}`]) + parseFloat(p[58][`ano${i}`])
            + parseFloat(p[56][`ano${i}`]) + parseFloat(p[68][`ano${i}`]) + parseFloat(p[74][`ano${i}`])
          ).toFixed(2)

          // Outros equipamentos
          p[92][`ano${i}`] = this.staff.cenarioSelected === 'Lucrativo' ? ((parseFloat(p[84][`ano${i}`]) + parseFloat(p[86][`ano${i}`]) + parseFloat(p[87][`ano${i}`]) + parseFloat(p[88][`ano${i}`]) + parseFloat(p[89][`ano${i}`])) * pisTax).toFixed(2) : 0
          p[93][`ano${i}`] = this.staff.cenarioSelected === 'Lucrativo' ? ((parseFloat(p[84][`ano${i}`]) + parseFloat(p[86][`ano${i}`]) + parseFloat(p[87][`ano${i}`]) + parseFloat(p[88][`ano${i}`]) + parseFloat(p[89][`ano${i}`])) * cofinsTax).toFixed(2) : 0
          p[95][`ano${i}`] = ((parseFloat(p[84][`ano${i}`]) + parseFloat(p[86][`ano${i}`]) + parseFloat(p[87][`ano${i}`]) + parseFloat(p[88][`ano${i}`]) + parseFloat(p[89][`ano${i}`])) * outrosTax).toFixed(2)
          p[94][`ano${i}`] = this.staff.cenarioSelected === 'Lucrativo' ? ((parseFloat(p[95][`ano${i}`]) + parseFloat(p[84][`ano${i}`]) + parseFloat(p[86][`ano${i}`]) + parseFloat(p[87][`ano${i}`]) + parseFloat(p[88][`ano${i}`]) + parseFloat(p[89][`ano${i}`])) * ipiTax).toFixed(2) : 0
          p[91][`ano${i}`] = ((icmsTax / (1 - icmsTax)) * (parseFloat(p[84][`ano${i}`]) + parseFloat(p[86][`ano${i}`]) + parseFloat(p[87][`ano${i}`]) + parseFloat(p[88][`ano${i}`]) + parseFloat(p[89][`ano${i}`]) + parseFloat(p[92][`ano${i}`]) + parseFloat(p[93][`ano${i}`]) + parseFloat(p[94][`ano${i}`]) + parseFloat(p[95][`ano${i}`]))).toFixed(2)
          p[90][`ano${i}`] = (parseFloat(p[91][`ano${i}`]) + parseFloat(p[92][`ano${i}`]) + parseFloat(p[93][`ano${i}`]) + parseFloat(p[94][`ano${i}`]) + parseFloat(p[95][`ano${i}`])).toFixed(2)
          p[82][`ano${i}`] = (parseFloat(p[84][`ano${i}`]) + parseFloat(p[85][`ano${i}`]) + parseFloat(p[86][`ano${i}`]) + parseFloat(p[87][`ano${i}`]) + parseFloat(p[88][`ano${i}`]) + parseFloat(p[90][`ano${i}`])).toFixed(2)

          // TOTAL
          p[0][`ano${i}`] = (parseFloat(p[1][`ano${i}`]) + parseFloat(p[82][`ano${i}`]) + parseFloat(p[96][`ano${i}`])).toFixed(2)
        }
        return p
      }
      return []
    },
    manutencaoItems() {
      if (this.dashboard && this.dashboard.project && this.dashboard.project.startYear && this.dashboard.capex && this.premissasFinanceirasItems && this.premissasFinanceirasItems.length) {
        const p = [
          {
            type: 'Manutencão',
            ano0: 0,
            ano1: 0,
            ano2: 0,
            ano3: 0,
            ano4: 0,
            ano5: 0,
            ano6: 0,
            ano7: 0,
            ano8: 0,
            ano9: 0,
            ano10: 0,
            ano11: 0,
            ano12: 0,
            ano13: 0,
            ano14: 0,
            ano15: 0,
            ano16: 0,
            ano17: 0,
            ano18: 0,
            ano19: 0,
            ano20: 0,
          },
          {
            type: '% Pagamento',
            ano0: 0,
            ano1: 0,
            ano2: 0,
            ano3: 0,
            ano4: 100,
            ano5: 100,
            ano6: 100,
            ano7: 100,
            ano8: 100,
            ano9: 100,
            ano10: 100,
            ano11: 100,
            ano12: 100,
            ano13: 100,
            ano14: 100,
            ano15: 100,
            ano16: 100,
            ano17: 100,
            ano18: 100,
            ano19: 100,
            ano20: 100,
          },
          {
            type: 'Proton',
            ano0: 0,
            ano1: 0,
            ano2: 0,
            ano3: 0,
            ano4: 0,
            ano5: 0,
            ano6: 0,
            ano7: 0,
            ano8: 0,
            ano9: 0,
            ano10: 0,
            ano11: 0,
            ano12: 0,
            ano13: 0,
            ano14: 0,
            ano15: 0,
            ano16: 0,
            ano17: 0,
            ano18: 0,
            ano19: 0,
            ano20: 0,
          },
          {
            type: '% Pagamento',
            ano0: 0,
            ano1: 0,
            ano2: 0,
            ano3: 0,
            ano4: 100,
            ano5: 100,
            ano6: 100,
            ano7: 100,
            ano8: 100,
            ano9: 100,
            ano10: 100,
            ano11: 100,
            ano12: 100,
            ano13: 100,
            ano14: 100,
            ano15: 100,
            ano16: 100,
            ano17: 100,
            ano18: 100,
            ano19: 100,
            ano20: 100,
          },
          {
            type: 'True beam 1',
            ano0: 0,
            ano1: 0,
            ano2: 0,
            ano3: 0,
            ano4: 0,
            ano5: 0,
            ano6: 0,
            ano7: 0,
            ano8: 0,
            ano9: 0,
            ano10: 0,
            ano11: 0,
            ano12: 0,
            ano13: 0,
            ano14: 0,
            ano15: 0,
            ano16: 0,
            ano17: 0,
            ano18: 0,
            ano19: 0,
            ano20: 0,
          },
          {
            type: '% Pagamento',
            ano0: 0,
            ano1: 0,
            ano2: 0,
            ano3: 0,
            ano4: 100,
            ano5: 100,
            ano6: 100,
            ano7: 100,
            ano8: 100,
            ano9: 100,
            ano10: 100,
            ano11: 100,
            ano12: 100,
            ano13: 100,
            ano14: 100,
            ano15: 100,
            ano16: 100,
            ano17: 100,
            ano18: 100,
            ano19: 100,
            ano20: 100,
          },
          {
            type: 'True beam 2',
            ano0: 0,
            ano1: 0,
            ano2: 0,
            ano3: 0,
            ano4: 0,
            ano5: 0,
            ano6: 0,
            ano7: 0,
            ano8: 0,
            ano9: 0,
            ano10: 0,
            ano11: 0,
            ano12: 0,
            ano13: 0,
            ano14: 0,
            ano15: 0,
            ano16: 0,
            ano17: 0,
            ano18: 0,
            ano19: 0,
            ano20: 0,
          },
          {
            type: '% Pagamento',
            ano0: 0,
            ano1: 0,
            ano2: 0,
            ano3: 0,
            ano4: 0,
            ano5: 0,
            ano6: 0,
            ano7: 0,
            ano8: 0,
            ano9: 0,
            ano10: 0,
            ano11: 0,
            ano12: 0,
            ano13: 0,
            ano14: 0,
            ano15: 0,
            ano16: 0,
            ano17: 0,
            ano18: 0,
            ano19: 0,
            ano20: 0,
          },
          {
            type: 'Acelerador 1',
            ano0: 0,
            ano1: 0,
            ano2: 0,
            ano3: 0,
            ano4: 0,
            ano5: 0,
            ano6: 0,
            ano7: 0,
            ano8: 0,
            ano9: 0,
            ano10: 0,
            ano11: 0,
            ano12: 0,
            ano13: 0,
            ano14: 0,
            ano15: 0,
            ano16: 0,
            ano17: 0,
            ano18: 0,
            ano19: 0,
            ano20: 0,
          },
          {
            type: '% Pagamento',
            ano0: 0,
            ano1: 0,
            ano2: 0,
            ano3: 0,
            ano4: 0,
            ano5: 0,
            ano6: 0,
            ano7: 0,
            ano8: 0,
            ano9: 0,
            ano10: 0,
            ano11: 0,
            ano12: 0,
            ano13: 0,
            ano14: 0,
            ano15: 0,
            ano16: 0,
            ano17: 0,
            ano18: 0,
            ano19: 0,
            ano20: 0,
          },
          {
            type: 'Acelerador 2',
            ano0: 0,
            ano1: 0,
            ano2: 0,
            ano3: 0,
            ano4: 0,
            ano5: 0,
            ano6: 0,
            ano7: 0,
            ano8: 0,
            ano9: 0,
            ano10: 0,
            ano11: 0,
            ano12: 0,
            ano13: 0,
            ano14: 0,
            ano15: 0,
            ano16: 0,
            ano17: 0,
            ano18: 0,
            ano19: 0,
            ano20: 0,
          },
          {
            type: '% Pagamento',
            ano0: 0,
            ano1: 0,
            ano2: 0,
            ano3: 0,
            ano4: 100,
            ano5: 100,
            ano6: 100,
            ano7: 100,
            ano8: 100,
            ano9: 100,
            ano10: 100,
            ano11: 100,
            ano12: 100,
            ano13: 100,
            ano14: 100,
            ano15: 100,
            ano16: 100,
            ano17: 100,
            ano18: 100,
            ano19: 100,
            ano20: 100,
          },
          {
            type: 'Outros equipamentos',
            ano0: 0,
            ano1: 0,
            ano2: 0,
            ano3: 0,
            ano4: 0,
            ano5: 0,
            ano6: 0,
            ano7: 0,
            ano8: 0,
            ano9: 0,
            ano10: 0,
            ano11: 0,
            ano12: 0,
            ano13: 0,
            ano14: 0,
            ano15: 0,
            ano16: 0,
            ano17: 0,
            ano18: 0,
            ano19: 0,
            ano20: 0,
          },
          {
            type: '% Pagamento',
            ano0: 0,
            ano1: 0,
            ano2: 0,
            ano3: 0,
            ano4: 0,
            ano5: 0,
            ano6: 100,
            ano7: 0,
            ano8: 0,
            ano9: 0,
            ano10: 0,
            ano11: 0,
            ano12: 0,
            ano13: 0,
            ano14: 0,
            ano15: 0,
            ano16: 0,
            ano17: 0,
            ano18: 0,
            ano19: 0,
            ano20: 0,
          },
          {
            type: 'Upgrade',
            ano0: 0,
            ano1: 0,
            ano2: 0,
            ano3: 0,
            ano4: 0,
            ano5: 0,
            ano6: 0,
            ano7: 0,
            ano8: 0,
            ano9: 0,
            ano10: 0,
            ano11: 0,
            ano12: 0,
            ano13: 0,
            ano14: 0,
            ano15: 0,
            ano16: 0,
            ano17: 0,
            ano18: 0,
            ano19: 0,
            ano20: 0,
          },
          {
            type: '% Pagamento',
            ano0: 0,
            ano1: 0,
            ano2: 0,
            ano3: 0,
            ano4: 50,
            ano5: 50,
            ano6: 50,
            ano7: 50,
            ano8: 50,
            ano9: 50,
            ano10: 50,
            ano11: 50,
            ano12: 50,
            ano13: 50,
            ano14: 50,
            ano15: 50,
            ano16: 50,
            ano17: 50,
            ano18: 50,
            ano19: 50,
            ano20: 50,
          },
          {
            type: 'Obras e estruturas',
            ano0: 0,
            ano1: 0,
            ano2: 0,
            ano3: 0,
            ano4: 0,
            ano5: 0,
            ano6: 0,
            ano7: 0,
            ano8: 0,
            ano9: 0,
            ano10: 0,
            ano11: 0,
            ano12: 0,
            ano13: 0,
            ano14: 0,
            ano15: 0,
            ano16: 0,
            ano17: 0,
            ano18: 0,
            ano19: 0,
            ano20: 0,
          },
          {
            type: '% Pagamento',
            ano0: 0,
            ano1: 0,
            ano2: 0,
            ano3: 0,
            ano4: 50,
            ano5: 50,
            ano6: 50,
            ano7: 50,
            ano8: 50,
            ano9: 50,
            ano10: 50,
            ano11: 50,
            ano12: 50,
            ano13: 50,
            ano14: 50,
            ano15: 50,
            ano16: 50,
            ano17: 50,
            ano18: 50,
            ano19: 50,
            ano20: 50,
          },
          {
            type: 'Mobiliario',
            ano0: 0,
            ano1: 0,
            ano2: 0,
            ano3: 0,
            ano4: 0,
            ano5: 0,
            ano6: 0,
            ano7: 0,
            ano8: 0,
            ano9: 0,
            ano10: 0,
            ano11: 0,
            ano12: 0,
            ano13: 0,
            ano14: 0,
            ano15: 0,
            ano16: 0,
            ano17: 0,
            ano18: 0,
            ano19: 0,
            ano20: 0,
          },
        ]
        let bp4f13
        let bp4f16
        let bp4f18
        let firstFactor
        let secondFactor
        const OnePlusP2N28 = (1 + (parseFloat(this.dashboard.capex.others.reservaTecnica ? this.dashboard.capex.others.reservaTecnica : 0) / 100))
        for (let i = 0; i <= 20; i += 1) {
          bp4f13 = parseFloat(this.premissasFinanceirasItems[2][`ano${i}`])
          bp4f16 = parseFloat(this.premissasFinanceirasItems[4][`ano${i}`])
          bp4f18 = parseFloat(this.premissasFinanceirasItems[6][`ano${i}`])
          firstFactor = OnePlusP2N28 * bp4f13 * bp4f16
          secondFactor = OnePlusP2N28 * bp4f18
          p[2][`ano${i}`] = ((parseFloat(p[1][`ano${i}`]) / 100) * firstFactor * parseFloat(this.dashboard.capex.proton.support)).toFixed(2)
          p[4][`ano${i}`] = ((parseFloat(p[3][`ano${i}`]) / 100) * firstFactor * parseFloat(this.dashboard.capex.trueBeam1.support)).toFixed(2)
          p[6][`ano${i}`] = ((parseFloat(p[5][`ano${i}`]) / 100) * firstFactor * parseFloat(this.dashboard.capex.trueBeam2.support)).toFixed(2)
          p[8][`ano${i}`] = ((parseFloat(p[7][`ano${i}`]) / 100) * firstFactor * parseFloat(this.dashboard.capex.acelerator1.support)).toFixed(2)
          p[10][`ano${i}`] = ((parseFloat(p[9][`ano${i}`]) / 100) * firstFactor * parseFloat(this.dashboard.capex.acelerator2.support)).toFixed(2)
          p[12][`ano${i}`] = ((parseFloat(p[11][`ano${i}`]) / 100) * firstFactor * parseFloat(this.dashboard.capex.otherEquipments.support)).toFixed(2)
          p[14][`ano${i}`] = ((parseFloat(p[13][`ano${i}`]) / 100) * firstFactor * parseFloat(this.dashboard.capex.upgrade.support)).toFixed(2)
          p[16][`ano${i}`] = ((parseFloat(p[15][`ano${i}`]) / 100) * secondFactor * parseFloat(this.dashboard.capex.handleAndStructure.support)).toFixed(2)
          p[18][`ano${i}`] = ((parseFloat(p[17][`ano${i}`]) / 100) * secondFactor * parseFloat(this.dashboard.capex.mobiliario.support)).toFixed(2)
          p[0][`ano${i}`] = (parseFloat(p[2][`ano${i}`]) + parseFloat(p[4][`ano${i}`]) + parseFloat(p[6][`ano${i}`]) + parseFloat(p[8][`ano${i}`]) + parseFloat(p[10][`ano${i}`]) + parseFloat(p[12][`ano${i}`]) + parseFloat(p[14][`ano${i}`]) + parseFloat(p[16][`ano${i}`]) + parseFloat(p[18][`ano${i}`])).toFixed(2)
        }
        return p
      }
      return []
    },
    opexItems() {
      if (this.premissasFinanceirasItems && this.premissasFinanceirasItems.length && this.dashboard && this.dashboard.project && this.dashboard.project.startYear) {
        const p = [
          {
            type: 'Opex',
            ano0: this.dashboard.project.startYear + 0,
            ano1: this.dashboard.project.startYear + 1,
            ano2: this.dashboard.project.startYear + 2,
            ano3: this.dashboard.project.startYear + 3,
            ano4: this.dashboard.project.startYear + 4,
            ano5: this.dashboard.project.startYear + 5,
            ano6: this.dashboard.project.startYear + 6,
            ano7: this.dashboard.project.startYear + 7,
            ano8: this.dashboard.project.startYear + 8,
            ano9: this.dashboard.project.startYear + 9,
            ano10: this.dashboard.project.startYear + 10,
            ano11: this.dashboard.project.startYear + 11,
            ano12: this.dashboard.project.startYear + 12,
            ano13: this.dashboard.project.startYear + 13,
            ano14: this.dashboard.project.startYear + 14,
            ano15: this.dashboard.project.startYear + 15,
            ano16: this.dashboard.project.startYear + 16,
            ano17: this.dashboard.project.startYear + 17,
            ano18: this.dashboard.project.startYear + 18,
            ano19: this.dashboard.project.startYear + 19,
            ano20: this.dashboard.project.startYear + 20,
          },
          {
            type: 'Custo administrativo',
            ano0: 0,
            ano1: 0,
            ano2: 0,
            ano3: 0,
            ano4: 0,
            ano5: 0,
            ano6: 0,
            ano7: 0,
            ano8: 0,
            ano9: 0,
            ano10: 0,
            ano11: 0,
            ano12: 0,
            ano13: 0,
            ano14: 0,
            ano15: 0,
            ano16: 0,
            ano17: 0,
            ano18: 0,
            ano19: 0,
            ano20: 0,
          },
          {
            type: '(%) Receita liquida',
            ano0: 0,
            ano1: 0,
            ano2: 0,
            ano3: 0,
            ano4: 0,
            ano5: 0,
            ano6: 0,
            ano7: 0,
            ano8: 0,
            ano9: 0,
            ano10: 0,
            ano11: 0,
            ano12: 0,
            ano13: 0,
            ano14: 0,
            ano15: 0,
            ano16: 0,
            ano17: 0,
            ano18: 0,
            ano19: 0,
            ano20: 0,
          },
          {
            type: '1 Aluguel / mes',
            ano0: 0,
            ano1: 0,
            ano2: 0,
            ano3: 0,
            ano4: 0,
            ano5: 0,
            ano6: 0,
            ano7: 0,
            ano8: 0,
            ano9: 0,
            ano10: 0,
            ano11: 0,
            ano12: 0,
            ano13: 0,
            ano14: 0,
            ano15: 0,
            ano16: 0,
            ano17: 0,
            ano18: 0,
            ano19: 0,
            ano20: 0,
          },
          {
            type: '2 Despesas Rateadas / Ano',
            ano0: 0,
            ano1: 0,
            ano2: 0,
            ano3: 0,
            ano4: 0,
            ano5: 0,
            ano6: 0,
            ano7: 0,
            ano8: 0,
            ano9: 0,
            ano10: 0,
            ano11: 0,
            ano12: 0,
            ano13: 0,
            ano14: 0,
            ano15: 0,
            ano16: 0,
            ano17: 0,
            ano18: 0,
            ano19: 0,
            ano20: 0,
          },
          {
            type: 'Equipamentos operando',
            ano0: 0,
            ano1: 0,
            ano2: 0,
            ano3: 0,
            ano4: 0,
            ano5: 0,
            ano6: 0,
            ano7: 0,
            ano8: 0,
            ano9: 0,
            ano10: 0,
            ano11: 0,
            ano12: 0,
            ano13: 0,
            ano14: 0,
            ano15: 0,
            ano16: 0,
            ano17: 0,
            ano18: 0,
            ano19: 0,
            ano20: 0,
          },
          {
            type: 'Administrativo / Operacionais',
            ano0: 0,
            ano1: 0,
            ano2: 0,
            ano3: 0,
            ano4: 0,
            ano5: 0,
            ano6: 0,
            ano7: 0,
            ano8: 0,
            ano9: 0,
            ano10: 0,
            ano11: 0,
            ano12: 0,
            ano13: 0,
            ano14: 0,
            ano15: 0,
            ano16: 0,
            ano17: 0,
            ano18: 0,
            ano19: 0,
            ano20: 0,
          },
          {
            type: 'Custo de pessoal',
            ano0: 0,
            ano1: 0,
            ano2: 0,
            ano3: 0,
            ano4: 0,
            ano5: 0,
            ano6: 0,
            ano7: 0,
            ano8: 0,
            ano9: 0,
            ano10: 0,
            ano11: 0,
            ano12: 0,
            ano13: 0,
            ano14: 0,
            ano15: 0,
            ano16: 0,
            ano17: 0,
            ano18: 0,
            ano19: 0,
            ano20: 0,
          },
          {
            type: 'Assistencial',
            ano0: 0,
            ano1: 0,
            ano2: 0,
            ano3: 0,
            ano4: 0,
            ano5: 0,
            ano6: 0,
            ano7: 0,
            ano8: 0,
            ano9: 0,
            ano10: 0,
            ano11: 0,
            ano12: 0,
            ano13: 0,
            ano14: 0,
            ano15: 0,
            ano16: 0,
            ano17: 0,
            ano18: 0,
            ano19: 0,
            ano20: 0,
          },
          {
            type: 'Administrativo',
            ano0: 0,
            ano1: 0,
            ano2: 0,
            ano3: 0,
            ano4: 0,
            ano5: 0,
            ano6: 0,
            ano7: 0,
            ano8: 0,
            ano9: 0,
            ano10: 0,
            ano11: 0,
            ano12: 0,
            ano13: 0,
            ano14: 0,
            ano15: 0,
            ano16: 0,
            ano17: 0,
            ano18: 0,
            ano19: 0,
            ano20: 0,
          },
          {
            type: 'Custo médico / físico',
            ano0: 0,
            ano1: 0,
            ano2: 0,
            ano3: 0,
            ano4: 0,
            ano5: 0,
            ano6: 0,
            ano7: 0,
            ano8: 0,
            ano9: 0,
            ano10: 0,
            ano11: 0,
            ano12: 0,
            ano13: 0,
            ano14: 0,
            ano15: 0,
            ano16: 0,
            ano17: 0,
            ano18: 0,
            ano19: 0,
            ano20: 0,
          },
          {
            type: 'Médico fixo',
            ano0: 0,
            ano1: 0,
            ano2: 0,
            ano3: 0,
            ano4: 0,
            ano5: 0,
            ano6: 0,
            ano7: 0,
            ano8: 0,
            ano9: 0,
            ano10: 0,
            ano11: 0,
            ano12: 0,
            ano13: 0,
            ano14: 0,
            ano15: 0,
            ano16: 0,
            ano17: 0,
            ano18: 0,
            ano19: 0,
            ano20: 0,
          },
          {
            type: 'Médico (% Receita)',
            ano0: 0,
            ano1: 0,
            ano2: 0,
            ano3: 0,
            ano4: 0,
            ano5: 0,
            ano6: 0,
            ano7: 0,
            ano8: 0,
            ano9: 0,
            ano10: 0,
            ano11: 0,
            ano12: 0,
            ano13: 0,
            ano14: 0,
            ano15: 0,
            ano16: 0,
            ano17: 0,
            ano18: 0,
            ano19: 0,
            ano20: 0,
          },
          {
            type: 'Físicos e dosimetristas',
            ano0: 0,
            ano1: 0,
            ano2: 0,
            ano3: 0,
            ano4: 0,
            ano5: 0,
            ano6: 0,
            ano7: 0,
            ano8: 0,
            ano9: 0,
            ano10: 0,
            ano11: 0,
            ano12: 0,
            ano13: 0,
            ano14: 0,
            ano15: 0,
            ano16: 0,
            ano17: 0,
            ano18: 0,
            ano19: 0,
            ano20: 0,
          },
        ]
        for (let i = 0; i <= 20; i += 1) {
          p[3][`ano${i}`] = (this.expenses.administrativo.aluguelPorMes ? parseFloat(this.expenses.administrativo.aluguelPorMes) * 12 * this.premissasFinanceirasItems[6][`ano${i}`] : 0).toFixed(2)
          p[4][`ano${i}`] = (this.expenses.administrativo.despesasRateadasPorAno && this.composicoesReceitasBrutasItems[2][`ano${i}`] ? parseFloat(this.expenses.administrativo.despesasRateadasPorAno) * this.premissasFinanceirasItems[6][`ano${i}`] : 0).toFixed(2)
          p[5][`ano${i}`] = 0
          if (i > 0) {
            if (this.incomings.taxaOcupacaoList.proton[`ano${i}`] && parseFloat(this.incomings.taxaOcupacaoList.proton[`ano${i}`]) && parseFloat(this.dashboard.capex.proton.pacients)) p[5][`ano${i}`] += 1
            if (this.incomings.taxaOcupacaoList.trueBeam1[`ano${i}`] && parseFloat(this.incomings.taxaOcupacaoList.trueBeam1[`ano${i}`]) && parseFloat(this.dashboard.capex.trueBeam1.pacients)) p[5][`ano${i}`] += 1
            if (this.incomings.taxaOcupacaoList.trueBeam2[`ano${i}`] && parseFloat(this.incomings.taxaOcupacaoList.trueBeam2[`ano${i}`]) && parseFloat(this.dashboard.capex.trueBeam2.pacients)) p[5][`ano${i}`] += 1
            if (this.incomings.taxaOcupacaoList.acelerator1[`ano${i}`] && parseFloat(this.incomings.taxaOcupacaoList.acelerator1[`ano${i}`]) && parseFloat(this.dashboard.capex.acelerator1.pacients)) p[5][`ano${i}`] += 1
            if (this.incomings.taxaOcupacaoList.acelerator2[`ano${i}`] && parseFloat(this.incomings.taxaOcupacaoList.acelerator2[`ano${i}`]) && parseFloat(this.dashboard.capex.acelerator2.pacients)) p[5][`ano${i}`] += 1
          }
          p[6][`ano${i}`] = 0
          if (i > 0) {
            if (this.incomings.taxaOcupacaoList.proton[`ano${i}`] && parseFloat(this.incomings.taxaOcupacaoList.proton[`ano${i}`]) && parseFloat(this.dashboard.capex.proton.pacients) && parseFloat(this.expenses.administrativo.operacionaisProton)) {
              p[6][`ano${i}`] += parseFloat(this.incomings.taxaOcupacaoList.proton[`ano${i}`]) * parseFloat(this.expenses.administrativo.operacionaisProton)
            }
            if (this.incomings.taxaOcupacaoList.trueBeam1[`ano${i}`] && parseFloat(this.incomings.taxaOcupacaoList.trueBeam1[`ano${i}`]) && parseFloat(this.dashboard.capex.trueBeam1.pacients) && parseFloat(this.expenses.administrativo.operacionaisTrueBeam1)) {
              p[6][`ano${i}`] += parseFloat(this.incomings.taxaOcupacaoList.trueBeam1[`ano${i}`]) * parseFloat(this.expenses.administrativo.operacionaisTrueBeam1)
            }
            if (this.incomings.taxaOcupacaoList.trueBeam2[`ano${i}`] && parseFloat(this.incomings.taxaOcupacaoList.trueBeam2[`ano${i}`]) && parseFloat(this.dashboard.capex.trueBeam2.pacients) && parseFloat(this.expenses.administrativo.operacionaisTrueBeam2)) {
              p[6][`ano${i}`] += parseFloat(this.incomings.taxaOcupacaoList.trueBeam2[`ano${i}`]) * parseFloat(this.expenses.administrativo.operacionaisTrueBeam2)
            }
            if (this.incomings.taxaOcupacaoList.acelerator1[`ano${i}`] && parseFloat(this.incomings.taxaOcupacaoList.acelerator1[`ano${i}`]) && parseFloat(this.dashboard.capex.acelerator1.pacients) && parseFloat(this.expenses.administrativo.operacionaisEq1)) {
              p[6][`ano${i}`] += parseFloat(this.incomings.taxaOcupacaoList.acelerator1[`ano${i}`]) * parseFloat(this.expenses.administrativo.operacionaisEq1)
            }
            if (this.incomings.taxaOcupacaoList.acelerator2[`ano${i}`] && parseFloat(this.incomings.taxaOcupacaoList.acelerator2[`ano${i}`]) && parseFloat(this.dashboard.capex.acelerator2.pacients) && parseFloat(this.expenses.administrativo.operacionaisEq2)) {
              p[6][`ano${i}`] += parseFloat(this.incomings.taxaOcupacaoList.acelerator2[`ano${i}`]) * parseFloat(this.expenses.administrativo.operacionaisEq2)
            }
            p[6][`ano${i}`] *= this.premissasFinanceirasItems[6][`ano${i}`]
            p[6][`ano${i}`] /= 100
            p[6][`ano${i}`] = parseFloat(p[6][`ano${i}`]).toFixed(2)
          }
          p[1][`ano${i}`] = (parseFloat(p[3][`ano${i}`]) + parseFloat(p[4][`ano${i}`]) + parseFloat(p[6][`ano${i}`])).toFixed(2)
          p[2][`ano${i}`] = (100 * (p[1][`ano${i}`] / parseFloat(this.receitaBrutaItems[4][`ano${i}`]))).toFixed(2)

          p[8][`ano${i}`] = parseFloat(this.premissasFinanceirasItems[6][`ano${i}`] * parseFloat(this.incomings.custoPessoalList.assistencial[`ano${i}`])).toFixed(2)
          p[9][`ano${i}`] = parseFloat(this.premissasFinanceirasItems[6][`ano${i}`] * parseFloat(this.incomings.custoPessoalList.administrativo[`ano${i}`])).toFixed(2)

          p[7][`ano${i}`] = (parseFloat(p[8][`ano${i}`]) + parseFloat(p[9][`ano${i}`])).toFixed(2)

          p[11][`ano${i}`] = parseFloat(parseFloat(this.premissasFinanceirasItems[6][`ano${i}`]) * parseFloat(this.incomings.custoPessoalList.medicos[`ano${i}`])).toFixed(2)
          let baseReceitaMedico = 0
          if (parseInt(p[5][`ano${i}`], 10) === 0) {
            baseReceitaMedico = parseFloat(this.staff.custoTotal.medicosPercentReceita.obras)
          } else if (parseInt(p[5][`ano${i}`], 10) === 1) {
            baseReceitaMedico = parseFloat(this.staff.custoTotal.medicosPercentReceita.maq1)
          } else if (parseInt(p[5][`ano${i}`], 10) === 2) {
            baseReceitaMedico = parseFloat(this.staff.custoTotal.medicosPercentReceita.maq2)
          } else if (parseInt(p[5][`ano${i}`], 10) === 3) {
            baseReceitaMedico = parseFloat(this.staff.custoTotal.medicosPercentReceita.maq3)
          } else if (parseInt(p[5][`ano${i}`], 10) === 4) {
            baseReceitaMedico = parseFloat(this.staff.custoTotal.medicosPercentReceita.maq4)
          } else {
            // if (equipamentoQuantidade >= 5)
            baseReceitaMedico = parseFloat(this.staff.custoTotal.medicosPercentReceita.maq5)
          }
          p[12][`ano${i}`] = ((parseFloat(baseReceitaMedico) * parseFloat(this.receitaBrutaItems[1][`ano${i}`])) / 100).toFixed(2)
          p[13][`ano${i}`] = parseFloat(this.premissasFinanceirasItems[6][`ano${i}`] * parseFloat(this.incomings.custoPessoalList.fisicosDosimetristas[`ano${i}`])).toFixed(2)

          p[10][`ano${i}`] = (parseFloat(p[11][`ano${i}`]) + parseFloat(p[12][`ano${i}`]) + parseFloat(p[13][`ano${i}`])).toFixed(2)

          this.fixCustoItems(p, i)
        }
        return p
      }
      return []
    },
    depreciacaoItems() {
      if (this.dashboard && this.dashboard.project && this.dashboard.project.startYear && this.capexItems && this.capexItems.length) {
        const p = [
          {
            type: 'Depreciacao total',
            ano0: 0,
            ano1: 0,
            ano2: 0,
            ano3: 0,
            ano4: 0,
            ano5: 0,
            ano6: 0,
            ano7: 0,
            ano8: 0,
            ano9: 0,
            ano10: 0,
            ano11: 0,
            ano12: 0,
            ano13: 0,
            ano14: 0,
            ano15: 0,
            ano16: 0,
            ano17: 0,
            ano18: 0,
            ano19: 0,
            ano20: 0,
          },
          {
            type: 'Equipamentos',
            ano0: 0,
            ano1: 0,
            ano2: 0,
            ano3: 0,
            ano4: 0,
            ano5: 0,
            ano6: 0,
            ano7: 0,
            ano8: 0,
            ano9: 0,
            ano10: 0,
            ano11: 0,
            ano12: 0,
            ano13: 0,
            ano14: 0,
            ano15: 0,
            ano16: 0,
            ano17: 0,
            ano18: 0,
            ano19: 0,
            ano20: 0,
          },
          {
            type: 'Proton',
            ano0: 0,
            ano1: 0,
            ano2: 0,
            ano3: 0,
            ano4: 0,
            ano5: 0,
            ano6: 0,
            ano7: 0,
            ano8: 0,
            ano9: 0,
            ano10: 0,
            ano11: 0,
            ano12: 0,
            ano13: 0,
            ano14: 0,
            ano15: 0,
            ano16: 0,
            ano17: 0,
            ano18: 0,
            ano19: 0,
            ano20: 0,
          },
          {
            type: 'Saldo',
            ano0: 0,
            ano1: 0,
            ano2: 0,
            ano3: 0,
            ano4: 0,
            ano5: 0,
            ano6: 0,
            ano7: 0,
            ano8: 0,
            ano9: 0,
            ano10: 0,
            ano11: 0,
            ano12: 0,
            ano13: 0,
            ano14: 0,
            ano15: 0,
            ano16: 0,
            ano17: 0,
            ano18: 0,
            ano19: 0,
            ano20: 0,
          },
          {
            type: 'True beam 1',
            ano0: 0,
            ano1: 0,
            ano2: 0,
            ano3: 0,
            ano4: 0,
            ano5: 0,
            ano6: 0,
            ano7: 0,
            ano8: 0,
            ano9: 0,
            ano10: 0,
            ano11: 0,
            ano12: 0,
            ano13: 0,
            ano14: 0,
            ano15: 0,
            ano16: 0,
            ano17: 0,
            ano18: 0,
            ano19: 0,
            ano20: 0,
          },
          {
            type: 'Saldo',
            ano0: 0,
            ano1: 0,
            ano2: 0,
            ano3: 0,
            ano4: 0,
            ano5: 0,
            ano6: 0,
            ano7: 0,
            ano8: 0,
            ano9: 0,
            ano10: 0,
            ano11: 0,
            ano12: 0,
            ano13: 0,
            ano14: 0,
            ano15: 0,
            ano16: 0,
            ano17: 0,
            ano18: 0,
            ano19: 0,
            ano20: 0,
          },
          {
            type: 'True beam 2',
            ano0: 0,
            ano1: 0,
            ano2: 0,
            ano3: 0,
            ano4: 0,
            ano5: 0,
            ano6: 0,
            ano7: 0,
            ano8: 0,
            ano9: 0,
            ano10: 0,
            ano11: 0,
            ano12: 0,
            ano13: 0,
            ano14: 0,
            ano15: 0,
            ano16: 0,
            ano17: 0,
            ano18: 0,
            ano19: 0,
            ano20: 0,
          },
          {
            type: 'Saldo',
            ano0: 0,
            ano1: 0,
            ano2: 0,
            ano3: 0,
            ano4: 0,
            ano5: 0,
            ano6: 0,
            ano7: 0,
            ano8: 0,
            ano9: 0,
            ano10: 0,
            ano11: 0,
            ano12: 0,
            ano13: 0,
            ano14: 0,
            ano15: 0,
            ano16: 0,
            ano17: 0,
            ano18: 0,
            ano19: 0,
            ano20: 0,
          },
          {
            type: 'Acelerador 1',
            ano0: 0,
            ano1: 0,
            ano2: 0,
            ano3: 0,
            ano4: 0,
            ano5: 0,
            ano6: 0,
            ano7: 0,
            ano8: 0,
            ano9: 0,
            ano10: 0,
            ano11: 0,
            ano12: 0,
            ano13: 0,
            ano14: 0,
            ano15: 0,
            ano16: 0,
            ano17: 0,
            ano18: 0,
            ano19: 0,
            ano20: 0,
          },
          {
            type: 'Saldo',
            ano0: 0,
            ano1: 0,
            ano2: 0,
            ano3: 0,
            ano4: 0,
            ano5: 0,
            ano6: 0,
            ano7: 0,
            ano8: 0,
            ano9: 0,
            ano10: 0,
            ano11: 0,
            ano12: 0,
            ano13: 0,
            ano14: 0,
            ano15: 0,
            ano16: 0,
            ano17: 0,
            ano18: 0,
            ano19: 0,
            ano20: 0,
          },
          {
            type: 'Acelerador 2',
            ano0: 0,
            ano1: 0,
            ano2: 0,
            ano3: 0,
            ano4: 0,
            ano5: 0,
            ano6: 0,
            ano7: 0,
            ano8: 0,
            ano9: 0,
            ano10: 0,
            ano11: 0,
            ano12: 0,
            ano13: 0,
            ano14: 0,
            ano15: 0,
            ano16: 0,
            ano17: 0,
            ano18: 0,
            ano19: 0,
            ano20: 0,
          },
          {
            type: 'Saldo',
            ano0: 0,
            ano1: 0,
            ano2: 0,
            ano3: 0,
            ano4: 0,
            ano5: 0,
            ano6: 0,
            ano7: 0,
            ano8: 0,
            ano9: 0,
            ano10: 0,
            ano11: 0,
            ano12: 0,
            ano13: 0,
            ano14: 0,
            ano15: 0,
            ano16: 0,
            ano17: 0,
            ano18: 0,
            ano19: 0,
            ano20: 0,
          },
          {
            type: 'Outros equipamentos',
            ano0: 0,
            ano1: 0,
            ano2: 0,
            ano3: 0,
            ano4: 0,
            ano5: 0,
            ano6: 0,
            ano7: 0,
            ano8: 0,
            ano9: 0,
            ano10: 0,
            ano11: 0,
            ano12: 0,
            ano13: 0,
            ano14: 0,
            ano15: 0,
            ano16: 0,
            ano17: 0,
            ano18: 0,
            ano19: 0,
            ano20: 0,
          },
          {
            type: 'Depreciação',
            ano0: 0,
            ano1: 0,
            ano2: 0,
            ano3: 0,
            ano4: 0,
            ano5: 0,
            ano6: 0,
            ano7: 0,
            ano8: 0,
            ano9: 0,
            ano10: 0,
            ano11: 0,
            ano12: 0,
            ano13: 0,
            ano14: 0,
            ano15: 0,
            ano16: 0,
            ano17: 0,
            ano18: 0,
            ano19: 0,
            ano20: 0,
          },
          {
            type: 'Saldo',
            ano0: 0,
            ano1: 0,
            ano2: 0,
            ano3: 0,
            ano4: 0,
            ano5: 0,
            ano6: 0,
            ano7: 0,
            ano8: 0,
            ano9: 0,
            ano10: 0,
            ano11: 0,
            ano12: 0,
            ano13: 0,
            ano14: 0,
            ano15: 0,
            ano16: 0,
            ano17: 0,
            ano18: 0,
            ano19: 0,
            ano20: 0,
          },
          {
            type: 'Obras e estruturas',
            ano0: 0,
            ano1: 0,
            ano2: 0,
            ano3: 0,
            ano4: 0,
            ano5: 0,
            ano6: 0,
            ano7: 0,
            ano8: 0,
            ano9: 0,
            ano10: 0,
            ano11: 0,
            ano12: 0,
            ano13: 0,
            ano14: 0,
            ano15: 0,
            ano16: 0,
            ano17: 0,
            ano18: 0,
            ano19: 0,
            ano20: 0,
          },
          {
            type: 'Depreciação',
            ano0: 0,
            ano1: 0,
            ano2: 0,
            ano3: 0,
            ano4: 0,
            ano5: 0,
            ano6: 0,
            ano7: 0,
            ano8: 0,
            ano9: 0,
            ano10: 0,
            ano11: 0,
            ano12: 0,
            ano13: 0,
            ano14: 0,
            ano15: 0,
            ano16: 0,
            ano17: 0,
            ano18: 0,
            ano19: 0,
            ano20: 0,
          },
          {
            type: 'Saldo',
            ano0: 0,
            ano1: 0,
            ano2: 0,
            ano3: 0,
            ano4: 0,
            ano5: 0,
            ano6: 0,
            ano7: 0,
            ano8: 0,
            ano9: 0,
            ano10: 0,
            ano11: 0,
            ano12: 0,
            ano13: 0,
            ano14: 0,
            ano15: 0,
            ano16: 0,
            ano17: 0,
            ano18: 0,
            ano19: 0,
            ano20: 0,
          },
          {
            type: 'Manutenção',
            ano0: 0,
            ano1: 0,
            ano2: 0,
            ano3: 0,
            ano4: 0,
            ano5: 0,
            ano6: 0,
            ano7: 0,
            ano8: 0,
            ano9: 0,
            ano10: 0,
            ano11: 0,
            ano12: 0,
            ano13: 0,
            ano14: 0,
            ano15: 0,
            ano16: 0,
            ano17: 0,
            ano18: 0,
            ano19: 0,
            ano20: 0,
          },
          {
            type: 'Depreciação',
            ano0: 0,
            ano1: 0,
            ano2: 0,
            ano3: 0,
            ano4: 0,
            ano5: 0,
            ano6: 0,
            ano7: 0,
            ano8: 0,
            ano9: 0,
            ano10: 0,
            ano11: 0,
            ano12: 0,
            ano13: 0,
            ano14: 0,
            ano15: 0,
            ano16: 0,
            ano17: 0,
            ano18: 0,
            ano19: 0,
            ano20: 0,
          },
          {
            type: 'Saldo',
            ano0: 0,
            ano1: 0,
            ano2: 0,
            ano3: 0,
            ano4: 0,
            ano5: 0,
            ano6: 0,
            ano7: 0,
            ano8: 0,
            ano9: 0,
            ano10: 0,
            ano11: 0,
            ano12: 0,
            ano13: 0,
            ano14: 0,
            ano15: 0,
            ano16: 0,
            ano17: 0,
            ano18: 0,
            ano19: 0,
            ano20: 0,
          },
        ]
        const sumProtonTotal = (parseFloat(this.sumYears(this.capexItems[2], 20)) + parseFloat(this.sumYears(this.capexItems[4], 20)) + parseFloat(this.sumYears(this.capexItems[10], 20))).toFixed(2)
        const sumTrueBeam1Total = (parseFloat(this.sumYears(this.capexItems[18], 20)) + parseFloat(this.sumYears(this.capexItems[20], 20)) + parseFloat(this.sumYears(this.capexItems[26], 20))).toFixed(2)
        const sumTrueBeam2Total = (parseFloat(this.sumYears(this.capexItems[24], 20)) + parseFloat(this.sumYears(this.capexItems[36], 20)) + parseFloat(this.sumYears(this.capexItems[42], 20))).toFixed(2)
        const sumAcelerador1Total = (parseFloat(this.sumYears(this.capexItems[40], 20)) + parseFloat(this.sumYears(this.capexItems[52], 20)) + parseFloat(this.sumYears(this.capexItems[58], 20))).toFixed(2)
        const sumAcelerador2Total = (parseFloat(this.sumYears(this.capexItems[56], 20)) + parseFloat(this.sumYears(this.capexItems[68], 20)) + parseFloat(this.sumYears(this.capexItems[74], 20))).toFixed(2)
        const sumOutrosEquipamentosTotal = (parseFloat(this.sumYears(this.capexItems[82], 20))).toFixed(2)
        const sumHandleAndStructureTotal = (parseFloat(this.sumYears(this.capexItems[84], 20))).toFixed(2)
        for (let i = 0; i <= 20; i += 1) {
          if (i === 0) {
            const sumProton = (parseFloat(this.capexItems[3][`ano${i}`]) === 0 ? 0 : (parseFloat(sumProtonTotal))).toFixed(2)
            const sumTrueBeam1 = (parseFloat(this.capexItems[19][`ano${i}`]) === 0 ? 0 : (parseFloat(sumTrueBeam1Total))).toFixed(2)
            const sumTrueBeam2 = (parseFloat(this.capexItems[25][`ano${i}`]) === 0 ? 0 : (parseFloat(sumTrueBeam2Total))).toFixed(2)
            const sumAcelerador1 = (parseFloat(this.capexItems[41][`ano${i}`]) === 0 ? 0 : (parseFloat(sumAcelerador1Total))).toFixed(2)
            const sumAcelerador2 = (parseFloat(this.capexItems[57][`ano${i}`]) === 0 ? 0 : (parseFloat(sumAcelerador2Total))).toFixed(2)
            const sumOutrosEquipamentos = (parseFloat(this.capexItems[83][`ano${i}`]) === 0 ? 0 : (parseFloat(sumOutrosEquipamentosTotal))).toFixed(2)
            const sumHandleAndStructure = (parseFloat(this.capexItems[85][`ano${i}`]) === 0 ? 0 : (parseFloat(sumHandleAndStructureTotal))).toFixed(2)
            p[2][`ano${i}`] = (parseFloat(sumProton) * (parseFloat(this.dashboard.capex.proton.depreciacao) / 100)).toFixed(2)
            p[4][`ano${i}`] = (parseFloat(sumTrueBeam1) * (parseFloat(this.dashboard.capex.trueBeam1.depreciacao) / 100)).toFixed(2)
            p[6][`ano${i}`] = (parseFloat(sumTrueBeam2) * (parseFloat(this.dashboard.capex.trueBeam2.depreciacao) / 100)).toFixed(2)
            p[8][`ano${i}`] = (parseFloat(sumAcelerador1) * (parseFloat(this.dashboard.capex.acelerator1.depreciacao) / 100)).toFixed(2)
            p[10][`ano${i}`] = (parseFloat(sumAcelerador2) * (parseFloat(this.dashboard.capex.acelerator2.depreciacao) / 100)).toFixed(2)
            p[13][`ano${i}`] = (parseFloat(sumOutrosEquipamentos) * (parseFloat(this.dashboard.capex.otherEquipments.depreciacao) / 100)).toFixed(2)
            p[16][`ano${i}`] = (parseFloat(sumHandleAndStructure) * (parseFloat(this.dashboard.capex.handleAndStructure.depreciacao) / 100)).toFixed(2)
          } else {
            // Proton
            // eslint-disable-next-line
            if (parseFloat(p[2][`ano${i - 1}`]) > 0) {
              if ((parseFloat(this.sumYears(p[2], i - 1))) >= (parseFloat(sumProtonTotal) - 1)) {
                p[2][`ano${i}`] = 0
              } else {
                p[2][`ano${i}`] = (parseFloat(sumProtonTotal) * (parseFloat(this.dashboard.capex.proton.depreciacao) / 100)).toFixed(2)
              }
            } else {
              // eslint-disable-next-line
              if (parseFloat(this.capexItems[3][`ano${i}`]) === 0) {
                p[2][`ano${i}`] = 0
              } else {
                p[2][`ano${i}`] = (parseFloat(sumProtonTotal) * (parseFloat(this.dashboard.capex.proton.depreciacao) / 100)).toFixed(2)
              }
            }
            // True beam 1
            // eslint-disable-next-line
            if (parseFloat(p[4][`ano${i - 1}`]) > 0) {
              if ((parseFloat(this.sumYears(p[4], i - 1))) >= (parseFloat(sumTrueBeam1Total) - 1)) {
                p[4][`ano${i}`] = 0
              } else {
                p[4][`ano${i}`] = (parseFloat(sumTrueBeam1Total) * (parseFloat(this.dashboard.capex.trueBeam1.depreciacao) / 100)).toFixed(2)
              }
            } else {
              // eslint-disable-next-line
              if (parseFloat(this.capexItems[19][`ano${i}`]) === 0) {
                p[4][`ano${i}`] = 0
              } else {
                p[4][`ano${i}`] = (parseFloat(sumTrueBeam1Total) * (parseFloat(this.dashboard.capex.trueBeam1.depreciacao) / 100)).toFixed(2)
              }
            }
            // True beam 2
            // eslint-disable-next-line
            if (parseFloat(p[6][`ano${i - 1}`]) > 0) {
              if ((parseFloat(this.sumYears(p[6], i - 1))) >= (parseFloat(sumTrueBeam2Total) - 1)) {
                p[6][`ano${i}`] = 0
              } else {
                p[6][`ano${i}`] = (parseFloat(sumTrueBeam2Total) * (parseFloat(this.dashboard.capex.trueBeam2.depreciacao) / 100)).toFixed(2)
              }
            } else {
              // eslint-disable-next-line
              if (parseFloat(this.capexItems[25][`ano${i}`]) === 0) {
                p[6][`ano${i}`] = 0
              } else {
                p[6][`ano${i}`] = (parseFloat(sumTrueBeam2Total) * (parseFloat(this.dashboard.capex.trueBeam2.depreciacao) / 100)).toFixed(2)
              }
            }
            // Acelerador 1
            // eslint-disable-next-line
            if (parseFloat(p[8][`ano${i - 1}`]) > 0) {
              if ((parseFloat(this.sumYears(p[8], i - 1))) >= (parseFloat(sumAcelerador1Total) - 1)) {
                p[8][`ano${i}`] = 0
              } else {
                p[8][`ano${i}`] = (parseFloat(sumAcelerador1Total) * (parseFloat(this.dashboard.capex.acelerator1.depreciacao) / 100)).toFixed(2)
              }
            } else {
              // eslint-disable-next-line
              if (parseFloat(this.capexItems[41][`ano${i}`]) === 0) {
                p[8][`ano${i}`] = 0
              } else {
                p[8][`ano${i}`] = (parseFloat(sumAcelerador1Total) * (parseFloat(this.dashboard.capex.acelerator1.depreciacao) / 100)).toFixed(2)
              }
            }
            // Acelerador 2
            // eslint-disable-next-line
            if (parseFloat(p[10][`ano${i - 1}`]) > 0) {
              if ((parseFloat(this.sumYears(p[10], i - 1))) >= (parseFloat(sumAcelerador2Total) - 1)) {
                p[10][`ano${i}`] = 0
              } else {
                p[10][`ano${i}`] = (parseFloat(sumAcelerador2Total) * (parseFloat(this.dashboard.capex.acelerator2.depreciacao) / 100)).toFixed(2)
              }
            } else {
              // eslint-disable-next-line
              if (parseFloat(this.capexItems[57][`ano${i}`]) === 0) {
                p[10][`ano${i}`] = 0
              } else {
                p[10][`ano${i}`] = (parseFloat(sumAcelerador2Total) * (parseFloat(this.dashboard.capex.acelerator2.depreciacao) / 100)).toFixed(2)
              }
            }
            // Other equipments
            // eslint-disable-next-line
            if (parseFloat(p[13][`ano${i - 1}`]) > 0) {
              if ((parseFloat(this.sumYears(p[13], i - 1))) >= (parseFloat(sumOutrosEquipamentosTotal) - 1)) {
                p[13][`ano${i}`] = 0
              } else {
                p[13][`ano${i}`] = (parseFloat(sumOutrosEquipamentosTotal) * (parseFloat(this.dashboard.capex.otherEquipments.depreciacao) / 100)).toFixed(2)
              }
            } else {
              // eslint-disable-next-line
              if (parseFloat(this.capexItems[83][`ano${i}`]) === 0) {
                p[13][`ano${i}`] = 0
              } else {
                p[13][`ano${i}`] = (parseFloat(sumOutrosEquipamentosTotal) * (parseFloat(this.dashboard.capex.otherEquipments.depreciacao) / 100)).toFixed(2)
              }
            }
            p[12][`ano${i}`] = p[13][`ano${i}`]
            // Other equipments
            // eslint-disable-next-line
            if (parseFloat(p[16][`ano${i - 1}`]) > 0) {
              if ((parseFloat(this.sumYears(p[16], i - 1))) >= (parseFloat(sumHandleAndStructureTotal) - 1)) {
                p[16][`ano${i}`] = 0
              } else {
                p[16][`ano${i}`] = (parseFloat(sumHandleAndStructureTotal) * (parseFloat(this.dashboard.capex.handleAndStructure.depreciacao) / 100)).toFixed(2)
              }
            } else {
              // eslint-disable-next-line
              if (parseFloat(this.capexItems[86][`ano${i}`]) === 0) {
                p[16][`ano${i}`] = 0
              } else {
                p[16][`ano${i}`] = (parseFloat(sumHandleAndStructureTotal) * (parseFloat(this.dashboard.capex.handleAndStructure.depreciacao) / 100)).toFixed(2)
              }
            }
            p[15][`ano${i}`] = p[16][`ano${i}`]
          }
          p[3][`ano${i}`] = Math.floor(parseFloat(sumProtonTotal) - parseFloat(this.sumYears(p[2], i)))
          p[3][`ano${i}`] = p[3][`ano${i}`] < 0 ? 0 : p[3][`ano${i}`]
          p[5][`ano${i}`] = Math.floor(parseFloat(sumTrueBeam1Total) - parseFloat(this.sumYears(p[4], i)))
          p[5][`ano${i}`] = p[5][`ano${i}`] < 0 ? 0 : p[5][`ano${i}`]
          p[7][`ano${i}`] = Math.floor(parseFloat(sumTrueBeam2Total) - parseFloat(this.sumYears(p[6], i)))
          p[7][`ano${i}`] = p[7][`ano${i}`] < 0 ? 0 : p[7][`ano${i}`]
          p[9][`ano${i}`] = Math.floor(parseFloat(sumAcelerador1Total) - parseFloat(this.sumYears(p[8], i)))
          p[9][`ano${i}`] = p[9][`ano${i}`] < 0 ? 0 : p[9][`ano${i}`]
          p[11][`ano${i}`] = Math.floor(parseFloat(sumAcelerador2Total) - parseFloat(this.sumYears(p[10], i)))
          p[11][`ano${i}`] = p[11][`ano${i}`] < 0 ? 0 : p[11][`ano${i}`]
          p[14][`ano${i}`] = Math.floor(parseFloat(sumOutrosEquipamentosTotal) - parseFloat(this.sumYears(p[13], i)))
          p[14][`ano${i}`] = p[14][`ano${i}`] < 0 ? 0 : p[14][`ano${i}`]
          p[17][`ano${i}`] = Math.floor(parseFloat(sumHandleAndStructureTotal) - parseFloat(this.sumYears(p[16], i)))
          p[17][`ano${i}`] = p[17][`ano${i}`] < 0 ? 0 : p[17][`ano${i}`]
          p[1][`ano${i}`] = parseFloat(p[2][`ano${i}`]) + parseFloat(p[4][`ano${i}`]) + parseFloat(p[6][`ano${i}`]) + parseFloat(p[8][`ano${i}`])
          p[0][`ano${i}`] = parseFloat(p[1][`ano${i}`]) + parseFloat(p[10][`ano${i}`]) + parseFloat(p[13][`ano${i}`]) + parseFloat(p[16][`ano${i}`])
        }
        return p
      }
      return []
    },
    pcldItems() {
      if (this.premissasFinanceirasItems && this.premissasFinanceirasItems.length && this.dashboard && this.dashboard.project && this.dashboard.project.startYear) {
        const p = [
          {
            type: 'Impostos + PCLD',
            ano0: 0,
            ano1: 0,
            ano2: 0,
            ano3: 0,
            ano4: 0,
            ano5: 0,
            ano6: 0,
            ano7: 0,
            ano8: 0,
            ano9: 0,
            ano10: 0,
            ano11: 0,
            ano12: 0,
            ano13: 0,
            ano14: 0,
            ano15: 0,
            ano16: 0,
            ano17: 0,
            ano18: 0,
            ano19: 0,
            ano20: 0,
          },
          {
            type: 'PIS/COFINS',
            ano0: 0,
            ano1: 0,
            ano2: 0,
            ano3: 0,
            ano4: 0,
            ano5: 0,
            ano6: 0,
            ano7: 0,
            ano8: 0,
            ano9: 0,
            ano10: 0,
            ano11: 0,
            ano12: 0,
            ano13: 0,
            ano14: 0,
            ano15: 0,
            ano16: 0,
            ano17: 0,
            ano18: 0,
            ano19: 0,
            ano20: 0,
          },
          {
            type: 'ISS',
            ano0: 0,
            ano1: 0,
            ano2: 0,
            ano3: 0,
            ano4: 0,
            ano5: 0,
            ano6: 0,
            ano7: 0,
            ano8: 0,
            ano9: 0,
            ano10: 0,
            ano11: 0,
            ano12: 0,
            ano13: 0,
            ano14: 0,
            ano15: 0,
            ano16: 0,
            ano17: 0,
            ano18: 0,
            ano19: 0,
            ano20: 0,
          },
          {
            type: 'PCLD',
            ano0: 0,
            ano1: 0,
            ano2: 0,
            ano3: 0,
            ano4: 0,
            ano5: 0,
            ano6: 0,
            ano7: 0,
            ano8: 0,
            ano9: 0,
            ano10: 0,
            ano11: 0,
            ano12: 0,
            ano13: 0,
            ano14: 0,
            ano15: 0,
            ano16: 0,
            ano17: 0,
            ano18: 0,
            ano19: 0,
            ano20: 0,
          },
        ]
        for (let i = 0; i <= 20; i += 1) {
          p[1][`ano${i}`] = parseFloat((this.composicoesReceitasBrutasItems[1][`ano${i}`] * (parseFloat(this.expenses.impostosMaisPcld.pisCofins) / 100)).toFixed(2))
          p[2][`ano${i}`] = parseFloat((this.composicoesReceitasBrutasItems[1][`ano${i}`] * (parseFloat(this.expenses.impostosMaisPcld.iss) / 100)).toFixed(2))
          p[3][`ano${i}`] = parseFloat((this.composicoesReceitasBrutasItems[1][`ano${i}`] * (parseFloat(this.expenses.impostosMaisPcld.pcld) / 100)).toFixed(2))
          p[0][`ano${i}`] = ((this.staff.cenarioSelected === 'Lucrativo' ? p[1][`ano${i}`] : 0) + p[2][`ano${i}`] + p[3][`ano${i}`]).toFixed(2)
        }
        return p
      }
      return []
    },
    impostoSobreLucroItems() {
      if (this.premissasFinanceirasItems && this.premissasFinanceirasItems.length && this.dashboard && this.dashboard.project && this.dashboard.project.startYear && this.ebitdaItems && this.ebitdaItems.length && this.presumidoItems && this.presumidoItems.length) {
        const p = [
          {
            type: 'Impostos sobre lucro',
            ano0: 0,
            ano1: 0,
            ano2: 0,
            ano3: 0,
            ano4: 0,
            ano5: 0,
            ano6: 0,
            ano7: 0,
            ano8: 0,
            ano9: 0,
            ano10: 0,
            ano11: 0,
            ano12: 0,
            ano13: 0,
            ano14: 0,
            ano15: 0,
            ano16: 0,
            ano17: 0,
            ano18: 0,
            ano19: 0,
            ano20: 0,
          },
          {
            type: 'Cenário',
            ano0: 0,
            ano1: 0,
            ano2: 0,
            ano3: 0,
            ano4: 0,
            ano5: 0,
            ano6: 0,
            ano7: 0,
            ano8: 0,
            ano9: 0,
            ano10: 0,
            ano11: 0,
            ano12: 0,
            ano13: 0,
            ano14: 0,
            ano15: 0,
            ano16: 0,
            ano17: 0,
            ano18: 0,
            ano19: 0,
            ano20: 0,
          },
          {
            type: 'Real',
            ano0: 0,
            ano1: 0,
            ano2: 0,
            ano3: 0,
            ano4: 0,
            ano5: 0,
            ano6: 0,
            ano7: 0,
            ano8: 0,
            ano9: 0,
            ano10: 0,
            ano11: 0,
            ano12: 0,
            ano13: 0,
            ano14: 0,
            ano15: 0,
            ano16: 0,
            ano17: 0,
            ano18: 0,
            ano19: 0,
            ano20: 0,
          },
          {
            type: 'EBT',
            ano0: 0,
            ano1: 0,
            ano2: 0,
            ano3: 0,
            ano4: 0,
            ano5: 0,
            ano6: 0,
            ano7: 0,
            ano8: 0,
            ano9: 0,
            ano10: 0,
            ano11: 0,
            ano12: 0,
            ano13: 0,
            ano14: 0,
            ano15: 0,
            ano16: 0,
            ano17: 0,
            ano18: 0,
            ano19: 0,
            ano20: 0,
          },
          {
            type: 'Prejuízos acumulados',
            ano0: 0,
            ano1: 0,
            ano2: 0,
            ano3: 0,
            ano4: 0,
            ano5: 0,
            ano6: 0,
            ano7: 0,
            ano8: 0,
            ano9: 0,
            ano10: 0,
            ano11: 0,
            ano12: 0,
            ano13: 0,
            ano14: 0,
            ano15: 0,
            ano16: 0,
            ano17: 0,
            ano18: 0,
            ano19: 0,
            ano20: 0,
          },
          {
            type: 'Novo saldo',
            ano0: 0,
            ano1: 0,
            ano2: 0,
            ano3: 0,
            ano4: 0,
            ano5: 0,
            ano6: 0,
            ano7: 0,
            ano8: 0,
            ano9: 0,
            ano10: 0,
            ano11: 0,
            ano12: 0,
            ano13: 0,
            ano14: 0,
            ano15: 0,
            ano16: 0,
            ano17: 0,
            ano18: 0,
            ano19: 0,
            ano20: 0,
          },
          {
            type: 'Saldo Utilizado',
            ano0: 0,
            ano1: 0,
            ano2: 0,
            ano3: 0,
            ano4: 0,
            ano5: 0,
            ano6: 0,
            ano7: 0,
            ano8: 0,
            ano9: 0,
            ano10: 0,
            ano11: 0,
            ano12: 0,
            ano13: 0,
            ano14: 0,
            ano15: 0,
            ano16: 0,
            ano17: 0,
            ano18: 0,
            ano19: 0,
            ano20: 0,
          },
          {
            type: 'Saldo final',
            ano0: 0,
            ano1: 0,
            ano2: 0,
            ano3: 0,
            ano4: 0,
            ano5: 0,
            ano6: 0,
            ano7: 0,
            ano8: 0,
            ano9: 0,
            ano10: 0,
            ano11: 0,
            ano12: 0,
            ano13: 0,
            ano14: 0,
            ano15: 0,
            ano16: 0,
            ano17: 0,
            ano18: 0,
            ano19: 0,
            ano20: 0,
          },
          {
            type: 'Base tributável',
            ano0: 0,
            ano1: 0,
            ano2: 0,
            ano3: 0,
            ano4: 0,
            ano5: 0,
            ano6: 0,
            ano7: 0,
            ano8: 0,
            ano9: 0,
            ano10: 0,
            ano11: 0,
            ano12: 0,
            ano13: 0,
            ano14: 0,
            ano15: 0,
            ano16: 0,
            ano17: 0,
            ano18: 0,
            ano19: 0,
            ano20: 0,
          },
        ]
        for (let i = 0; i <= 20; i += 1) {
          p[3][`ano${i}`] = (parseFloat(this.ebitdaItems[0][`ano${i}`]) - parseFloat(this.ebitdaItems[3][`ano${i}`])).toFixed(2)
          p[8][`ano${i}`] = parseFloat(p[3][`ano${i}`]) < 0 ? 0 : (parseFloat(p[3][`ano${i}`]) - parseFloat(p[6][`ano${i}`]))
          p[2][`ano${i}`] = ((-34 / 100) * parseFloat(p[8][`ano${i}`])).toFixed(2)
          p[0][`ano${i}`] = parseFloat(p[2][`ano${i}`]) > parseFloat(this.presumidoItems[0][`ano${i}`]) ? parseFloat(p[2][`ano${i}`]).toFixed(2) : parseFloat(this.presumidoItems[0][`ano${i}`]).toFixed(2)
          p[1][`ano${i}`] = parseFloat(p[0][`ano${i}`]) === parseFloat(this.presumidoItems[0][`ano${i}`]) ? 'PRESUMIDO' : 'REAL'
          p[4][`ano${i}`] = 0
          if (i > 0) {
            p[4][`ano${i}`] = (parseFloat(p[7][`ano${i - 1}`])).toFixed(2)
          }
          if (parseFloat(p[3][`ano${i}`]) < 0) {
            p[6][`ano${i}`] = 0
          } else if ((parseFloat(p[3][`ano${i}`]) + parseFloat(p[4][`ano${i}`])) < 0) {
            p[6][`ano${i}`] = 0.34 * parseFloat(p[3][`ano${i}`])
          } else {
            p[6][`ano${i}`] = -parseFloat(p[4][`ano${i}`])
          }
          p[6][`ano${i}`] = p[6][`ano${i}`].toFixed(2)
          p[5][`ano${i}`] = parseFloat(p[3][`ano${i}`]) < 0 ? parseFloat(p[3][`ano${i}`]) : 0
          p[7][`ano${i}`] = p[1][`ano${i}`] === 'PRESUMIDO' ? 0 : (parseFloat(p[4][`ano${i}`]) + parseFloat(p[5][`ano${i}`]) + parseFloat(p[6][`ano${i}`]))
          p[7][`ano${i}`] = p[7][`ano${i}`].toFixed(2)
        }
        return p
      }
      return []
    },
    presumidoItems() {
      if (this.dashboard && this.dashboard.project && this.dashboard.project.startYear && this.expenses && this.expenses.impostosSobreLucro && this.expenses.impostosSobreLucro.ir && this.expenses.impostosSobreLucro.csll && this.receitaBrutaItems && this.receitaBrutaItems.length) {
        const p = [
          {
            type: 'Presumido',
            ano0: 0,
            ano1: 0,
            ano2: 0,
            ano3: 0,
            ano4: 0,
            ano5: 0,
            ano6: 0,
            ano7: 0,
            ano8: 0,
            ano9: 0,
            ano10: 0,
            ano11: 0,
            ano12: 0,
            ano13: 0,
            ano14: 0,
            ano15: 0,
            ano16: 0,
            ano17: 0,
            ano18: 0,
            ano19: 0,
            ano20: 0,
          },
          {
            type: 'IR',
            ano0: 0,
            ano1: 0,
            ano2: 0,
            ano3: 0,
            ano4: 0,
            ano5: 0,
            ano6: 0,
            ano7: 0,
            ano8: 0,
            ano9: 0,
            ano10: 0,
            ano11: 0,
            ano12: 0,
            ano13: 0,
            ano14: 0,
            ano15: 0,
            ano16: 0,
            ano17: 0,
            ano18: 0,
            ano19: 0,
            ano20: 0,
          },
          {
            type: 'CSLL',
            ano0: 0,
            ano1: 0,
            ano2: 0,
            ano3: 0,
            ano4: 0,
            ano5: 0,
            ano6: 0,
            ano7: 0,
            ano8: 0,
            ano9: 0,
            ano10: 0,
            ano11: 0,
            ano12: 0,
            ano13: 0,
            ano14: 0,
            ano15: 0,
            ano16: 0,
            ano17: 0,
            ano18: 0,
            ano19: 0,
            ano20: 0,
          },
        ]
        const ir = parseFloat(this.expenses.impostosSobreLucro.ir) / 100
        const csll = parseFloat(this.expenses.impostosSobreLucro.csll) / 100
        for (let i = 0; i <= 20; i += 1) {
          const receitaBruta = ir * parseFloat(this.receitaBrutaItems[1][`ano${i}`])
          const receitaBruta2 = csll * parseFloat(this.receitaBrutaItems[1][`ano${i}`])
          const p1 = -(((receitaBruta) * (15 / 100)) + (((receitaBruta) - 20) * (10 / 100)))
          const p2 = -(((receitaBruta2) * (9 / 100)))
          p[1][`ano${i}`] = p1 > 0 ? 0 : p1
          p[2][`ano${i}`] = p2 > 0 ? 0 : p2
          p[0][`ano${i}`] = parseFloat(p[1][`ano${i}`]) + parseFloat(p[2][`ano${i}`])
          p[0][`ano${i}`] = p[0][`ano${i}`].toFixed(2)
          p[1][`ano${i}`] = p[1][`ano${i}`].toFixed(2)
          p[2][`ano${i}`] = p[2][`ano${i}`].toFixed(2)
        }
        return p
      }
      return []
    },
    presumidoAliquotaEfetivaItems() {
      if (this.dashboard && this.dashboard.project && this.dashboard.project.startYear && this.expenses && this.expenses.impostosSobreLucro && this.expenses.impostosSobreLucro.ir && this.expenses.impostosSobreLucro.csll && this.receitaBrutaItems && this.receitaBrutaItems.length && this.impostoSobreLucroItems && this.impostoSobreLucroItems.length && this.ebtItems && this.ebtItems.length) {
        const p = [
          {
            type: '% Aliquota efetiva do IR',
            ano0: 0,
            ano1: 0,
            ano2: 0,
            ano3: 0,
            ano4: 0,
            ano5: 0,
            ano6: 0,
            ano7: 0,
            ano8: 0,
            ano9: 0,
            ano10: 0,
            ano11: 0,
            ano12: 0,
            ano13: 0,
            ano14: 0,
            ano15: 0,
            ano16: 0,
            ano17: 0,
            ano18: 0,
            ano19: 0,
            ano20: 0,
          },
        ]
        for (let i = 0; i <= 20; i += 1) {
          if (parseFloat(this.impostoSobreLucroItems[0][`ano${i}`]) && parseFloat(this.ebtItems[0][`ano${i}`])) {
            p[0][`ano${i}`] = (100 * (parseFloat(-this.impostoSobreLucroItems[0][`ano${i}`]) / parseFloat(this.ebtItems[0][`ano${i}`]))).toFixed(2)
          }
        }
        return p
      }
      return []
    },
    capitalDeGiroItems() {
      if (this.dashboard && this.dashboard.project && this.dashboard.project.startYear && this.dashboard.capex.bills.dias && this.receitaBrutaItems && this.receitaBrutaItems.length) {
        const p = [
          {
            type: 'Delta NCG',
            ano0: 0,
            ano1: 0,
            ano2: 0,
            ano3: 0,
            ano4: 0,
            ano5: 0,
            ano6: 0,
            ano7: 0,
            ano8: 0,
            ano9: 0,
            ano10: 0,
            ano11: 0,
            ano12: 0,
            ano13: 0,
            ano14: 0,
            ano15: 0,
            ano16: 0,
            ano17: 0,
            ano18: 0,
            ano19: 0,
            ano20: 0,
          },
          {
            type: 'Contas a pagar',
            ano0: 0,
            ano1: 0,
            ano2: 0,
            ano3: 0,
            ano4: 0,
            ano5: 0,
            ano6: 0,
            ano7: 0,
            ano8: 0,
            ano9: 0,
            ano10: 0,
            ano11: 0,
            ano12: 0,
            ano13: 0,
            ano14: 0,
            ano15: 0,
            ano16: 0,
            ano17: 0,
            ano18: 0,
            ano19: 0,
            ano20: 0,
          },
          {
            type: 'Administrativo',
            ano0: 0,
            ano1: 0,
            ano2: 0,
            ano3: 0,
            ano4: 0,
            ano5: 0,
            ano6: 0,
            ano7: 0,
            ano8: 0,
            ano9: 0,
            ano10: 0,
            ano11: 0,
            ano12: 0,
            ano13: 0,
            ano14: 0,
            ano15: 0,
            ano16: 0,
            ano17: 0,
            ano18: 0,
            ano19: 0,
            ano20: 0,
          },
          {
            type: 'Administrativo',
            ano0: 0,
            ano1: 0,
            ano2: 0,
            ano3: 0,
            ano4: 0,
            ano5: 0,
            ano6: 0,
            ano7: 0,
            ano8: 0,
            ano9: 0,
            ano10: 0,
            ano11: 0,
            ano12: 0,
            ano13: 0,
            ano14: 0,
            ano15: 0,
            ano16: 0,
            ano17: 0,
            ano18: 0,
            ano19: 0,
            ano20: 0,
          },
          {
            type: 'Manutenção',
            ano0: 0,
            ano1: 0,
            ano2: 0,
            ano3: 0,
            ano4: 0,
            ano5: 0,
            ano6: 0,
            ano7: 0,
            ano8: 0,
            ano9: 0,
            ano10: 0,
            ano11: 0,
            ano12: 0,
            ano13: 0,
            ano14: 0,
            ano15: 0,
            ano16: 0,
            ano17: 0,
            ano18: 0,
            ano19: 0,
            ano20: 0,
          },
          {
            type: 'Aluguel',
            ano0: 0,
            ano1: 0,
            ano2: 0,
            ano3: 0,
            ano4: 0,
            ano5: 0,
            ano6: 0,
            ano7: 0,
            ano8: 0,
            ano9: 0,
            ano10: 0,
            ano11: 0,
            ano12: 0,
            ano13: 0,
            ano14: 0,
            ano15: 0,
            ano16: 0,
            ano17: 0,
            ano18: 0,
            ano19: 0,
            ano20: 0,
          },
          {
            type: 'Serviços compartilhados',
            ano0: 0,
            ano1: 0,
            ano2: 0,
            ano3: 0,
            ano4: 0,
            ano5: 0,
            ano6: 0,
            ano7: 0,
            ano8: 0,
            ano9: 0,
            ano10: 0,
            ano11: 0,
            ano12: 0,
            ano13: 0,
            ano14: 0,
            ano15: 0,
            ano16: 0,
            ano17: 0,
            ano18: 0,
            ano19: 0,
            ano20: 0,
          },
          {
            type: 'Obrigações sociais',
            ano0: 0,
            ano1: 0,
            ano2: 0,
            ano3: 0,
            ano4: 0,
            ano5: 0,
            ano6: 0,
            ano7: 0,
            ano8: 0,
            ano9: 0,
            ano10: 0,
            ano11: 0,
            ano12: 0,
            ano13: 0,
            ano14: 0,
            ano15: 0,
            ano16: 0,
            ano17: 0,
            ano18: 0,
            ano19: 0,
            ano20: 0,
          },
          {
            type: 'Médicos',
            ano0: 0,
            ano1: 0,
            ano2: 0,
            ano3: 0,
            ano4: 0,
            ano5: 0,
            ano6: 0,
            ano7: 0,
            ano8: 0,
            ano9: 0,
            ano10: 0,
            ano11: 0,
            ano12: 0,
            ano13: 0,
            ano14: 0,
            ano15: 0,
            ano16: 0,
            ano17: 0,
            ano18: 0,
            ano19: 0,
            ano20: 0,
          },
          {
            type: 'Físicos',
            ano0: 0,
            ano1: 0,
            ano2: 0,
            ano3: 0,
            ano4: 0,
            ano5: 0,
            ano6: 0,
            ano7: 0,
            ano8: 0,
            ano9: 0,
            ano10: 0,
            ano11: 0,
            ano12: 0,
            ano13: 0,
            ano14: 0,
            ano15: 0,
            ano16: 0,
            ano17: 0,
            ano18: 0,
            ano19: 0,
            ano20: 0,
          },
          {
            type: 'Pessoal da clínica',
            ano0: 0,
            ano1: 0,
            ano2: 0,
            ano3: 0,
            ano4: 0,
            ano5: 0,
            ano6: 0,
            ano7: 0,
            ano8: 0,
            ano9: 0,
            ano10: 0,
            ano11: 0,
            ano12: 0,
            ano13: 0,
            ano14: 0,
            ano15: 0,
            ano16: 0,
            ano17: 0,
            ano18: 0,
            ano19: 0,
            ano20: 0,
          },
          {
            type: 'Obrigações tributárias',
            ano0: 0,
            ano1: 0,
            ano2: 0,
            ano3: 0,
            ano4: 0,
            ano5: 0,
            ano6: 0,
            ano7: 0,
            ano8: 0,
            ano9: 0,
            ano10: 0,
            ano11: 0,
            ano12: 0,
            ano13: 0,
            ano14: 0,
            ano15: 0,
            ano16: 0,
            ano17: 0,
            ano18: 0,
            ano19: 0,
            ano20: 0,
          },
          {
            type: 'Imposto de renda IR/CSLL',
            ano0: 0,
            ano1: 0,
            ano2: 0,
            ano3: 0,
            ano4: 0,
            ano5: 0,
            ano6: 0,
            ano7: 0,
            ano8: 0,
            ano9: 0,
            ano10: 0,
            ano11: 0,
            ano12: 0,
            ano13: 0,
            ano14: 0,
            ano15: 0,
            ano16: 0,
            ano17: 0,
            ano18: 0,
            ano19: 0,
            ano20: 0,
          },
          {
            type: 'Contas a receber',
            ano0: 0,
            ano1: 0,
            ano2: 0,
            ano3: 0,
            ano4: 0,
            ano5: 0,
            ano6: 0,
            ano7: 0,
            ano8: 0,
            ano9: 0,
            ano10: 0,
            ano11: 0,
            ano12: 0,
            ano13: 0,
            ano14: 0,
            ano15: 0,
            ano16: 0,
            ano17: 0,
            ano18: 0,
            ano19: 0,
            ano20: 0,
          },
          {
            type: 'NCG',
            ano0: 0,
            ano1: 0,
            ano2: 0,
            ano3: 0,
            ano4: 0,
            ano5: 0,
            ano6: 0,
            ano7: 0,
            ano8: 0,
            ano9: 0,
            ano10: 0,
            ano11: 0,
            ano12: 0,
            ano13: 0,
            ano14: 0,
            ano15: 0,
            ano16: 0,
            ano17: 0,
            ano18: 0,
            ano19: 0,
            ano20: 0,
          },
        ]
        const p13Factor = parseFloat(this.dashboard.capex.bills.dias) / 360
        const p3Factor = parseFloat(this.dashboard.capex.bills.administrativo) / 360
        const p4Factor = parseFloat(this.dashboard.capex.bills.manutencao) / 360
        const p5Factor = parseFloat(this.dashboard.capex.bills.aluguel) / 360
        const p6Factor = parseFloat(this.dashboard.capex.bills.csc) / 360
        const p8And9Factor = parseFloat(this.dashboard.capex.bills.obrigacoesSociais) / 360
        const p10Factor = 0
        const p11Factor = parseFloat(this.dashboard.capex.bills.obricacoesTributarias) / 360
        const p12Factor = parseFloat(this.dashboard.capex.bills.irlCsll) / 360
        for (let i = 0; i <= 20; i += 1) {
          p[13][`ano${i}`] = (p13Factor * parseFloat(this.receitaBrutaItems[4][`ano${i}`])).toFixed(2)
          p[3][`ano${i}`] = (p3Factor * parseFloat(this.lucroBrutoItems[4][`ano${i}`])).toFixed(2)
          p[4][`ano${i}`] = (p4Factor * parseFloat(this.lucroBrutoItems[6][`ano${i}`])).toFixed(2)
          p[5][`ano${i}`] = (p5Factor * parseFloat(this.lucroBrutoItems[7][`ano${i}`])).toFixed(2)
          p[6][`ano${i}`] = (p6Factor * parseFloat(this.lucroBrutoItems[8][`ano${i}`])).toFixed(2)
          p[2][`ano${i}`] = (parseFloat(p[3][`ano${i}`]) + parseFloat(p[4][`ano${i}`]) + parseFloat(p[5][`ano${i}`]) + parseFloat(p[6][`ano${i}`])).toFixed(2)

          p[8][`ano${i}`] = (p8And9Factor * parseFloat(this.custoItems[1][`ano${i}`])).toFixed(2)
          p[9][`ano${i}`] = (p8And9Factor * parseFloat(this.custoItems[3][`ano${i}`])).toFixed(2)
          p[10][`ano${i}`] = (p10Factor * parseFloat(this.lucroBrutoItems[5][`ano${i}`])).toFixed(2)
          p[7][`ano${i}`] = (parseFloat(p[8][`ano${i}`]) + parseFloat(p[9][`ano${i}`]) + parseFloat(p[10][`ano${i}`])).toFixed(2)

          p[11][`ano${i}`] = (p11Factor * parseFloat(this.receitaBrutaItems[3][`ano${i}`])).toFixed(2)
          p[12][`ano${i}`] = (p12Factor * (-parseFloat(this.ebtItems[3][`ano${i}`]))).toFixed(2)

          p[1][`ano${i}`] = (parseFloat(p[2][`ano${i}`]) + parseFloat(p[7][`ano${i}`]) + parseFloat(p[11][`ano${i}`]) + parseFloat(p[12][`ano${i}`])).toFixed(2)
          p[14][`ano${i}`] = (parseFloat(p[1][`ano${i}`]) - parseFloat(p[13][`ano${i}`])).toFixed(2)
          if (i === 0) {
            p[0][`ano${i}`] = parseFloat(p[14][`ano${i}`])
          } else {
            p[0][`ano${i}`] = (parseFloat(p[14][`ano${i}`]) - parseFloat(p[14][`ano${i - 1}`])).toFixed(2)
          }
        }
        return p
      }
      return []
    },
    caixaMinimoDividendoItems() {
      if (this.dashboard && this.dashboard.project && this.dashboard.project.startYear) {
        const p = [
          {
            type: 'Dividendos por lucro',
            ano0: this.dashboard.project.startYear + 0,
            ano1: this.dashboard.project.startYear + 1,
            ano2: this.dashboard.project.startYear + 2,
            ano3: this.dashboard.project.startYear + 3,
            ano4: this.dashboard.project.startYear + 4,
            ano5: this.dashboard.project.startYear + 5,
            ano6: this.dashboard.project.startYear + 6,
            ano7: this.dashboard.project.startYear + 7,
            ano8: this.dashboard.project.startYear + 8,
            ano9: this.dashboard.project.startYear + 9,
            ano10: this.dashboard.project.startYear + 10,
            ano11: this.dashboard.project.startYear + 11,
            ano12: this.dashboard.project.startYear + 12,
            ano13: this.dashboard.project.startYear + 13,
            ano14: this.dashboard.project.startYear + 14,
            ano15: this.dashboard.project.startYear + 15,
            ano16: this.dashboard.project.startYear + 16,
            ano17: this.dashboard.project.startYear + 17,
            ano18: this.dashboard.project.startYear + 18,
            ano19: this.dashboard.project.startYear + 19,
            ano20: this.dashboard.project.startYear + 20,
          },
          {
            type: 'Caixa mínimo',
            ano0: 0,
            ano1: 0,
            ano2: 0,
            ano3: 0,
            ano4: 0,
            ano5: 0,
            ano6: 0,
            ano7: 0,
            ano8: 0,
            ano9: 0,
            ano10: 0,
            ano11: 0,
            ano12: 0,
            ano13: 0,
            ano14: 0,
            ano15: 0,
            ano16: 0,
            ano17: 0,
            ano18: 0,
            ano19: 0,
            ano20: 0,
          },
          {
            type: 'Resultado acumulado',
            ano0: 0,
            ano1: 0,
            ano2: 0,
            ano3: 0,
            ano4: 0,
            ano5: 0,
            ano6: 0,
            ano7: 0,
            ano8: 0,
            ano9: 0,
            ano10: 0,
            ano11: 0,
            ano12: 0,
            ano13: 0,
            ano14: 0,
            ano15: 0,
            ano16: 0,
            ano17: 0,
            ano18: 0,
            ano19: 0,
            ano20: 0,
          },
          {
            type: 'Mínimo obrigatório',
            ano0: 0,
            ano1: 0,
            ano2: 0,
            ano3: 0,
            ano4: 0,
            ano5: 0,
            ano6: 0,
            ano7: 0,
            ano8: 0,
            ano9: 0,
            ano10: 0,
            ano11: 0,
            ano12: 0,
            ano13: 0,
            ano14: 0,
            ano15: 0,
            ano16: 0,
            ano17: 0,
            ano18: 0,
            ano19: 0,
            ano20: 0,
          },
          {
            type: 'Disponível para dividendos',
            ano0: 0,
            ano1: 0,
            ano2: 0,
            ano3: 0,
            ano4: 0,
            ano5: 0,
            ano6: 0,
            ano7: 0,
            ano8: 0,
            ano9: 0,
            ano10: 0,
            ano11: 0,
            ano12: 0,
            ano13: 0,
            ano14: 0,
            ano15: 0,
            ano16: 0,
            ano17: 0,
            ano18: 0,
            ano19: 0,
            ano20: 0,
          },
          {
            type: 'Dividendos via sobre caixa',
            ano0: this.dashboard.project.startYear + 0,
            ano1: this.dashboard.project.startYear + 1,
            ano2: this.dashboard.project.startYear + 2,
            ano3: this.dashboard.project.startYear + 3,
            ano4: this.dashboard.project.startYear + 4,
            ano5: this.dashboard.project.startYear + 5,
            ano6: this.dashboard.project.startYear + 6,
            ano7: this.dashboard.project.startYear + 7,
            ano8: this.dashboard.project.startYear + 8,
            ano9: this.dashboard.project.startYear + 9,
            ano10: this.dashboard.project.startYear + 10,
            ano11: this.dashboard.project.startYear + 11,
            ano12: this.dashboard.project.startYear + 12,
            ano13: this.dashboard.project.startYear + 13,
            ano14: this.dashboard.project.startYear + 14,
            ano15: this.dashboard.project.startYear + 15,
            ano16: this.dashboard.project.startYear + 16,
            ano17: this.dashboard.project.startYear + 17,
            ano18: this.dashboard.project.startYear + 18,
            ano19: this.dashboard.project.startYear + 19,
            ano20: this.dashboard.project.startYear + 20,
          },
          {
            type: 'Caixa mínimo',
            ano0: 0,
            ano1: 0,
            ano2: 0,
            ano3: 0,
            ano4: 0,
            ano5: 0,
            ano6: 0,
            ano7: 0,
            ano8: 0,
            ano9: 0,
            ano10: 0,
            ano11: 0,
            ano12: 0,
            ano13: 0,
            ano14: 0,
            ano15: 0,
            ano16: 0,
            ano17: 0,
            ano18: 0,
            ano19: 0,
            ano20: 0,
          },
          {
            type: 'Passível de distribuição',
            ano0: 0,
            ano1: 0,
            ano2: 0,
            ano3: 0,
            ano4: 0,
            ano5: 0,
            ano6: 0,
            ano7: 0,
            ano8: 0,
            ano9: 0,
            ano10: 0,
            ano11: 0,
            ano12: 0,
            ano13: 0,
            ano14: 0,
            ano15: 0,
            ano16: 0,
            ano17: 0,
            ano18: 0,
            ano19: 0,
            ano20: 0,
          },
          {
            type: 'Dividendos propostos',
            ano0: 0,
            ano1: 0,
            ano2: 0,
            ano3: 0,
            ano4: 0,
            ano5: 0,
            ano6: 0,
            ano7: 0,
            ano8: 0,
            ano9: 0,
            ano10: 0,
            ano11: 0,
            ano12: 0,
            ano13: 0,
            ano14: 0,
            ano15: 0,
            ano16: 0,
            ano17: 0,
            ano18: 0,
            ano19: 0,
            ano20: 0,
          },
        ]
        let p7Clausula
        for (let i = 0; i <= 20; i += 1) {
          p[1][`ano${i}`] = (parseFloat(this.dashboard.caixa.caixaMinimo) * parseFloat(this.premissasFinanceirasItems[6][`ano${i}`])).toFixed(2)
          p[2][`ano${i}`] = (parseFloat(this.lucroLiquidoItems[0][`ano${i}`])).toFixed(2)
          if (i === 0) {
            p[3][`ano${i}`] = 0
          } else {
            p[3][`ano${i}`] = (parseFloat(p[2][`ano${i - 1}`]) < 0 ? 0 : parseFloat(p[2][`ano${i - 1}`]) * (parseFloat(this.dashboard.caixa.minimoObrigatorio) / 100)).toFixed(2)
          }
          p[4][`ano${i}`] = parseFloat(p[3][`ano${i}`]) * (parseFloat(this.dashboard.caixa.minimoObrigatorio) / 100)

          p[6][`ano${i}`] = parseFloat(p[1][`ano${i}`])
          p7Clausula = parseFloat(this.caixaInicialItems[0][`ano${i}`]) + parseFloat(this.fcOperacionalItems[0][`ano${i}`]) + parseFloat(this.fcInvestimentosItems[1][`ano${i}`]) + parseFloat(this.fcInvestimentosItems[2][`ano${i}`]) - parseFloat(p[6][`ano${i}`])
          p[7][`ano${i}`] = p7Clausula < 0 ? 0 : p7Clausula.toFixed(2)
          p[8][`ano${i}`] = parseFloat(p[4][`ano${i}`]) > 0 ? -parseFloat(p[4][`ano${i}`]) : parseFloat(p[4][`ano${i}`])
        }
        return p
      }
      return []
    },
    dividaItems() {
      if (this.dashboard && this.dashboard.project && this.dashboard.project.startYear && this.dashboard.divida && this.capexItems && this.capexItems.length) {
        const p = [
          {
            type: 'Saldo inicial',
            ano0: 0,
            ano1: 0,
            ano2: 0,
            ano3: 0,
            ano4: 0,
            ano5: 0,
            ano6: 0,
            ano7: 0,
            ano8: 0,
            ano9: 0,
            ano10: 0,
            ano11: 0,
            ano12: 0,
            ano13: 0,
            ano14: 0,
            ano15: 0,
            ano16: 0,
            ano17: 0,
            ano18: 0,
            ano19: 0,
            ano20: 0,
          },
          {
            type: 'Captação',
            ano0: 0,
            ano1: 0,
            ano2: 0,
            ano3: 0,
            ano4: 0,
            ano5: 0,
            ano6: 0,
            ano7: 0,
            ano8: 0,
            ano9: 0,
            ano10: 0,
            ano11: 0,
            ano12: 0,
            ano13: 0,
            ano14: 0,
            ano15: 0,
            ano16: 0,
            ano17: 0,
            ano18: 0,
            ano19: 0,
            ano20: 0,
          },
          {
            type: 'Amortização',
            ano0: 0,
            ano1: 0,
            ano2: 0,
            ano3: 0,
            ano4: 0,
            ano5: 0,
            ano6: 0,
            ano7: 0,
            ano8: 0,
            ano9: 0,
            ano10: 0,
            ano11: 0,
            ano12: 0,
            ano13: 0,
            ano14: 0,
            ano15: 0,
            ano16: 0,
            ano17: 0,
            ano18: 0,
            ano19: 0,
            ano20: 0,
          },
          {
            type: 'Dívida ano 0',
            ano0: 0,
            ano1: 0,
            ano2: 0,
            ano3: 0,
            ano4: 0,
            ano5: 0,
            ano6: 0,
            ano7: 0,
            ano8: 0,
            ano9: 0,
            ano10: 0,
            ano11: 0,
            ano12: 0,
            ano13: 0,
            ano14: 0,
            ano15: 0,
            ano16: 0,
            ano17: 0,
            ano18: 0,
            ano19: 0,
            ano20: 0,
          },
          {
            type: 'Dívida ano 1',
            ano0: 0,
            ano1: 0,
            ano2: 0,
            ano3: 0,
            ano4: 0,
            ano5: 0,
            ano6: 0,
            ano7: 0,
            ano8: 0,
            ano9: 0,
            ano10: 0,
            ano11: 0,
            ano12: 0,
            ano13: 0,
            ano14: 0,
            ano15: 0,
            ano16: 0,
            ano17: 0,
            ano18: 0,
            ano19: 0,
            ano20: 0,
          },
          {
            type: 'Dívida ano 2',
            ano0: 0,
            ano1: 0,
            ano2: 0,
            ano3: 0,
            ano4: 0,
            ano5: 0,
            ano6: 0,
            ano7: 0,
            ano8: 0,
            ano9: 0,
            ano10: 0,
            ano11: 0,
            ano12: 0,
            ano13: 0,
            ano14: 0,
            ano15: 0,
            ano16: 0,
            ano17: 0,
            ano18: 0,
            ano19: 0,
            ano20: 0,
          },
          {
            type: 'Dívida Capital de giro',
            ano0: 0,
            ano1: 0,
            ano2: 0,
            ano3: 0,
            ano4: 0,
            ano5: 0,
            ano6: 0,
            ano7: 0,
            ano8: 0,
            ano9: 0,
            ano10: 0,
            ano11: 0,
            ano12: 0,
            ano13: 0,
            ano14: 0,
            ano15: 0,
            ano16: 0,
            ano17: 0,
            ano18: 0,
            ano19: 0,
            ano20: 0,
          },
          {
            type: 'Acrual de juros',
            ano0: 0,
            ano1: 0,
            ano2: 0,
            ano3: 0,
            ano4: 0,
            ano5: 0,
            ano6: 0,
            ano7: 0,
            ano8: 0,
            ano9: 0,
            ano10: 0,
            ano11: 0,
            ano12: 0,
            ano13: 0,
            ano14: 0,
            ano15: 0,
            ano16: 0,
            ano17: 0,
            ano18: 0,
            ano19: 0,
            ano20: 0,
          },
          {
            type: 'Pagamento juros',
            ano0: 0,
            ano1: 0,
            ano2: 0,
            ano3: 0,
            ano4: 0,
            ano5: 0,
            ano6: 0,
            ano7: 0,
            ano8: 0,
            ano9: 0,
            ano10: 0,
            ano11: 0,
            ano12: 0,
            ano13: 0,
            ano14: 0,
            ano15: 0,
            ano16: 0,
            ano17: 0,
            ano18: 0,
            ano19: 0,
            ano20: 0,
          },
          {
            type: 'Saldo final',
            ano0: 0,
            ano1: 0,
            ano2: 0,
            ano3: 0,
            ano4: 0,
            ano5: 0,
            ano6: 0,
            ano7: 0,
            ano8: 0,
            ano9: 0,
            ano10: 0,
            ano11: 0,
            ano12: 0,
            ano13: 0,
            ano14: 0,
            ano15: 0,
            ano16: 0,
            ano17: 0,
            ano18: 0,
            ano19: 0,
            ano20: 0,
          },
        ]
        let p3Sum
        let p4Sum
        let p5Sum
        let p6Sum
        for (let i = 0; i <= 20; i += 1) {
          p[1][`ano${i}`] = ((parseFloat(this.dashboard.divida.captacaoPercent) / 100) * parseFloat(this.capexItems[0][`ano${i}`]))
          if (i < 1) {
            p[3][`ano${i}`] = 0
          } else {
            p3Sum = this.sumYears(p[3], (i - 1))
            p[3][`ano${i}`] = (-p3Sum) === parseFloat(p[1].ano0) ? 0 : ((-parseFloat(p[1].ano0) / parseFloat(this.dashboard.divida.divitaTotalAnos))).toFixed(2)
          }
          if (i < 2) {
            p[4][`ano${i}`] = 0
          } else {
            p4Sum = this.sumYears(p[4], (i - 1))
            p[4][`ano${i}`] = (-p4Sum) === parseFloat(p[1].ano1) ? 0 : ((-parseFloat(p[1].ano1) / parseFloat(this.dashboard.divida.divitaTotalAnos))).toFixed(2)
          }
          if (i < 3) {
            p[5][`ano${i}`] = 0
          } else {
            p5Sum = this.sumYears(p[5], (i - 1))
            p[5][`ano${i}`] = (-p5Sum) === parseFloat(p[1].ano2) ? 0 : ((-parseFloat(p[1].ano2) / parseFloat(this.dashboard.divida.divitaTotalAnos))).toFixed(2)
          }
          if (i < 4) {
            p[6][`ano${i}`] = 0
          } else {
            p6Sum = this.sumYears(p[6], (i - 1))
            p[6][`ano${i}`] = (-p6Sum) === parseFloat(p[1].ano3) ? 0 : ((-parseFloat(p[1].ano3) / parseFloat(this.dashboard.divida.dividaCapitalGiroAnos))).toFixed(2)
          }
          p[2][`ano${i}`] = (parseFloat(p[3][`ano${i}`]) + parseFloat(p[4][`ano${i}`]) + parseFloat(p[5][`ano${i}`]) + parseFloat(p[6][`ano${i}`])).toFixed(2)
          p[2][`ano${i}`] = parseFloat(p[2][`ano${i}`])
          // TODO FIX
          // p[7][`ano${i}`] = (parseFloat(this.dashboard.divida.acrualJurosPercent) / 100) + (H$128)
          p[7][`ano${i}`] = 0
          p[8][`ano${i}`] = -parseFloat(p[7][`ano${i}`])
          if (i === 0) {
            p[9][`ano${i}`] = (parseFloat(p[1][`ano${i}`]) + parseFloat(p[2][`ano${i}`])).toFixed(2)
            p[9][`ano${i}`] = parseFloat(p[9][`ano${i}`]) >= 0 ? parseFloat(p[9][`ano${i}`]) : 0
            p[0][`ano${i}`] = 0
          } else {
            p[9][`ano${i}`] = (parseFloat(p[9][`ano${i - 1}`]) + parseFloat(p[2][`ano${i}`])).toFixed(2)
            p[9][`ano${i}`] = parseFloat(p[9][`ano${i}`]) >= 0 ? parseFloat(p[9][`ano${i}`]) : 0
            p[0][`ano${i}`] = parseFloat(p[9][`ano${i - 1}`])
          }
        }
        return p
      }
      return []
    },
    // BP 2
    caixaInicialItems() {
      if (this.dashboard && this.dashboard.project && this.dashboard.project.startYear) {
        const p = [
          {
            type: 'Caixa inicial',
            ano0: 0,
            ano1: 0,
            ano2: 0,
            ano3: 0,
            ano4: 0,
            ano5: 0,
            ano6: 0,
            ano7: 0,
            ano8: 0,
            ano9: 0,
            ano10: 0,
            ano11: 0,
            ano12: 0,
            ano13: 0,
            ano14: 0,
            ano15: 0,
            ano16: 0,
            ano17: 0,
            ano18: 0,
            ano19: 0,
            ano20: 0,
          },
        ]
        for (let i = 1; i <= 20; i += 1) {
          p[0][`ano${i}`] = parseFloat(this.fcCaixaFinalItems[0][`ano${i - 1}`])
        }
        return p
      }
      return []
    },
    fcOperacionalItems() {
      if (this.dashboard && this.dashboard.project && this.dashboard.project.startYear && this.receitaBrutaItems && this.receitaBrutaItems.length && this.capitalDeGiroItems && this.capitalDeGiroItems.length && this.lucroBrutoItems && this.lucroBrutoItems.length && this.ebtItems && this.ebtItems.length) {
        const p = [
          {
            type: 'FC Operacional',
            ano0: 0,
            ano1: 0,
            ano2: 0,
            ano3: 0,
            ano4: 0,
            ano5: 0,
            ano6: 0,
            ano7: 0,
            ano8: 0,
            ano9: 0,
            ano10: 0,
            ano11: 0,
            ano12: 0,
            ano13: 0,
            ano14: 0,
            ano15: 0,
            ano16: 0,
            ano17: 0,
            ano18: 0,
            ano19: 0,
            ano20: 0,
          },
          {
            type: '(+) Recebimento de clientes',
            ano0: 0,
            ano1: 0,
            ano2: 0,
            ano3: 0,
            ano4: 0,
            ano5: 0,
            ano6: 0,
            ano7: 0,
            ano8: 0,
            ano9: 0,
            ano10: 0,
            ano11: 0,
            ano12: 0,
            ano13: 0,
            ano14: 0,
            ano15: 0,
            ano16: 0,
            ano17: 0,
            ano18: 0,
            ano19: 0,
            ano20: 0,
          },
          {
            type: '(-) Serviços Médicos',
            ano0: 0,
            ano1: 0,
            ano2: 0,
            ano3: 0,
            ano4: 0,
            ano5: 0,
            ano6: 0,
            ano7: 0,
            ano8: 0,
            ano9: 0,
            ano10: 0,
            ano11: 0,
            ano12: 0,
            ano13: 0,
            ano14: 0,
            ano15: 0,
            ano16: 0,
            ano17: 0,
            ano18: 0,
            ano19: 0,
            ano20: 0,
          },
          {
            type: '(-) Despesas administrativas',
            ano0: 0,
            ano1: 0,
            ano2: 0,
            ano3: 0,
            ano4: 0,
            ano5: 0,
            ano6: 0,
            ano7: 0,
            ano8: 0,
            ano9: 0,
            ano10: 0,
            ano11: 0,
            ano12: 0,
            ano13: 0,
            ano14: 0,
            ano15: 0,
            ano16: 0,
            ano17: 0,
            ano18: 0,
            ano19: 0,
            ano20: 0,
          },
          {
            type: '(-) Despesas de pessoal',
            ano0: 0,
            ano1: 0,
            ano2: 0,
            ano3: 0,
            ano4: 0,
            ano5: 0,
            ano6: 0,
            ano7: 0,
            ano8: 0,
            ano9: 0,
            ano10: 0,
            ano11: 0,
            ano12: 0,
            ano13: 0,
            ano14: 0,
            ano15: 0,
            ano16: 0,
            ano17: 0,
            ano18: 0,
            ano19: 0,
            ano20: 0,
          },
          {
            type: '(-) Impostos sobre lucro',
            ano0: 0,
            ano1: 0,
            ano2: 0,
            ano3: 0,
            ano4: 0,
            ano5: 0,
            ano6: 0,
            ano7: 0,
            ano8: 0,
            ano9: 0,
            ano10: 0,
            ano11: 0,
            ano12: 0,
            ano13: 0,
            ano14: 0,
            ano15: 0,
            ano16: 0,
            ano17: 0,
            ano18: 0,
            ano19: 0,
            ano20: 0,
          },
          {
            type: '(-) Serviços compartilhados',
            ano0: 0,
            ano1: 0,
            ano2: 0,
            ano3: 0,
            ano4: 0,
            ano5: 0,
            ano6: 0,
            ano7: 0,
            ano8: 0,
            ano9: 0,
            ano10: 0,
            ano11: 0,
            ano12: 0,
            ano13: 0,
            ano14: 0,
            ano15: 0,
            ano16: 0,
            ano17: 0,
            ano18: 0,
            ano19: 0,
            ano20: 0,
          },
        ]
        for (let i = 0; i <= 20; i += 1) {
          if (i === 0) {
            p[1][`ano${i}`] = (parseFloat(this.receitaBrutaItems[4][`ano${i}`]) - parseFloat(this.capitalDeGiroItems[13][`ano${i}`])).toFixed(2)
          } else {
            p[1][`ano${i}`] = (parseFloat(this.receitaBrutaItems[4][`ano${i}`]) - parseFloat(this.capitalDeGiroItems[13][`ano${i}`]) + parseFloat(this.capitalDeGiroItems[13][`ano${i - 1}`])).toFixed(2)
          }
          if (parseFloat(this.custoItems[1][`ano${i}`]) > 0) {
            p[2][`ano${i}`] = parseFloat(this.capitalDeGiroItems[8][`ano${i}`]) + parseFloat(this.capitalDeGiroItems[9][`ano${i}`]) - (parseFloat(this.custoItems[3][`ano${i}`]) + parseFloat(this.custoItems[1][`ano${i}`]))
            if (i > 0) {
              p[2][`ano${i}`] = parseFloat(p[2][`ano${i}`]) - parseFloat(this.capitalDeGiroItems[8][`ano${i - 1}`]) - parseFloat(this.capitalDeGiroItems[9][`ano${i - 1}`])
            }
          } else {
            p[2][`ano${i}`] = parseFloat(this.custoItems[2][`ano${i}`])
          }
          p[2][`ano${i}`] = parseFloat(p[2][`ano${i}`]).toFixed(2)
          p[3][`ano${i}`] = (parseFloat(this.lucroBrutoItems[4][`ano${i}`]) + parseFloat(this.capitalDeGiroItems[6][`ano${i}`])) + (parseFloat(this.lucroBrutoItems[6][`ano${i}`]) + parseFloat(this.capitalDeGiroItems[7][`ano${i}`])) + (parseFloat(this.lucroBrutoItems[7][`ano${i}`]) + parseFloat(this.capitalDeGiroItems[8][`ano${i}`]))
          if (i > 0) {
            p[3][`ano${i}`] = parseFloat(p[3][`ano${i}`]) - parseFloat(this.capitalDeGiroItems[6][`ano${i - 1}`]) - parseFloat(this.capitalDeGiroItems[7][`ano${i - 1}`]) - parseFloat(this.capitalDeGiroItems[8][`ano${i - 1}`])
          }
          p[3][`ano${i}`] = -parseFloat(p[3][`ano${i}`]).toFixed(2)
          p[4][`ano${i}`] = (parseFloat(this.lucroBrutoItems[5][`ano${i}`]) + parseFloat(this.capitalDeGiroItems[10][`ano${i}`]))
          if (i > 0) {
            p[4][`ano${i}`] = parseFloat(p[4][`ano${i}`]) - parseFloat(this.capitalDeGiroItems[10][`ano${i - 1}`])
          }
          p[4][`ano${i}`] = -parseFloat(p[4][`ano${i}`]).toFixed(2)
          p[5][`ano${i}`] = (parseFloat(this.ebtItems[3][`ano${i}`]) + parseFloat(this.capitalDeGiroItems[12][`ano${i}`]))
          if (i > 0) {
            p[5][`ano${i}`] = parseFloat(p[5][`ano${i}`]) - parseFloat(this.capitalDeGiroItems[12][`ano${i - 1}`])
          }
          p[5][`ano${i}`] = parseFloat(p[5][`ano${i}`]).toFixed(2)
          p[6][`ano${i}`] = (parseFloat(this.lucroBrutoItems[8][`ano${i}`]) + parseFloat(this.capitalDeGiroItems[6][`ano${i}`]))
          if (i > 0) {
            p[6][`ano${i}`] = parseFloat(p[6][`ano${i}`]) - parseFloat(this.capitalDeGiroItems[6][`ano${i - 1}`])
          }
          p[6][`ano${i}`] = -parseFloat(p[6][`ano${i}`]).toFixed(2)
          p[0][`ano${i}`] = (parseFloat(p[1][`ano${i}`]) + parseFloat(p[2][`ano${i}`]) + parseFloat(p[3][`ano${i}`]) + parseFloat(p[4][`ano${i}`]) + parseFloat(p[5][`ano${i}`]) + parseFloat(p[6][`ano${i}`])).toFixed(2)
        }
        return p
      }
      return []
    },
    fcInvestimentosItems() {
      if (this.dashboard && this.dashboard.project && this.dashboard.project.startYear) {
        const p = [
          {
            type: 'FC Investimentos',
            ano0: 0,
            ano1: 0,
            ano2: 0,
            ano3: 0,
            ano4: 0,
            ano5: 0,
            ano6: 0,
            ano7: 0,
            ano8: 0,
            ano9: 0,
            ano10: 0,
            ano11: 0,
            ano12: 0,
            ano13: 0,
            ano14: 0,
            ano15: 0,
            ano16: 0,
            ano17: 0,
            ano18: 0,
            ano19: 0,
            ano20: 0,
          },
          {
            type: '(-) Capex Inicial',
            ano0: 0,
            ano1: 0,
            ano2: 0,
            ano3: 0,
            ano4: 0,
            ano5: 0,
            ano6: 0,
            ano7: 0,
            ano8: 0,
            ano9: 0,
            ano10: 0,
            ano11: 0,
            ano12: 0,
            ano13: 0,
            ano14: 0,
            ano15: 0,
            ano16: 0,
            ano17: 0,
            ano18: 0,
            ano19: 0,
            ano20: 0,
          },
          {
            type: '(-) Capex de manutenção',
            ano0: 0,
            ano1: 0,
            ano2: 0,
            ano3: 0,
            ano4: 0,
            ano5: 0,
            ano6: 0,
            ano7: 0,
            ano8: 0,
            ano9: 0,
            ano10: 0,
            ano11: 0,
            ano12: 0,
            ano13: 0,
            ano14: 0,
            ano15: 0,
            ano16: 0,
            ano17: 0,
            ano18: 0,
            ano19: 0,
            ano20: 0,
          },
          {
            type: '(+) Aporte de capital',
            ano0: 0,
            ano1: 0,
            ano2: 0,
            ano3: 0,
            ano4: 0,
            ano5: 0,
            ano6: 0,
            ano7: 0,
            ano8: 0,
            ano9: 0,
            ano10: 0,
            ano11: 0,
            ano12: 0,
            ano13: 0,
            ano14: 0,
            ano15: 0,
            ano16: 0,
            ano17: 0,
            ano18: 0,
            ano19: 0,
            ano20: 0,
          },
        ]
        for (let i = 0; i <= 20; i += 1) {
          p[1][`ano${i}`] = -(parseFloat(this.capexItems[1][`ano${i}`]) + parseFloat(this.capexItems[82][`ano${i}`]) + parseFloat(this.capexItems[96][`ano${i}`])).toFixed(2)
          p[2][`ano${i}`] = -parseFloat(this.manutencaoItems[0][`ano${i}`]).toFixed(2)
          p[3][`ano${i}`] = 0
          if (i < 5) { p[3][`ano${i}`] = this.getAporteNoAno(i) }
          p[0][`ano${i}`] = (parseFloat(p[1][`ano${i}`]) + parseFloat(p[2][`ano${i}`]) + parseFloat(p[3][`ano${i}`])).toFixed(2)
        }
        return p
      }
      return []
    },
    fcFinanciamentosItems() {
      if (this.dashboard && this.dashboard.project && this.dashboard.project.startYear) {
        const p = [
          {
            type: 'FC Financiamentos',
            ano0: 0,
            ano1: 0,
            ano2: 0,
            ano3: 0,
            ano4: 0,
            ano5: 0,
            ano6: 0,
            ano7: 0,
            ano8: 0,
            ano9: 0,
            ano10: 0,
            ano11: 0,
            ano12: 0,
            ano13: 0,
            ano14: 0,
            ano15: 0,
            ano16: 0,
            ano17: 0,
            ano18: 0,
            ano19: 0,
            ano20: 0,
          },
          {
            type: '(+) Captação',
            ano0: 0,
            ano1: 0,
            ano2: 0,
            ano3: 0,
            ano4: 0,
            ano5: 0,
            ano6: 0,
            ano7: 0,
            ano8: 0,
            ano9: 0,
            ano10: 0,
            ano11: 0,
            ano12: 0,
            ano13: 0,
            ano14: 0,
            ano15: 0,
            ano16: 0,
            ano17: 0,
            ano18: 0,
            ano19: 0,
            ano20: 0,
          },
          {
            type: '(-) Amortização',
            ano0: 0,
            ano1: 0,
            ano2: 0,
            ano3: 0,
            ano4: 0,
            ano5: 0,
            ano6: 0,
            ano7: 0,
            ano8: 0,
            ano9: 0,
            ano10: 0,
            ano11: 0,
            ano12: 0,
            ano13: 0,
            ano14: 0,
            ano15: 0,
            ano16: 0,
            ano17: 0,
            ano18: 0,
            ano19: 0,
            ano20: 0,
          },
          {
            type: '(-) Juros',
            ano0: 0,
            ano1: 0,
            ano2: 0,
            ano3: 0,
            ano4: 0,
            ano5: 0,
            ano6: 0,
            ano7: 0,
            ano8: 0,
            ano9: 0,
            ano10: 0,
            ano11: 0,
            ano12: 0,
            ano13: 0,
            ano14: 0,
            ano15: 0,
            ano16: 0,
            ano17: 0,
            ano18: 0,
            ano19: 0,
            ano20: 0,
          },
        ]
        for (let i = 0; i <= 20; i += 1) {
          p[1][`ano${i}`] = parseFloat(this.dividaItems[1][`ano${i}`])
          p[2][`ano${i}`] = parseFloat(this.dividaItems[2][`ano${i}`])
          p[3][`ano${i}`] = parseFloat(this.dividaItems[8][`ano${i}`])
          p[0][`ano${i}`] = (parseFloat(p[1][`ano${i}`]) + parseFloat(p[2][`ano${i}`]) + parseFloat(p[3][`ano${i}`]))
          p[0][`ano${i}`] = (parseFloat(p[0][`ano${i}`]) < 0) ? 0 : parseFloat(p[0][`ano${i}`])
        }
        return p
      }
      return []
    },
    fcCaixaFinalItems() {
      if (this.dashboard && this.dashboard.project && this.dashboard.project.startYear) {
        const p = [
          {
            type: 'Caixa final',
            ano0: 0,
            ano1: 0,
            ano2: 0,
            ano3: 0,
            ano4: 0,
            ano5: 0,
            ano6: 0,
            ano7: 0,
            ano8: 0,
            ano9: 0,
            ano10: 0,
            ano11: 0,
            ano12: 0,
            ano13: 0,
            ano14: 0,
            ano15: 0,
            ano16: 0,
            ano17: 0,
            ano18: 0,
            ano19: 0,
            ano20: 0,
          },
          {
            type: '(%) Conversão Caixa / EBITDA',
            ano0: 0,
            ano1: 0,
            ano2: 0,
            ano3: 0,
            ano4: 0,
            ano5: 0,
            ano6: 0,
            ano7: 0,
            ano8: 0,
            ano9: 0,
            ano10: 0,
            ano11: 0,
            ano12: 0,
            ano13: 0,
            ano14: 0,
            ano15: 0,
            ano16: 0,
            ano17: 0,
            ano18: 0,
            ano19: 0,
            ano20: 0,
          },
        ]
        for (let i = 0; i <= 20; i += 1) {
          if (i > 0) {
            p[0][`ano${i}`] = (parseFloat(p[0][`ano${i - 1}`]) + parseFloat(this.fcOperacionalItems[0][`ano${i}`]) + parseFloat(this.fcInvestimentosItems[0][`ano${i}`]) + parseFloat(this.fcFinanciamentosItems[0][`ano${i}`])).toFixed(2)
          } else {
            p[0][`ano${i}`] = (parseFloat(this.fcOperacionalItems[0][`ano${i}`]) + parseFloat(this.fcInvestimentosItems[0][`ano${i}`]) + parseFloat(this.fcFinanciamentosItems[0][`ano${i}`])).toFixed(2)
          }
          p[1][`ano${i}`] = parseFloat(this.ebitdaItems[0][`ano${i}`]) <= 0 ? 0 : (parseFloat(this.fcOperacionalItems[0][`ano${i}`]) / parseFloat(this.ebitdaItems[0][`ano${i}`])).toFixed(2)
        }
        return p
      }
      return []
    },
    fcfAcumuladoItems() {
      if (this.dashboard && this.dashboard.project && this.dashboard.project.startYear) {
        const p = [
          {
            type: 'FCF',
            ano0: 0,
            ano1: 0,
            ano2: 0,
            ano3: 0,
            ano4: 0,
            ano5: 0,
            ano6: 0,
            ano7: 0,
            ano8: 0,
            ano9: 0,
            ano10: 0,
            ano11: 0,
            ano12: 0,
            ano13: 0,
            ano14: 0,
            ano15: 0,
            ano16: 0,
            ano17: 0,
            ano18: 0,
            ano19: 0,
            ano20: 0,
          },
          {
            type: 'FCF Acumulado',
            ano0: 0,
            ano1: 0,
            ano2: 0,
            ano3: 0,
            ano4: 0,
            ano5: 0,
            ano6: 0,
            ano7: 0,
            ano8: 0,
            ano9: 0,
            ano10: 0,
            ano11: 0,
            ano12: 0,
            ano13: 0,
            ano14: 0,
            ano15: 0,
            ano16: 0,
            ano17: 0,
            ano18: 0,
            ano19: 0,
            ano20: 0,
          },
        ]
        for (let i = 0; i <= 20; i += 1) {
          p[0][`ano${i}`] = (parseFloat(this.fcOperacionalItems[0][`ano${i}`]) + parseFloat(this.fcInvestimentosItems[0][`ano${i}`]) + parseFloat(this.fcFinanciamentosItems[0][`ano${i}`])).toFixed(2)
          p[1][`ano${i}`] = this.sumYears(p[0], i)
        }
        return p
      }
      return []
    },
    fcProjetoItems() {
      if (this.dashboard && this.dashboard.project && this.dashboard.project.startYear) {
        const p = [
          {
            type: 'FC do Projeto',
            ano0: 0,
            ano1: 0,
            ano2: 0,
            ano3: 0,
            ano4: 0,
            ano5: 0,
            ano6: 0,
            ano7: 0,
            ano8: 0,
            ano9: 0,
            ano10: 0,
            ano11: 0,
            ano12: 0,
            ano13: 0,
            ano14: 0,
            ano15: 0,
            ano16: 0,
            ano17: 0,
            ano18: 0,
            ano19: 0,
            ano20: 0,
          },
          {
            type: 'FC Acumulado do Projeto',
            ano0: 0,
            ano1: 0,
            ano2: 0,
            ano3: 0,
            ano4: 0,
            ano5: 0,
            ano6: 0,
            ano7: 0,
            ano8: 0,
            ano9: 0,
            ano10: 0,
            ano11: 0,
            ano12: 0,
            ano13: 0,
            ano14: 0,
            ano15: 0,
            ano16: 0,
            ano17: 0,
            ano18: 0,
            ano19: 0,
            ano20: 0,
          },
        ]
        for (let i = 0; i <= 20; i += 1) {
          p[0][`ano${i}`] = (parseFloat(this.fcOperacionalItems[0][`ano${i}`]) + parseFloat(this.fcInvestimentosItems[1][`ano${i}`]) + parseFloat(this.fcInvestimentosItems[2][`ano${i}`])).toFixed(2)
          p[1][`ano${i}`] = this.sumYears(p[0], i)
        }
        this.calculateIRR(p[0], 20)
        this.calculatePayback(p[0], p[1], 20)
        this.calculateVPL(p[0], 20)
        return p
      }
      return []
    },
    // BP 3
    bpAtivoItems() {
      if (this.dashboard && this.dashboard.project && this.dashboard.project.startYear) {
        const p = [
          {
            type: 'Ativo',
            ano0: 0,
            ano1: 0,
            ano2: 0,
            ano3: 0,
            ano4: 0,
            ano5: 0,
            ano6: 0,
            ano7: 0,
            ano8: 0,
            ano9: 0,
            ano10: 0,
            ano11: 0,
            ano12: 0,
            ano13: 0,
            ano14: 0,
            ano15: 0,
            ano16: 0,
            ano17: 0,
            ano18: 0,
            ano19: 0,
            ano20: 0,
          },
          {
            type: 'Ativo Circulante',
            ano0: 0,
            ano1: 0,
            ano2: 0,
            ano3: 0,
            ano4: 0,
            ano5: 0,
            ano6: 0,
            ano7: 0,
            ano8: 0,
            ano9: 0,
            ano10: 0,
            ano11: 0,
            ano12: 0,
            ano13: 0,
            ano14: 0,
            ano15: 0,
            ano16: 0,
            ano17: 0,
            ano18: 0,
            ano19: 0,
            ano20: 0,
          },
          {
            type: 'Caixa e disponibilidades',
            ano0: 0,
            ano1: 0,
            ano2: 0,
            ano3: 0,
            ano4: 0,
            ano5: 0,
            ano6: 0,
            ano7: 0,
            ano8: 0,
            ano9: 0,
            ano10: 0,
            ano11: 0,
            ano12: 0,
            ano13: 0,
            ano14: 0,
            ano15: 0,
            ano16: 0,
            ano17: 0,
            ano18: 0,
            ano19: 0,
            ano20: 0,
          },
          {
            type: 'Contas a receber',
            ano0: 0,
            ano1: 0,
            ano2: 0,
            ano3: 0,
            ano4: 0,
            ano5: 0,
            ano6: 0,
            ano7: 0,
            ano8: 0,
            ano9: 0,
            ano10: 0,
            ano11: 0,
            ano12: 0,
            ano13: 0,
            ano14: 0,
            ano15: 0,
            ano16: 0,
            ano17: 0,
            ano18: 0,
            ano19: 0,
            ano20: 0,
          },
          {
            type: 'Ativo não circulante',
            ano0: 0,
            ano1: 0,
            ano2: 0,
            ano3: 0,
            ano4: 0,
            ano5: 0,
            ano6: 0,
            ano7: 0,
            ano8: 0,
            ano9: 0,
            ano10: 0,
            ano11: 0,
            ano12: 0,
            ano13: 0,
            ano14: 0,
            ano15: 0,
            ano16: 0,
            ano17: 0,
            ano18: 0,
            ano19: 0,
            ano20: 0,
          },
          {
            type: 'Imobilizado',
            ano0: 0,
            ano1: 0,
            ano2: 0,
            ano3: 0,
            ano4: 0,
            ano5: 0,
            ano6: 0,
            ano7: 0,
            ano8: 0,
            ano9: 0,
            ano10: 0,
            ano11: 0,
            ano12: 0,
            ano13: 0,
            ano14: 0,
            ano15: 0,
            ano16: 0,
            ano17: 0,
            ano18: 0,
            ano19: 0,
            ano20: 0,
          },
          {
            type: 'Intangível',
            ano0: 0,
            ano1: 0,
            ano2: 0,
            ano3: 0,
            ano4: 0,
            ano5: 0,
            ano6: 0,
            ano7: 0,
            ano8: 0,
            ano9: 0,
            ano10: 0,
            ano11: 0,
            ano12: 0,
            ano13: 0,
            ano14: 0,
            ano15: 0,
            ano16: 0,
            ano17: 0,
            ano18: 0,
            ano19: 0,
            ano20: 0,
          },
        ]
        // for (let i = 0; i <= 20; i += 1) {
        //   if (parseFloat(this.impostoSobreLucroItems[0][`ano${i}`]) && parseFloat(this.ebtItems[0][`ano${i}`])) {
        //     p[0][`ano${i}`] = (100 * (parseFloat(-this.impostoSobreLucroItems[0][`ano${i}`]) / parseFloat(this.ebtItems[0][`ano${i}`]))).toFixed(2)
        //   }
        // }
        return p
      }
      return []
    },
    bpPassivoItems() {
      if (this.dashboard && this.dashboard.project && this.dashboard.project.startYear) {
        const p = [
          {
            type: 'Passivo',
            ano0: 0,
            ano1: 0,
            ano2: 0,
            ano3: 0,
            ano4: 0,
            ano5: 0,
            ano6: 0,
            ano7: 0,
            ano8: 0,
            ano9: 0,
            ano10: 0,
            ano11: 0,
            ano12: 0,
            ano13: 0,
            ano14: 0,
            ano15: 0,
            ano16: 0,
            ano17: 0,
            ano18: 0,
            ano19: 0,
            ano20: 0,
          },
          {
            type: 'Passivo circulante',
            ano0: 0,
            ano1: 0,
            ano2: 0,
            ano3: 0,
            ano4: 0,
            ano5: 0,
            ano6: 0,
            ano7: 0,
            ano8: 0,
            ano9: 0,
            ano10: 0,
            ano11: 0,
            ano12: 0,
            ano13: 0,
            ano14: 0,
            ano15: 0,
            ano16: 0,
            ano17: 0,
            ano18: 0,
            ano19: 0,
            ano20: 0,
          },
          {
            type: 'Contas a pagar',
            ano0: 0,
            ano1: 0,
            ano2: 0,
            ano3: 0,
            ano4: 0,
            ano5: 0,
            ano6: 0,
            ano7: 0,
            ano8: 0,
            ano9: 0,
            ano10: 0,
            ano11: 0,
            ano12: 0,
            ano13: 0,
            ano14: 0,
            ano15: 0,
            ano16: 0,
            ano17: 0,
            ano18: 0,
            ano19: 0,
            ano20: 0,
          },
          {
            type: 'Obrigações Sociais',
            ano0: 0,
            ano1: 0,
            ano2: 0,
            ano3: 0,
            ano4: 0,
            ano5: 0,
            ano6: 0,
            ano7: 0,
            ano8: 0,
            ano9: 0,
            ano10: 0,
            ano11: 0,
            ano12: 0,
            ano13: 0,
            ano14: 0,
            ano15: 0,
            ano16: 0,
            ano17: 0,
            ano18: 0,
            ano19: 0,
            ano20: 0,
          },
          {
            type: 'Obrigações Tributárias',
            ano0: 0,
            ano1: 0,
            ano2: 0,
            ano3: 0,
            ano4: 0,
            ano5: 0,
            ano6: 0,
            ano7: 0,
            ano8: 0,
            ano9: 0,
            ano10: 0,
            ano11: 0,
            ano12: 0,
            ano13: 0,
            ano14: 0,
            ano15: 0,
            ano16: 0,
            ano17: 0,
            ano18: 0,
            ano19: 0,
            ano20: 0,
          },
          {
            type: 'Imposto de Renda e IR/CSLL',
            ano0: 0,
            ano1: 0,
            ano2: 0,
            ano3: 0,
            ano4: 0,
            ano5: 0,
            ano6: 0,
            ano7: 0,
            ano8: 0,
            ano9: 0,
            ano10: 0,
            ano11: 0,
            ano12: 0,
            ano13: 0,
            ano14: 0,
            ano15: 0,
            ano16: 0,
            ano17: 0,
            ano18: 0,
            ano19: 0,
            ano20: 0,
          },
          {
            type: 'Empréstimos e financiamentos',
            ano0: 0,
            ano1: 0,
            ano2: 0,
            ano3: 0,
            ano4: 0,
            ano5: 0,
            ano6: 0,
            ano7: 0,
            ano8: 0,
            ano9: 0,
            ano10: 0,
            ano11: 0,
            ano12: 0,
            ano13: 0,
            ano14: 0,
            ano15: 0,
            ano16: 0,
            ano17: 0,
            ano18: 0,
            ano19: 0,
            ano20: 0,
          },
          {
            type: 'Passivo não circulante',
            ano0: 0,
            ano1: 0,
            ano2: 0,
            ano3: 0,
            ano4: 0,
            ano5: 0,
            ano6: 0,
            ano7: 0,
            ano8: 0,
            ano9: 0,
            ano10: 0,
            ano11: 0,
            ano12: 0,
            ano13: 0,
            ano14: 0,
            ano15: 0,
            ano16: 0,
            ano17: 0,
            ano18: 0,
            ano19: 0,
            ano20: 0,
          },
          {
            type: 'Empréstimos e financiamentos',
            ano0: 0,
            ano1: 0,
            ano2: 0,
            ano3: 0,
            ano4: 0,
            ano5: 0,
            ano6: 0,
            ano7: 0,
            ano8: 0,
            ano9: 0,
            ano10: 0,
            ano11: 0,
            ano12: 0,
            ano13: 0,
            ano14: 0,
            ano15: 0,
            ano16: 0,
            ano17: 0,
            ano18: 0,
            ano19: 0,
            ano20: 0,
          },
        ]
        // for (let i = 0; i <= 20; i += 1) {
        //   if (parseFloat(this.impostoSobreLucroItems[0][`ano${i}`]) && parseFloat(this.ebtItems[0][`ano${i}`])) {
        //     p[0][`ano${i}`] = (100 * (parseFloat(-this.impostoSobreLucroItems[0][`ano${i}`]) / parseFloat(this.ebtItems[0][`ano${i}`]))).toFixed(2)
        //   }
        // }
        return p
      }
      return []
    },
    bpPatrimonioItems() {
      if (this.dashboard && this.dashboard.project && this.dashboard.project.startYear) {
        const p = [
          {
            type: 'Patrimônio Líquido',
            ano0: 0,
            ano1: 0,
            ano2: 0,
            ano3: 0,
            ano4: 0,
            ano5: 0,
            ano6: 0,
            ano7: 0,
            ano8: 0,
            ano9: 0,
            ano10: 0,
            ano11: 0,
            ano12: 0,
            ano13: 0,
            ano14: 0,
            ano15: 0,
            ano16: 0,
            ano17: 0,
            ano18: 0,
            ano19: 0,
            ano20: 0,
          },
          {
            type: 'Capital social',
            ano0: 0,
            ano1: 0,
            ano2: 0,
            ano3: 0,
            ano4: 0,
            ano5: 0,
            ano6: 0,
            ano7: 0,
            ano8: 0,
            ano9: 0,
            ano10: 0,
            ano11: 0,
            ano12: 0,
            ano13: 0,
            ano14: 0,
            ano15: 0,
            ano16: 0,
            ano17: 0,
            ano18: 0,
            ano19: 0,
            ano20: 0,
          },
          {
            type: 'Reserva de lucros',
            ano0: 0,
            ano1: 0,
            ano2: 0,
            ano3: 0,
            ano4: 0,
            ano5: 0,
            ano6: 0,
            ano7: 0,
            ano8: 0,
            ano9: 0,
            ano10: 0,
            ano11: 0,
            ano12: 0,
            ano13: 0,
            ano14: 0,
            ano15: 0,
            ano16: 0,
            ano17: 0,
            ano18: 0,
            ano19: 0,
            ano20: 0,
          },
          {
            type: 'Check',
            ano0: 0,
            ano1: 0,
            ano2: 0,
            ano3: 0,
            ano4: 0,
            ano5: 0,
            ano6: 0,
            ano7: 0,
            ano8: 0,
            ano9: 0,
            ano10: 0,
            ano11: 0,
            ano12: 0,
            ano13: 0,
            ano14: 0,
            ano15: 0,
            ano16: 0,
            ano17: 0,
            ano18: 0,
            ano19: 0,
            ano20: 0,
          },
          {
            type: 'Dívida Líquida / EBITDA',
            ano0: 0,
            ano1: 0,
            ano2: 0,
            ano3: 0,
            ano4: 0,
            ano5: 0,
            ano6: 0,
            ano7: 0,
            ano8: 0,
            ano9: 0,
            ano10: 0,
            ano11: 0,
            ano12: 0,
            ano13: 0,
            ano14: 0,
            ano15: 0,
            ano16: 0,
            ano17: 0,
            ano18: 0,
            ano19: 0,
            ano20: 0,
          },
        ]
        // for (let i = 0; i <= 20; i += 1) {
        //   if (parseFloat(this.impostoSobreLucroItems[0][`ano${i}`]) && parseFloat(this.ebtItems[0][`ano${i}`])) {
        //     p[0][`ano${i}`] = (100 * (parseFloat(-this.impostoSobreLucroItems[0][`ano${i}`]) / parseFloat(this.ebtItems[0][`ano${i}`]))).toFixed(2)
        //   }
        // }
        return p
      }
      return []
    },
    valuationItems() {
      if (this.premissasFinanceirasItems && this.premissasFinanceirasItems.length && this.dashboard && this.dashboard.project && this.dashboard.project.startYear) {
        const p = [
          {
            type: 'Valuation',
            ano0: this.dashboard.project.startYear + 0,
            ano1: this.dashboard.project.startYear + 1,
            ano2: this.dashboard.project.startYear + 2,
            ano3: this.dashboard.project.startYear + 3,
            ano4: this.dashboard.project.startYear + 4,
            ano5: this.dashboard.project.startYear + 5,
            ano6: this.dashboard.project.startYear + 6,
            ano7: this.dashboard.project.startYear + 7,
            ano8: this.dashboard.project.startYear + 8,
            ano9: this.dashboard.project.startYear + 9,
            ano10: this.dashboard.project.startYear + 10,
            ano11: this.dashboard.project.startYear + 11,
            ano12: this.dashboard.project.startYear + 12,
            ano13: this.dashboard.project.startYear + 13,
            ano14: this.dashboard.project.startYear + 14,
            ano15: this.dashboard.project.startYear + 15,
            ano16: this.dashboard.project.startYear + 16,
            ano17: this.dashboard.project.startYear + 17,
            ano18: this.dashboard.project.startYear + 18,
            ano19: this.dashboard.project.startYear + 19,
            ano20: this.dashboard.project.startYear + 20,
          },
          {
            type: 'EBIT',
            ano0: 0,
            ano1: 0,
            ano2: 0,
            ano3: 0,
            ano4: 0,
            ano5: 0,
            ano6: 0,
            ano7: 0,
            ano8: 0,
            ano9: 0,
            ano10: 0,
            ano11: 0,
            ano12: 0,
            ano13: 0,
            ano14: 0,
            ano15: 0,
            ano16: 0,
            ano17: 0,
            ano18: 0,
            ano19: 0,
            ano20: 0,
          },
          {
            type: '( - ) IR sem alavancagem',
            ano0: 0,
            ano1: 0,
            ano2: 0,
            ano3: 0,
            ano4: 0,
            ano5: 0,
            ano6: 0,
            ano7: 0,
            ano8: 0,
            ano9: 0,
            ano10: 0,
            ano11: 0,
            ano12: 0,
            ano13: 0,
            ano14: 0,
            ano15: 0,
            ano16: 0,
            ano17: 0,
            ano18: 0,
            ano19: 0,
            ano20: 0,
          },
          {
            type: 'NOPLAT',
            ano0: 0,
            ano1: 0,
            ano2: 0,
            ano3: 0,
            ano4: 0,
            ano5: 0,
            ano6: 0,
            ano7: 0,
            ano8: 0,
            ano9: 0,
            ano10: 0,
            ano11: 0,
            ano12: 0,
            ano13: 0,
            ano14: 0,
            ano15: 0,
            ano16: 0,
            ano17: 0,
            ano18: 0,
            ano19: 0,
            ano20: 0,
          },
          {
            type: '+ Depreciação',
            ano0: 0,
            ano1: 0,
            ano2: 0,
            ano3: 0,
            ano4: 0,
            ano5: 0,
            ano6: 0,
            ano7: 0,
            ano8: 0,
            ano9: 0,
            ano10: 0,
            ano11: 0,
            ano12: 0,
            ano13: 0,
            ano14: 0,
            ano15: 0,
            ano16: 0,
            ano17: 0,
            ano18: 0,
            ano19: 0,
            ano20: 0,
          },
          {
            type: 'Fluxo de caixa bruto',
            ano0: 0,
            ano1: 0,
            ano2: 0,
            ano3: 0,
            ano4: 0,
            ano5: 0,
            ano6: 0,
            ano7: 0,
            ano8: 0,
            ano9: 0,
            ano10: 0,
            ano11: 0,
            ano12: 0,
            ano13: 0,
            ano14: 0,
            ano15: 0,
            ano16: 0,
            ano17: 0,
            ano18: 0,
            ano19: 0,
            ano20: 0,
          },
          {
            type: '+/- Delta capital de giro',
            ano0: 0,
            ano1: 0,
            ano2: 0,
            ano3: 0,
            ano4: 0,
            ano5: 0,
            ano6: 0,
            ano7: 0,
            ano8: 0,
            ano9: 0,
            ano10: 0,
            ano11: 0,
            ano12: 0,
            ano13: 0,
            ano14: 0,
            ano15: 0,
            ano16: 0,
            ano17: 0,
            ano18: 0,
            ano19: 0,
            ano20: 0,
          },
          {
            type: '(-) Investimentos',
            ano0: 0,
            ano1: 0,
            ano2: 0,
            ano3: 0,
            ano4: 0,
            ano5: 0,
            ano6: 0,
            ano7: 0,
            ano8: 0,
            ano9: 0,
            ano10: 0,
            ano11: 0,
            ano12: 0,
            ano13: 0,
            ano14: 0,
            ano15: 0,
            ano16: 0,
            ano17: 0,
            ano18: 0,
            ano19: 0,
            ano20: 0,
          },
        ]
        for (let i = 0; i <= 20; i += 1) {
          p[1][`ano${i}`] = (parseFloat(this.ebitdaItems[0][`ano${i}`]) - parseFloat(this.ebitdaItems[3][`ano${i}`])).toFixed(2)
          p[2][`ano${i}`] = parseFloat(this.ebtItems[3][`ano${i}`])
          p[3][`ano${i}`] = (parseFloat(p[1][`ano${i}`]) + parseFloat(p[2][`ano${i}`])).toFixed(2)
          p[4][`ano${i}`] = parseFloat(this.ebitdaItems[3][`ano${i}`])
          p[5][`ano${i}`] = (parseFloat(p[3][`ano${i}`]) + parseFloat(p[4][`ano${i}`])).toFixed(2)
        }
        return p
      }
      return []
    },
    fields() {
      return [
        { key: 'type', label: '' },
        { key: 'ano0', label: 'ano 0' },
        { key: 'ano1', label: 'ano 1' },
        { key: 'ano2', label: 'ano 2' },
        { key: 'ano3', label: 'ano 3' },
        { key: 'ano4', label: 'ano 4' },
        { key: 'ano5', label: 'ano 5' },
        { key: 'ano6', label: 'ano 6' },
        { key: 'ano7', label: 'ano 7' },
        { key: 'ano8', label: 'ano 8' },
        { key: 'ano9', label: 'ano 9' },
        { key: 'ano10', label: 'ano 10' },
        { key: 'ano11', label: 'ano 11' },
        { key: 'ano12', label: 'ano 12' },
        { key: 'ano13', label: 'ano 13' },
        { key: 'ano14', label: 'ano 14' },
        { key: 'ano15', label: 'ano 15' },
        { key: 'ano16', label: 'ano 16' },
        { key: 'ano17', label: 'ano 17' },
        { key: 'ano18', label: 'ano 18' },
        { key: 'ano19', label: 'ano 19' },
        { key: 'ano20', label: 'ano 20' },
      ]
    },
  },
  methods: {
    fixCustoItems(p, i) {
      this.custoItems[0][`ano${i}`] = parseFloat(p[10][`ano${i}`])
      this.custoItems[1][`ano${i}`] = parseFloat(p[11][`ano${i}`])
      this.custoItems[2][`ano${i}`] = parseFloat(p[12][`ano${i}`])
      this.custoItems[3][`ano${i}`] = parseFloat(p[13][`ano${i}`])
    },
    sumYears(arr, last) {
      let res = 0
      for (let i = 0; i <= last; i += 1) {
        res += parseFloat(arr[`ano${i}`])
      }
      return res.toFixed(2)
    },
    getAporteNoAno(i) {
      const aporteData = this.dashboard.capex.bills.aporteCapital
      return parseFloat(aporteData.socio1[`ano${i + 1}`]) + parseFloat(aporteData.socio2[`ano${i + 1}`]) + parseFloat(aporteData.socio3[`ano${i + 1}`]) + parseFloat(aporteData.socio4[`ano${i + 1}`]) + parseFloat(aporteData.socio5[`ano${i + 1}`])
    },
    calculateIRR(arr, size) {
      const data = []
      for (let j = 0; j <= size; j += 1) {
        let val = parseFloat(arr[`ano${j}`])
        // arr deve ter pelo menos um campo negativo
        if (j === 0 && val >= 0) val = -0.1
        data.push(val)
      }
      const result = irr(data)
      const newDashboard = { ...this.dashboard }
      newDashboard.project.tir = (result * 100).toFixed(2)
    },
    calculatePayback(arr42, arr44, size) {
      let remaining = 0
      let c = 1
      while (!remaining && c <= size) {
        if (parseFloat(arr44[`ano${c}`]) > 0) {
          remaining = (-parseFloat(arr44[`ano${c - 1}`]) / parseFloat(arr42[`ano${c}`])) * 12
        }
        c += 1
      }
      const data = []
      for (let j = 0; j <= size; j += 1) {
        data.push(parseFloat(arr44[`ano${j}`]))
      }
      const result = data.filter(e => e <= 0).length * 12
      const newDashboard = { ...this.dashboard }
      newDashboard.project.payback = (result + remaining).toFixed(2)
    },
    calculateVPL(arr, size) {
      const data = []
      // eslint-disable-next-line
      const rate = this.dashboard.project.tma
      const first = parseFloat(arr.ano0)
      for (let j = 1; j <= size; j += 1) {
        data.push(parseFloat(arr[`ano${j}`]))
      }
      let vpl = 0
      for (let j = 0; j < data.length; j += 1) {
        vpl += data[j] / ((1 + rate) ** (j))
      }
      const newDashboard = { ...this.dashboard }
      newDashboard.project.vpl = (vpl + first).toFixed(2)
    },
  },
}
</script>

<style>
</style>

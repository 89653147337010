<template>
  <div>
    <b-overlay :show="saving">
      <b-card
        footer-class="text-muted"
        body-class="p-0"
      >
        <b-tabs
          v-model="tabIndex"
          pills
          nav-class="mb-3 pt-2 pr-2 pl-2"
          small
          justified
        >
          <b-tab button-id="businesPlan">
            <div slot="title">
              {{ $t('IncomingAndExpenses.tabs.incoming') }}
            </div>
            <div class="mx-2">
              <h4>Pacientes e taxa de ocupação</h4>
              <b-row class="my-1">
                <b-col>
                  <b-form-group label="Pacientes">
                    <b-input-group>
                      <b-form-input
                        v-model="incomings.pacients"
                        type="number"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group label="Taxa de ocupação">
                    <b-input-group append="%">
                      <b-form-input
                        v-model="incomings.taxaOcupacao"
                        type="number"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group label="Variação da taxa de ocupação">
                    <b-input-group append="%">
                      <b-form-input
                        v-model="incomings.taxaOcupacaoPercentPerYear"
                        type="number"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row class="my-1">
                <b-col>
                  <b-form-group label="dias uteis por mês para hipofracionamento">
                    <b-input-group>
                      <b-form-input
                        v-model="incomings.capacidadeComHipofracionamentoDiasUteis"
                        type="number"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group label="Média de dias uteis por tratamento">
                    <b-input-group>
                      <b-form-input
                        v-model="incomings.capacidadeComHipofracionamentoMediaDiasUteisPorTratamento"
                        type="number"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row class="my-1">
                <b-col>
                  <b-form-group label="Capacidade anual">
                    <b-input-group>
                      <b-form-input
                        :value="incomings.capacidadeAnual"
                        :disabled="true"
                        type="number"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group label="Capacidade com hipofracionamento">
                    <b-input-group>
                      <b-form-input
                        :value="incomings.capacidadeComHipofracionamento"
                        :disabled="true"
                        type="number"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row class="my-1">
                <b-col>
                  <b-form-group label="Taxa de ocupação (Ressonancia)">
                    <b-input-group append="%">
                      <b-form-input
                        v-model="incomings.taxaOcupacaoRessonancia"
                        type="number"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group label="Taxa de ocupação (Tomografo)">
                    <b-input-group append="%">
                      <b-form-input
                        v-model="incomings.taxaOcupacaoTomografo"
                        type="number"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
              </b-row>
            </div>
            <div class="mx-2">
              <h4>Matriz da taxa de ocupação</h4>
              <b-table
                striped
                hover
                responsive
                class="position-relative"
                :items="taxaDeOcupacaoItems"
                :fields="fields"
              >
                <template #cell(type)="data">
                  {{ data.item.type }}
                </template>
                <template #cell(ano0)="data">
                  {{ data.item.type }}
                  <b-form-input
                    v-model="incomings.taxaOcupacaoList[data.item.type].ano0"
                    type="number"
                  />
                </template>
                <template #cell(ano1)="data">
                  {{ data.item.type }}
                  <b-form-input
                    v-model="incomings.taxaOcupacaoList[data.item.type].ano1"
                    type="number"
                  />
                </template>
                <template #cell(ano2)="data">
                  {{ data.item.type }}
                  <b-form-input
                    v-model="incomings.taxaOcupacaoList[data.item.type].ano2"
                    type="number"
                  />
                </template>
                <template #cell(ano3)="data">
                  {{ data.item.type }}
                  <b-form-input
                    v-model="incomings.taxaOcupacaoList[data.item.type].ano3"
                    type="number"
                  />
                </template>
                <template #cell(ano4)="data">
                  {{ data.item.type }}
                  <b-form-input
                    v-model="incomings.taxaOcupacaoList[data.item.type].ano4"
                    type="number"
                  />
                </template>
                <template #cell(ano5)="data">
                  {{ data.item.type }}
                  <b-form-input
                    v-model="incomings.taxaOcupacaoList[data.item.type].ano5"
                    type="number"
                  />
                </template>
                <template #cell(ano6)="data">
                  {{ data.item.type }}
                  <b-form-input
                    v-model="incomings.taxaOcupacaoList[data.item.type].ano6"
                    type="number"
                  />
                </template>
                <template #cell(ano7)="data">
                  {{ data.item.type }}
                  <b-form-input
                    v-model="incomings.taxaOcupacaoList[data.item.type].ano7"
                    type="number"
                  />
                </template>
                <template #cell(ano8)="data">
                  {{ data.item.type }}
                  <b-form-input
                    v-model="incomings.taxaOcupacaoList[data.item.type].ano8"
                    type="number"
                  />
                </template>
                <template #cell(ano9)="data">
                  {{ data.item.type }}
                  <b-form-input
                    v-model="incomings.taxaOcupacaoList[data.item.type].ano9"
                    type="number"
                  />
                </template>
                <template #cell(ano10)="data">
                  {{ data.item.type }}
                  <b-form-input
                    v-model="incomings.taxaOcupacaoList[data.item.type].ano10"
                    type="number"
                  />
                </template>
                <template #cell(ano11)="data">
                  {{ data.item.type }}
                  <b-form-input
                    v-model="incomings.taxaOcupacaoList[data.item.type].ano11"
                    type="number"
                  />
                </template>
                <template #cell(ano12)="data">
                  {{ data.item.type }}
                  <b-form-input
                    v-model="incomings.taxaOcupacaoList[data.item.type].ano12"
                    type="number"
                  />
                </template>
                <template #cell(ano13)="data">
                  {{ data.item.type }}
                  <b-form-input
                    v-model="incomings.taxaOcupacaoList[data.item.type].ano13"
                    type="number"
                  />
                </template>
                <template #cell(ano14)="data">
                  {{ data.item.type }}
                  <b-form-input
                    v-model="incomings.taxaOcupacaoList[data.item.type].ano14"
                    type="number"
                  />
                </template>
                <template #cell(ano15)="data">
                  {{ data.item.type }}
                  <b-form-input
                    v-model="incomings.taxaOcupacaoList[data.item.type].ano15"
                    type="number"
                  />
                </template>
                <template #cell(ano16)="data">
                  {{ data.item.type }}
                  <b-form-input
                    v-model="incomings.taxaOcupacaoList[data.item.type].ano16"
                    type="number"
                  />
                </template>
                <template #cell(ano17)="data">
                  {{ data.item.type }}
                  <b-form-input
                    v-model="incomings.taxaOcupacaoList[data.item.type].ano17"
                    type="number"
                  />
                </template>
                <template #cell(ano18)="data">
                  {{ data.item.type }}
                  <b-form-input
                    v-model="incomings.taxaOcupacaoList[data.item.type].ano18"
                    type="number"
                  />
                </template>
                <template #cell(ano19)="data">
                  {{ data.item.type }}
                  <b-form-input
                    v-model="incomings.taxaOcupacaoList[data.item.type].ano19"
                    type="number"
                  />
                </template>
                <template #cell(ano20)="data">
                  {{ data.item.type }}
                  <b-form-input
                    v-model="incomings.taxaOcupacaoList[data.item.type].ano20"
                    type="number"
                  />
                </template>
              </b-table>
            </div>
          </b-tab>
          <b-tab button-id="premisses">
            <div slot="title">
              {{ $t('IncomingAndExpenses.tabs.opex') }}
            </div>
            <div class="mx-2">
              <h4>Custo administrativo</h4>
              <b-row class="my-1">
                <b-col>
                  <b-form-group label="Aluguel / mês">
                    <b-input-group>
                      <b-form-input
                        v-model="expenses.administrativo.aluguelPorMes"
                        type="number"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group label="Despesas rateadas / ano">
                    <b-input-group>
                      <b-form-input
                        v-model="expenses.administrativo.despesasRateadasPorAno"
                        type="number"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group label="Operacionais Proton">
                    <b-input-group>
                      <b-form-input
                        v-model="expenses.administrativo.operacionaisProton"
                        type="number"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row class="my-1">
                <b-col>
                  <b-form-group label="Operacionais TrueBeam 1">
                    <b-input-group>
                      <b-form-input
                        v-model="expenses.administrativo.operacionaisTrueBeam1"
                        type="number"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group label="Operacionais TrueBeam 2">
                    <b-input-group>
                      <b-form-input
                        v-model="expenses.administrativo.operacionaisTrueBeam2"
                        type="number"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row class="my-1">
                <b-col>
                  <b-form-group label="Operacionais EQ 1">
                    <b-input-group>
                      <b-form-input
                        v-model="expenses.administrativo.operacionaisOutrosEq1"
                        type="number"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group label="Operacionais EQ 2">
                    <b-input-group>
                      <b-form-input
                        v-model="expenses.administrativo.operacionaisOutrosEq2"
                        type="number"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group label="Operacionais Outros EQ">
                    <b-input-group>
                      <b-form-input
                        v-model="expenses.administrativo.operacionaisOutrosEq"
                        type="number"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
              </b-row>
            </div>
            <div class="mx-2">
              <h4>Custo de pessoal</h4>
              <b-table
                striped
                hover
                responsive
                class="position-relative"
                :items="custoPessoalItems"
                :fields="fields"
              >
                <template #cell(type)="data">
                  {{ data.item.type }}
                </template>
                <template #cell(ano0)="data">
                  {{ data.item.type }}
                  <b-form-input
                    v-model="incomings.custoPessoalList[data.item.type].ano0"
                    type="number"
                    :disabled="true"
                  />
                </template>
                <template #cell(ano1)="data">
                  {{ data.item.type }}
                  <b-form-input
                    v-model="incomings.custoPessoalList[data.item.type].ano1"
                    type="number"
                    :disabled="true"
                  />
                </template>
                <template #cell(ano2)="data">
                  {{ data.item.type }}
                  <b-form-input
                    v-model="incomings.custoPessoalList[data.item.type].ano2"
                    type="number"
                    :disabled="true"
                  />
                </template>
                <template #cell(ano3)="data">
                  {{ data.item.type }}
                  <b-form-input
                    v-model="incomings.custoPessoalList[data.item.type].ano3"
                    type="number"
                    :disabled="true"
                  />
                </template>
                <template #cell(ano4)="data">
                  {{ data.item.type }}
                  <b-form-input
                    v-model="incomings.custoPessoalList[data.item.type].ano4"
                    type="number"
                    :disabled="true"
                  />
                </template>
                <template #cell(ano5)="data">
                  {{ data.item.type }}
                  <b-form-input
                    v-model="incomings.custoPessoalList[data.item.type].ano5"
                    type="number"
                    :disabled="true"
                  />
                </template>
                <template #cell(ano6)="data">
                  {{ data.item.type }}
                  <b-form-input
                    v-model="incomings.custoPessoalList[data.item.type].ano6"
                    type="number"
                    :disabled="true"
                  />
                </template>
                <template #cell(ano7)="data">
                  {{ data.item.type }}
                  <b-form-input
                    v-model="incomings.custoPessoalList[data.item.type].ano7"
                    type="number"
                    :disabled="true"
                  />
                </template>
                <template #cell(ano8)="data">
                  {{ data.item.type }}
                  <b-form-input
                    v-model="incomings.custoPessoalList[data.item.type].ano8"
                    type="number"
                    :disabled="true"
                  />
                </template>
                <template #cell(ano9)="data">
                  {{ data.item.type }}
                  <b-form-input
                    v-model="incomings.custoPessoalList[data.item.type].ano9"
                    type="number"
                    :disabled="true"
                  />
                </template>
                <template #cell(ano10)="data">
                  {{ data.item.type }}
                  <b-form-input
                    v-model="incomings.custoPessoalList[data.item.type].ano10"
                    type="number"
                    :disabled="true"
                  />
                </template>
                <template #cell(ano11)="data">
                  {{ data.item.type }}
                  <b-form-input
                    v-model="incomings.custoPessoalList[data.item.type].ano11"
                    type="number"
                    :disabled="true"
                  />
                </template>
                <template #cell(ano12)="data">
                  {{ data.item.type }}
                  <b-form-input
                    v-model="incomings.custoPessoalList[data.item.type].ano12"
                    type="number"
                    :disabled="true"
                  />
                </template>
                <template #cell(ano13)="data">
                  {{ data.item.type }}
                  <b-form-input
                    v-model="incomings.custoPessoalList[data.item.type].ano13"
                    type="number"
                    :disabled="true"
                  />
                </template>
                <template #cell(ano14)="data">
                  {{ data.item.type }}
                  <b-form-input
                    v-model="incomings.custoPessoalList[data.item.type].ano14"
                    type="number"
                    :disabled="true"
                  />
                </template>
                <template #cell(ano15)="data">
                  {{ data.item.type }}
                  <b-form-input
                    v-model="incomings.custoPessoalList[data.item.type].ano15"
                    type="number"
                    :disabled="true"
                  />
                </template>
                <template #cell(ano16)="data">
                  {{ data.item.type }}
                  <b-form-input
                    v-model="incomings.custoPessoalList[data.item.type].ano16"
                    type="number"
                    :disabled="true"
                  />
                </template>
                <template #cell(ano17)="data">
                  {{ data.item.type }}
                  <b-form-input
                    v-model="incomings.custoPessoalList[data.item.type].ano17"
                    type="number"
                    :disabled="true"
                  />
                </template>
                <template #cell(ano18)="data">
                  {{ data.item.type }}
                  <b-form-input
                    v-model="incomings.custoPessoalList[data.item.type].ano18"
                    type="number"
                    :disabled="true"
                  />
                </template>
                <template #cell(ano19)="data">
                  {{ data.item.type }}
                  <b-form-input
                    v-model="incomings.custoPessoalList[data.item.type].ano19"
                    type="number"
                    :disabled="true"
                  />
                </template>
                <template #cell(ano20)="data">
                  {{ data.item.type }}
                  <b-form-input
                    v-model="incomings.custoPessoalList[data.item.type].ano20"
                    type="number"
                    :disabled="true"
                  />
                </template>
              </b-table>
            </div>
          </b-tab>
          <b-tab button-id="personAndMedicalCost">
            <div slot="title">
              {{ $t('IncomingAndExpenses.tabs.indicesAndTaxs') }}
            </div>
            <div class="mx-2">
              <h4>Indices</h4>
              <b-row class="my-1">
                <b-col>
                  <b-form-group label="CDI">
                    <b-input-group append="%">
                      <b-form-input
                        v-model="expenses.indices.cdi"
                        type="number"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group label="1 USD em BRL">
                    <b-input-group>
                      <b-form-input
                        v-model="expenses.indices.usdToBrl"
                        type="number"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group label="US CPI">
                    <b-input-group append="%">
                      <b-form-input
                        v-model="expenses.indices.usCpi"
                        type="number"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row class="my-1">
                <b-col>
                  <b-form-group label="IPCA">
                    <b-input-group append="%">
                      <b-form-input
                        v-model="expenses.indices.ipca"
                        type="number"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group label="IPCA (PROTON)">
                    <b-input-group append="%">
                      <b-form-input
                        v-model="expenses.indices.ipcaProton"
                        type="number"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
              </b-row>
            </div>
            <div class="mx-2">
              <h4>Variações</h4>
              <b-row class="my-1">
                <b-col>
                  <b-form-group label="CDI (Variação / ano)">
                    <b-input-group append="%">
                      <b-form-input
                        v-model="expenses.indices.cdiPercentPerYear"
                        type="number"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group label="1 USD em BRL (Variação / ano)">
                    <b-input-group append="%">
                      <b-form-input
                        v-model="expenses.indices.usdToBrlPercentPerYear"
                        type="number"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group label="US CPI (Variação / ano)">
                    <b-input-group append="%">
                      <b-form-input
                        v-model="expenses.indices.usCpiPercentPerYear"
                        type="number"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row class="my-1">
                <b-col>
                  <b-form-group label="IPCA (Variação / ano)">
                    <b-input-group append="%">
                      <b-form-input
                        v-model="expenses.indices.ipcaPercentPerYear"
                        type="number"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
              </b-row>
            </div>
            <div class="mx-2">
              <h4>Impostos sobre lucros</h4>
              <b-row class="my-1">
                <b-col>
                  <b-form-group label="Cenário">
                    <b-form-select
                      v-model="staff.cenarioSelected"
                      :options="cenariosList"
                      placeholder="Selecione"
                    />
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group label="IR">
                    <b-input-group append="%">
                      <b-form-input
                        v-model="expenses.impostosSobreLucro.ir"
                        type="number"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group label="CSLL">
                    <b-input-group append="%">
                      <b-form-input
                        v-model="expenses.impostosSobreLucro.csll"
                        type="number"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
              </b-row>
            </div>
            <div class="mx-2">
              <h4>Impostos + PCLD</h4>
              <b-row class="my-1">
                <b-col>
                  <b-form-group label="PIS / COFINS">
                    <b-input-group append="%">
                      <b-form-input
                        v-model="expenses.impostosMaisPcld.pisCofins"
                        type="number"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group label="iss">
                    <b-input-group append="%">
                      <b-form-input
                        v-model="expenses.impostosMaisPcld.iss"
                        type="number"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group label="pcld">
                    <b-input-group append="%">
                      <b-form-input
                        v-model="expenses.impostosMaisPcld.pcld"
                        type="number"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
              </b-row>
            </div>
          </b-tab>
          <b-tab button-id="ticket">
            <div slot="title">
              {{ $t('IncomingAndExpenses.tabs.staff') }}
            </div>
            <div class="mx-2">
              <h4>HC</h4>
              <b-row class="my-1">
                <b-col>
                  <h6 class="mt-2">
                    Radioterapeuta
                  </h6>
                </b-col>
                <b-col>
                  <b-form-group label="Obras">
                    <b-input-group>
                      <b-form-input
                        v-model="staff.hc.radioterapeuta.obras"
                        type="number"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group label="Maq 1">
                    <b-input-group>
                      <b-form-input
                        v-model="staff.hc.radioterapeuta.maq1"
                        type="number"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group label="Maq 2">
                    <b-input-group>
                      <b-form-input
                        v-model="staff.hc.radioterapeuta.maq2"
                        type="number"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group label="Maq 3">
                    <b-input-group>
                      <b-form-input
                        v-model="staff.hc.radioterapeuta.maq3"
                        type="number"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group label="Maq 4">
                    <b-input-group>
                      <b-form-input
                        v-model="staff.hc.radioterapeuta.maq4"
                        type="number"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group label="Maq 5">
                    <b-input-group>
                      <b-form-input
                        v-model="staff.hc.radioterapeuta.maq5"
                        type="number"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row class="my-1">
                <b-col>
                  <h6 class="mt-2">
                    Coordenador / Responsável
                  </h6>
                </b-col>
                <b-col>
                  <b-form-group label="Obras">
                    <b-input-group>
                      <b-form-input
                        v-model="staff.hc.coordenadorResponsavel.obras"
                        type="number"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group label="Maq 1">
                    <b-input-group>
                      <b-form-input
                        v-model="staff.hc.coordenadorResponsavel.maq1"
                        type="number"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group label="Maq 2">
                    <b-input-group>
                      <b-form-input
                        v-model="staff.hc.coordenadorResponsavel.maq2"
                        type="number"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group label="Maq 3">
                    <b-input-group>
                      <b-form-input
                        v-model="staff.hc.coordenadorResponsavel.maq3"
                        type="number"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group label="Maq 4">
                    <b-input-group>
                      <b-form-input
                        v-model="staff.hc.coordenadorResponsavel.maq4"
                        type="number"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group label="Maq 5">
                    <b-input-group>
                      <b-form-input
                        v-model="staff.hc.coordenadorResponsavel.maq5"
                        type="number"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row class="my-1">
                <b-col>
                  <h6 class="mt-2">
                    Gerente
                  </h6>
                </b-col>
                <b-col>
                  <b-form-group label="Obras">
                    <b-input-group>
                      <b-form-input
                        v-model="staff.hc.gerenteUnidade.obras"
                        type="number"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group label="Maq 1">
                    <b-input-group>
                      <b-form-input
                        v-model="staff.hc.gerenteUnidade.maq1"
                        type="number"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group label="Maq 2">
                    <b-input-group>
                      <b-form-input
                        v-model="staff.hc.gerenteUnidade.maq2"
                        type="number"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group label="Maq 3">
                    <b-input-group>
                      <b-form-input
                        v-model="staff.hc.gerenteUnidade.maq3"
                        type="number"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group label="Maq 4">
                    <b-input-group>
                      <b-form-input
                        v-model="staff.hc.gerenteUnidade.maq4"
                        type="number"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group label="Maq 5">
                    <b-input-group>
                      <b-form-input
                        v-model="staff.hc.gerenteUnidade.maq5"
                        type="number"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row class="my-1">
                <b-col>
                  <h6 class="mt-2">
                    Físico médico
                  </h6>
                </b-col>
                <b-col>
                  <b-form-group label="Obras">
                    <b-input-group>
                      <b-form-input
                        v-model="staff.hc.fisicoMedico.obras"
                        type="number"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group label="Maq 1">
                    <b-input-group>
                      <b-form-input
                        v-model="staff.hc.fisicoMedico.maq1"
                        type="number"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group label="Maq 2">
                    <b-input-group>
                      <b-form-input
                        v-model="staff.hc.fisicoMedico.maq2"
                        type="number"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group label="Maq 3">
                    <b-input-group>
                      <b-form-input
                        v-model="staff.hc.fisicoMedico.maq3"
                        type="number"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group label="Maq 4">
                    <b-input-group>
                      <b-form-input
                        v-model="staff.hc.fisicoMedico.maq4"
                        type="number"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group label="Maq 5">
                    <b-input-group>
                      <b-form-input
                        v-model="staff.hc.fisicoMedico.maq5"
                        type="number"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row class="my-1">
                <b-col>
                  <h6 class="mt-2">
                    Dosimetrista
                  </h6>
                </b-col>
                <b-col>
                  <b-form-group label="Obras">
                    <b-input-group>
                      <b-form-input
                        v-model="staff.hc.dosimetrista.obras"
                        type="number"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group label="Maq 1">
                    <b-input-group>
                      <b-form-input
                        v-model="staff.hc.dosimetrista.maq1"
                        type="number"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group label="Maq 2">
                    <b-input-group>
                      <b-form-input
                        v-model="staff.hc.dosimetrista.maq2"
                        type="number"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group label="Maq 3">
                    <b-input-group>
                      <b-form-input
                        v-model="staff.hc.dosimetrista.maq3"
                        type="number"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group label="Maq 4">
                    <b-input-group>
                      <b-form-input
                        v-model="staff.hc.dosimetrista.maq4"
                        type="number"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group label="Maq 5">
                    <b-input-group>
                      <b-form-input
                        v-model="staff.hc.dosimetrista.maq5"
                        type="number"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row class="my-1">
                <b-col>
                  <h6 class="mt-2">
                    Tecnico Radioterapia
                  </h6>
                </b-col>
                <b-col>
                  <b-form-group label="Obras">
                    <b-input-group>
                      <b-form-input
                        v-model="staff.hc.tecnicoRadioterapia.obras"
                        type="number"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group label="Maq 1">
                    <b-input-group>
                      <b-form-input
                        v-model="staff.hc.tecnicoRadioterapia.maq1"
                        type="number"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group label="Maq 2">
                    <b-input-group>
                      <b-form-input
                        v-model="staff.hc.tecnicoRadioterapia.maq2"
                        type="number"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group label="Maq 3">
                    <b-input-group>
                      <b-form-input
                        v-model="staff.hc.tecnicoRadioterapia.maq3"
                        type="number"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group label="Maq 4">
                    <b-input-group>
                      <b-form-input
                        v-model="staff.hc.tecnicoRadioterapia.maq4"
                        type="number"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group label="Maq 5">
                    <b-input-group>
                      <b-form-input
                        v-model="staff.hc.tecnicoRadioterapia.maq5"
                        type="number"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row class="my-1">
                <b-col>
                  <h6 class="mt-2">
                    Enfermeiros
                  </h6>
                </b-col>
                <b-col>
                  <b-form-group label="Obras">
                    <b-input-group>
                      <b-form-input
                        v-model="staff.hc.enfermeiros.obras"
                        type="number"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group label="Maq 1">
                    <b-input-group>
                      <b-form-input
                        v-model="staff.hc.enfermeiros.maq1"
                        type="number"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group label="Maq 2">
                    <b-input-group>
                      <b-form-input
                        v-model="staff.hc.enfermeiros.maq2"
                        type="number"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group label="Maq 3">
                    <b-input-group>
                      <b-form-input
                        v-model="staff.hc.enfermeiros.maq3"
                        type="number"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group label="Maq 4">
                    <b-input-group>
                      <b-form-input
                        v-model="staff.hc.enfermeiros.maq4"
                        type="number"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group label="Maq 5">
                    <b-input-group>
                      <b-form-input
                        v-model="staff.hc.enfermeiros.maq5"
                        type="number"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row class="my-1">
                <b-col>
                  <h6 class="mt-2">
                    Recepcionista
                  </h6>
                </b-col>
                <b-col>
                  <b-form-group label="Obras">
                    <b-input-group>
                      <b-form-input
                        v-model="staff.hc.recepcionista.obras"
                        type="number"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group label="Maq 1">
                    <b-input-group>
                      <b-form-input
                        v-model="staff.hc.recepcionista.maq1"
                        type="number"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group label="Maq 2">
                    <b-input-group>
                      <b-form-input
                        v-model="staff.hc.recepcionista.maq2"
                        type="number"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group label="Maq 3">
                    <b-input-group>
                      <b-form-input
                        v-model="staff.hc.recepcionista.maq3"
                        type="number"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group label="Maq 4">
                    <b-input-group>
                      <b-form-input
                        v-model="staff.hc.recepcionista.maq4"
                        type="number"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group label="Maq 5">
                    <b-input-group>
                      <b-form-input
                        v-model="staff.hc.recepcionista.maq5"
                        type="number"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row class="my-1">
                <b-col>
                  <h6 class="mt-2">
                    Secretaria
                  </h6>
                </b-col>
                <b-col>
                  <b-form-group label="Obras">
                    <b-input-group>
                      <b-form-input
                        v-model="staff.hc.secretaria.obras"
                        type="number"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group label="Maq 1">
                    <b-input-group>
                      <b-form-input
                        v-model="staff.hc.secretaria.maq1"
                        type="number"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group label="Maq 2">
                    <b-input-group>
                      <b-form-input
                        v-model="staff.hc.secretaria.maq2"
                        type="number"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group label="Maq 3">
                    <b-input-group>
                      <b-form-input
                        v-model="staff.hc.secretaria.maq3"
                        type="number"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group label="Maq 4">
                    <b-input-group>
                      <b-form-input
                        v-model="staff.hc.secretaria.maq4"
                        type="number"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group label="Maq 5">
                    <b-input-group>
                      <b-form-input
                        v-model="staff.hc.secretaria.maq5"
                        type="number"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row class="my-1">
                <b-col>
                  <h6 class="mt-2">
                    Equipe back office
                  </h6>
                </b-col>
                <b-col>
                  <b-form-group label="Obras">
                    <b-input-group>
                      <b-form-input
                        v-model="staff.hc.equipeBackOffice.obras"
                        type="number"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group label="Maq 1">
                    <b-input-group>
                      <b-form-input
                        v-model="staff.hc.equipeBackOffice.maq1"
                        type="number"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group label="Maq 2">
                    <b-input-group>
                      <b-form-input
                        v-model="staff.hc.equipeBackOffice.maq2"
                        type="number"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group label="Maq 3">
                    <b-input-group>
                      <b-form-input
                        v-model="staff.hc.equipeBackOffice.maq3"
                        type="number"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group label="Maq 4">
                    <b-input-group>
                      <b-form-input
                        v-model="staff.hc.equipeBackOffice.maq4"
                        type="number"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group label="Maq 5">
                    <b-input-group>
                      <b-form-input
                        v-model="staff.hc.equipeBackOffice.maq5"
                        type="number"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row class="my-1">
                <b-col>
                  <h6 class="mt-2">
                    Auxiliar Saude
                  </h6>
                </b-col>
                <b-col>
                  <b-form-group label="Obras">
                    <b-input-group>
                      <b-form-input
                        v-model="staff.hc.auxiliarSaude.obras"
                        type="number"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group label="Maq 1">
                    <b-input-group>
                      <b-form-input
                        v-model="staff.hc.auxiliarSaude.maq1"
                        type="number"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group label="Maq 2">
                    <b-input-group>
                      <b-form-input
                        v-model="staff.hc.auxiliarSaude.maq2"
                        type="number"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group label="Maq 3">
                    <b-input-group>
                      <b-form-input
                        v-model="staff.hc.auxiliarSaude.maq3"
                        type="number"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group label="Maq 4">
                    <b-input-group>
                      <b-form-input
                        v-model="staff.hc.auxiliarSaude.maq4"
                        type="number"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group label="Maq 5">
                    <b-input-group>
                      <b-form-input
                        v-model="staff.hc.auxiliarSaude.maq5"
                        type="number"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
              </b-row>
            </div>
            <div class="mx-2">
              <h4>Premissas</h4>
              <b-row class="my-1">
                <b-col>
                  <h6 class="mt-2">
                    Radioterapeuta
                  </h6>
                </b-col>
                <b-col>
                  <b-form-group label="Salario">
                    <b-input-group>
                      <b-form-input
                        v-model="staff.hc.radioterapeuta.salario"
                        type="number"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group label="Adicionais">
                    <b-input-group append="%">
                      <b-form-input
                        v-model="staff.hc.radioterapeuta.adicionais"
                        type="number"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group label="Encargos">
                    <b-input-group append="%">
                      <b-form-input
                        v-model="staff.hc.radioterapeuta.encargos"
                        type="number"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row class="my-1">
                <b-col>
                  <h6 class="mt-2">
                    Coordenador / Responsável
                  </h6>
                </b-col>
                <b-col>
                  <b-form-group label="Salario">
                    <b-input-group>
                      <b-form-input
                        v-model="staff.hc.coordenadorResponsavel.salario"
                        type="number"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group label="Adicionais">
                    <b-input-group append="%">
                      <b-form-input
                        v-model="staff.hc.coordenadorResponsavel.adicionais"
                        type="number"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group label="Encargos">
                    <b-input-group append="%">
                      <b-form-input
                        v-model="staff.hc.coordenadorResponsavel.encargos"
                        type="number"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row class="my-1">
                <b-col>
                  <h6 class="mt-2">
                    Gerente
                  </h6>
                </b-col>
                <b-col>
                  <b-form-group label="Salario">
                    <b-input-group>
                      <b-form-input
                        v-model="staff.hc.gerenteUnidade.salario"
                        type="number"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group label="Adicionais">
                    <b-input-group append="%">
                      <b-form-input
                        v-model="staff.hc.gerenteUnidade.adicionais"
                        type="number"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group label="Encargos">
                    <b-input-group append="%">
                      <b-form-input
                        v-model="staff.hc.gerenteUnidade.encargos"
                        type="number"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row class="my-1">
                <b-col>
                  <h6 class="mt-2">
                    Físico médico
                  </h6>
                </b-col>
                <b-col>
                  <b-form-group label="Salario">
                    <b-input-group>
                      <b-form-input
                        v-model="staff.hc.fisicoMedico.salario"
                        type="number"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group label="Adicionais">
                    <b-input-group append="%">
                      <b-form-input
                        v-model="staff.hc.fisicoMedico.adicionais"
                        type="number"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group label="Encargos">
                    <b-input-group append="%">
                      <b-form-input
                        v-model="staff.hc.fisicoMedico.encargos"
                        type="number"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row class="my-1">
                <b-col>
                  <h6 class="mt-2">
                    Dosimetrista
                  </h6>
                </b-col>
                <b-col>
                  <b-form-group label="Salario">
                    <b-input-group>
                      <b-form-input
                        v-model="staff.hc.dosimetrista.salario"
                        type="number"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group label="Adicionais">
                    <b-input-group append="%">
                      <b-form-input
                        v-model="staff.hc.dosimetrista.adicionais"
                        type="number"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group label="Encargos">
                    <b-input-group append="%">
                      <b-form-input
                        v-model="staff.hc.dosimetrista.encargos"
                        type="number"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row class="my-1">
                <b-col>
                  <h6 class="mt-2">
                    Tecnico Radioterapia
                  </h6>
                </b-col>
                <b-col>
                  <b-form-group label="Salario">
                    <b-input-group>
                      <b-form-input
                        v-model="staff.hc.tecnicoRadioterapia.salario"
                        type="number"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group label="Adicionais">
                    <b-input-group append="%">
                      <b-form-input
                        v-model="staff.hc.tecnicoRadioterapia.adicionais"
                        type="number"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group label="Encargos">
                    <b-input-group append="%">
                      <b-form-input
                        v-model="staff.hc.tecnicoRadioterapia.encargos"
                        type="number"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row class="my-1">
                <b-col>
                  <h6 class="mt-2">
                    Enfermeiros
                  </h6>
                </b-col>
                <b-col>
                  <b-form-group label="Salario">
                    <b-input-group>
                      <b-form-input
                        v-model="staff.hc.enfermeiros.salario"
                        type="number"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group label="Adicionais">
                    <b-input-group append="%">
                      <b-form-input
                        v-model="staff.hc.enfermeiros.adicionais"
                        type="number"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group label="Encargos">
                    <b-input-group append="%">
                      <b-form-input
                        v-model="staff.hc.enfermeiros.encargos"
                        type="number"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row class="my-1">
                <b-col>
                  <h6 class="mt-2">
                    Recepcionista
                  </h6>
                </b-col>
                <b-col>
                  <b-form-group label="Salario">
                    <b-input-group>
                      <b-form-input
                        v-model="staff.hc.recepcionista.salario"
                        type="number"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group label="Adicionais">
                    <b-input-group append="%">
                      <b-form-input
                        v-model="staff.hc.recepcionista.adicionais"
                        type="number"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group label="Encargos">
                    <b-input-group append="%">
                      <b-form-input
                        v-model="staff.hc.recepcionista.encargos"
                        type="number"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row class="my-1">
                <b-col>
                  <h6 class="mt-2">
                    Secretaria
                  </h6>
                </b-col>
                <b-col>
                  <b-form-group label="Salario">
                    <b-input-group>
                      <b-form-input
                        v-model="staff.hc.secretaria.salario"
                        type="number"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group label="Adicionais">
                    <b-input-group append="%">
                      <b-form-input
                        v-model="staff.hc.secretaria.adicionais"
                        type="number"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group label="Encargos">
                    <b-input-group append="%">
                      <b-form-input
                        v-model="staff.hc.secretaria.encargos"
                        type="number"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row class="my-1">
                <b-col>
                  <h6 class="mt-2">
                    Equipe back office
                  </h6>
                </b-col>
                <b-col>
                  <b-form-group label="Salario">
                    <b-input-group>
                      <b-form-input
                        v-model="staff.hc.equipeBackOffice.salario"
                        type="number"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group label="Adicionais">
                    <b-input-group append="%">
                      <b-form-input
                        v-model="staff.hc.equipeBackOffice.adicionais"
                        type="number"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group label="Encargos">
                    <b-input-group append="%">
                      <b-form-input
                        v-model="staff.hc.equipeBackOffice.encargos"
                        type="number"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row class="my-1">
                <b-col>
                  <h6 class="mt-2">
                    Auxiliar Saude
                  </h6>
                </b-col>
                <b-col>
                  <b-form-group label="Salario">
                    <b-input-group>
                      <b-form-input
                        v-model="staff.hc.auxiliarSaude.salario"
                        type="number"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group label="Adicionais">
                    <b-input-group append="%">
                      <b-form-input
                        v-model="staff.hc.auxiliarSaude.adicionais"
                        type="number"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group label="Encargos">
                    <b-input-group append="%">
                      <b-form-input
                        v-model="staff.hc.auxiliarSaude.encargos"
                        type="number"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
              </b-row>
            </div>
            <div class="mx-2">
              <h4>Custo total</h4>
              <b-row class="my-1">
                <b-col>
                  <b-form-group label="Meses (Bonus)">
                    <b-input-group>
                      <b-form-input
                        v-model="staff.custoTotal.mesesBonus"
                        type="number"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row class="my-1">
                <b-col>
                  <h6 class="mt-2">
                    Administrativo
                  </h6>
                </b-col>
                <b-col>
                  <b-form-group label="Obras">
                    <b-input-group>
                      <b-form-input
                        :value="staff.custoTotal.administrativo.obras"
                        :disabled="true"
                        type="number"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group label="Maq 1">
                    <b-input-group>
                      <b-form-input
                        :value="staff.custoTotal.administrativo.maq1"
                        :disabled="true"
                        type="number"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group label="Maq 2">
                    <b-input-group>
                      <b-form-input
                        :value="staff.custoTotal.administrativo.maq2"
                        :disabled="true"
                        type="number"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group label="Maq 3">
                    <b-input-group>
                      <b-form-input
                        :value="staff.custoTotal.administrativo.maq3"
                        :disabled="true"
                        type="number"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group label="Maq 4">
                    <b-input-group>
                      <b-form-input
                        :value="staff.custoTotal.administrativo.maq4"
                        :disabled="true"
                        type="number"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group label="Maq 5">
                    <b-input-group>
                      <b-form-input
                        :value="staff.custoTotal.administrativo.maq5"
                        :disabled="true"
                        type="number"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row class="my-1">
                <b-col>
                  <h6 class="mt-2">
                    Assistencial
                  </h6>
                </b-col>
                <b-col>
                  <b-form-group label="Obras">
                    <b-input-group>
                      <b-form-input
                        :value="staff.custoTotal.assistencial.obras"
                        :disabled="true"
                        type="number"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group label="Maq 1">
                    <b-input-group>
                      <b-form-input
                        :value="staff.custoTotal.assistencial.maq1"
                        :disabled="true"
                        type="number"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group label="Maq 2">
                    <b-input-group>
                      <b-form-input
                        :value="staff.custoTotal.assistencial.maq2"
                        :disabled="true"
                        type="number"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group label="Maq 3">
                    <b-input-group>
                      <b-form-input
                        :value="staff.custoTotal.assistencial.maq3"
                        :disabled="true"
                        type="number"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group label="Maq 4">
                    <b-input-group>
                      <b-form-input
                        :value="staff.custoTotal.assistencial.maq4"
                        :disabled="true"
                        type="number"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group label="Maq 5">
                    <b-input-group>
                      <b-form-input
                        :value="staff.custoTotal.assistencial.maq5"
                        :disabled="true"
                        type="number"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row class="my-1">
                <b-col>
                  <h6 class="mt-2">
                    Médicos
                  </h6>
                </b-col>
                <b-col>
                  <b-form-group label="Obras">
                    <b-input-group>
                      <b-form-input
                        :value="staff.custoTotal.medicos.obras"
                        :disabled="true"
                        type="number"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group label="Maq 1">
                    <b-input-group>
                      <b-form-input
                        :value="staff.custoTotal.medicos.maq1"
                        :disabled="true"
                        type="number"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group label="Maq 2">
                    <b-input-group>
                      <b-form-input
                        :value="staff.custoTotal.medicos.maq2"
                        :disabled="true"
                        type="number"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group label="Maq 3">
                    <b-input-group>
                      <b-form-input
                        :value="staff.custoTotal.medicos.maq3"
                        :disabled="true"
                        type="number"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group label="Maq 4">
                    <b-input-group>
                      <b-form-input
                        :value="staff.custoTotal.medicos.maq4"
                        :disabled="true"
                        type="number"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group label="Maq 5">
                    <b-input-group>
                      <b-form-input
                        :value="staff.custoTotal.medicos.maq5"
                        :disabled="true"
                        type="number"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row class="my-1">
                <b-col>
                  <h6 class="mt-2">
                    Físicos / Dosimetristas
                  </h6>
                </b-col>
                <b-col>
                  <b-form-group label="Obras">
                    <b-input-group>
                      <b-form-input
                        :value="staff.custoTotal.fisicosDosimetristas.obras"
                        :disabled="true"
                        type="number"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group label="Maq 1">
                    <b-input-group>
                      <b-form-input
                        :value="staff.custoTotal.fisicosDosimetristas.maq1"
                        :disabled="true"
                        type="number"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group label="Maq 2">
                    <b-input-group>
                      <b-form-input
                        :value="staff.custoTotal.fisicosDosimetristas.maq2"
                        :disabled="true"
                        type="number"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group label="Maq 3">
                    <b-input-group>
                      <b-form-input
                        :value="staff.custoTotal.fisicosDosimetristas.maq3"
                        :disabled="true"
                        type="number"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group label="Maq 4">
                    <b-input-group>
                      <b-form-input
                        :value="staff.custoTotal.fisicosDosimetristas.maq4"
                        :disabled="true"
                        type="number"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group label="Maq 5">
                    <b-input-group>
                      <b-form-input
                        :value="staff.custoTotal.fisicosDosimetristas.maq5"
                        :disabled="true"
                        type="number"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row class="my-1">
                <b-col>
                  <h6 class="mt-2">
                    Médicos (% Receita)
                  </h6>
                </b-col>
                <b-col>
                  <b-form-group label="Obras">
                    <b-input-group append="%">
                      <b-form-input
                        v-model="staff.custoTotal.medicosPercentReceita.obras"
                        type="number"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group label="Maq 1">
                    <b-input-group append="%">
                      <b-form-input
                        v-model="staff.custoTotal.medicosPercentReceita.maq1"
                        type="number"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group label="Maq 2">
                    <b-input-group append="%">
                      <b-form-input
                        v-model="staff.custoTotal.medicosPercentReceita.maq2"
                        type="number"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group label="Maq 3">
                    <b-input-group append="%">
                      <b-form-input
                        v-model="staff.custoTotal.medicosPercentReceita.maq3"
                        type="number"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group label="Maq 4">
                    <b-input-group append="%">
                      <b-form-input
                        v-model="staff.custoTotal.medicosPercentReceita.maq4"
                        type="number"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group label="Maq 5">
                    <b-input-group append="%">
                      <b-form-input
                        v-model="staff.custoTotal.medicosPercentReceita.maq5"
                        type="number"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
              </b-row>
            </div>
            <div class="mx-2">
              <h4>% Atuação</h4>
              <b-row class="my-1">
                <b-col>
                  <h6 class="mt-2">
                    Ano 0
                  </h6>
                </b-col>
                <b-col>
                  <b-form-group label="Assistencial">
                    <b-input-group append="%">
                      <b-form-input
                        v-model="staff.atuacao.ano0.assistencial"
                        type="number"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group label="Administrativo">
                    <b-input-group append="%">
                      <b-form-input
                        v-model="staff.atuacao.ano0.administrativo"
                        type="number"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group label="Medicos">
                    <b-input-group append="%">
                      <b-form-input
                        v-model="staff.atuacao.ano0.medicos"
                        type="number"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group label="Dosimetristas">
                    <b-input-group append="%">
                      <b-form-input
                        v-model="staff.atuacao.ano0.fisicosDosimetristas"
                        type="number"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row class="my-1">
                <b-col>
                  <h6 class="mt-2">
                    Ano 1
                  </h6>
                </b-col>
                <b-col>
                  <b-form-group label="Assistencial">
                    <b-input-group append="%">
                      <b-form-input
                        v-model="staff.atuacao.ano1.assistencial"
                        type="number"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group label="Administrativo">
                    <b-input-group append="%">
                      <b-form-input
                        v-model="staff.atuacao.ano1.administrativo"
                        type="number"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group label="Medicos">
                    <b-input-group append="%">
                      <b-form-input
                        v-model="staff.atuacao.ano1.medicos"
                        type="number"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group label="Dosimetristas">
                    <b-input-group append="%">
                      <b-form-input
                        v-model="staff.atuacao.ano1.fisicosDosimetristas"
                        type="number"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row class="my-1">
                <b-col>
                  <h6 class="mt-2">
                    Ano 2
                  </h6>
                </b-col>
                <b-col>
                  <b-form-group label="Assistencial">
                    <b-input-group append="%">
                      <b-form-input
                        v-model="staff.atuacao.ano2.assistencial"
                        type="number"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group label="Administrativo">
                    <b-input-group append="%">
                      <b-form-input
                        v-model="staff.atuacao.ano2.administrativo"
                        type="number"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group label="Medicos">
                    <b-input-group append="%">
                      <b-form-input
                        v-model="staff.atuacao.ano2.medicos"
                        type="number"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group label="Dosimetristas">
                    <b-input-group append="%">
                      <b-form-input
                        v-model="staff.atuacao.ano2.fisicosDosimetristas"
                        type="number"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row class="my-1">
                <b-col>
                  <h6 class="mt-2">
                    Ano 3
                  </h6>
                </b-col>
                <b-col>
                  <b-form-group label="Assistencial">
                    <b-input-group append="%">
                      <b-form-input
                        v-model="staff.atuacao.ano3.assistencial"
                        type="number"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group label="Administrativo">
                    <b-input-group append="%">
                      <b-form-input
                        v-model="staff.atuacao.ano3.administrativo"
                        type="number"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group label="Medicos">
                    <b-input-group append="%">
                      <b-form-input
                        v-model="staff.atuacao.ano3.medicos"
                        type="number"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group label="Dosimetristas">
                    <b-input-group append="%">
                      <b-form-input
                        v-model="staff.atuacao.ano3.fisicosDosimetristas"
                        type="number"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row class="my-1">
                <b-col>
                  <h6 class="mt-2">
                    Ano 4
                  </h6>
                </b-col>
                <b-col>
                  <b-form-group label="Assistencial">
                    <b-input-group append="%">
                      <b-form-input
                        v-model="staff.atuacao.ano4.assistencial"
                        type="number"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group label="Administrativo">
                    <b-input-group append="%">
                      <b-form-input
                        v-model="staff.atuacao.ano4.administrativo"
                        type="number"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group label="Medicos">
                    <b-input-group append="%">
                      <b-form-input
                        v-model="staff.atuacao.ano4.medicos"
                        type="number"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group label="Dosimetristas">
                    <b-input-group append="%">
                      <b-form-input
                        v-model="staff.atuacao.ano4.fisicosDosimetristas"
                        type="number"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row class="my-1">
                <b-col>
                  <h6 class="mt-2">
                    Ano 5
                  </h6>
                </b-col>
                <b-col>
                  <b-form-group label="Assistencial">
                    <b-input-group append="%">
                      <b-form-input
                        v-model="staff.atuacao.ano5.assistencial"
                        type="number"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group label="Administrativo">
                    <b-input-group append="%">
                      <b-form-input
                        v-model="staff.atuacao.ano5.administrativo"
                        type="number"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group label="Medicos">
                    <b-input-group append="%">
                      <b-form-input
                        v-model="staff.atuacao.ano5.medicos"
                        type="number"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group label="Dosimetristas">
                    <b-input-group append="%">
                      <b-form-input
                        v-model="staff.atuacao.ano5.fisicosDosimetristas"
                        type="number"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row class="my-1">
                <b-col>
                  <h6 class="mt-2">
                    Ano 6
                  </h6>
                </b-col>
                <b-col>
                  <b-form-group label="Assistencial">
                    <b-input-group append="%">
                      <b-form-input
                        v-model="staff.atuacao.ano6.assistencial"
                        type="number"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group label="Administrativo">
                    <b-input-group append="%">
                      <b-form-input
                        v-model="staff.atuacao.ano6.administrativo"
                        type="number"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group label="Medicos">
                    <b-input-group append="%">
                      <b-form-input
                        v-model="staff.atuacao.ano6.medicos"
                        type="number"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group label="Dosimetristas">
                    <b-input-group append="%">
                      <b-form-input
                        v-model="staff.atuacao.ano6.fisicosDosimetristas"
                        type="number"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row class="my-1">
                <b-col>
                  <h6 class="mt-2">
                    Ano 7
                  </h6>
                </b-col>
                <b-col>
                  <b-form-group label="Assistencial">
                    <b-input-group append="%">
                      <b-form-input
                        v-model="staff.atuacao.ano7.assistencial"
                        type="number"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group label="Administrativo">
                    <b-input-group append="%">
                      <b-form-input
                        v-model="staff.atuacao.ano7.administrativo"
                        type="number"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group label="Medicos">
                    <b-input-group append="%">
                      <b-form-input
                        v-model="staff.atuacao.ano7.medicos"
                        type="number"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group label="Dosimetristas">
                    <b-input-group append="%">
                      <b-form-input
                        v-model="staff.atuacao.ano7.fisicosDosimetristas"
                        type="number"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
              </b-row>
            </div>
          </b-tab>
        </b-tabs>
      </b-card>
    </b-overlay>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import {
  BTabs,
  BTab,
  BCard,
  BOverlay,
  BRow,
  BCol,
  BTable,
  BFormGroup,
  BFormSelect,
  BFormInput,
  BInputGroup,
} from 'bootstrap-vue'

export default {
  name: 'IncomingAndExpenses',

  components: {
    BRow,
    BCol,
    BTable,
    BTabs,
    BTab,
    BCard,
    BOverlay,
    BFormInput,
    BFormGroup,
    BFormSelect,
    BInputGroup,
  },
  data() {
    return {
      saving: false,
      tabIndex: 0,
      cenariosList: [
        { text: 'Lucrativo', value: 'Lucrativo' },
        { text: 'Filantropico', value: 'Filantropico' },
      ],
      staff: {
        cenarioSelected: 'Lucrativo',
        atuacao: {
          ano0: {
            assistencial: 100,
            administrativo: 100,
            medicos: 100,
            fisicosDosimetristas: 100,
          },
          ano1: {
            assistencial: 100,
            administrativo: 100,
            medicos: 100,
            fisicosDosimetristas: 100,
          },
          ano2: {
            assistencial: 100,
            administrativo: 100,
            medicos: 100,
            fisicosDosimetristas: 100,
          },
          ano3: {
            assistencial: 100,
            administrativo: 100,
            medicos: 100,
            fisicosDosimetristas: 100,
          },
          ano4: {
            assistencial: 100,
            administrativo: 100,
            medicos: 100,
            fisicosDosimetristas: 100,
          },
          ano5: {
            assistencial: 100,
            administrativo: 100,
            medicos: 100,
            fisicosDosimetristas: 100,
          },
          ano6: {
            assistencial: 100,
            administrativo: 100,
            medicos: 100,
            fisicosDosimetristas: 100,
          },
          ano7: {
            assistencial: 100,
            administrativo: 100,
            medicos: 100,
            fisicosDosimetristas: 100,
          },
        },
        hc: {
          radioterapeuta: {
            obras: 0,
            maq1: 3,
            maq2: 4,
            maq3: 5,
            maq4: 7,
            maq5: 8,
            salario: 50000,
            adicionais: 0,
            encargos: 12,
          },
          coordenadorResponsavel: {
            obras: 1,
            maq1: 1,
            maq2: 1,
            maq3: 1,
            maq4: 1,
            maq5: 1,
            salario: 50000,
            adicionais: 0,
            encargos: 12,
          },
          gerenteUnidade: {
            obras: 1,
            maq1: 1,
            maq2: 1,
            maq3: 1,
            maq4: 1,
            maq5: 1,
            salario: 50000,
            adicionais: 0,
            encargos: 55,
          },
          fisicoMedico: {
            obras: 0,
            maq1: 2,
            maq2: 3,
            maq3: 4,
            maq4: 5,
            maq5: 5,
            salario: 14000,
            adicionais: 30,
            encargos: 55,
          },
          dosimetrista: {
            obras: 0,
            maq1: 1,
            maq2: 2,
            maq3: 3,
            maq4: 3,
            maq5: 4,
            salario: 4500,
            adicionais: 30,
            encargos: 55,
          },
          tecnicoRadioterapia: {
            obras: 0,
            maq1: 8,
            maq2: 14,
            maq3: 21,
            maq4: 27,
            maq5: 33,
            salario: 3500,
            adicionais: 30,
            encargos: 55,
          },
          enfermeiros: {
            obras: 0,
            maq1: 2,
            maq2: 2,
            maq3: 3,
            maq4: 4,
            maq5: 5,
            salario: 3500,
            adicionais: 30,
            encargos: 55,
          },
          recepcionista: {
            obras: 0,
            maq1: 2,
            maq2: 4,
            maq3: 4,
            maq4: 4,
            maq5: 4,
            salario: 1200,
            adicionais: 0,
            encargos: 55,
          },
          secretaria: {
            obras: 0,
            maq1: 1,
            maq2: 3,
            maq3: 4,
            maq4: 4,
            maq5: 4,
            salario: 1500,
            adicionais: 0,
            encargos: 55,
          },
          equipeBackOffice: {
            obras: 0,
            maq1: 3,
            maq2: 4,
            maq3: 4,
            maq4: 4,
            maq5: 5,
            salario: 2000,
            adicionais: 0,
            encargos: 55,
          },
          auxiliarSaude: {
            obras: 0,
            maq1: 0,
            maq2: 0,
            maq3: 0,
            maq4: 0,
            maq5: 0,
            salario: 1300,
            adicionais: 30,
            encargos: 55,
          },
        },
        valorTotal: {
          radioterapeuta: {
            obras: 0,
            maq1: 0,
            maq2: 0,
            maq3: 0,
            maq4: 0,
            maq5: 0,
          },
          coordenadorResponsavel: {
            obras: 0,
            maq1: 0,
            maq2: 0,
            maq3: 0,
            maq4: 0,
            maq5: 0,
          },
          gerenteUnidade: {
            obras: 0,
            maq1: 0,
            maq2: 0,
            maq3: 0,
            maq4: 0,
            maq5: 0,
          },
          fisicoMedico: {
            obras: 0,
            maq1: 0,
            maq2: 0,
            maq3: 0,
            maq4: 0,
            maq5: 0,
          },
          dosimetrista: {
            obras: 0,
            maq1: 0,
            maq2: 0,
            maq3: 0,
            maq4: 0,
            maq5: 0,
          },
          tecnicoRadioterapia: {
            obras: 0,
            maq1: 0,
            maq2: 0,
            maq3: 0,
            maq4: 0,
            maq5: 0,
          },
          enfermeiros: {
            obras: 0,
            maq1: 0,
            maq2: 0,
            maq3: 0,
            maq4: 0,
            maq5: 0,
          },
          recepcionista: {
            obras: 0,
            maq1: 0,
            maq2: 0,
            maq3: 0,
            maq4: 0,
            maq5: 0,
          },
          secretaria: {
            obras: 0,
            maq1: 0,
            maq2: 0,
            maq3: 0,
            maq4: 0,
            maq5: 0,
          },
          equipeBackOffice: {
            obras: 0,
            maq1: 0,
            maq2: 0,
            maq3: 0,
            maq4: 0,
            maq5: 0,
          },
          auxiliarSaude: {
            obras: 0,
            maq1: 0,
            maq2: 0,
            maq3: 0,
            maq4: 0,
            maq5: 0,
          },
          total: {
            obras: 0,
            maq1: 0,
            maq2: 0,
            maq3: 0,
            maq4: 0,
            maq5: 0,
          },
        },
        custoTotal: {
          administrativo: {
            obras: null,
            maq1: null,
            maq2: null,
            maq3: null,
            maq4: null,
            maq5: null,
          },
          assistencial: {
            obras: null,
            maq1: null,
            maq2: null,
            maq3: null,
            maq4: null,
            maq5: null,
          },
          medicos: {
            obras: null,
            maq1: null,
            maq2: null,
            maq3: null,
            maq4: null,
            maq5: null,
          },
          fisicosDosimetristas: {
            obras: null,
            maq1: null,
            maq2: null,
            maq3: null,
            maq4: null,
            maq5: null,
          },
          medicosPercentReceita: {
            obras: 0,
            maq1: 15,
            maq2: 15,
            maq3: 15,
            maq4: 15,
            maq5: 16,
          },
          mesesBonus: 13,
        },
      },
      incomings: {
        pacients: 80,
        taxaOcupacao: 90,
        taxaOcupacaoPercentPerYear: null,
        equipamentosOperando: {
          ano0: 0,
          ano1: 0,
          ano2: 0,
          ano3: 0,
          ano4: 0,
          ano5: 0,
          ano6: 0,
          ano7: 0,
          ano8: 0,
          ano9: 0,
          ano10: 0,
          ano11: 0,
          ano12: 0,
          ano13: 0,
          ano14: 0,
          ano15: 0,
          ano16: 0,
          ano17: 0,
          ano18: 0,
          ano19: 0,
          ano20: 0,
        },
        taxaOcupacaoList: {
          proton: {
            ano0: 0,
            ano1: 0,
            ano2: 0,
            ano3: 0,
            ano4: 0,
            ano5: 0,
            ano6: 0,
            ano7: 0,
            ano8: 0,
            ano9: 0,
            ano10: 0,
            ano11: 0,
            ano12: 0,
            ano13: 0,
            ano14: 0,
            ano15: 0,
            ano16: 0,
            ano17: 0,
            ano18: 0,
            ano19: 0,
            ano20: 0,
          },
          trueBeam1: {
            ano0: 0,
            ano1: 0,
            ano2: 0,
            ano3: 0,
            ano4: 0,
            ano5: 0,
            ano6: 0,
            ano7: 0,
            ano8: 0,
            ano9: 0,
            ano10: 0,
            ano11: 0,
            ano12: 0,
            ano13: 0,
            ano14: 0,
            ano15: 0,
            ano16: 0,
            ano17: 0,
            ano18: 0,
            ano19: 0,
            ano20: 0,
          },
          trueBeam2: {
            ano0: 0,
            ano1: 0,
            ano2: 0,
            ano3: 0,
            ano4: 0,
            ano5: 0,
            ano6: 0,
            ano7: 0,
            ano8: 0,
            ano9: 0,
            ano10: 0,
            ano11: 0,
            ano12: 0,
            ano13: 0,
            ano14: 0,
            ano15: 0,
            ano16: 0,
            ano17: 0,
            ano18: 0,
            ano19: 0,
            ano20: 0,
          },
          acelerator1: {
            ano0: 0,
            ano1: 0,
            ano2: 0,
            ano3: 0,
            ano4: 0,
            ano5: 0,
            ano6: 0,
            ano7: 0,
            ano8: 0,
            ano9: 0,
            ano10: 0,
            ano11: 0,
            ano12: 0,
            ano13: 0,
            ano14: 0,
            ano15: 0,
            ano16: 0,
            ano17: 0,
            ano18: 0,
            ano19: 0,
            ano20: 0,
          },
          acelerator2: {
            ano0: 0,
            ano1: 0,
            ano2: 0,
            ano3: 0,
            ano4: 0,
            ano5: 0,
            ano6: 0,
            ano7: 0,
            ano8: 0,
            ano9: 0,
            ano10: 0,
            ano11: 0,
            ano12: 0,
            ano13: 0,
            ano14: 0,
            ano15: 0,
            ano16: 0,
            ano17: 0,
            ano18: 0,
            ano19: 0,
            ano20: 0,
          },
        },
        custoPessoalList: {
          assistencial: {
            ano0: 0,
            ano1: 0,
            ano2: 0,
            ano3: 0,
            ano4: 0,
            ano5: 0,
            ano6: 0,
            ano7: 0,
            ano8: 0,
            ano9: 0,
            ano10: 0,
            ano11: 0,
            ano12: 0,
            ano13: 0,
            ano14: 0,
            ano15: 0,
            ano16: 0,
            ano17: 0,
            ano18: 0,
            ano19: 0,
            ano20: 0,
          },
          administrativo: {
            ano0: 0,
            ano1: 0,
            ano2: 0,
            ano3: 0,
            ano4: 0,
            ano5: 0,
            ano6: 0,
            ano7: 0,
            ano8: 0,
            ano9: 0,
            ano10: 0,
            ano11: 0,
            ano12: 0,
            ano13: 0,
            ano14: 0,
            ano15: 0,
            ano16: 0,
            ano17: 0,
            ano18: 0,
            ano19: 0,
            ano20: 0,
          },
          medicos: {
            ano0: 0,
            ano1: 0,
            ano2: 0,
            ano3: 0,
            ano4: 0,
            ano5: 0,
            ano6: 0,
            ano7: 0,
            ano8: 0,
            ano9: 0,
            ano10: 0,
            ano11: 0,
            ano12: 0,
            ano13: 0,
            ano14: 0,
            ano15: 0,
            ano16: 0,
            ano17: 0,
            ano18: 0,
            ano19: 0,
            ano20: 0,
          },
          fisicosDosimetristas: {
            ano0: 0,
            ano1: 0,
            ano2: 0,
            ano3: 0,
            ano4: 0,
            ano5: 0,
            ano6: 0,
            ano7: 0,
            ano8: 0,
            ano9: 0,
            ano10: 0,
            ano11: 0,
            ano12: 0,
            ano13: 0,
            ano14: 0,
            ano15: 0,
            ano16: 0,
            ano17: 0,
            ano18: 0,
            ano19: 0,
            ano20: 0,
          },
        },
        taxaOcupacaoRessonancia: null,
        taxaOcupacaoTomografo: null,
        capacidadeAnual: null,
        capacidadeComHipofracionamentoDiasUteis: null,
        capacidadeComHipofracionamentoMediaDiasUteisPorTratamento: null,
        capacidadeComHipofracionamento: null,
      },
      expenses: {
        administrativo: {
          aluguelPorMes: 1000,
          despesasRateadasPorAno: 1000,
          operacionaisProton: null,
          operacionaisTrueBeam1: 30,
          operacionaisTrueBeam2: null,
          operacionaisEq1: null,
          operacionaisEq2: null,
          operacionaisOutrosEq: null,
        },
        indices: {
          cdi: 3.84,
          usdToBrl: 5.4,
          usCpi: 2.5,
          ipca: 5,
          ipcaProton: 80,
          cdiPercentPerYear: 0.5,
          usdToBrlPercentPerYear: 1,
          usCpiPercentPerYear: 0.5,
          ipcaPercentPerYear: 1.5,
        },
        impostosSobreLucro: {
          ir: 8,
          csll: 12,
        },
        impostosMaisPcld: {
          pisCofins: 4.65,
          iss: 3,
          pcld: 3,
        },
      },
    }
  },
  computed: {
    ...mapGetters('businessPlan', ['dashboard']),
    taxaDeOcupacaoItems() {
      return Object.keys(this.incomings.taxaOcupacaoList).map(e => ({
        type: e,
        ano0: this.incomings.taxaOcupacaoList[e].ano0,
        ano1: this.incomings.taxaOcupacaoList[e].ano1,
        ano2: this.incomings.taxaOcupacaoList[e].ano2,
        ano3: this.incomings.taxaOcupacaoList[e].ano3,
        ano4: this.incomings.taxaOcupacaoList[e].ano4,
        ano5: this.incomings.taxaOcupacaoList[e].ano5,
        ano6: this.incomings.taxaOcupacaoList[e].ano6,
        ano7: this.incomings.taxaOcupacaoList[e].ano7,
        ano8: this.incomings.taxaOcupacaoList[e].ano8,
        ano9: this.incomings.taxaOcupacaoList[e].ano9,
        ano10: this.incomings.taxaOcupacaoList[e].ano10,
        ano11: this.incomings.taxaOcupacaoList[e].ano11,
        ano12: this.incomings.taxaOcupacaoList[e].ano12,
        ano13: this.incomings.taxaOcupacaoList[e].ano13,
        ano14: this.incomings.taxaOcupacaoList[e].ano14,
        ano15: this.incomings.taxaOcupacaoList[e].ano15,
        ano16: this.incomings.taxaOcupacaoList[e].ano16,
        ano17: this.incomings.taxaOcupacaoList[e].ano17,
        ano18: this.incomings.taxaOcupacaoList[e].ano18,
        ano19: this.incomings.taxaOcupacaoList[e].ano19,
        ano20: this.incomings.taxaOcupacaoList[e].ano20,
      }))
    },
    custoPessoalItems() {
      return Object.keys(this.incomings.custoPessoalList).map(e => ({
        type: e,
        ano0: this.incomings.custoPessoalList[e].ano0,
        ano1: this.incomings.custoPessoalList[e].ano1,
        ano2: this.incomings.custoPessoalList[e].ano2,
        ano3: this.incomings.custoPessoalList[e].ano3,
        ano4: this.incomings.custoPessoalList[e].ano4,
        ano5: this.incomings.custoPessoalList[e].ano5,
        ano6: this.incomings.custoPessoalList[e].ano6,
        ano7: this.incomings.custoPessoalList[e].ano7,
        ano8: this.incomings.custoPessoalList[e].ano8,
        ano9: this.incomings.custoPessoalList[e].ano9,
        ano10: this.incomings.custoPessoalList[e].ano10,
        ano11: this.incomings.custoPessoalList[e].ano11,
        ano12: this.incomings.custoPessoalList[e].ano12,
        ano13: this.incomings.custoPessoalList[e].ano13,
        ano14: this.incomings.custoPessoalList[e].ano14,
        ano15: this.incomings.custoPessoalList[e].ano15,
        ano16: this.incomings.custoPessoalList[e].ano16,
        ano17: this.incomings.custoPessoalList[e].ano17,
        ano18: this.incomings.custoPessoalList[e].ano18,
        ano19: this.incomings.custoPessoalList[e].ano19,
        ano20: this.incomings.custoPessoalList[e].ano20,
      }))
    },
    fields() {
      return [
        { key: 'type', label: 'equipamento' },
        { key: 'ano0', label: 'ano 0' },
        { key: 'ano1', label: 'ano 1' },
        { key: 'ano2', label: 'ano 2' },
        { key: 'ano3', label: 'ano 3' },
        { key: 'ano4', label: 'ano 4' },
        { key: 'ano5', label: 'ano 5' },
        { key: 'ano6', label: 'ano 6' },
        { key: 'ano7', label: 'ano 7' },
        { key: 'ano8', label: 'ano 8' },
        { key: 'ano9', label: 'ano 9' },
        { key: 'ano10', label: 'ano 10' },
        { key: 'ano11', label: 'ano 11' },
        { key: 'ano12', label: 'ano 12' },
        { key: 'ano13', label: 'ano 13' },
        { key: 'ano14', label: 'ano 14' },
        { key: 'ano15', label: 'ano 15' },
        { key: 'ano16', label: 'ano 16' },
        { key: 'ano17', label: 'ano 17' },
        { key: 'ano18', label: 'ano 18' },
        { key: 'ano19', label: 'ano 19' },
        { key: 'ano20', label: 'ano 20' },
      ]
    },
  },
  watch: {
    'staff.atuacao': {
      deep: true,
      handler() { this.fixValorTotal() },
    },
    'staff.hc': {
      deep: true,
      handler() { this.fixValorTotal() },
    },
    'staff.custoTotal.mesesBonus': {
      handler() { this.fixValorTotal() },
    },
    'incomings.pacients': 'fixCapacidades',
    'incomings.taxaOcupacao': 'fixTaxaOcupacaoList',
    'incomings.taxaOcupacaoPercentPerYear': 'fixTaxaOcupacaoList',
    'incomings.capacidadeComHipofracionamentoDiasUteis': 'fixCapacidades',
    'incomings.capacidadeComHipofracionamentoMediaDiasUteisPorTratamento': 'fixCapacidades',
    staff: {
      deep: true,
      handler() { this.updateStaff() },
    },
    incomings: {
      deep: true,
      handler() { this.updateIncomings() },
    },
    expenses: {
      deep: true,
      handler() { this.updateExpenses() },
    },
  },
  mounted() {
    this.fixCapacidades()
    this.fixTaxaOcupacaoList()
    this.fixValorTotal()
    this.updateIncomings()
    this.updateExpenses()
  },
  methods: {
    fixValorTotal() {
      const hcFields = [
        'radioterapeuta',
        'coordenadorResponsavel',
        'gerenteUnidade',
        'fisicoMedico',
        'dosimetrista',
        'tecnicoRadioterapia',
        'enfermeiros',
        'recepcionista',
        'secretaria',
        'equipeBackOffice',
        'auxiliarSaude',
      ]
      const resourceFields = [
        'obras', 'maq1', 'maq2', 'maq3', 'maq4', 'maq5',
      ]
      // eslint-disable-next-line
      for(let hc of hcFields) {
        // eslint-disable-next-line
        for(let resource of resourceFields) {
          this.staff.valorTotal[hc][resource] = parseFloat(0)
          this.staff.valorTotal.total[resource] = parseFloat(0)
        }
      }
      // eslint-disable-next-line
      for(let hc of hcFields) {
        // eslint-disable-next-line
        for(let resource of resourceFields) {
          this.staff.valorTotal[hc][resource] = parseFloat(parseFloat(this.staff.hc[hc][resource]) * parseFloat(this.staff.hc[hc].salario) * ((100 + parseFloat(this.staff.hc[hc].adicionais)) / 100) * ((100 + parseFloat(this.staff.hc[hc].encargos)) / 100))
          this.staff.valorTotal.total[resource] = parseFloat(this.staff.valorTotal.total[resource]) + parseFloat(this.staff.valorTotal[hc][resource])
        }
      }
      this.staff.custoTotal.administrativo.obras = (((parseFloat(this.staff.custoTotal.mesesBonus)) * (this.staff.valorTotal.gerenteUnidade.obras + this.staff.valorTotal.recepcionista.obras + this.staff.valorTotal.secretaria.obras + this.staff.valorTotal.equipeBackOffice.obras)) / 1000).toFixed(2)
      this.staff.custoTotal.administrativo.maq1 = (((parseFloat(this.staff.custoTotal.mesesBonus)) * (this.staff.valorTotal.gerenteUnidade.maq1 + this.staff.valorTotal.recepcionista.maq1 + this.staff.valorTotal.secretaria.maq1 + this.staff.valorTotal.equipeBackOffice.maq1)) / 1000).toFixed(2)
      this.staff.custoTotal.administrativo.maq2 = (((parseFloat(this.staff.custoTotal.mesesBonus)) * (this.staff.valorTotal.gerenteUnidade.maq2 + this.staff.valorTotal.recepcionista.maq2 + this.staff.valorTotal.secretaria.maq2 + this.staff.valorTotal.equipeBackOffice.maq2)) / 1000).toFixed(2)
      this.staff.custoTotal.administrativo.maq3 = (((parseFloat(this.staff.custoTotal.mesesBonus)) * (this.staff.valorTotal.gerenteUnidade.maq3 + this.staff.valorTotal.recepcionista.maq3 + this.staff.valorTotal.secretaria.maq3 + this.staff.valorTotal.equipeBackOffice.maq3)) / 1000).toFixed(2)
      this.staff.custoTotal.administrativo.maq4 = (((parseFloat(this.staff.custoTotal.mesesBonus)) * (this.staff.valorTotal.gerenteUnidade.maq4 + this.staff.valorTotal.recepcionista.maq4 + this.staff.valorTotal.secretaria.maq4 + this.staff.valorTotal.equipeBackOffice.maq4)) / 1000).toFixed(2)
      this.staff.custoTotal.administrativo.maq5 = (((parseFloat(this.staff.custoTotal.mesesBonus)) * (this.staff.valorTotal.gerenteUnidade.maq5 + this.staff.valorTotal.recepcionista.maq5 + this.staff.valorTotal.secretaria.maq5 + this.staff.valorTotal.equipeBackOffice.maq5)) / 1000).toFixed(2)
      this.staff.custoTotal.assistencial.obras = (((parseFloat(this.staff.custoTotal.mesesBonus)) * (this.staff.valorTotal.tecnicoRadioterapia.obras + this.staff.valorTotal.enfermeiros.obras + this.staff.valorTotal.auxiliarSaude.obras)) / 1000).toFixed(2)
      this.staff.custoTotal.assistencial.maq1 = (((parseFloat(this.staff.custoTotal.mesesBonus)) * (this.staff.valorTotal.tecnicoRadioterapia.maq1 + this.staff.valorTotal.enfermeiros.maq1 + this.staff.valorTotal.auxiliarSaude.maq1)) / 1000).toFixed(2)
      this.staff.custoTotal.assistencial.maq2 = (((parseFloat(this.staff.custoTotal.mesesBonus)) * (this.staff.valorTotal.tecnicoRadioterapia.maq2 + this.staff.valorTotal.enfermeiros.maq2 + this.staff.valorTotal.auxiliarSaude.maq2)) / 1000).toFixed(2)
      this.staff.custoTotal.assistencial.maq3 = (((parseFloat(this.staff.custoTotal.mesesBonus)) * (this.staff.valorTotal.tecnicoRadioterapia.maq3 + this.staff.valorTotal.enfermeiros.maq3 + this.staff.valorTotal.auxiliarSaude.maq3)) / 1000).toFixed(2)
      this.staff.custoTotal.assistencial.maq4 = (((parseFloat(this.staff.custoTotal.mesesBonus)) * (this.staff.valorTotal.tecnicoRadioterapia.maq4 + this.staff.valorTotal.enfermeiros.maq4 + this.staff.valorTotal.auxiliarSaude.maq4)) / 1000).toFixed(2)
      this.staff.custoTotal.assistencial.maq5 = (((parseFloat(this.staff.custoTotal.mesesBonus)) * (this.staff.valorTotal.tecnicoRadioterapia.maq5 + this.staff.valorTotal.enfermeiros.maq5 + this.staff.valorTotal.auxiliarSaude.maq5)) / 1000).toFixed(2)
      this.staff.custoTotal.fisicosDosimetristas.obras = (((parseFloat(this.staff.custoTotal.mesesBonus)) * (this.staff.valorTotal.dosimetrista.obras + this.staff.valorTotal.fisicoMedico.obras)) / 1000).toFixed(2)
      this.staff.custoTotal.fisicosDosimetristas.maq1 = (((parseFloat(this.staff.custoTotal.mesesBonus)) * (this.staff.valorTotal.dosimetrista.maq1 + this.staff.valorTotal.fisicoMedico.maq1)) / 1000).toFixed(2)
      this.staff.custoTotal.fisicosDosimetristas.maq2 = (((parseFloat(this.staff.custoTotal.mesesBonus)) * (this.staff.valorTotal.dosimetrista.maq2 + this.staff.valorTotal.fisicoMedico.maq2)) / 1000).toFixed(2)
      this.staff.custoTotal.fisicosDosimetristas.maq3 = (((parseFloat(this.staff.custoTotal.mesesBonus)) * (this.staff.valorTotal.dosimetrista.maq3 + this.staff.valorTotal.fisicoMedico.maq3)) / 1000).toFixed(2)
      this.staff.custoTotal.fisicosDosimetristas.maq4 = (((parseFloat(this.staff.custoTotal.mesesBonus)) * (this.staff.valorTotal.dosimetrista.maq4 + this.staff.valorTotal.fisicoMedico.maq4)) / 1000).toFixed(2)
      this.staff.custoTotal.fisicosDosimetristas.maq5 = (((parseFloat(this.staff.custoTotal.mesesBonus)) * (this.staff.valorTotal.dosimetrista.maq5 + this.staff.valorTotal.fisicoMedico.maq5)) / 1000).toFixed(2)
      this.staff.custoTotal.medicos.obras = ((12 * (this.staff.valorTotal.radioterapeuta.obras + this.staff.valorTotal.coordenadorResponsavel.obras)) / 1000).toFixed(2)
      this.staff.custoTotal.medicos.maq1 = ((12 * (this.staff.valorTotal.radioterapeuta.maq1 + this.staff.valorTotal.coordenadorResponsavel.maq1)) / 1000).toFixed(2)
      this.staff.custoTotal.medicos.maq2 = ((12 * (this.staff.valorTotal.radioterapeuta.maq2 + this.staff.valorTotal.coordenadorResponsavel.maq2)) / 1000).toFixed(2)
      this.staff.custoTotal.medicos.maq3 = ((12 * (this.staff.valorTotal.radioterapeuta.maq3 + this.staff.valorTotal.coordenadorResponsavel.maq3)) / 1000).toFixed(2)
      this.staff.custoTotal.medicos.maq4 = ((12 * (this.staff.valorTotal.radioterapeuta.maq4 + this.staff.valorTotal.coordenadorResponsavel.maq4)) / 1000).toFixed(2)
      this.staff.custoTotal.medicos.maq5 = ((12 * (this.staff.valorTotal.radioterapeuta.maq5 + this.staff.valorTotal.coordenadorResponsavel.maq5)) / 1000).toFixed(2)
      const equipamentoQuantidade = Array(21).fill(0)
      for (let i = 0; i <= 20; i += 1) {
        if (i > 0) {
          if (this.incomings.taxaOcupacaoList.proton[`ano${i}`] && parseFloat(this.incomings.taxaOcupacaoList.proton[`ano${i}`]) && parseFloat(this.dashboard.capex.proton.pacients)) equipamentoQuantidade[i] += 1
          if (this.incomings.taxaOcupacaoList.trueBeam1[`ano${i}`] && parseFloat(this.incomings.taxaOcupacaoList.trueBeam1[`ano${i}`]) && parseFloat(this.dashboard.capex.trueBeam1.pacients)) equipamentoQuantidade[i] += 1
          if (this.incomings.taxaOcupacaoList.trueBeam2[`ano${i}`] && parseFloat(this.incomings.taxaOcupacaoList.trueBeam2[`ano${i}`]) && parseFloat(this.dashboard.capex.trueBeam2.pacients)) equipamentoQuantidade[i] += 1
          if (this.incomings.taxaOcupacaoList.acelerator1[`ano${i}`] && parseFloat(this.incomings.taxaOcupacaoList.acelerator1[`ano${i}`]) && parseFloat(this.dashboard.capex.acelerator1.pacients)) equipamentoQuantidade[i] += 1
          if (this.incomings.taxaOcupacaoList.acelerator2[`ano${i}`] && parseFloat(this.incomings.taxaOcupacaoList.acelerator2[`ano${i}`]) && parseFloat(this.dashboard.capex.acelerator2.pacients)) equipamentoQuantidade[i] += 1
        }
      }
      for (let i = 0; i <= 20; i += 1) {
        let baseAssistencial = 0
        let baseAdministrativo = 0
        let baseMedicos = 0
        let baseFisicosDosimetristas = 0
        if (equipamentoQuantidade[i] === 0) {
          baseAssistencial = parseFloat(this.staff.custoTotal.assistencial.obras)
          baseAdministrativo = parseFloat(this.staff.custoTotal.administrativo.obras)
          baseMedicos = parseFloat(this.staff.custoTotal.medicos.obras)
          baseFisicosDosimetristas = parseFloat(this.staff.custoTotal.fisicosDosimetristas.obras)
        } else if (equipamentoQuantidade[i] === 1) {
          baseAssistencial = parseFloat(this.staff.custoTotal.assistencial.maq1)
          baseAdministrativo = parseFloat(this.staff.custoTotal.administrativo.maq1)
          baseMedicos = parseFloat(this.staff.custoTotal.medicos.maq1)
          baseFisicosDosimetristas = parseFloat(this.staff.custoTotal.fisicosDosimetristas.maq1)
        } else if (equipamentoQuantidade[i] === 2) {
          baseAssistencial = parseFloat(this.staff.custoTotal.assistencial.maq2)
          baseAdministrativo = parseFloat(this.staff.custoTotal.administrativo.maq2)
          baseMedicos = parseFloat(this.staff.custoTotal.medicos.maq2)
          baseFisicosDosimetristas = parseFloat(this.staff.custoTotal.fisicosDosimetristas.maq2)
        } else if (equipamentoQuantidade[i] === 3) {
          baseAssistencial = parseFloat(this.staff.custoTotal.assistencial.maq3)
          baseAdministrativo = parseFloat(this.staff.custoTotal.administrativo.maq3)
          baseMedicos = parseFloat(this.staff.custoTotal.medicos.maq3)
          baseFisicosDosimetristas = parseFloat(this.staff.custoTotal.fisicosDosimetristas.maq3)
        } else if (equipamentoQuantidade[i] === 4) {
          baseAssistencial = parseFloat(this.staff.custoTotal.assistencial.maq4)
          baseAdministrativo = parseFloat(this.staff.custoTotal.administrativo.maq4)
          baseMedicos = parseFloat(this.staff.custoTotal.medicos.maq4)
          baseFisicosDosimetristas = parseFloat(this.staff.custoTotal.fisicosDosimetristas.maq4)
        } else {
          // if (equipamentoQuantidade >= 5)
          baseAssistencial = parseFloat(this.staff.custoTotal.assistencial.maq5)
          baseAdministrativo = parseFloat(this.staff.custoTotal.administrativo.maq5)
          baseMedicos = parseFloat(this.staff.custoTotal.medicos.maq5)
          baseFisicosDosimetristas = parseFloat(this.staff.custoTotal.fisicosDosimetristas.maq5)
        }
        this.incomings.custoPessoalList.administrativo[`ano${i}`] = parseFloat(this.staff.atuacao[i <= 7 ? `ano${i}` : 'ano7'].administrativo / 100) * (baseAdministrativo)
        this.incomings.custoPessoalList.assistencial[`ano${i}`] = parseFloat(this.staff.atuacao[i <= 7 ? `ano${i}` : 'ano7'].assistencial / 100) * (baseAssistencial)
        this.incomings.custoPessoalList.medicos[`ano${i}`] = parseFloat(this.staff.atuacao[i <= 7 ? `ano${i}` : 'ano7'].medicos / 100) * (baseMedicos)
        this.incomings.custoPessoalList.fisicosDosimetristas[`ano${i}`] = parseFloat(this.staff.atuacao[i <= 7 ? `ano${i}` : 'ano7'].fisicosDosimetristas / 100) * (baseFisicosDosimetristas)
      }
    },
    fixTaxaOcupacaoList() {
      const equipments = ['proton', 'trueBeam1', 'trueBeam2', 'acelerator1', 'acelerator2']
      for (let i = 0; i < 5; i += 1) {
        for (let j = 0; j <= 21; j += 1) {
          if (!this.incomings.taxaOcupacaoPercentPerYear) {
            this.incomings.taxaOcupacaoList[equipments[i]][`ano${j}`] = parseFloat(this.incomings.taxaOcupacao)
          } else {
            this.incomings.taxaOcupacaoList[equipments[i]][`ano${j}`] = parseFloat(this.incomings.taxaOcupacao) + (j * ((parseFloat(this.incomings.taxaOcupacao) * parseFloat(this.incomings.taxaOcupacaoPercentPerYear)) / 100))
          }
        }
      }
    },
    updateStaff() {
      this.$store.commit('businessPlan/UPDATE_STAFF', this.staff)
    },
    updateIncomings() {
      this.$store.commit('businessPlan/UPDATE_INCOMINGS', this.incomings)
      this.updateStaff()
    },
    updateExpenses() {
      this.$store.commit('businessPlan/UPDATE_EXPENSES', this.expenses)
      this.updateStaff()
    },
    fixCapacidades() {
      this.incomings.capacidadeAnual = this.incomings.pacients * 12
      if (this.incomings.capacidadeComHipofracionamentoDiasUteis && this.incomings.capacidadeComHipofracionamentoMediaDiasUteisPorTratamento) {
        const fracao = this.incomings.capacidadeComHipofracionamentoDiasUteis / this.incomings.capacidadeComHipofracionamentoMediaDiasUteisPorTratamento
        this.incomings.capacidadeComHipofracionamento = (Math.ceil(this.incomings.capacidadeAnual * (fracao))).toFixed(2)
      } else {
        this.incomings.capacidadeComHipofracionamento = (Math.ceil(this.incomings.capacidadeAnual * (21 / 18.5))).toFixed(2)
      }
    },
  },
}
</script>

<style>
</style>

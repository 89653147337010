<template>
  <div>
    <b-overlay :show="saving">
      <b-card
        footer-class="text-muted"
        body-class="p-0"
      >
        <b-tabs
          v-model="tabIndex"
          pills
          nav-class="mb-3 pt-2 pr-2 pl-2"
          small
          justified
        >
          <b-tab button-id="businesPlan">
            <div slot="title">
              {{ $t('Dashboard.tabs.project') }}
            </div>
            <div class="mx-2">
              <b-row class="my-1">
                <b-col>
                  <b-form-group label="Ano de início do projeto">
                    <b-form-select
                      v-model="dashboard.project.startYear"
                      :options="yearList"
                      placeholder="Selecione"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row class="my-1">
                <b-col>
                  <b-form-group label="Cliente">
                    <b-form-input
                      v-model="dashboard.project.customer"
                      placeholder="Nome do cliente"
                    />
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group label="Estado">
                    <b-form-select
                      v-model="dashboard.project.uf"
                      :options="ufList"
                      placeholder="Selecione"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row class="my-1">
                <b-col>
                  <b-form-group label="Caixa Mínimo">
                    <b-form-input
                      v-model="dashboard.caixa.caixaMinimo"
                      type="number"
                      placeholder="Valor de caixa disponível"
                    />
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group label="Mínimo obrigatório (%)">
                    <b-form-input
                      v-model="dashboard.caixa.minimoObrigatorio"
                      type="number"
                      placeholder="Porcentagem de mínimo obrigatório"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row class="my-1">
                <b-col>
                  <b-form-group label="Captacao (%)">
                    <b-form-input
                      v-model="dashboard.divida.captacaoPercent"
                      type="number"
                      placeholder="Percentual de captacao"
                    />
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group label="Ano(s) de endividamento">
                    <b-form-input
                      v-model="dashboard.divida.divitaTotalAnos"
                      type="number"
                      placeholder="Ano(s)"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row class="my-1">
                <b-col>
                  <b-form-group label="Anos de divida do capital de giro">
                    <b-form-input
                      v-model="dashboard.divida.dividaCapitalGiroAnos"
                      type="number"
                      placeholder="Ano(s)"
                    />
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group label="Acrual de juros (%)">
                    <b-form-input
                      v-model="dashboard.divida.acrualJurosPercent"
                      type="number"
                      placeholder="Porcentagem"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row class="my-1">
                <b-col>
                  <b-form-group label="Detalhes do projeto">
                    <b-form-textarea
                      v-model="dashboard.project.details"
                      placeholder="Exemplo de análise de viabilidade"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row class="my-1">
                <b-col>
                  <b-form-group label="TMA">
                    <b-input-group append="%">
                      <b-form-input
                        v-model="dashboard.project.tma"
                        type="number"
                        placeholder="Defina a taxa mínima"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <b-form-group label="Perpetuidade">
                    <b-input-group append="%">
                      <b-form-input
                        v-model="dashboard.project.perp"
                        type="number"
                        placeholder="Defina a perpetuidade"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row class="my-1">
                <b-col>
                  <b-form-group label="TIR">
                    <b-input-group append="%">
                      <b-form-input
                        v-model="dashboard.project.tir"
                        type="number"
                        placeholder="Taxa Interna de Retorno"
                        :disabled="true"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <b-form-group label="VPL">
                    <b-form-input
                      v-model="dashboard.project.vpl"
                      type="number"
                      placeholder="Valor presente líquido"
                      :disabled="true"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <b-form-group label="Payback (meses)">
                    <b-form-input
                      v-model="dashboard.project.payback"
                      type="number"
                      placeholder="Tempo em meses para pagar o investimento"
                      :disabled="true"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
            </div>
          </b-tab>
          <b-tab button-id="premisses">
            <div slot="title">
              {{ $t('Dashboard.tabs.capex') }}
            </div>
            <div class="mx-2">
              <h4>Equipamentos e Manutenção e Pagamentos</h4>
              <b-row class="my-1">
                <b-col>
                  <b-form-group label="Custo">
                    <b-input-group prepend="Proton">
                      <b-form-input
                        v-model="dashboard.capex.proton.cost"
                        type="number"
                        placeholder="Custo de aquisição do Proton"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <b-form-group label="Manutenção">
                    <b-input-group prepend="Proton">
                      <b-form-input
                        v-model="dashboard.capex.proton.support"
                        type="number"
                        placeholder="Custo de manutenção do Proton"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <b-form-group label="Ano de pagamento">
                    <b-input-group prepend="Proton">
                      <b-form-select
                        v-model="dashboard.capex.proton.year"
                        :options="equipmentYears"
                        placeholder="Ano de pagamento"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row class="my-1">
                <b-col>
                  <b-form-group label="Custo">
                    <b-input-group prepend="True Beam1">
                      <b-form-input
                        v-model="dashboard.capex.trueBeam1.cost"
                        type="number"
                        placeholder="Custo de aquisição do True Beam1"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <b-form-group label="Manutenção">
                    <b-input-group prepend="True Beam 1">
                      <b-form-input
                        v-model="dashboard.capex.trueBeam1.support"
                        type="number"
                        placeholder="Custo de manutenção do True Beam 1"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <b-form-group label="Ano de pagamento">
                    <b-input-group prepend="True Beam 1">
                      <b-form-select
                        v-model="dashboard.capex.trueBeam1.year"
                        :options="equipmentYears"
                        placeholder="Ano de pagamento"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row class="my-1">
                <b-col>
                  <b-form-group label="Custo">
                    <b-input-group prepend="True Beam 2">
                      <b-form-input
                        v-model="dashboard.capex.trueBeam2.cost"
                        type="number"
                        placeholder="Custo de aquisição do True Beam 2"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <b-form-group label="Manutenção">
                    <b-input-group prepend="True Beam 2">
                      <b-form-input
                        v-model="dashboard.capex.trueBeam2.support"
                        type="number"
                        placeholder="Custo de manutenção do True Beam 2"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <b-form-group label="Ano de pagamento">
                    <b-input-group prepend="True Beam 2">
                      <b-form-select
                        v-model="dashboard.capex.trueBeam2.year"
                        :options="equipmentYears"
                        placeholder="Ano de pagamento"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row class="my-1">
                <b-col>
                  <b-form-group label="Custo">
                    <b-input-group prepend="Acelerador 1">
                      <b-form-input
                        v-model="dashboard.capex.acelerator1.cost"
                        type="number"
                        placeholder="Custo de aquisição do Acelerador 1"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <b-form-group label="Manutenção">
                    <b-input-group prepend="Acelerador 1">
                      <b-form-input
                        v-model="dashboard.capex.acelerator1.support"
                        type="number"
                        placeholder="Custo de manutenção do Acelerador 1"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <b-form-group label="Ano de pagamento">
                    <b-input-group prepend="Acelerador 1">
                      <b-form-select
                        v-model="dashboard.capex.acelerator1.year"
                        :options="equipmentYears"
                        placeholder="Ano de pagamento"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row class="my-1">
                <b-col>
                  <b-form-group label="Custo">
                    <b-input-group prepend="Acelerador 2">
                      <b-form-input
                        v-model="dashboard.capex.acelerator2.cost"
                        type="number"
                        placeholder="Custo de aquisição do Acelerador 2"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <b-form-group label="Manutenção">
                    <b-input-group prepend="Acelerador 2">
                      <b-form-input
                        v-model="dashboard.capex.acelerator2.support"
                        type="number"
                        placeholder="Custo de manutenção do Acelerador 2"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <b-form-group label="Ano de pagamento">
                    <b-input-group prepend="Acelerador 2">
                      <b-form-select
                        v-model="dashboard.capex.acelerator2.year"
                        :options="equipmentYears"
                        placeholder="Ano de pagamento"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row class="my-1">
                <b-col>
                  <b-form-group label="Custo">
                    <b-input-group prepend="Outros equipamentos">
                      <b-form-input
                        v-model="dashboard.capex.otherEquipments.cost"
                        type="number"
                        placeholder="Custo de aquisição do Outros equipamentos"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <b-form-group label="Manutenção">
                    <b-input-group prepend="Outros equipamentos">
                      <b-form-input
                        v-model="dashboard.capex.otherEquipments.support"
                        type="number"
                        placeholder="Custo de manutenção do Outros equipamentos"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <b-form-group label="Ano de pagamento">
                    <b-input-group prepend="Outros equipamentos">
                      <b-form-select
                        v-model="dashboard.capex.otherEquipments.year"
                        :options="equipmentYears"
                        placeholder="Ano de pagamento"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row class="my-1">
                <b-col>
                  <b-form-group label="Custo">
                    <b-input-group prepend="Obras e estrutura">
                      <b-form-input
                        v-model="dashboard.capex.handleAndStructure.cost"
                        type="number"
                        placeholder="Custo de aquisição do Obras e estrutura"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <b-form-group label="Manutenção">
                    <b-input-group prepend="Obras e estrutura">
                      <b-form-input
                        v-model="dashboard.capex.handleAndStructure.support"
                        type="number"
                        placeholder="Custo de manutenção do Obras e estrutura"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <b-form-group label="Ano de pagamento">
                    <b-input-group prepend="Obras e estrutura">
                      <b-form-select
                        v-model="dashboard.capex.handleAndStructure.year"
                        :options="equipmentYears"
                        placeholder="Ano de pagamento"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row class="my-1">
                <b-col>
                  <b-form-group label="Custo">
                    <b-input-group prepend="Mobiliario">
                      <b-form-input
                        v-model="dashboard.capex.mobiliario.cost"
                        type="number"
                        placeholder="Custo de aquisição do mobiliario"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <b-form-group label="Manutenção">
                    <b-input-group prepend="Mobiliario">
                      <b-form-input
                        v-model="dashboard.capex.mobiliario.support"
                        type="number"
                        placeholder="Custo de manutenção do mobiliario"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <b-form-group label="Ano de pagamento">
                    <b-input-group prepend="Mobiliario">
                      <b-form-select
                        v-model="dashboard.capex.mobiliario.year"
                        :options="equipmentYears"
                        placeholder="Ano de pagamento"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row class="my-1">
                <b-col>
                  <b-form-group label="Custo">
                    <b-input-group prepend="Upgrade">
                      <b-form-input
                        v-model="dashboard.capex.upgrade.cost"
                        type="number"
                        placeholder="Custo de aquisição do upgrade"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <b-form-group label="Manutenção">
                    <b-input-group prepend="Upgrade">
                      <b-form-input
                        v-model="dashboard.capex.upgrade.support"
                        type="number"
                        placeholder="Custo de manutenção do upgrade"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <b-form-group label="Ano de pagamento">
                    <b-input-group prepend="Upgrade">
                      <b-form-select
                        v-model="dashboard.capex.upgrade.year"
                        :options="equipmentYears"
                        placeholder="Ano de pagamento"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
              </b-row>
              <h4>Impostos e custo de importação</h4>
              <b-row class="my-1">
                <b-col>
                  <b-form-group label="">
                    <b-input-group
                      prepend="ICMS"
                      append="%"
                    >
                      <b-form-input
                        v-model="dashboard.capex.tax.icms"
                        type="number"
                        placeholder="ICMS"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <b-form-group label="">
                    <b-input-group
                      prepend="Seguro"
                      append="%"
                    >
                      <b-form-input
                        v-model="dashboard.capex.importTax.seguro"
                        type="number"
                        placeholder="Seguro"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row class="my-1">
                <b-col>
                  <b-form-group label="">
                    <b-input-group
                      prepend="pis"
                      append="%"
                    >
                      <b-form-input
                        v-model="dashboard.capex.tax.pis"
                        type="number"
                        placeholder="pis"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <b-form-group label="">
                    <b-input-group
                      prepend="frete"
                      append="%"
                    >
                      <b-form-input
                        v-model="dashboard.capex.importTax.frete"
                        type="number"
                        placeholder="frete"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row class="my-1">
                <b-col>
                  <b-form-group label="">
                    <b-input-group
                      prepend="cofins"
                      append="%"
                    >
                      <b-form-input
                        v-model="dashboard.capex.tax.cofins"
                        type="number"
                        placeholder="cofins"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <b-form-group label="">
                    <b-input-group
                      prepend="taxas"
                      append="%"
                    >
                      <b-form-input
                        v-model="dashboard.capex.importTax.taxas"
                        type="number"
                        placeholder="taxas"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row class="my-1">
                <b-col>
                  <b-form-group label="">
                    <b-input-group
                      prepend="ipi"
                      append="%"
                    >
                      <b-form-input
                        v-model="dashboard.capex.tax.ipi"
                        type="number"
                        placeholder="ipi"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <b-form-group label="">
                    <b-input-group
                      prepend="portos e armazenamento"
                      append="%"
                    >
                      <b-form-input
                        v-model="dashboard.capex.importTax.portosArmazenamento"
                        type="number"
                        placeholder="portos e armazenamento"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row class="my-1">
                <b-col>
                  <b-form-group label="">
                    <b-input-group
                      prepend="outros"
                      append="%"
                    >
                      <b-form-input
                        v-model="dashboard.capex.tax.outros"
                        type="number"
                        placeholder="outros"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <b-form-group label="">
                    <b-input-group
                      prepend="acessoria"
                      append="%"
                    >
                      <b-form-input
                        v-model="dashboard.capex.importTax.acessoria"
                        type="number"
                        placeholder="acessoria"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row class="my-1">
                <b-col>
                  <b-form-group label="">
                    <b-input-group
                      prepend="transporte"
                      append="%"
                    >
                      <b-form-input
                        v-model="dashboard.capex.importTax.transporte"
                        type="number"
                        placeholder="transporte"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row class="my-1">
                <b-col>
                  <b-form-group label="">
                    <b-input-group
                      prepend="desembaraço"
                      append="%"
                    >
                      <b-form-input
                        v-model="dashboard.capex.importTax.desembaraco"
                        type="number"
                        placeholder="desembaraço"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
              </b-row>
              <h4>Outros equipamentos</h4>
              <b-row class="my-1">
                <b-col>
                  <b-form-group label="">
                    <b-input-group
                      prepend="QA + Dosimetria"
                    >
                      <b-form-input
                        v-model="dashboard.capex.anotherEquipments.dosimetria"
                        type="number"
                        placeholder="QA + Dosimetria"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <b-form-group label="">
                    <b-input-group
                      prepend="Acessório Imobilização"
                    >
                      <b-form-input
                        v-model="dashboard.capex.anotherEquipments.acessorioImobilizacao"
                        type="number"
                        placeholder="Acessório Imobilização"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <b-form-group label="">
                    <b-input-group
                      prepend="Software"
                    >
                      <b-form-input
                        v-model="dashboard.capex.anotherEquipments.software"
                        type="number"
                        placeholder="Software"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row class="my-1">
                <b-col>
                  <b-form-group label="">
                    <b-input-group
                      prepend="Ressonância magnética"
                    >
                      <b-form-input
                        v-model="dashboard.capex.anotherEquipments.ressonanciaMagnetica"
                        type="number"
                        placeholder="Ressonância magnética"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <b-form-group label="">
                    <b-input-group
                      prepend="Tomografia"
                    >
                      <b-form-input
                        v-model="dashboard.capex.anotherEquipments.tomografia"
                        type="number"
                        placeholder="Tomografia"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <b-form-group label="">
                    <b-input-group
                      prepend="Outros"
                    >
                      <b-form-input
                        v-model="dashboard.capex.anotherEquipments.outros"
                        type="number"
                        placeholder="Outros"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
              </b-row>
              <h4>Obras e estrutura</h4>
              <b-row class="my-1">
                <b-col>
                  <b-form-group label="">
                    <b-input-group
                      prepend="obras civil"
                    >
                      <b-form-input
                        v-model="dashboard.capex.obras.civil"
                        type="number"
                        placeholder="obras civil"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <b-form-group label="">
                    <b-input-group
                      prepend="mobiliario"
                    >
                      <b-form-input
                        v-model="dashboard.capex.obras.mobiliario"
                        type="number"
                        placeholder="mobiliario"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
              </b-row>
              <h4>Outros</h4>
              <b-row class="my-1">
                <b-col>
                  <b-form-group label="">
                    <b-input-group prepend="Reserva Tecnica">
                      <b-form-input
                        v-model="dashboard.capex.others.reservaTecnica"
                        type="number"
                        placeholder="Reserva Tecnica"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
              </b-row>
            </div>
          </b-tab>
          <b-tab button-id="personAndMedicalCost">
            <div slot="title">
              {{ $t('Dashboard.tabs.workingCapital') }}
            </div>
            <div class="mx-2">
              <h4>Contas a pagar</h4>
              <b-row class="my-1">
                <b-col>
                  <b-form-group label="">
                    <b-input-group
                      prepend="Administrativo"
                      append="dias"
                    >
                      <b-form-input
                        v-model="dashboard.capex.bills.administrativo"
                        type="number"
                        placeholder="Dias"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <b-form-group label="">
                    <b-input-group
                      prepend="manutenção"
                      append="dias"
                    >
                      <b-form-input
                        v-model="dashboard.capex.bills.manutencao"
                        type="number"
                        placeholder="Dias"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <b-form-group label="">
                    <b-input-group
                      prepend="aluguel"
                      append="dias"
                    >
                      <b-form-input
                        v-model="dashboard.capex.bills.aluguel"
                        type="number"
                        placeholder="Dias"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row class="my-1">
                <b-col>
                  <b-form-group label="">
                    <b-input-group
                      prepend="csc"
                      append="dias"
                    >
                      <b-form-input
                        v-model="dashboard.capex.bills.csc"
                        type="number"
                        placeholder="Dias"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <b-form-group label="">
                    <b-input-group
                      prepend="obrigacoes sociais"
                      append="dias"
                    >
                      <b-form-input
                        v-model="dashboard.capex.bills.obrigacoesSociais"
                        type="number"
                        placeholder="Dias"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <b-form-group label="">
                    <b-input-group
                      prepend="Obricacoes Tributarias"
                      append="dias"
                    >
                      <b-form-input
                        v-model="dashboard.capex.bills.obricacoesTributarias"
                        type="number"
                        placeholder="Dias"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row class="my-1">
                <b-col>
                  <b-form-group label="">
                    <b-input-group
                      prepend="IRL/CSLL"
                      append="dias"
                    >
                      <b-form-input
                        v-model="dashboard.capex.bills.irlCsll"
                        type="number"
                        placeholder="Dias"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
              </b-row>
              <h4>Contas a receber</h4>
              <b-row class="my-1">
                <b-col>
                  <b-form-group label="">
                    <b-input-group
                      prepend="Dias"
                      append="dias"
                    >
                      <b-form-input
                        v-model="dashboard.capex.bills.dias"
                        type="number"
                        placeholder="Dias"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
              </b-row>
              <h4>Aporte de capital</h4>
              <b-row class="my-1">
                <b-col>
                  <h6 class="mt-1">
                    Sócio 1
                  </h6>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <b-form-group label="ano 1">
                    <b-input-group
                      prepend="U$D"
                    >
                      <b-form-input
                        v-model="dashboard.capex.bills.aporteCapital.socio1.ano1"
                        type="number"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <b-form-group label="ano 2">
                    <b-input-group
                      prepend="U$D"
                    >
                      <b-form-input
                        v-model="dashboard.capex.bills.aporteCapital.socio1.ano2"
                        type="number"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <b-form-group label="ano 3">
                    <b-input-group
                      prepend="U$D"
                    >
                      <b-form-input
                        v-model="dashboard.capex.bills.aporteCapital.socio1.ano3"
                        type="number"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <b-form-group label="ano 4">
                    <b-input-group
                      prepend="U$D"
                    >
                      <b-form-input
                        v-model="dashboard.capex.bills.aporteCapital.socio1.ano4"
                        type="number"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <b-form-group label="ano 5">
                    <b-input-group
                      prepend="U$D"
                    >
                      <b-form-input
                        v-model="dashboard.capex.bills.aporteCapital.socio1.ano5"
                        type="number"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row class="my-1">
                <b-col>
                  <h6 class="mt-1">
                    Sócio 2
                  </h6>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <b-form-group label="ano 1">
                    <b-input-group
                      prepend="U$D"
                    >
                      <b-form-input
                        v-model="dashboard.capex.bills.aporteCapital.socio2.ano1"
                        type="number"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <b-form-group label="ano 2">
                    <b-input-group
                      prepend="U$D"
                    >
                      <b-form-input
                        v-model="dashboard.capex.bills.aporteCapital.socio2.ano2"
                        type="number"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <b-form-group label="ano 3">
                    <b-input-group
                      prepend="U$D"
                    >
                      <b-form-input
                        v-model="dashboard.capex.bills.aporteCapital.socio2.ano3"
                        type="number"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <b-form-group label="ano 4">
                    <b-input-group
                      prepend="U$D"
                    >
                      <b-form-input
                        v-model="dashboard.capex.bills.aporteCapital.socio2.ano4"
                        type="number"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <b-form-group label="ano 5">
                    <b-input-group
                      prepend="U$D"
                    >
                      <b-form-input
                        v-model="dashboard.capex.bills.aporteCapital.socio2.ano5"
                        type="number"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row class="my-1">
                <b-col>
                  <h6 class="mt-1">
                    Sócio 3
                  </h6>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <b-form-group label="ano 1">
                    <b-input-group
                      prepend="U$D"
                    >
                      <b-form-input
                        v-model="dashboard.capex.bills.aporteCapital.socio3.ano1"
                        type="number"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <b-form-group label="ano 2">
                    <b-input-group
                      prepend="U$D"
                    >
                      <b-form-input
                        v-model="dashboard.capex.bills.aporteCapital.socio3.ano2"
                        type="number"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <b-form-group label="ano 3">
                    <b-input-group
                      prepend="U$D"
                    >
                      <b-form-input
                        v-model="dashboard.capex.bills.aporteCapital.socio3.ano3"
                        type="number"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <b-form-group label="ano 4">
                    <b-input-group
                      prepend="U$D"
                    >
                      <b-form-input
                        v-model="dashboard.capex.bills.aporteCapital.socio3.ano4"
                        type="number"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <b-form-group label="ano 5">
                    <b-input-group
                      prepend="U$D"
                    >
                      <b-form-input
                        v-model="dashboard.capex.bills.aporteCapital.socio3.ano5"
                        type="number"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row class="my-1">
                <b-col>
                  <h6 class="mt-1">
                    Sócio 4
                  </h6>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <b-form-group label="ano 1">
                    <b-input-group
                      prepend="U$D"
                    >
                      <b-form-input
                        v-model="dashboard.capex.bills.aporteCapital.socio4.ano1"
                        type="number"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <b-form-group label="ano 2">
                    <b-input-group
                      prepend="U$D"
                    >
                      <b-form-input
                        v-model="dashboard.capex.bills.aporteCapital.socio4.ano2"
                        type="number"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <b-form-group label="ano 3">
                    <b-input-group
                      prepend="U$D"
                    >
                      <b-form-input
                        v-model="dashboard.capex.bills.aporteCapital.socio4.ano3"
                        type="number"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <b-form-group label="ano 4">
                    <b-input-group
                      prepend="U$D"
                    >
                      <b-form-input
                        v-model="dashboard.capex.bills.aporteCapital.socio4.ano4"
                        type="number"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <b-form-group label="ano 5">
                    <b-input-group
                      prepend="U$D"
                    >
                      <b-form-input
                        v-model="dashboard.capex.bills.aporteCapital.socio4.ano5"
                        type="number"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row class="my-1">
                <b-col>
                  <h6 class="mt-1">
                    Sócio 5
                  </h6>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <b-form-group label="ano 1">
                    <b-input-group
                      prepend="U$D"
                    >
                      <b-form-input
                        v-model="dashboard.capex.bills.aporteCapital.socio5.ano1"
                        type="number"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <b-form-group label="ano 2">
                    <b-input-group
                      prepend="U$D"
                    >
                      <b-form-input
                        v-model="dashboard.capex.bills.aporteCapital.socio5.ano2"
                        type="number"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <b-form-group label="ano 3">
                    <b-input-group
                      prepend="U$D"
                    >
                      <b-form-input
                        v-model="dashboard.capex.bills.aporteCapital.socio5.ano3"
                        type="number"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <b-form-group label="ano 4">
                    <b-input-group
                      prepend="U$D"
                    >
                      <b-form-input
                        v-model="dashboard.capex.bills.aporteCapital.socio5.ano4"
                        type="number"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <b-form-group label="ano 5">
                    <b-input-group
                      prepend="U$D"
                    >
                      <b-form-input
                        v-model="dashboard.capex.bills.aporteCapital.socio5.ano5"
                        type="number"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
              </b-row>
            </div>
          </b-tab>
          <b-tab button-id="ticket">
            <div slot="title">
              {{ $t('Dashboard.tabs.equipmentRegister') }}
            </div>
            <div class="mx-2">
              <h4>Pacientes, ticket e depreciação</h4>
              <b-row class="my-1">
                <b-col>
                  <b-form-group label="Pacientes">
                    <b-input-group prepend="Proton">
                      <b-form-input
                        v-model="dashboard.capex.proton.pacients"
                        type="number"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <b-form-group label="Ticket">
                    <b-input-group
                      prepend="Proton"
                      append="BRL"
                    >
                      <b-form-input
                        v-model="dashboard.capex.proton.ticket"
                        type="number"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <b-form-group label="Depreciação">
                    <b-input-group
                      prepend="Proton"
                      append="%"
                    >
                      <b-form-input
                        v-model="dashboard.capex.proton.depreciacao"
                        type="number"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row class="my-1">
                <b-col>
                  <b-form-group label="Pacientes">
                    <b-input-group prepend="True Beam 1">
                      <b-form-input
                        v-model="dashboard.capex.trueBeam1.pacients"
                        type="number"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <b-form-group label="Ticket">
                    <b-input-group
                      prepend="True Beam 1"
                      append="BRL"
                    >
                      <b-form-input
                        v-model="dashboard.capex.trueBeam1.ticket"
                        type="number"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <b-form-group label="Depreciação">
                    <b-input-group
                      prepend="True Beam 1"
                      append="%"
                    >
                      <b-form-input
                        v-model="dashboard.capex.trueBeam1.depreciacao"
                        type="number"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row class="my-1">
                <b-col>
                  <b-form-group label="Pacientes">
                    <b-input-group prepend="True Beam 2">
                      <b-form-input
                        v-model="dashboard.capex.trueBeam2.pacients"
                        type="number"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <b-form-group label="Ticket">
                    <b-input-group
                      prepend="True Beam 2"
                      append="BRL"
                    >
                      <b-form-input
                        v-model="dashboard.capex.trueBeam2.ticket"
                        type="number"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <b-form-group label="Depreciação">
                    <b-input-group
                      prepend="True Beam 2"
                      append="%"
                    >
                      <b-form-input
                        v-model="dashboard.capex.trueBeam2.depreciacao"
                        type="number"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row class="my-1">
                <b-col>
                  <b-form-group label="Pacientes">
                    <b-input-group prepend="Acelerador 1">
                      <b-form-input
                        v-model="dashboard.capex.acelerator1.pacients"
                        type="number"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <b-form-group label="Ticket">
                    <b-input-group
                      prepend="Acelerador 1"
                      append="BRL"
                    >
                      <b-form-input
                        v-model="dashboard.capex.acelerator1.ticket"
                        type="number"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <b-form-group label="Depreciação">
                    <b-input-group
                      prepend="Acelerador 1"
                      append="%"
                    >
                      <b-form-input
                        v-model="dashboard.capex.acelerator1.depreciacao"
                        type="number"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row class="my-1">
                <b-col>
                  <b-form-group label="Pacientes">
                    <b-input-group prepend="Acelerador 2">
                      <b-form-input
                        v-model="dashboard.capex.acelerator2.pacients"
                        type="number"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <b-form-group label="Ticket">
                    <b-input-group
                      prepend="Acelerador 2"
                      append="BRL"
                    >
                      <b-form-input
                        v-model="dashboard.capex.acelerator2.ticket"
                        type="number"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <b-form-group label="Depreciação">
                    <b-input-group
                      prepend="Acelerador 2"
                      append="%"
                    >
                      <b-form-input
                        v-model="dashboard.capex.acelerator2.depreciacao"
                        type="number"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row class="my-1">
                <b-col>
                  <b-form-group label="Ticket">
                    <b-input-group
                      prepend="Ressonancia"
                      append="BRL"
                    >
                      <b-form-input
                        v-model="dashboard.capex.ressonancia.ticket"
                        type="number"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row class="my-1">
                <b-col>
                  <b-form-group label="Ticket">
                    <b-input-group
                      prepend="Tomografo"
                      append="BRL"
                    >
                      <b-form-input
                        v-model="dashboard.capex.tomografo.ticket"
                        type="number"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row class="my-1">
                <b-col>
                  <b-form-group label="Depreciação">
                    <b-input-group
                      prepend="Outros equipamentos"
                      append="%"
                    >
                      <b-form-input
                        v-model="dashboard.capex.otherEquipments.depreciacao"
                        type="number"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row class="my-1">
                <b-col>
                  <b-form-group label="Depreciação">
                    <b-input-group
                      prepend="Obras e estruturas"
                      append="%"
                    >
                      <b-form-input
                        v-model="dashboard.capex.handleAndStructure.depreciacao"
                        type="number"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row class="my-1">
                <b-col>
                  <b-form-group label="Depreciação">
                    <b-input-group
                      prepend="Manutencao"
                      append="%"
                    >
                      <b-form-input
                        v-model="dashboard.capex.manutencao.depreciacao"
                        type="number"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row class="my-1">
                <b-col>
                  <b-form-group label="Depreciação">
                    <b-input-group
                      prepend="Mobiliário"
                      append="%"
                    >
                      <b-form-input
                        v-model="dashboard.capex.mobiliario.depreciacao"
                        type="number"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row class="my-1">
                <b-col>
                  <b-form-group label="Depreciação">
                    <b-input-group
                      prepend="Upgrade"
                      append="%"
                    >
                      <b-form-input
                        v-model="dashboard.capex.upgrade.depreciacao"
                        type="number"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
              </b-row>
            </div>
          </b-tab>
        </b-tabs>
      </b-card>
    </b-overlay>
  </div>
</template>

<script>
import {
  BTabs,
  BTab,
  BCard,
  BOverlay,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BFormSelect,
  BInputGroup,
} from 'bootstrap-vue'

export default {
  name: 'Dashboard',
  components: {
    BRow,
    BCol,
    BTabs,
    BTab,
    BCard,
    BOverlay,
    BFormInput,
    BFormTextarea,
    BFormGroup,
    BFormSelect,
    BInputGroup,
  },
  data() {
    return {
      saving: false,
      tabIndex: 0,
      equipmentYears: [
        { text: 0, value: 0 },
        { text: 1, value: 1 },
        { text: 2, value: 2 },
        { text: 3, value: 3 },
        { text: 4, value: 4 },
        { text: 5, value: 5 },
        { text: 6, value: 6 },
        { text: 7, value: 7 },
        { text: 8, value: 8 },
        { text: 9, value: 9 },
        { text: 10, value: 10 },
        { text: 11, value: 11 },
        { text: 12, value: 12 },
        { text: 13, value: 13 },
        { text: 14, value: 14 },
      ],
      ufList: [
        { text: 'AC', value: 'AC' },
        { text: 'AL', value: 'AL' },
        { text: 'AM', value: 'AM' },
        { text: 'AP', value: 'AP' },
        { text: 'BA', value: 'BA' },
        { text: 'CE', value: 'CE' },
        { text: 'DF', value: 'DF' },
        { text: 'ES', value: 'ES' },
        { text: 'GO', value: 'GO' },
        { text: 'MA', value: 'MA' },
        { text: 'MG', value: 'MG' },
        { text: 'MT', value: 'MT' },
        { text: 'MS', value: 'MS' },
        { text: 'PA', value: 'PA' },
        { text: 'PB', value: 'PB' },
        { text: 'PE', value: 'PE' },
        { text: 'PI', value: 'PI' },
        { text: 'PR', value: 'PR' },
        { text: 'RJ', value: 'RJ' },
        { text: 'RN', value: 'RN' },
        { text: 'RO', value: 'RO' },
        { text: 'RR', value: 'RR' },
        { text: 'RS', value: 'RS' },
        { text: 'SC', value: 'SC' },
        { text: 'SE', value: 'SE' },
        { text: 'SP', value: 'SP' },
        { text: 'TO', value: 'TO' },
      ],
      dashboard: {
        caixa: {
          caixaMinimo: 4000,
          minimoObrigatorio: 100,
        },
        divida: {
          captacaoPercent: 50,
          divitaTotalAnos: 6,
          dividaCapitalGiroAnos: 4,
          acrualJurosPercent: 1,
        },
        project: {
          customer: null,
          uf: null,
          details: null,
          startYear: null,
          tma: 16,
          perp: null,
          tir: null,
          vpl: null,
          payback: null,
        },
        capex: {
          bills: {
            administrativo: 15,
            manutencao: 15,
            aluguel: 15,
            csc: 15,
            obrigacoesSociais: 0,
            obricacoesTributarias: 0,
            irlCsll: 0,
            dias: 60,
            aporteCapital: {
              socio1: {
                ano1: 1,
                ano2: 0,
                ano3: 0,
                ano4: 0,
                ano5: 0,
              },
              socio2: {
                ano1: 1,
                ano2: 0,
                ano3: 0,
                ano4: 0,
                ano5: 0,
              },
              socio3: {
                ano1: 0,
                ano2: 0,
                ano3: 0,
                ano4: 0,
                ano5: 0,
              },
              socio4: {
                ano1: 0,
                ano2: 0,
                ano3: 0,
                ano4: 0,
                ano5: 0,
              },
              socio5: {
                ano1: 0,
                ano2: 0,
                ano3: 0,
                ano4: 0,
                ano5: 0,
              },
            },
          },
          proton: {
            cost: 180,
            support: 150,
            year: 1,
            pacients: 80,
            ticket: 1850,
            depreciacao: 10,
          },
          trueBeam1: {
            cost: 1400,
            support: 150,
            year: 1,
            pacients: 80,
            ticket: 1390,
            depreciacao: 10,
          },
          trueBeam2: {
            cost: null,
            support: 0,
            year: null,
            pacients: null,
            ticket: null,
            depreciacao: 10,
          },
          acelerator1: {
            cost: null,
            support: 0,
            year: null,
            pacients: null,
            ticket: null,
            depreciacao: 10,
          },
          acelerator2: {
            cost: null,
            support: 0,
            year: null,
            pacients: null,
            ticket: null,
            depreciacao: 10,
          },
          ressonancia: {
            cost: null,
            support: 0,
            year: null,
            pacients: null,
            ticket: null,
            depreciacao: null,
          },
          tomografo: {
            cost: null,
            support: 0,
            year: null,
            pacients: null,
            ticket: null,
            depreciacao: null,
          },
          otherEquipments: {
            cost: 0,
            support: 0,
            year: 1,
            pacients: null,
            ticket: null,
            depreciacao: 10,
          },
          mobiliario: {
            cost: 4,
            support: 5,
            year: 1,
            pacients: null,
            ticket: null,
            depreciacao: null,
          },
          handleAndStructure: {
            cost: 9,
            support: 10,
            year: 1,
            pacients: null,
            ticket: null,
            depreciacao: 4,
          },
          manutencao: {
            cost: 9,
            support: 10,
            year: 1,
            pacients: null,
            ticket: null,
            depreciacao: 5,
          },
          upgrade: {
            cost: 9,
            support: 10,
            year: 1,
            pacients: null,
            ticket: null,
            depreciacao: null,
          },
          tax: {
            icms: 18,
            pis: 2.1,
            cofins: 10.7,
            ipi: 5,
            outros: 0,
          },
          importTax: {
            seguro: 2,
            frete: 3,
            taxas: 0.5,
            portosArmazenamento: 2,
            acessoria: 1.5,
            transporte: 1,
            desembaraco: 1,
          },
          obras: {
            civil: 5000,
            mobiliario: 1500,
          },
          others: { reservaTecnica: 5 },
          anotherEquipments: {
            dosimetria: 100,
            acessorioImobilizacao: 100,
            software: 100,
            ressonanciaMagnetica: 0,
            tomografia: 0,
            outros: 0,
          },
        },
      },
    }
  },
  computed: {
    yearList() {
      const currentYear = new Date().getFullYear()
      return Array.from({ length: 20 }, (v, k) => k + currentYear)
    },
  },
  watch: {
    dashboard: {
      deep: true,
      handler() { this.updateDashboard() },
    },
  },
  mounted() {
    this.dashboard.project.startYear = new Date().getFullYear()
    this.updateDashboard()
  },
  methods: {
    updateDashboard() { this.$store.commit('businessPlan/UPDATE_DASHBOARD', this.dashboard) },
  },
}
</script>

<style>
</style>
